import React, { useEffect, useState } from 'react';
import Tooltip from 'components/Tooltip';
import { getStringSegments, TaskStatusOptions, trimText } from 'utils/Utils';
import moment from 'moment';
import { Loader } from 'components/Svg';
import { useNavigate } from 'react-router-dom';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';

const TasksBox = ({ tasks, overviewLoadings }) => {
  const navigate = useNavigate();
  const { accessType, permissions } = useSelector((state) => state.User);

  const [descriptionLength, setDescriptionLength] = useState(20);

  const handleResize = () => {
    const descriptionCell = document.getElementById('taskCellId0');
    if (descriptionCell) {
      setDescriptionLength(parseInt((descriptionCell?.clientWidth - 2) / 10));
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [tasks]);

  return (
    <div className="p-6 border border-[#D0D5DD] rounded-[12px]">
      <h3 className="text-[22px] font-medium text-[#666] mb-4">
        Upcoming Tasks
      </h3>
      {overviewLoadings?.tasks ? (
        <div className="flex flex-col h-[180px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : (
        <>
          {tasks?.length > 0 ? (
            <div className="overflow-auto scrollbar" id="insightsOverviewTasks">
              <table className="w-full table-auto">
                <tbody className="divide-y divide-[#D0D5DD]">
                  {tasks?.map((task, i) => (
                    <tr key={task?.id} className="">
                      <td
                        className="py-3 pr-2 min-w-[10rem]"
                        id={`taskCellId${i}`}
                      >
                        <div>
                          <Tooltip
                            tooltipShow={task?.name?.length > descriptionLength}
                            content={
                              task?.name?.length > 30
                                ? (() => {
                                    const segments = getStringSegments(
                                      task?.name,
                                      60,
                                    );
                                    return segments.map((segment, index) => (
                                      <p key={index}>{segment}</p>
                                    ));
                                  })()
                                : task?.name
                            }
                            contentClassName="border-none rounded-[10px] translate-y-1 overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            isFixed
                            ids={['insightsOverviewTasks', 'insightsPage']}
                          >
                            <h4 className="text-[18px] my-0 leading-normal text-[#667085]">
                              {trimText(task?.name, descriptionLength)}
                            </h4>
                          </Tooltip>
                          {task?.status !== 'completed' &&
                            moment().isAfter(
                              moment(task.deadlineDate).add(1, 'days'),
                            ) && (
                              <div className=" text-[10px] w-fit font-medium text-[#FF4B4B] inline-flex items-center gap-1 bg-[#ff4b4b1a] px-2 h-4 rounded-[5px]">
                                <div className="h-[5px] w-[5px] rounded-full bg-[#FF4B4B]" />{' '}
                                Overdue
                              </div>
                            )}
                        </div>
                      </td>
                      <td className="py-3 px-2 min-w-[4rem]">
                        <div>
                          <Tooltip
                            content={`${task?.assignee?.firstName} ${task?.assignee?.lastName}`}
                            tooltipShow
                            className="text-center w-fit"
                            contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            isFixed
                            ids={['insightsOverviewTasks', 'insightsPage']}
                          >
                            <span
                              className="flex items-center"
                              style={{ maxWidth: 'calc(100% - 12px)' }}
                            >
                              {task?.assignee?.avatarUrl ? (
                                <img
                                  src={task?.assignee?.avatarUrl}
                                  alt="avatar"
                                  className="w-10 h-10 min-h-10 min-w-10 rounded-[42px]  object-contain"
                                />
                              ) : (
                                <div className="min-h-10 min-w-10 rounded-[42px] border border-slate-400 bg-slate-400 text-white text-md flex items-center justify-center mr-2">
                                  {task?.assignee?.firstName
                                    ?.charAt?.(0)
                                    ?.toUpperCase() +
                                    task?.assignee?.lastName
                                      ?.charAt?.(0)
                                      ?.toUpperCase()}
                                </div>
                              )}
                            </span>
                          </Tooltip>
                        </div>
                      </td>
                      <td className="py-3 px-2 min-w-[5rem] ">
                        <div className="flex flex-col items-end">
                          <div
                            className="capitalize text-[10px] w-fit font-medium  inline-flex items-center gap-1 px-2 h-4 rounded-[5px]"
                            style={{
                              color:
                                task?.priority === 'high'
                                  ? '#FA8E8E'
                                  : task?.priority === 'medium'
                                    ? '#EDB900'
                                    : '#40AA00',
                              backgroundColor:
                                task?.priority === 'high'
                                  ? '#FA8E8E1a'
                                  : task?.priority === 'medium'
                                    ? '#EDB9001a'
                                    : '#40AA001a',
                            }}
                          >
                            {task?.priority}
                          </div>
                          <div
                            className="text-[12px] text-right"
                            style={{
                              color: TaskStatusOptions?.find(
                                (s) => s?.id === task?.status,
                              )?.textColor,
                            }}
                          >
                            {
                              TaskStatusOptions?.find(
                                (s) => s?.id === task?.status,
                              )?.name
                            }
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex w-full items-center justify-center flex-col my-4">
              <p className="text-slate-600 text-[16px] text-center">
                You're all caught up! No tasks need your attention right now.
              </p>
            </div>
          )}
          {(tasks?.length > 0 ||
            checkPermission(
              PermissionKeys.MANAGE_TRANSACTIONS,
              accessType,
              permissions,
            )) && (
            <button
              type="button"
              className="w-full h-[38px] border border-indigo-500 mt-4 rounded-[6px] text-indigo-500"
              onClick={() =>
                navigate(tasks?.length > 0 ? '/inbox' : '/inbox?id=new')
              }
            >
              {tasks?.length > 0 ? 'View All' : 'Add New Task'}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default TasksBox;
