import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import OTPInput from 'react-otp-input';
import { signIn, twoFactorAuth } from '../../API/backend_helper';
import Logo from '../../images/custom/count-logo.png';
import {
  ADMIN,
  FIRM_ID,
  IS_ACCOUNTANT,
  STAFF,
  SUPPORT,
  TEAM_ID,
  TEAM_NAME,
} from '../../utils/Utils';
import AuthSlider from '../../components/AuthSlider';
import TextInput from '../../components/TextInput';
import { setAccessType, setLocalUser } from '../../redux/User/reducer';

function Signin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const defaultMail = searchParams.get('email') || '';
  const [loading, setLoading] = useState(false);
  const [isSignin, setIsSignin] = useState('');
  const [otp, setOtp] = useState('');

  document.title = 'COUNT | Sign In';

  const onLogin = (response) => {
    const token = response?.auth?.access_token;
    const refreshToken = response?.auth?.refresh_token;
    const twoFactorToken = response?.auth?.twoFactorToken;
    localStorage.setItem('accessToken', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('twoFactorToken', twoFactorToken);

    dispatch(setLocalUser(response?.user));
    // For Bookkeeper
    if (response?.user?.isAccountingFirmUser) {
      localStorage.setItem(IS_ACCOUNTANT, 'true');
      if (response?.user?.accountingFirms?.length > 0) {
        localStorage.setItem(FIRM_ID, response?.user?.accountingFirms?.[0]?.id);
        dispatch(setAccessType(ADMIN));
        navigate('/accountant');
      } else {
        navigate('/practice/new');
      }
      return;
    }
    // For Staff User
    if (response?.user?.realm === STAFF || response?.user?.realm === SUPPORT) {
      navigate('/manage-workspaces');
      return;
    }

    if (response?.teams?.length > 0) {
      if (response?.teams?.length === 1) {
        const teamId = response?.teams?.[0]?.id;
        localStorage.setItem(TEAM_ID, teamId);
        localStorage.setItem(TEAM_NAME, response?.teams?.[0]?.name);
        navigate('/');
      } else {
        navigate('/manage-workspaces');
      }
      // const connections = await getConnections(token, teamId);
    } else {
      navigate('/workspace');
    }
  };

  const onSubmit = async (email, password) => {
    setLoading(true);
    try {
      const response = await signIn({
        email: email?.toLowerCase(),
        password,
      });
      if (typeof response === 'string') {
        toast.error(response);
      } else if (
        response?.message ===
        'Your email is not verified. Please verify your email first. You can verify by requesting a reset link by clicking forgot password.'
      ) {
        toast.error('Email not verified. Please click resend for a new OTP');
        navigate(`/verify-email?email=${encodeURIComponent(email)}`);
      } else if (response?.require2FA) {
        setIsSignin(email);
      } else {
        onLogin(response);
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  const onVerifyEmail = async () => {
    setLoading(true);
    try {
      const response = await twoFactorAuth(isSignin?.toLowerCase(), otp);
      if (typeof response === 'string') {
        toast.error(response);
      } else {
        if (
          response?.message ===
          'Your email is not verified. Please verify your email first. You can verify by requesting a reset link by clicking forgot password.'
        ) {
          toast.error('Email not verified. Please click resend for a new OTP');
          navigate(`/verify-email?email=${encodeURIComponent(isSignin)}`);
          return;
        }
        onLogin(response);
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} alt="logo" className="h-8" />
                </Link>
              </div>
            </div>
            {!isSignin ? (
              <div className="max-w-[476px] min-w-[50%] mx-auto w-full px-4 pt-8 pb-12">
                <h1 className="text-[40px] font-medium text-[#232323CC] mb-3 ">
                  Sign in
                </h1>
                <p className="text-lg text-[#667085CC] mb-9">
                  Please login to continue to your account.
                </p>

                {/* Form */}
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: decodeURI(defaultMail || ''),
                    password: '',
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .matches(
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        'Please enter valid email',
                      )
                      .required('Please Enter Email'),
                    password: Yup.string()
                      // .min(8, "Password must be at least 8 characters")
                      // .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
                      // .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
                      // .matches(RegExp("(.*[0-9].*)"), "At least one number")
                      .required('Please Enter Password'),
                  })}
                  onSubmit={async (values) => {
                    const { email, password } = values;
                    onSubmit(email, password);
                  }}
                >
                  {(validation) => (
                    <Form>
                      <div className="space-y-8">
                        <TextInput
                          label="Email"
                          type="email"
                          placeholder=" "
                          id="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          error={
                            validation.touched.email
                              ? validation.errors.email
                              : ''
                          }
                          required
                        />
                        <TextInput
                          label="Password"
                          type="password"
                          placeholder=" "
                          id="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          error={
                            validation.touched.password
                              ? validation.errors.password
                              : ''
                          }
                          autoComplete="on"
                          required
                        />
                      </div>
                      <div
                        className={`flex items-center justify-between ${validation.errors.password && validation.touched.password ? 'mt-8' : 'mt-6'} mb-7`}
                      >
                        <label className="inline-flex items-center text-slate-600 font-medium">
                          <input
                            type="checkbox"
                            className="form-checkbox mr-[10px] h-[18px] w-[18px]"
                          />{' '}
                          Keep me logged in
                        </label>
                        <div className="mr-1">
                          <Link
                            className="text-sm text-indigo-500 hover:underline"
                            to="/forgot-password"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <button
                        className="btn w-full py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
                           disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                        type="submit"
                        disabled={loading}
                      >
                        {loading && (
                          <svg
                            className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                          </svg>
                        )}
                        Sign in
                      </button>
                    </Form>
                  )}
                </Formik>
                {/* Footer */}
                {/* Divider  Line */}
                <div className="flex w-full mt-7 items-center gap-3 mb-6 !hidden">
                  <div className="h-[1px] flex-1 bg-[#D9D9D9]" />
                  <span className="text-[#6E6E6E] font-medium">or</span>
                  <div className="h-[1px] flex-1 bg-[#D9D9D9]" />
                </div>
                {/* Social Login Buttons  */}
                <div className="flex justify-between gap-2 flex-wrap !hidden">
                  <button
                    className="mx-auto btn gap-3 whitespace-nowrap text-base py-[15px] px-5 bg-white border border-[#E6E8E7] rounded-[10px]"
                    type="button"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.16 9.31774C17.16 8.71501 17.1059 8.13547 17.0055 7.5791H9V10.8671H13.5746C13.3775 11.9296 12.7786 12.8298 11.8784 13.4325V15.5653H14.6255C16.2327 14.0855 17.16 11.9064 17.16 9.31774Z"
                        fill="#4285F4"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.99826 17.6252C11.2933 17.6252 13.2174 16.8641 14.6237 15.5659L11.8767 13.4332C11.1155 13.9432 10.1419 14.2446 8.99826 14.2446C6.7844 14.2446 4.91053 12.7493 4.24212 10.7402H1.40234V12.9425C2.80098 15.7205 5.67553 17.6252 8.99826 17.6252Z"
                        fill="#34A853"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.24387 10.7399C4.07387 10.2299 3.97728 9.68512 3.97728 9.1249C3.97728 8.56467 4.07387 8.01989 4.24387 7.50989V5.30762H1.40409C0.82841 6.45512 0.5 7.7533 0.5 9.1249C0.5 10.4965 0.82841 11.7947 1.40409 12.9422L4.24387 10.7399Z"
                        fill="#FBBC05"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.99826 4.00569C10.2462 4.00569 11.3667 4.43455 12.2476 5.27683L14.6855 2.83887C13.2135 1.46727 11.2894 0.625 8.99826 0.625C5.67553 0.625 2.80098 2.52978 1.40234 5.30773L4.24212 7.51001C4.91053 5.50092 6.7844 4.00569 8.99826 4.00569Z"
                        fill="#EA4335"
                      />
                    </svg>{' '}
                    Sign in with Google
                  </button>
                  <button
                    className="mx-auto btn gap-3 whitespace-nowrap text-base py-[15px] px-5 bg-white border border-[#E6E8E7] rounded-[10px]"
                    type="button"
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.1001 19.285C19.6901 18.385 19.9101 17.925 20.3601 16.915C17.0401 15.655 16.5101 10.925 19.7901 9.11499C18.7901 7.85499 17.3801 7.125 16.0501 7.125C15.0901 7.125 14.4301 7.37501 13.8401 7.60501C13.3401 7.79501 12.8901 7.965 12.3301 7.965C11.7301 7.965 11.2001 7.77501 10.6401 7.57501C10.0301 7.35501 9.39006 7.125 8.59006 7.125C7.10006 7.125 5.51007 8.035 4.50007 9.595C3.08007 11.795 3.33007 15.915 5.62007 19.435C6.44007 20.695 7.54007 22.105 8.97007 22.125C9.57007 22.135 9.96007 21.955 10.3901 21.765C10.8801 21.545 11.4101 21.305 12.3401 21.305C13.2701 21.295 13.7901 21.545 14.2801 21.765C14.7001 21.955 15.0801 22.135 15.6701 22.125C17.1201 22.105 18.2801 20.545 19.1001 19.285Z"
                        fill="#101828"
                        fillOpacity="0.8"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.8404 2.125C16.0004 3.225 15.5504 4.31501 14.9604 5.07501C14.3304 5.89501 13.2304 6.535 12.1704 6.495C11.9804 5.435 12.4704 4.34499 13.0704 3.61499C13.7404 2.81499 14.8704 2.195 15.8404 2.125Z"
                        fill="#101828"
                        fillOpacity="0.8"
                      />
                    </svg>{' '}
                    Sign in with Apple
                  </button>
                </div>
                <div className="mt-8 text-center">
                  <div className="text-base">
                    Need an account?{' '}
                    <Link
                      className="font-medium underline ml-1 text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                      to="/signup"
                    >
                      Create one
                    </Link>
                  </div>
                </div>
                <div className=" mt-4 text-center">
                  <div className="text-base">
                    Sign in as an employee?{' '}
                    <Link
                      className="font-medium underline ml-1 text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                      to="/person/signin"
                    >
                      Employee Sign In
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex text-center flex-col items-center max-w-[456px] min-w-[50%] mx-auto w-full  px-4 py-8">
                <div className="h-16 w-16 mb-6 rounded-full bg-[#FFF2EA] inline-flex justify-center items-center">
                  <svg
                    width="28"
                    height="23"
                    viewBox="0 0 28 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.52734 5.26482L11.71 12.3927C12.5346 12.9699 12.9469 13.2585 13.3953 13.3703C13.7915 13.469 14.2058 13.469 14.6019 13.3703C15.0503 13.2585 15.4626 12.9699 16.2872 12.3927L26.4699 5.26482M7.51355 21.4775H20.4837C22.579 21.4775 23.6267 21.4775 24.427 21.0697C25.131 20.711 25.7034 20.1386 26.0621 19.4346C26.4699 18.6343 26.4699 17.5866 26.4699 15.4913V7.50965C26.4699 5.41428 26.4699 4.36659 26.0621 3.56627C25.7034 2.86228 25.131 2.28992 24.427 1.93122C23.6267 1.52344 22.579 1.52344 20.4837 1.52344H7.51355C5.41818 1.52344 4.3705 1.52344 3.57017 1.93122C2.86619 2.28992 2.29383 2.86228 1.93513 3.56627C1.52734 4.36659 1.52734 5.41428 1.52734 7.50965V15.4913C1.52734 17.5866 1.52734 18.6343 1.93513 19.4346C2.29383 20.1386 2.86619 20.711 3.57017 21.0697C4.3705 21.4775 5.41818 21.4775 7.51355 21.4775Z"
                      stroke="#E48642"
                      strokeWidth="2.13793"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <h1 className="text-[40px] font-medium text-[#232323CC] mb-3 ">
                  Security Check
                </h1>
                <p className="text-lg text-[#667085CC] mb-4">
                  We've sent an email to
                  <span className="font-medium text-[#667085]">
                    {' '}
                    {isSignin}
                  </span>{' '}
                  with your security code. Please check your email and enter the
                  code to continue.
                </p>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span className="w-3" />}
                  renderInput={(props) => (
                    <input
                      {...props}
                      type="number"
                      className="form-input remove-arrows !h-10 !w-10 rounded-[12px] p-0 border-[#D0D5DD] shadow-none focus:border-indigo-600 focus:!shadow-none"
                    />
                  )}
                />
                <button
                  className="btn text-lg rounded-[10px] mt-8 mb-6 font-semibold text-white w-full py-3 !bg-indigo-500"
                  onClick={onVerifyEmail}
                  disabled={otp?.length !== 6 || loading}
                  type="button"
                >
                  {loading && (
                    <svg
                      className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                    </svg>
                  )}
                  Continue
                </button>
                <div className="text-lg text-[#667085CC]">
                  Wrong account?
                  <span
                    className="text-indigo-500 font-medium cursor-pointer"
                    onClick={() => {
                      setIsSignin('');
                      setOtp('');
                    }}
                    tabIndex="0"
                    onKeyDown={null}
                    role="button"
                  >
                    {' '}
                    Back to login
                    {/* {resending ? 'Resending ...' : 'Click to resend'} */}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Auth Slider */}

        <AuthSlider />
        {/* <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div> */}
      </div>
    </main>
  );
}

export default Signin;
