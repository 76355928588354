import React, { useRef, useEffect, useState } from 'react';
import {
  Chart,
  DoughnutController,
  ArcElement,
  TimeScale,
  Tooltip,
} from 'chart.js';

import 'chartjs-adapter-moment';

// Import utilities
import { useSelector } from 'react-redux';
import {
  getCurrencySymbolFromIso,
  tailwindConfig,
  TEAM_CURRENCY,
} from '../utils/Utils';

Chart.register(DoughnutController, ArcElement, TimeScale, Tooltip);

function DoughnutChart({ data, width, height, hideLegends = false }) {
  const [chart, setChart] = useState(null);
  const canvas = useRef(null);
  const legend = useRef(null);

  const { team } = useSelector((state) => state.Team);
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);
  const { currencies } = useSelector((state) => state.Currencies);

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.fontSize = '12px';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
    const type = data?.datasets?.[0]?.label;
    const percentage = data?.datasets?.[0]?.data?.[
      tooltip?.dataPoints?.[0]?.dataIndex
    ]?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const amount =
      data?.datasets?.[0]?.amounts?.[tooltip?.dataPoints?.[0]?.dataIndex];
    const formattedAmount = `  ${amount?.toString().charAt(0) === '-' ? '-' : ''}${getCurrencySymbolFromIso(
      teamDefaultCurrency,
      currencies,
    )}${
      amount < 0
        ? (-1 * amount)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : amount?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
    }`;
    const subType = tooltip?.dataPoints?.[0]?.label;
    const color =
      data?.datasets?.[0]?.backgroundColor?.[
        tooltip?.dataPoints?.[0]?.dataIndex
      ];

    // Set Text
    if (tooltip.body) {
      while (tooltipEl.firstChild) {
        tooltipEl.firstChild.remove();
      }
      // tooltipEl.appendChild(tooltipTitle);
      const data = `
      <div>
        <div class="mb-2 flex px-2">
          <div class="text-[18px] text-slate-600 font-medium leading-tight">${type}</div>
        </div>
        <div class="flex justify-between items-center px-2 gap-4">
          <div class="flex gap-2 items-center">
            <div class="h-3 w-3 min-h-3 min-w-3 rounded" style="background: ${color}"></div>
            <p class="text-slate-500 text-base" >${subType}</p>
          </div>
        </div>
        <p class="pl-2 text-[18px] text-slate-700 font-medium">${formattedAmount}</p>
        <p class="pl-2 text-base text-slate-500">${percentage}%</p>
      </div>`;
      tooltipEl.innerHTML = data;
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Hidden if formatted Value is 0
    if (parseFloat(tooltip?.body?.[0]?.lines?.[0]?.split(': ')?.[1]) === 0) {
      tooltipEl.style.display = 'none';
    } else {
      tooltipEl.style.display = 'block';
    }

    // Display, position, and set styles for font
    tooltipEl.classList.add('down-arrow-tooltip');
    tooltipEl.style.backgroundColor = '#ffffff';
    tooltipEl.style.boxShadow = `0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01)`;
    tooltipEl.style.border = `1px solid #e2e8f0`;
    tooltipEl.style.color = '#64748b';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
    tooltipEl.style.top = `${-10 + positionY + tooltip.caretY}px`;
    tooltipEl.style.transform = 'translate(-50%, -100%)';
    tooltipEl.style.maxWidth = '300px';
    tooltipEl.style.minWidth = '200px';

    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
  };

  useEffect(() => {
    const ctx = canvas.current;
    // eslint-disable-next-line no-unused-vars
    const newChart = new Chart(ctx, {
      type: 'doughnut',
      data,
      options: {
        cutout: '75%',
        layout: {
          padding: 24,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            titleColor: 'black',
            bodyColor: '#94a3b8',
            backgroundColor: '#fff',
            borderColor: '#cbd5e1',
            enabled: false,
            external: externalTooltipHandler,
            mode: 'point',
            intersect: true,
          },
        },
        interaction: {
          mode: 'point',
          intersect: true,
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [
        {
          id: 'htmlLegend',
          afterUpdate(c, args, options) {
            const ul = legend.current;
            if (!ul) return;
            // Remove old legend items
            while (ul.firstChild) {
              ul.firstChild.remove();
            }
            // Reuse the built-in legendItems generator
            const items = c.options.plugins.legend.labels.generateLabels(c);
            items.forEach((item) => {
              const li = document.createElement('li');
              li.style.margin = tailwindConfig().theme.margin[1];
              // Button element
              const button = document.createElement('button');
              button.classList.add(
                'btn-xs',
                'bg-white',
                'bg-slate-800',
                'text-slate-500',
                'text-slate-400',
                'border',
                'border-slate-200',
                'border-slate-700',
                'shadow-md',
              );
              button.style.opacity = item.hidden ? '.3' : '';
              button.onclick = () => {
                c.toggleDataVisibility(item.index);
                c.update();
              };
              // Color box
              const box = document.createElement('span');
              box.style.display = 'block';
              box.style.width = tailwindConfig().theme.width[2];
              box.style.height = tailwindConfig().theme.height[2];
              box.style.backgroundColor = item.fillStyle;
              box.style.borderRadius = tailwindConfig().theme.borderRadius.sm;
              box.style.marginRight = tailwindConfig().theme.margin[1];
              box.style.pointerEvents = 'none';
              // Label
              const label = document.createElement('span');
              label.style.display = 'flex';
              label.style.alignItems = 'center';
              const labelText = document.createTextNode(item.text);
              label.appendChild(labelText);
              li.appendChild(button);
              button.appendChild(box);
              button.appendChild(label);
              ul.appendChild(li);
            });
          },
        },
      ],
    });
    setChart(newChart);
    return () => newChart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chart) return;
    chart.options.plugins.tooltip.titleColor = 'black';
    chart.options.plugins.tooltip.bodyColor = '#e2e8f0';
    chart.options.plugins.tooltip.backgroundColor = '#e2e8f0';
    chart.options.plugins.tooltip.borderColor = '#64748b';

    chart.update('none');
  }, []);

  return (
    <div className="grow flex flex-col justify-center">
      <div className="z-10">
        <canvas ref={canvas} width={width} height={height} />
      </div>

      <div
        className="px-5 pt-2 pb-6"
        style={hideLegends ? { display: 'none' } : {}}
      >
        <ul ref={legend} className="flex flex-wrap justify-center -m-1" />
      </div>
    </div>
  );
}

export default DoughnutChart;
