import React, { useState } from 'react';
import FirmMembersModal from 'pages/manageWorkspace/members/FirmModal';
import MembersModal from 'pages/manageWorkspace/members/MembersModal';
import {
  addFirmToWorkspace,
  getMembersApi,
  getTeamById,
} from 'API/backend_helper';
import { useSelector, useDispatch } from 'react-redux';
import { TEAM_ID } from 'utils/Utils';
import { toast } from 'react-toastify';
import { setTeam } from '../../redux/Team/reducer';

const InviteMembers = ({ updateOneStep }) => {
  const dispatch = useDispatch();

  const { team } = useSelector((state) => state.Team);
  const TeamId = localStorage.getItem(TEAM_ID);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [localInvite, setLocalInvite] = useState({});
  const [inviteFirmOpen, setInviteFirmOpen] = useState(false);
  const [firmInvitedSuccessfully, setFirmInvitedSuccessfully] = useState(false);
  const [memberInvitedSuccessfully, setMemberInvitedSuccessfully] =
    useState(false);

  const loadTeam = async (teamId = TeamId) => {
    try {
      const res = await getTeamById(teamId);
      dispatch(setTeam(res));
    } catch (error) {
      console.error(error);
    }
  };

  const inviteFirmToWorkspace = async (firmId) => {
    try {
      const res = await addFirmToWorkspace(firmId);
      if (res?.data?.firmInvite?.id) {
        toast.success('Firm invite sent successfully.');
        setFirmInvitedSuccessfully(true);
      }
      await loadTeam(team?.id);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <div>
      <MembersModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        getMembers={() => {
          toast.success('Team member invited successfully');
          setMemberInvitedSuccessfully(true);
        }}
        localInvite={localInvite}
        setLocalInvite={setLocalInvite}
      />
      <FirmMembersModal
        isOpen={inviteFirmOpen}
        setIsOpen={setInviteFirmOpen}
        inviteFirmToWorkspace={inviteFirmToWorkspace}
      />
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Invite Team Members:
      </h3>

      <div className="flex justify-between items-center w-full flex-wrap mb-6 gap-4">
        <p className="text-[18px] font-medium text-slate-500">
          Invite Accountant/Bookkeeper
        </p>
        <button
          className="ml-auto btn !bg-indigo-500 text-base hover:!bg-indigo-600 text-white"
          aria-controls="basic-modal"
          onClick={() => {
            setInviteFirmOpen(true);
          }}
          type="button"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2"
          >
            <path
              d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
              stroke="white"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Invite Accountant/Bookkeeper
        </button>
      </div>

      <div className="flex justify-between items-center w-full flex-wrap gap-4">
        <p className="text-[18px] font-medium text-slate-500 ">
          Invite Team Member
        </p>
        <button
          className="ml-auto btn !bg-indigo-500 text-base hover:!bg-indigo-600 text-white"
          aria-controls="basic-modal"
          onClick={() => {
            setIsOpenModal(true);
          }}
          type="button"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2"
          >
            <path
              d="M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334"
              stroke="white"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Invite Team Member
        </button>
      </div>

      <footer className="flex flex-wrap justify-between mt-[40px] gap-4">
        {!firmInvitedSuccessfully || !memberInvitedSuccessfully ? (
          <button
            type="button"
            onClick={updateOneStep}
            className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
          >
            Skip
          </button>
        ) : (
          <button
            type="button"
            onClick={updateOneStep}
            className="h-[38px] inline-flex justify-center items-center w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
          >
            Next
          </button>
        )}
      </footer>
    </div>
  );
};

export default InviteMembers;
