import React from 'react';

const CircularProgressBar = ({ content = '', percentage = 0 }) => (
  <div
    className="circularProgressBar"
    data-value={content}
    style={{
      background: `conic-gradient(#78BD4F ${percentage}%, #D9D9D9 0)`,
    }}
  />
);

export default CircularProgressBar;
