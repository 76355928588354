import React, { useEffect, useRef, useState } from 'react';
import { StatusDropdownArrow } from './Svg';
import Transition from '../../utils/Transition';
import useClickOutside from '../../utils/useClickOutside';

const TasksDropdown = ({
  statusOptions,
  task,
  handleUpdateTask,
  isFlyout = false,
  isTableDropdown = false,
  bottomOfTable = false,
  ids = [],
  disabled = false,
}) => {
  const trigger = useRef();
  const dropdown = useRef();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fixedDropdownPositions, setFixedDropdownPositions] = useState({});

  useClickOutside(dropdown, () => {
    setDropdownOpen(false);
  });

  const getFixedPositionValues = () => {
    const { x, y, height } = trigger.current?.getBoundingClientRect();
    const dropdownDimensions = dropdown.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const values = {
      left:
        x + dropdownDimensions?.width > windowWidth
          ? windowWidth - dropdownDimensions?.width - 20
          : x,
      top:
        y + height + dropdownDimensions?.height > windowHeight
          ? windowHeight - dropdownDimensions?.height - 20
          : y + height,
    };
    setFixedDropdownPositions(values);
  };

  useEffect(() => {
    if (bottomOfTable) {
      if (dropdownOpen) {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            // ele.style.overflow = 'hidden';
            ele.style.pointerEvents = 'none';
          }
        });
      } else {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            // ele.style.overflow = 'auto';
            ele.style.pointerEvents = 'auto';
          }
        });
      }
      getFixedPositionValues();
      return () => {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            // ele.style.overflow = 'auto';
            ele.style.pointerEvents = 'auto';
          }
        });
      };
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (isTableDropdown) {
      const mouseMoveHandler = ({ clientX, clientY }) => {
        if (!dropdown.current) {
          return;
        }
        const { left, top, width, height } =
          dropdown?.current?.getBoundingClientRect();
        const offsetX = Math.max(clientX - (left + width), left - clientX);
        const offsetY = Math.max(clientY - (top + height), top - clientY);
        const distance = Math.sqrt(offsetX ** 2 + offsetY ** 2);
        if (dropdownOpen && distance > 300) {
          setDropdownOpen(false);
        }
      };
      document.addEventListener('mousemove', mouseMoveHandler);
      return () => document.removeEventListener('mousemove', mouseMoveHandler);
    }
  }, [dropdownOpen]);

  return (
    <div
      className="w-[130px] relative flex py-1 px-2 justify-center items-center gap-2.5 rounded-[22px] text-[14px] leading-6"
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          setDropdownOpen((prev) => !prev);
        }
      }}
      ref={trigger}
      style={{
        backgroundColor: statusOptions?.find(
          (option) => option.id === task?.status,
        )?.background,
        color: statusOptions?.find((option) => option.id === task?.status)
          ?.textColor,
      }}
      role="button"
      tabIndex="0"
    >
      <div className="flex items-center gap-2">
        <p>
          {statusOptions?.find((option) => option.id === task?.status)?.name}
        </p>
        {!disabled && (
          <StatusDropdownArrow
            fill={
              statusOptions?.find((option) => option.id === task?.status)
                ?.textColor
            }
            rotate={dropdownOpen}
          />
        )}
      </div>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`
            ${
              bottomOfTable
                ? 'fixed !w-fit min-w-40'
                : 'absolute top-8 left-0 w-full z-60'
            }
              bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-visible mt-1`}
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        style={
          bottomOfTable
            ? {
                top: fixedDropdownPositions?.top,
                left: fixedDropdownPositions?.left,
                zIndex: '1000',
              }
            : {}
        }
      >
        {dropdownOpen && (
          <div
            className="divide-y pointer-events-auto divide-slate-200 dark:divide-slate-700  "
            ref={dropdown}
          >
            <div className="max-h-48 overflow-auto scrollbar rounded-[22px]">
              {statusOptions.map((option, index) => (
                <button
                  type="button"
                  key={index}
                  className="flex items-center justify-between w-[80%] rounded-[22px] px-2 my-2 mx-auto cursor-pointer"
                  style={{
                    backgroundColor: option.background,
                    color: option.textColor,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isFlyout) {
                      handleUpdateTask(option?.id);
                    } else {
                      handleUpdateTask(
                        task?.id,
                        {
                          ...task,
                          status: option?.id,
                        },
                        'status',
                      );
                    }

                    setDropdownOpen(false);
                  }}
                >
                  {option?.name}
                </button>
              ))}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default TasksDropdown;
