import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import Transition from 'utils/Transition';
import { getAllPastMonths } from 'utils/Utils';

function MonthDropdown({
  selected,
  setSelected,
  name,
  error = false,
  disabled = false,
  className = '',
  scrollIntoView = true,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    if (scrollIntoView) {
      if (dropdownOpen) {
        dropdown.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [dropdownOpen]);

  const OptionsToMap = ({ options }) =>
    options?.map((option) => (
      <button
        type="button"
        key={option.id}
        tabIndex="0"
        className={`flex pl-[24px] border-none items-center gap-2 text-[13px] text-[#667085]  hover:text-slate-800 w-full dark:hover:bg-slate-700/20 py-[2px] px-3 cursor-pointer ${
          option.id === selected && 'text-indigo-500 hover:!text-indigo-600'
        }`}
        onClick={() => {
          setSelected(option.id);
          setDropdownOpen(false);
        }}
      >
        <span>{option[name]}</span>
        <svg
          className={`shrink-0 mr-2 fill-current text-indigo-500 ${
            option.id !== selected && 'invisible'
          }`}
          width="12"
          height="9"
          viewBox="0 0 12 9"
        >
          <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
        </svg>
      </button>
    ));

  return (
    <>
      <div className={`relative inline-flex h-full w-full ${className}`}>
        <button
          type="button"
          ref={trigger}
          className={`h-10 max-h-full px-4 text-base  btn w-full justify-between min-w-[114px] bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300
        dark:hover:border-slate-600 text-[#667085cc] hover:text-slate-600 dark:text-slate-300
         dark:hover:text-slate-200 ${
           error ? '!border-rose-400 !text-rose-400' : ''
         }`}
          aria-label="Select date range"
          aria-haspopup="true"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          aria-expanded={dropdownOpen}
          disabled={disabled}
        >
          <span className="flex items-center">
            <span>
              {selected?.split(',')?.[0]
                ? moment(selected?.split(',')?.[0], 'MMM').format('MMM')
                : ''}{' '}
              {selected?.split(',')?.[1]?.trim() || 'Select...'}
            </span>
          </span>
          <svg
            className={`shrink-0 ml-1 fill-current text-[#667085cc] ${
              error ? 'fill-rose-400' : ''
            } ${dropdownOpen ? 'rotate-180' : ''}`}
            width="11"
            height="7"
            viewBox="0 0 11 7"
          >
            <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
          </svg>
        </button>
        <Transition
          show={dropdownOpen}
          tag="div"
          className="z-10 absolute top-full right-0 w-[162px] bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
          enter="transition ease-out duration-100 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          {dropdownOpen && (
            <div
              ref={dropdown}
              className="font-medium max-h-48 scrollbar overflow-auto	 text-sm text-slate-600 dark:text-slate-300  dark:divide-slate-700"
              onFocus={() => setDropdownOpen(true)}
              onBlur={() => setDropdownOpen(false)}
            >
              {getAllPastMonths()?.map((group) => (
                <div key={group.id}>
                  <span
                    className=" cursor-pointer font-bold whitespace-nowrap flex items-center pr-4 text-xs text-[#515A6C]  py-1 px-4"
                    onClick={() => {
                      setSelected(`,${group?.year}`);
                      setDropdownOpen(false);
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    {group?.year}

                    <svg
                      className={`shrink-0 ml-2 fill-current text-indigo-500 ${
                        `,${group?.year}` !== selected && 'invisible'
                      }`}
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                    >
                      <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                    </svg>
                  </span>

                  <OptionsToMap options={group?.months} />
                </div>
              ))}
            </div>
          )}
        </Transition>
      </div>
      {error && <div className=" text-xs mt-1 text-rose-400">{error}</div>}
    </>
  );
}

export default MonthDropdown;
