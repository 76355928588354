import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { checkUserEmailAuth, getUserCountry } from 'API/backend_helper';
import { toast } from 'react-toastify';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import TextInput from '../../../components/TextInput';

const Form1 = ({ defaultMail, onSubmit, form1Data }) => {
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [wrongEmail, setWrongEmail] = useState();
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleCheckEmail = async (values) => {
    setLoading(true);
    try {
      const res = await checkUserEmailAuth(encodeURIComponent(values.email));
      setWrongEmail('');
      if (res?.status === 'success') {
        onSubmit(values);
      }
    } catch (err) {
      toast.error(err);
      setWrongEmail(values.email);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const res = await getUserCountry();
        const country = res?.country;
        setSelectedCountry(country);
      } catch (err) {
        console.log('err', err);
      }
    };
    fetchCountry();
  }, []);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        firstName: form1Data?.firstName || '',
        lastName: form1Data?.lastName || '',
        email: form1Data?.email || defaultMail || '',
        password: form1Data?.password || '',
        // dateOfBirth: null,
        phone: form1Data?.phone || '',
        confirm_password: form1Data?.confirm_password || '',
      }}
      validate={(values) => {
        const errors = {};
        const { phone } = values;
        if (!phone) {
          errors.phone = 'Please Enter Phone';
        }
        if (phone && !isValidPhoneNumber(phone)) {
          errors.phone = 'Phone number is not valid';
        }

        return errors;
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .trim()
          .required('Please Enter First Name')
          .max(100, 'First Name must be at most 100 characters'),
        lastName: Yup.string()
          .trim()
          .required('Please Enter Last Name')
          .max(100, 'Last Name must be at most 100 characters'),
        // phone: Yup.string()
        // .matches(/^\d+$/, "Phone number must be numeric")
        // .max(100, "Phone number must be at most 100 characters")
        // .required('Please enter phone'),
        email: Yup.string()
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            'Please enter valid email',
          )
          .required('Please Enter Email')
          .max(100, 'Email must be at most 100 characters'),
        // dateOfBirth: Yup.string().required(
        //   "Please Select Date of Birth"
        // ),
        password: Yup.string()
          .required('Please Enter Password')
          .min(8, 'Password must be at least 8 characters')
          .matches(/^\S*$/, 'Password cannot contain spaces')
          .matches(/.*[a-z].*/, 'At least one lowercase letter')
          .matches(/.*[A-Z].*/, 'At least one uppercase letter')
          .matches(/.*[0-9].*/, 'At least one number')
          .max(200, 'Password must be at most 200 characters'),
        confirm_password: Yup.string()
          .required('Confirm password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .max(200, 'Password must be at most 200 characters'),
      })}
      onSubmit={handleCheckEmail}
    >
      {(validation) => (
        <Form>
          <div className="space-y-8">
            <TextInput
              label="First Name"
              placeholder=" "
              id="firstName"
              name="firstName"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.firstName || ''}
              error={
                validation.touched.firstName ? validation.errors.firstName : ''
              }
              required
            />
            <TextInput
              label="Last Name"
              placeholder=" "
              id="lastName"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.lastName || ''}
              error={
                validation.touched.lastName ? validation.errors.lastName : ''
              }
              required
            />
            <TextInput
              label="Email"
              placeholder=" "
              id="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ''}
              error={
                validation.touched.email
                  ? validation.errors.email ||
                    (wrongEmail && validation.values.email === wrongEmail
                      ? 'Email already exists'
                      : '')
                  : ''
              }
              required
            />
            <div>
              <label
                htmlFor="phone"
                className={`text-sm text-[667085] mb-2 block ${
                  validation.touched.phone && validation.errors.phone
                    ? 'text-rose-400'
                    : ''
                }`}
              >
                Phone Number <span className="text-rose-500">*</span>
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                value={validation.values.phone}
                onChange={(value) =>
                  validation.setFieldValue('phone', value || '')
                }
                international
                defaultCountry={selectedCountry || ''}
                id="phone"
                name="phone"
                className={`phone_textInput ${
                  validation.touched.phone && validation.errors.phone
                    ? 'phone_textInputError'
                    : ''
                } `}
              />
              <span className=" text-xs text-rose-400">
                {validation.touched.phone ? validation.errors.phone : ''}
              </span>
            </div>
            <TextInput
              label="Password"
              placeholder=" "
              type="password"
              id="password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.password || ''}
              error={
                validation.touched.password ? validation.errors.password : ''
              }
              required
            />
            <p className={`text-[13px] !mt-4 `}>
              Should contain at least 1 uppercase, 1 numeric and a total of 8
              characters.
            </p>
            <TextInput
              label="Confirm Password"
              placeholder=" "
              type="password"
              id="confirm_password"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.confirm_password || ''}
              error={
                validation.touched.confirm_password
                  ? validation.errors.confirm_password
                  : ''
              }
              required
            />

            <button
              className="btn w-full py-4 text-lg rounded-[10px] font-semibold !bg-indigo-500 hover:bg-indigo-600 text-white
          disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
              type="submit"
              disabled={
                loading ||
                (wrongEmail && validation.values.email === wrongEmail)
              }
            >
              {loading && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Form1;
