import React from 'react';

const OnBoardSuccess = ({ handleOnBoardComplete }) => (
  <div>
    <h3 className="text-[20px] font-medium text-[#101828] mb-4">
      On Boarded Successfully
    </h3>
    <footer className="flex justify-center mt-[60px] gap-4">
      <button
        className="h-[38px]  px-4 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
        type="button"
        onClick={handleOnBoardComplete}
      >
        Go To Dashboards
      </button>
    </footer>
  </div>
);

export default OnBoardSuccess;
