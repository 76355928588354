import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QuickBookReauth from 'pages/appIntegrations/quickbooksSettings/quickBookReauth';
import SquareLogo from './square-logo.png';
import QuickBookLogo from './quickbooks-logo.png';
import { quickBookAuth } from '../../../API/backend_helper';
import { DevEnv } from '../../../utils/Utils';

const GUSTO_FEATURES = [
  'Run payroll in minutes',
  'Calculates taxes and deductions',
  "Works with COUNT's hourly employee time tracking add-on",
];

export const QUICK_BOOK_FEATURES = [
  <>
    One-click import your data from{' '}
    <span className="font-semibold"> Quickbooks Online! </span>{' '}
  </>,
  'Imports Accounts, Customers, Vendors, Products and Transactions.',
];

const SQUARE_FEATURES = [
  'Automatically import your sales into COUNT',
  'Inventory management built In',
  'Customize imported sales details',
];

const AppIntegrations = ({ team, setTeam }) => {
  const navigate = useNavigate();
  const Env = process.env.REACT_APP_ENV;

  const [gustoLoading, setGustoLoading] = useState(false);
  const [squareLoading, setSquareLoading] = useState(false);
  const [quickBookLoading, setQuickBookLoading] = useState(false);
  // const [quickbookStatus,set]

  const onGustoConnect = () => {
    setGustoLoading(true);
    const randomNumber = Math.floor(1000000000 + Math.random() * 9000000000);
    localStorage.setItem('Gusto_Random_Number', randomNumber);
    window.location = `https://api.gusto-demo.com/oauth/authorize?client_id=neIT4SzdrNsozIvGyebzgT2syYP7RCpQYn7s4moTyn8&redirect_uri=https%3A%2F%2Fdev-app.getcount.com%2Fintegration%2Fgusto%2Foauth&response_type=code`;
  };

  const onSquareConnect = () => {
    setSquareLoading(true);
    const randomNumber = Math.floor(1000000000 + Math.random() * 9000000000);
    localStorage.setItem('Square_Random_Number', randomNumber);
    window.location = `
    https://squareupsandbox.com/oauth2/authorize?client_id=sandbox-sq0idb-9Z_jNnzbT9BflSBR7NMGPg&scope=CUSTOMERS_READ+MERCHANT_PROFILE_READ+BANK_ACCOUNTS_READ+ITEMS_READ+ITEMS_WRITE+ORDERS_READ+PAYMENTS_READ+EMPLOYEES_READ+GIFTCARDS_READ+INVENTORY_READ+INVOICES_READ+PAYOUTS_READ+VENDOR_READ&session=false&state=${randomNumber}`;
  };

  const onQuickBookConnect = async () => {
    try {
      setQuickBookLoading(true);
      const res = await quickBookAuth({});
      setQuickBookLoading(false);
      window.location = res?.redirectUrl;
    } catch (err) {
      setQuickBookLoading(false);
      console.log('err', err);
    }
  };

  const squareIntegrated = useMemo(() => {
    let square = null;
    if (team?.apps?.length > 0) {
      for (let i = 0; i < team?.apps?.length; i++) {
        if (team?.apps?.[i]?.app === 'square') {
          square = team?.apps?.[i];
          break;
        }
      }
    }
    return square;
  }, [team]);

  if (!team?.id) {
    return (
      <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="animate-spin w-10 h-10 fill-current shrink-0"
        >
          <path
            d="M14.5 2.41699V7.25033"
            stroke="#E48642"
            strokeWidth="2.55882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5 21.749V26.5824"
            stroke="#E48642"
            strokeWidth="2.55882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.95703 5.95703L9.37662 9.37662"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.623 19.6211L23.0426 23.0407"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.41699 14.5H7.25033"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.749 14.5H26.5824"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.95703 23.0407L9.37662 19.6211"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.623 9.37662L23.0426 5.95703"
            stroke="#E48642"
            strokeWidth="1.58955"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="text-slate-600 font-medium text-[0.875rem] text-center">
          Fetching data, please wait...
        </p>
      </div>
    );
  }

  return (
    <>
      <QuickBookReauth />
      <div className="grid grid-cols-12 gap-[18px]">
        {/* Gusto Box  1  */}
        {Env === DevEnv && (
          <div className="col-span-12 sm:col-span-6 xl:col-span-4 xxl:col-span-3 rounded-[10px] border border-[#D0D5DD]">
            <div className="h-[89px] border-b border-[#D0D5DD] bg-white w-full rounded-t-[inherit] pl-[22px] inline-flex items-center">
              <svg
                height="41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className=" w-auto"
              >
                <path
                  d="M94.984 5.947c-6.609 0-11.986 5.401-11.986 12.04 0 6.64 5.377 12.042 11.986 12.042 6.608 0 11.985-5.402 11.985-12.041 0-6.64-5.377-12.041-11.985-12.041zm0 18.375c-3.475 0-6.306-2.844-6.306-6.334 0-3.49 2.831-6.335 6.306-6.335 3.474 0 6.305 2.844 6.305 6.335 0 3.49-2.831 6.334-6.305 6.334zM59.688 15.55l-2.095-1.052c-.91-.453-1.48-.804-1.71-1.062-.23-.259-.35-.545-.35-.85 0-.406.185-.748.543-1.016.359-.277.864-.406 1.508-.406 1.176 0 2.481.711 3.915 2.142l3.539-3.555c-.91-1.219-2.013-2.151-3.318-2.807a9.185 9.185 0 00-4.173-.979c-2.16 0-3.943.637-5.331 1.921-1.388 1.284-2.087 2.826-2.087 4.635 0 2.808 1.839 5.144 5.524 7l1.93.97c1.664.84 2.491 1.699 2.491 2.585 0 .48-.23.896-.689 1.265-.46.37-1.066.545-1.82.545-.698 0-1.498-.24-2.399-.73a8.072 8.072 0 01-2.353-1.902l-3.52 3.85c1.985 2.605 4.632 3.916 7.96 3.916 2.5 0 4.494-.683 5.974-2.04 1.489-1.358 2.233-3.057 2.233-5.089 0-1.523-.413-2.862-1.231-3.998-.827-1.136-2.344-2.244-4.54-3.343zM24.1 27.407V6.427h-5.69V7.83a11.863 11.863 0 00-6.425-1.883C5.378 5.947 0 11.348 0 17.987c0 6.64 5.377 12.042 11.986 12.042 2.334 0 4.522-.674 6.36-1.837 0 .184-.01.35-.01.461 0 3.49-2.83 6.335-6.304 6.335a6.27 6.27 0 01-3.337-.97l-2.813 4.95a11.87 11.87 0 006.159 1.736c6.608 0 12.059-5.402 12.059-12.042 0-.258.018-1.015 0-1.255zm-12.115-3.085c-3.474 0-6.305-2.844-6.305-6.334 0-3.49 2.831-6.335 6.306-6.335 3.474 0 6.305 2.844 6.305 6.335 0 3.49-2.831 6.334-6.306 6.334zM46.766 6.417h-5.718v11.451c.01 1.625.01 4.432-1.185 5.632-.57.573-1.195 1.09-2.51 1.09-1.323 0-1.948-.517-2.518-1.09-1.195-1.2-1.186-4.017-1.176-5.633V6.418h-5.717V17.83c-.019 2.354-.046 6.722 2.821 9.621 1.682 1.7 3.686 2.567 6.581 2.567 2.896 0 4.9-.858 6.581-2.567 2.868-2.9 2.84-7.276 2.822-9.622l.019-11.413zM79.165 23.298c-.46.35-1.572 1.126-2.83.95-1.03-.138-1.867-.895-1.995-3.425V11.33h6.718V6.4H74.34V0H68.64v1.256h-.01v18.819c0 2.992.754 9.945 7.694 9.945 3.511-.055 5.644-1.967 6.535-2.715l.12-.092-3.42-4.23c-.009.01-.248.204-.395.315z"
                  fill="#F45D48"
                />
              </svg>
            </div>
            <div className="min-h-[184px] h-fit px-6 py-4 w-full flex flex-col justify-center gap-4 border-b border-[#D0D5DD]">
              {GUSTO_FEATURES.map((f, index) => (
                <div key={index} className="flex gap-3">
                  <svg
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="min-h-[22px] min-w-[22px]"
                  >
                    <path
                      d="M6.5 11.5L9.5 14.5L15.5 8.5M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                      stroke="#78BD4F"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-[#667085]">{f}</p>
                </div>
              ))}
            </div>

            <div className="pt-9 pb-7 px-7">
              {team?.app?.gusto?.[0]?.id ? (
                <div
                  onClick={() => navigate('/app-integrations/gusto-settings')}
                  className="font-medium cursor-pointer text-center w-fit mx-auto underline text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                  role="button"
                  tabIndex="0"
                >
                  Manage
                </div>
              ) : (
                <a
                  target="_blank"
                  href="https://app.getcount.com/integration/gusto/oauth"
                  rel="noreferrer"
                >
                  <button
                    className="h-[53px] w-full px-4 leading-tight bg-indigo-500 text-white rounded-[6px] inline-flex justify-center items-center gap-2 text-[16px] font-semibold disabled:bg-indigo-400 disabled:cursor-not-allowed"
                    disabled={gustoLoading}
                    onClick={onGustoConnect}
                    type="button"
                  >
                    {gustoLoading ? (
                      <svg
                        className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg>
                    ) : (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_4618_46369)">
                          <path
                            d="M7.2832 9.49851C7.5874 9.90518 7.9755 10.2417 8.42118 10.4852C8.86685 10.7287 9.35969 10.8735 9.86625 10.9097C10.3728 10.946 10.8812 10.8729 11.3571 10.6954C11.8329 10.5179 12.265 10.2402 12.624 9.88101L14.749 7.75601C15.3942 7.08804 15.7512 6.19341 15.7431 5.2648C15.735 4.33618 15.3625 3.44789 14.7059 2.79124C14.0492 2.13458 13.1609 1.76211 12.2323 1.75404C11.3037 1.74597 10.4091 2.10295 9.74112 2.74809L8.52279 3.95934"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.1163 8.08435C9.81207 7.67767 9.42398 7.34118 8.9783 7.09768C8.53262 6.85419 8.03979 6.70939 7.53323 6.67311C7.02667 6.63683 6.51823 6.70992 6.0424 6.88742C5.56657 7.06492 5.13448 7.34267 4.77544 7.70185L2.65044 9.82685C2.00529 10.4948 1.64831 11.3894 1.65638 12.3181C1.66445 13.2467 2.03693 14.135 2.69358 14.7916C3.35023 15.4483 4.23853 15.8207 5.16714 15.8288C6.09575 15.8369 6.99039 15.4799 7.65835 14.8348L8.8696 13.6235"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4618_46369">
                            <rect
                              width="17"
                              height="17"
                              fill="white"
                              transform="translate(0.199219 0.291016)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    Connect
                  </button>
                </a>
              )}
            </div>
          </div>
        )}

        {/* Square Box 2  */}
        {Env === DevEnv && (
          <div className="col-span-12 sm:col-span-6 xl:col-span-4 xxl:col-span-3 rounded-[10px] border border-[#D0D5DD]">
            <div className="h-[89px] border-b border-[#D0D5DD] bg-[#000000] w-full rounded-t-[inherit] pl-[22px] inline-flex items-center">
              <img src={SquareLogo} alt="square" className="h-14" />
            </div>
            <div className="min-h-[184px] h-fit px-6 py-4 w-full flex flex-col justify-center gap-4 border-b border-[#D0D5DD]">
              {SQUARE_FEATURES.map((f, index) => (
                <div key={index} className="flex gap-3">
                  <svg
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="min-h-[22px] min-w-[22px]"
                  >
                    <path
                      d="M6.5 11.5L9.5 14.5L15.5 8.5M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                      stroke="#78BD4F"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p className="text-[#667085]">{f}</p>
                </div>
              ))}
            </div>

            <div className="pt-9 pb-7 px-7">
              {squareIntegrated ? (
                <div
                  onClick={() => navigate('/app-integrations/square-settings')}
                  className="font-medium cursor-pointer text-center w-fit mx-auto underline text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                  role="button"
                  tabIndex="0"
                >
                  Manage
                </div>
              ) : (
                <a
                  target="_blank"
                  href="https://app.getcount.com/integration/square/oauth"
                  rel="noreferrer"
                >
                  <button
                    className="h-[53px] w-full px-4 leading-tight bg-indigo-500 text-white rounded-[6px] inline-flex justify-center items-center gap-2 text-[16px] font-semibold disabled:bg-indigo-400 disabled:cursor-not-allowed"
                    disabled={squareLoading}
                    onClick={onSquareConnect}
                    type="button"
                  >
                    {squareLoading ? (
                      <svg
                        className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                      </svg>
                    ) : (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="min-h-[18px] min-w-[18px]"
                      >
                        <g clipPath="url(#clip0_4618_46369)">
                          <path
                            d="M7.2832 9.49851C7.5874 9.90518 7.9755 10.2417 8.42118 10.4852C8.86685 10.7287 9.35969 10.8735 9.86625 10.9097C10.3728 10.946 10.8812 10.8729 11.3571 10.6954C11.8329 10.5179 12.265 10.2402 12.624 9.88101L14.749 7.75601C15.3942 7.08804 15.7512 6.19341 15.7431 5.2648C15.735 4.33618 15.3625 3.44789 14.7059 2.79124C14.0492 2.13458 13.1609 1.76211 12.2323 1.75404C11.3037 1.74597 10.4091 2.10295 9.74112 2.74809L8.52279 3.95934"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.1163 8.08435C9.81207 7.67767 9.42398 7.34118 8.9783 7.09768C8.53262 6.85419 8.03979 6.70939 7.53323 6.67311C7.02667 6.63683 6.51823 6.70992 6.0424 6.88742C5.56657 7.06492 5.13448 7.34267 4.77544 7.70185L2.65044 9.82685C2.00529 10.4948 1.64831 11.3894 1.65638 12.3181C1.66445 13.2467 2.03693 14.135 2.69358 14.7916C3.35023 15.4483 4.23853 15.8207 5.16714 15.8288C6.09575 15.8369 6.99039 15.4799 7.65835 14.8348L8.8696 13.6235"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4618_46369">
                            <rect
                              width="17"
                              height="17"
                              fill="white"
                              transform="translate(0.199219 0.291016)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                    Connect
                  </button>
                </a>
              )}
            </div>
          </div>
        )}

        <div className="col-span-12 sm:col-span-6 xl:col-span-4 xxl:col-span-3 rounded-[10px] border border-[#D0D5DD]">
          <div className="h-[89px] border-b border-[#D0D5DD]  w-full rounded-t-[inherit] pl-[22px] inline-flex items-center">
            <img src={QuickBookLogo} alt="square" className="h-20" />
          </div>
          <div className="min-h-[184px] h-fit px-6 py-4 w-full flex flex-col justify-center gap-4 border-b border-[#D0D5DD]">
            {QUICK_BOOK_FEATURES.map((f, index) => (
              <div key={index} className="flex gap-3">
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="min-h-[22px] min-w-[22px]"
                >
                  <path
                    d="M6.5 11.5L9.5 14.5L15.5 8.5M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                    stroke="#78BD4F"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-[#667085]">{f}</p>
              </div>
            ))}
          </div>

          <div className="pt-9 pb-7 px-7">
            {team?.app?.quickbooks?.[0]?.id ? (
              <div
                onClick={() =>
                  navigate('/app-integrations/quickbooks-settings')
                }
                className="font-medium cursor-pointer text-center w-fit mx-auto underline text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
                role="button"
                tabIndex="0"
              >
                Manage
              </div>
            ) : (
              <button
                className="h-[53px] w-full px-4 leading-tight bg-indigo-500 text-white rounded-[6px] inline-flex justify-center items-center gap-2 text-[16px] font-semibold disabled:bg-indigo-400 disabled:cursor-not-allowed"
                disabled={quickBookLoading}
                onClick={onQuickBookConnect}
                type="button"
              >
                {quickBookLoading ? (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                ) : (
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="min-h-[18px] min-w-[18px]"
                  >
                    <g clipPath="url(#clip0_4618_46369)">
                      <path
                        d="M7.2832 9.49851C7.5874 9.90518 7.9755 10.2417 8.42118 10.4852C8.86685 10.7287 9.35969 10.8735 9.86625 10.9097C10.3728 10.946 10.8812 10.8729 11.3571 10.6954C11.8329 10.5179 12.265 10.2402 12.624 9.88101L14.749 7.75601C15.3942 7.08804 15.7512 6.19341 15.7431 5.2648C15.735 4.33618 15.3625 3.44789 14.7059 2.79124C14.0492 2.13458 13.1609 1.76211 12.2323 1.75404C11.3037 1.74597 10.4091 2.10295 9.74112 2.74809L8.52279 3.95934"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.1163 8.08435C9.81207 7.67767 9.42398 7.34118 8.9783 7.09768C8.53262 6.85419 8.03979 6.70939 7.53323 6.67311C7.02667 6.63683 6.51823 6.70992 6.0424 6.88742C5.56657 7.06492 5.13448 7.34267 4.77544 7.70185L2.65044 9.82685C2.00529 10.4948 1.64831 11.3894 1.65638 12.3181C1.66445 13.2467 2.03693 14.135 2.69358 14.7916C3.35023 15.4483 4.23853 15.8207 5.16714 15.8288C6.09575 15.8369 6.99039 15.4799 7.65835 14.8348L8.8696 13.6235"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4618_46369">
                        <rect
                          width="17"
                          height="17"
                          fill="white"
                          transform="translate(0.199219 0.291016)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                )}
                Connect
              </button>
            )}
          </div>
        </div>

        {/* Square Box 3 */}

        {/* <div className="col-span-12 sm:col-span-6 xl:col-span-4 xxl:col-span-3 rounded-[10px] border border-[#D0D5DD]">
        <div className="h-[89px] border-b border-[#D0D5DD] bg-[#000000] w-full rounded-t-[inherit] pl-[22px] inline-flex items-center">
          <img src={SquareLogo} alt="square" className="h-14" />
        </div>
        <div
          className={`min-h-[184px] h-fit px-6 py-4 w-full flex flex-col justify-center gap-4 border-b border-[#D0D5DD]`}
        >
          {STRIPE_FEATURES.map((f) => (
            <div className="flex gap-3">
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="min-h-[22px] min-w-[22px]"
              >
                <path
                  d="M6.5 11.5L9.5 14.5L15.5 8.5M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                  stroke="#78BD4F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-[#667085]">{f}</p>
            </div>
          ))}
        </div>

        <div className="pt-9 pb-7 px-7">
          <a
            target="_blank"
            href={"https://app.getcount.com/integration/square/oauth"}
          >
            <button
              className="h-[53px] w-full px-4 leading-tight bg-indigo-500 text-white rounded-[6px] inline-flex justify-center items-center gap-2 text-[16px] font-semibold disabled:bg-indigo-400 disabled:cursor-not-allowed"
              disabled={!team?.id}
            >
              {false ? (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              ) : (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="min-h-[18px] min-w-[18px]"
                >
                  <g clip-path="url(#clip0_4618_46369)">
                    <path
                      d="M7.2832 9.49851C7.5874 9.90518 7.9755 10.2417 8.42118 10.4852C8.86685 10.7287 9.35969 10.8735 9.86625 10.9097C10.3728 10.946 10.8812 10.8729 11.3571 10.6954C11.8329 10.5179 12.265 10.2402 12.624 9.88101L14.749 7.75601C15.3942 7.08804 15.7512 6.19341 15.7431 5.2648C15.735 4.33618 15.3625 3.44789 14.7059 2.79124C14.0492 2.13458 13.1609 1.76211 12.2323 1.75404C11.3037 1.74597 10.4091 2.10295 9.74112 2.74809L8.52279 3.95934"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.1163 8.08435C9.81207 7.67767 9.42398 7.34118 8.9783 7.09768C8.53262 6.85419 8.03979 6.70939 7.53323 6.67311C7.02667 6.63683 6.51823 6.70992 6.0424 6.88742C5.56657 7.06492 5.13448 7.34267 4.77544 7.70185L2.65044 9.82685C2.00529 10.4948 1.64831 11.3894 1.65638 12.3181C1.66445 13.2467 2.03693 14.135 2.69358 14.7916C3.35023 15.4483 4.23853 15.8207 5.16714 15.8288C6.09575 15.8369 6.99039 15.4799 7.65835 14.8348L8.8696 13.6235"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4618_46369">
                      <rect
                        width="17"
                        height="17"
                        fill="white"
                        transform="translate(0.199219 0.291016)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
              Connect With Square
            </button>
          </a>
        </div>
      </div> */}

        {/* Square Box 4 */}
        {/* <div className="col-span-12 sm:col-span-6 xl:col-span-4 xxl:col-span-3 rounded-[10px] border border-[#D0D5DD]">
        <div className="h-[89px] border-b border-[#D0D5DD] bg-[#000000] w-full rounded-t-[inherit] pl-[22px] inline-flex items-center">
          <img src={SquareLogo} alt="square" className="h-14" />
        </div>
        <div
          className={`min-h-[184px] h-fit px-6 py-4 w-full flex flex-col justify-center gap-4 border-b border-[#D0D5DD]`}
        >
          {STRIPE_FEATURES.map((f) => (
            <div className="flex gap-3">
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="min-h-[22px] min-w-[22px]"
              >
                <path
                  d="M6.5 11.5L9.5 14.5L15.5 8.5M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                  stroke="#78BD4F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-[#667085]">{f}</p>
            </div>
          ))}
        </div>

        <div className="pt-9 pb-7 px-7">
          <a
            target="_blank"
            href={"https://app.getcount.com/integration/square/oauth"}
          >
            <button
              className="h-[53px] w-full px-4 leading-tight bg-indigo-500 text-white rounded-[6px] inline-flex justify-center items-center gap-2 text-[16px] font-semibold disabled:bg-indigo-400 disabled:cursor-not-allowed"
              disabled={!team?.id}
            >
              {false ? (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              ) : (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="min-h-[18px] min-w-[18px]"
                >
                  <g clip-path="url(#clip0_4618_46369)">
                    <path
                      d="M7.2832 9.49851C7.5874 9.90518 7.9755 10.2417 8.42118 10.4852C8.86685 10.7287 9.35969 10.8735 9.86625 10.9097C10.3728 10.946 10.8812 10.8729 11.3571 10.6954C11.8329 10.5179 12.265 10.2402 12.624 9.88101L14.749 7.75601C15.3942 7.08804 15.7512 6.19341 15.7431 5.2648C15.735 4.33618 15.3625 3.44789 14.7059 2.79124C14.0492 2.13458 13.1609 1.76211 12.2323 1.75404C11.3037 1.74597 10.4091 2.10295 9.74112 2.74809L8.52279 3.95934"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.1163 8.08435C9.81207 7.67767 9.42398 7.34118 8.9783 7.09768C8.53262 6.85419 8.03979 6.70939 7.53323 6.67311C7.02667 6.63683 6.51823 6.70992 6.0424 6.88742C5.56657 7.06492 5.13448 7.34267 4.77544 7.70185L2.65044 9.82685C2.00529 10.4948 1.64831 11.3894 1.65638 12.3181C1.66445 13.2467 2.03693 14.135 2.69358 14.7916C3.35023 15.4483 4.23853 15.8207 5.16714 15.8288C6.09575 15.8369 6.99039 15.4799 7.65835 14.8348L8.8696 13.6235"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4618_46369">
                      <rect
                        width="17"
                        height="17"
                        fill="white"
                        transform="translate(0.199219 0.291016)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
              Connect With Square
            </button>
          </a>
        </div>
      </div> */}
      </div>
    </>
  );
};

export default AppIntegrations;
