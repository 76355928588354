import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import { updateProject } from '../../API/backend_helper';

const renderEventContent = (eventInfo) => {
  const backgroundColor =
    eventInfo?.event?.extendedProps?.status === 'Not started'
      ? '#6670851'
      : eventInfo?.event?.extendedProps?.status === 'In progress'
        ? '#42B4F71'
        : eventInfo?.event?.extendedProps?.status === 'Completed'
          ? '#40AA001'
          : '#E486421';
  return (
    <div
      className={`bg-[${backgroundColor}] py-[2px] px-2`}
      style={{
        backgroundColor,
        border: backgroundColor,
        color: 'white',
        cursor: 'pointer',
      }}
    >
      <p>{eventInfo.event.title}</p>
    </div>
  );
};

const CalendarView = ({
  projects,
  statusOptions,
  setSelectedProject,
  setProjectView,
}) => {
  const calendarRef = useRef(null);
  const dragContainerRef = useRef(null);
  const navigate = useNavigate();
  const { accessType, permissions } = useSelector((state) => state.User);

  const [projectsWithoutDates, setProjectsWithoutDates] = useState([]);

  const events = projects.reduce((acc, project) => {
    const color =
      project.status === 'Not started'
        ? '#667085'
        : project.status === 'In progress'
          ? '#42B4F7'
          : project.status === 'Completed'
            ? '#40AA00'
            : '#E48642';
    const baseData = {
      editable: true,
      startEditable: true,
      durationEditable: true,
      resourceEditable: true,
      allDay: true,
      id: project.id,
      extendedProps: {
        status: project.status,
      },
      color,
    };
    if (project.startDate || project.endDate) {
      if (project.startDate && project.endDate) {
        const adjustedEndDate = moment
          .utc(project.endDate)
          .add(1, 'days')
          .format('YYYY-MM-DD');
        acc.push({
          ...baseData,
          title: project.name,
          start: moment.utc(project.startDate).format('YYYY-MM-DD'),
          end: adjustedEndDate,
        });
      } else if (project.startDate) {
        const adjustedStartDate = moment
          .utc(project.startDate)
          .format('YYYY-MM-DD');
        acc.push({
          ...baseData,
          title: project.name,
          start: adjustedStartDate,
        });
      } else if (project.endDate) {
        const adjustedEndDate = moment
          .utc(project.endDate)
          .add(1, 'days')
          .format('YYYY-MM-DD');
        acc.push({
          ...baseData,
          title: project.name,
          end: adjustedEndDate,
        });
      }
    }
    return acc;
  }, []);

  useEffect(() => {
    if (projects?.length > 0) {
      setProjectsWithoutDates(
        projects.filter((project) => !project.startDate && !project.endDate),
      );
    }
  }, [projects]);

  useEffect(() => {
    const draggableEl = dragContainerRef.current;
    new Draggable(draggableEl, {
      itemSelector: '.draggable-project',
      eventData(eventEl) {
        const projectId = eventEl.getAttribute('data-id');
        const projectStatus = eventEl.getAttribute('data-status');
        const statusOption = statusOptions.find(
          (option) => option.id === projectStatus,
        );
        const backgroundColor = statusOption.background;
        const color = statusOption.textColor;
        return {
          title: eventEl.innerText,
          id: projectId,
          color,
          classNames: ['truncate'],
          extendedProps: {
            status: projectStatus,
          },
        };
      },
    });
  }, []);

  const handleEventReceive = async (info) => {
    const project = projects.find(
      (project) => parseFloat(project.id) === parseFloat(info.event.id),
    );
    if (project) {
      project.startDate = info.event.startStr;
      try {
        setProjectsWithoutDates(
          projectsWithoutDates.filter(
            (p) => parseFloat(p.id) !== parseFloat(info.event.id),
          ),
        );
        await updateProject(project.id, project);
      } catch (err) {
        console.log('err', err);
      }
    }
  };

  const handleEventDrop = async (info) => {
    const project = projects.find(
      (project) => parseFloat(project.id) === parseFloat(info.event.id),
    );

    if (project) {
      project.startDate = info.event.startStr;

      if (info.event.endStr) {
        const startDate = moment(info.event.startStr);
        const endDate = moment(info.event.endStr);

        const duration = endDate.diff(startDate, 'days');

        if (duration > 1) {
          project.endDate = endDate.subtract(1, 'days').format('YYYY-MM-DD');
        } else {
          delete project.endDate;
        }
      } else {
        delete project.endDate;
      }

      try {
        await updateProject(project.id, project);
      } catch (err) {
        console.log('err', err);
      }
    }
  };

  const handleEventResize = async (info) => {
    const project = projects.find(
      (project) => parseFloat(project.id) === parseFloat(info.event.id),
    );

    if (project) {
      project.startDate = info.event.startStr;

      if (info.event.endStr) {
        const startDate = moment(info.event.startStr);
        const endDate = moment(info.event.endStr);

        const duration = endDate.diff(startDate, 'days');

        if (duration > 1) {
          project.endDate = endDate.subtract(1, 'days').format('YYYY-MM-DD');
        } else {
          delete project.endDate;
        }
      } else {
        delete project.endDate;
      }
      try {
        await updateProject(project.id, project);
      } catch (err) {
        console.log('err', err);
      }
    }
  };

  const handleEventClick = (info) => {
    const project = projects.find(
      (project) => parseFloat(project.id) === parseFloat(info.event.id),
    );
    if (project) {
      navigate(`/projects/${project?.id}?tab=overview`);
    }
  };

  return (
    <div className="flex xl:flex-row flex-col gap-4">
      <div
        ref={dragContainerRef}
        className={`${projectsWithoutDates?.length > 0 ? 'max-w-full w-[400px] xl:w-[20%]' : 'hidden'}`}
      >
        <div className="mb-[2rem] mt-[4px]">
          <h3 className="m-0 p-0 text-[1.5rem] leading-[2rem] text-slate-600">
            Not Scheduled
          </h3>
        </div>
        <div className="p-2.5 rounded-xl border border-[#D0D5DD] flex flex-col gap-2">
          {projectsWithoutDates.map((project) => {
            const statusOption = statusOptions.find(
              (option) => option.id === project.status,
            );
            const backgroundColor = statusOption.background;
            const color = statusOption.textColor;
            return (
              <div
                key={project.id}
                className={` ${
                  checkPermission(
                    PermissionKeys.MANAGE_PROJECTS,
                    accessType,
                    permissions,
                  )
                    ? 'draggable-project cursor-grab'
                    : 'cursor-pointer'
                }  rounded-xl p-2`}
                data-id={project.id}
                data-status={project.status}
                style={{
                  backgroundColor,
                  color,
                }}
                onClick={() => {
                  navigate(`/projects/${project?.id}?tab=overview`);
                }}
                role="button"
                tabIndex="0"
                disabled={
                  !checkPermission(
                    PermissionKeys.MANAGE_PROJECTS,
                    accessType,
                    permissions,
                  )
                }
              >
                {project.name}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`${projectsWithoutDates?.length > 0 ? 'w-full xl:w-[80%]' : 'w-full'}`}
      >
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          eventContent={renderEventContent}
          editable
          droppable
          eventReceive={handleEventReceive}
          buttonText={{ today: 'Today' }}
          eventDurationEditable
          eventResizableFromStart
          eventStartEditable
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          eventClick={handleEventClick}
          fixedWeekCount={false}
          aspectRatio={1.2}
        />
      </div>
    </div>
  );
};

export default CalendarView;
