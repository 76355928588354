import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getStringSegments } from 'utils/Utils';
import Tooltip from '../../../components/Tooltip';
import DollarIcon from '../../../images/custom/DollarIcon.png';
import { updateTransaction } from '../../../API/backend_helper';
import useClickOutside from '../../../utils/useClickOutside';

const ReusableAccount = ({
  account,
  setDropdownOpen,
  updateAccount,
  dropdownOpen,
  details,
}) => (
  <div className="ml-2 cursor-pointer" key={account?.id}>
    <span
      className={`font-normal whitespace-nowrap flex items-center pr-4 text-[13px] text-slate-600  hover:text-slate-800 dark:hover:text-slate-200 py-1 px-3
                  ${account?.id === details?.accountId && '!text-indigo-500 hover:!text-indigo-600'}`}
      onClick={() => {
        setDropdownOpen(!dropdownOpen);
        if (account?.id !== details?.accountId) {
          updateAccount(account);
        }
      }}
      tabIndex="0"
      role="button"
      onKeyDown={null}
    >
      {account?.name} {account?.mask && `(...${account?.mask})`}
      <svg
        className={`shrink-0 ml-2 fill-current text-indigo-500 ${
          account?.id !== details?.accountId && 'invisible'
        }`}
        width="12"
        height="9"
        viewBox="0 0 12 9"
      >
        <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
      </svg>
    </span>
  </div>
);

const PaymentMethod = ({
  details,
  type,
  institution,
  dropdown,
  accounts,
  disabled = false,
  reloadTransaction,
  flyout,
  setLoader = () => {},
}) => {
  const dropdownRef = useRef(null);
  const trigger = useRef(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState(institution);

  useClickOutside(dropdownRef, (event) => {
    if (!trigger.current.contains(event.target)) {
      if (dropdownOpen) {
        setDropdownOpen(false);
      }
    }
  });

  useEffect(() => {
    if (!institution) {
      setSelectedInstitution(details?.account);
    }
  }, []);

  const updateAccount = async (account) => {
    setDropdownOpen(false);
    if (+account?.id !== +institution?.id) {
      setLoader(true);
      try {
        await updateTransaction(details?.id, {
          accountId: account?.id,
        });
        setSelectedInstitution(account?.institution);
        details.account = account;
        details.accountId = account?.id;
        reloadTransaction({ scrollToTop: false });
      } catch (e) {
        toast.error(
          'An error occurred while changing accounts. Please refresh and try again.',
        );
        setSelectedInstitution(institution);
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <div
      className={`relative flex justify-between w-full items-center ${dropdown && !disabled ? 'cursor-pointer' : 'cursor-not-allowed'}`}
      onClick={() => {
        if (!disabled && dropdown) {
          setDropdownOpen(!dropdownOpen);
        }
      }}
      ref={trigger}
      tabIndex="0"
      role="button"
      onKeyDown={null}
    >
      <div
        className={`col-span-6 order-1 sm:order-none sm:col-span-3 flex items-center justify-between rounded space-x-4 lg:sidebar-expanded:col-span-6 xl:sidebar-expanded:col-span-3 border border-gray-300 px-2.5 py-[0.438rem] ${flyout ? 'w-full' : 'w-[16.688rem]'}  h-10`}
      >
        <div className="items-center gap-2.5 flex">
          <Tooltip
            position="top-right"
            content={
              details?.account?.type?.type === 'Cash' ||
              details?.account?.type?.subtype === 'Cash'
                ? 'Cash'
                : selectedInstitution?.name?.length > 30
                  ? (() => {
                      const segments = getStringSegments(
                        selectedInstitution?.name,
                        40,
                      );
                      return segments.map((segment, index) => (
                        <p key={index}>{segment}</p>
                      ));
                    })()
                  : selectedInstitution?.name
            }
            contentClassName="text-rose-500"
          >
            <div
              className="w-[1.625rem] h-[1.625rem]  shrink-0  rounded-full  mr-0"
              style={
                (selectedInstitution?.color || details?.color) &&
                !selectedInstitution?.logoUrl
                  ? {
                      background:
                        details?.account?.color || selectedInstitution?.color,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  : {}
              }
            >
              {type === 'account' &&
                (details?.account?.type?.type === 'Cash' ||
                  details?.account?.type?.subtype === 'Cash') && (
                  <img
                    src={DollarIcon}
                    alt="logo"
                    className="w-10 h-5 mt-1.5 fill-current text-white"
                  />
                )}
              {selectedInstitution?.logoUrl ? (
                <img
                  src={`${selectedInstitution?.logoUrl}`}
                  alt="logo"
                  className="w-[1.625rem] h-[1.625rem] fill-current text-white object-cover"
                />
              ) : (
                <div className="flex items-center justify-center w-[1.625rem] h-[1.725rem]">
                  <div
                    style={{ textTransform: 'uppercase' }}
                    className="text-black font-normal text-xs"
                  >
                    {details?.account?.institution?.name
                      ?.split(' ')
                      .slice(0, 2)
                      .map((word) => word.charAt(0))
                      .join('') ||
                      details?.account?.name
                        ?.split(' ')
                        .slice(0, 2)
                        .map((word) => word.charAt(0))
                        .join('')}
                  </div>
                </div>
              )}
            </div>
          </Tooltip>
          <div>
            <div className="text-sm font-medium leading-6 text-gray-500 dark:text-slate-100">
              {details?.account?.name?.length > 50
                ? `${details?.account?.name.slice(0, 50)}...`
                : details?.account?.name}{' '}
              {details?.account?.mask && `(...${details?.account?.mask})`}
            </div>
          </div>
        </div>
        <svg
          onClick={setDropdownOpen}
          className={`ml-auto cursor-pointer ${dropdownOpen ? 'rotate-180' : ''}`}
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
            stroke="#667085"
            strokeOpacity="0.3"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {dropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute  top-10  mt-1 w-full h-40 overflow-y-auto scrollbar rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
        >
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="max-h-60 pt-2 w-full flex flex-col">
              {accounts?.map(
                (group) =>
                  group?.categories?.length > 0 && (
                    <div key={group?.id}>
                      <div
                        className="cursor-default"
                        onClick={(e) => e.stopPropagation()}
                        tabIndex="0"
                        role="button"
                        onKeyDown={null}
                      >
                        <span className="font-semibold text-[10px] uppercase tracking-wider whitespace-nowrap flex items-center pr-4  text-[#515A6C]  py-1 px-3">
                          {group?.name}
                        </span>
                      </div>
                      {group?.categories?.map((account) => (
                        <>
                          <ReusableAccount
                            key={account?.id}
                            account={account}
                            setDropdownOpen={setDropdownOpen}
                            updateAccount={updateAccount}
                            dropdownOpen={dropdownOpen}
                            details={details}
                          />
                          <div className="ml-4">
                            {account?.subAccounts?.map((subAccount) => (
                              <ReusableAccount
                                key={subAccount?.id}
                                account={subAccount}
                                setDropdownOpen={setDropdownOpen}
                                updateAccount={updateAccount}
                                dropdownOpen={dropdownOpen}
                                details={details}
                              />
                            ))}
                          </div>
                        </>
                      ))}
                    </div>
                  ),
              )}
            </div>
          </div>
        </div>
      )}

      {(type === 'account' &&
        (details?.type === 'cash' || details?.subtype === 'cash')) ||
      type === 'panel' ? null : (
        <div className="text-sm leading-6 font-normal text-gray-500 text-opacity-80">
          **{details?.accountMask || details?.mask || details?.account?.mask}
        </div>
      )}
    </div>
  );
};

export default PaymentMethod;
