import { useEffect, useState } from 'react';
import { getCustomerById, getCustomers } from 'API/backend_helper';

let searchTimeout = null;

const useCustomerSearch = (customers = [], setCustomers = () => {}) => {
  const [dropdownCustomers, setDropdownCustomers] = useState([]);
  const [searchCustomerLoading, setSearchCustomerLoading] = useState(false);

  useEffect(() => {
    setDropdownCustomers([...customers]);
  }, [customers]);

  const getCustomersBySearch = async ({ limit = 50, search = '' }) => {
    try {
      const res = await getCustomers({
        search,
        limit,
        status: 'active',
      });
      return res?.data;
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadCustomerById = async (id) => {
    const selectedCustomer = customers?.find(
      (customer) => +customer?.id === +id,
    );
    if (selectedCustomer?.id) {
      setDropdownCustomers([selectedCustomer]);
      return;
    }
    try {
      const res = await getCustomerById(id);
      if (res?.data?.customer && res?.data?.customer?.status === 'active') {
        setDropdownCustomers([res?.data?.customer, ...customers]);
      } else {
        setDropdownCustomers([...customers]);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleCustomerSearch = (value, customerId) => {
    clearTimeout(searchTimeout);
    if (value) {
      searchTimeout = setTimeout(async () => {
        setSearchCustomerLoading(true);
        const data = await getCustomersBySearch({
          search: encodeURIComponent(value || ''),
        });
        setSearchCustomerLoading(false);
        const filteredCustomers = data?.records?.filter((customer) =>
          customer.customer.toLowerCase().includes(value.toLowerCase()),
        );
        const isInSearchData = data?.records?.filter(
          (c) => c?.id === customerId,
        );
        const selectedCustomer = dropdownCustomers?.find(
          (c) => c?.id === customerId,
        );
        if (isInSearchData?.length === 0 && selectedCustomer) {
          setDropdownCustomers([selectedCustomer, ...filteredCustomers]);
        } else {
          setDropdownCustomers(filteredCustomers);
        }
      }, 500);
    } else {
      let selectedCustomer = null;
      selectedCustomer = dropdownCustomers?.find((c) => c?.id === customerId);
      if (customerId && selectedCustomer) {
        setDropdownCustomers([...customers]);
      } else if (customerId && !selectedCustomer) {
        loadCustomerById(customerId);
      } else {
        setDropdownCustomers([...customers]);
      }
    }
  };
  const resetCustomerSearch = (selectedOption) => {
    // setDropdownCustomers([selectedOption]);
    const isAlreadyInCustomer =
      customers?.find((cus) => cus?.id === selectedOption?.id)?.id ===
      selectedOption?.id;
    if (isAlreadyInCustomer) {
      setDropdownCustomers([...customers]);
    } else if (selectedOption?.status === 'active') {
      setDropdownCustomers([selectedOption, ...customers]);
      setCustomers([selectedOption, ...customers]);
    }
  };

  return {
    dropdownCustomers,
    setDropdownCustomers,
    handleCustomerSearch,
    resetCustomerSearch,
    searchCustomerLoading,
    loadCustomerById,
  };
};

export default useCustomerSearch;
