import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import VendorForm from './VendorForm';
import {
  createMerchant,
  getMerchants,
  updateMerchant,
  uploadMerchantLogo,
  deleteMerchant,
  getTransactions,
  getSingleMerchant,
} from '../../API/backend_helper';
import SearchForm from '../../partials/actions/SearchForm';
import PaginationClassic from '../../components/PaginationClassic';
import DeleteVendorModal from './DeleteVendorModal';
import Tooltip from '../../components/Tooltip';
import emptySearchImg from '../../images/custom/empty-search.svg';
import { TEAM_CURRENCY, trimText } from '../../utils/Utils';
import VendorFlyout from './VendorFlyout';
import AppLayout from '../../components/AppLayout';

const VendorType = {
  MERCHANT: 'Merchant',
  SUPPLIER: 'Supplier',
  CONTRACTOR: 'Contractor',
};

const STATUS_OPTIONS = [
  {
    name: 'Active',
    textColor: '#78BD4F',
    background: '#40AA001A',
    id: 'active',
  },
  {
    name: 'Inactive',
    textColor: '#E48642',
    background: '#E486421A',
    id: 'inactive',
  },
];

let searchTimeout = null;

const Vendors = () => {
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [isLoading, setIsLoading] = useState(false);
  const [buttonToggler, setButtonToggler] = useState(false);
  const [flyoutIsLoading, setFlyoutIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [activeVendor, setActiveVendor] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [localLimit, setLocalLimit] = useState(50);
  const [formOpen, setFormOpen] = useState(false);
  const [title, setTitle] = useState('Vendors');
  const [errMessage, setErrMessage] = useState('');
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [activeVendorTransactions, setActiveVendorTransactions] = useState([]);
  const [activeVendorAnalytics, setActiveVendorAnalytics] = useState({});
  const [deleteVendorLoading, setDeleteVendorLoading] = useState(false);
  const [vendorNameLength, setVendorNameLength] = useState(75);

  const { accessType, permissions } = useSelector((state) => state.User);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setVendorNameLength(75);
      } else if (window.innerWidth > 1400) {
        setVendorNameLength(55);
      } else if (window.innerWidth > 1200) {
        setVendorNameLength(50);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(PermissionKeys.VIEW_VENDORS, accessType, permissions)
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  const getData = async ({
    limit = localLimit,
    page = vendors?.page,
    search = searchValue || '',
    loading = false,
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getMerchants({
        page,
        search,
        limit,
      });
      setVendors(res);
      if (loading) {
        setIsLoading(false);
      }
    } catch (e) {
      if (loading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const handleGetTransactions = async () => {
    try {
      const response = await getTransactions({
        limit: 2,
        vendors: activeVendor?.id,
      });
      setActiveVendorTransactions(response?.transactions);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleGetVendor = async () => {
    try {
      setFlyoutIsLoading(true);
      const response = await getSingleMerchant(activeVendor?.id);
      setActiveVendorAnalytics(response?.transactionStats);
      setFlyoutIsLoading(false);
    } catch (err) {
      console.log('err', err);
      setFlyoutIsLoading(false);
    }
  };

  const handleAddVendor = async (data) => {
    try {
      const response = await createMerchant(data);
      getData({});
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdateVendor = async (data, id) => {
    try {
      const response = await updateMerchant(data, id);
      getData({});
      return response;
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  const handleUploadVendorLogo = async (data, id) => {
    try {
      await uploadMerchantLogo(data, id);
      getData({});
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleDeleteVendor = async (vendor) => {
    try {
      setDeleteVendorLoading(true);
      await deleteMerchant(vendor.id);
      getData({});
      setActiveVendor(null);
      setModalOpen(false);
      toast.success('Vendor deleted successfully');
    } catch (err) {
      if (err.message) {
        setErrMessage(JSON.parse(err.message));
        setModalOpen(true);
      } else {
        setModalOpen(false);
      }
    }
    setDeleteVendorLoading(false);
  };

  const { page, totalRecords } = vendors;

  const onSearch = (val) => {
    setSearchValue(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getData({
        page,
        search: encodeURIComponent(val || ''),
        loading: false,
      });
    }, 900);
  };

  const goToTransactions = (vendorId) => {
    navigate(`/transactions?page=1&vendor=${vendorId}`);
  };

  useEffect(() => {
    getData({ loading: true });
  }, []);

  useEffect(() => {
    if (!formOpen && !flyoutOpen && !modalOpen) {
      setFlyoutOpen(false);
      setActiveVendor(null);
      getData({ loading: true });
    }
  }, []);

  useEffect(() => {
    if (activeVendor) {
      handleGetTransactions();
      handleGetVendor();
    }
  }, [activeVendor]);

  useEffect(() => {
    if (location?.state?.insights) {
      setTitle('Edit Vendor');
      setActiveVendor(location?.state?.vendor);
      setFlyoutOpen(false);
      setFormOpen(true);
    }
  }, [location]);

  document.title = 'COUNT | Vendors';

  return (
    <>
      {/*
      <VendorModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        handleAddVendor={handleAddVendor}
        activeVendor={activeVendor}
        handleUpdateVendor={handleUpdateVendor}
        handleUploadVendorLogo={handleUploadVendorLogo}
        setActiveVendor={setActiveVendor}
        setFormOpen={setFormOpen}
        setTitle={setTitle}
      /> */}
      <DeleteVendorModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        handleDeleteVendor={handleDeleteVendor}
        activeVendor={activeVendor}
        errMessage={errMessage}
        setErrMessage={setErrMessage}
        deleteVendorLoading={deleteVendorLoading}
      />
      <AppLayout>
        <main className="relative grow ">
          <VendorFlyout
            flyoutOpen={flyoutOpen}
            setFlyoutOpen={setFlyoutOpen}
            activeVendor={activeVendor}
            setActiveVendor={setActiveVendor}
            activeVendorTransactions={activeVendorTransactions}
            activeVendorAnalytics={activeVendorAnalytics}
            goToTransactions={goToTransactions}
            handleUpdateVendor={handleUpdateVendor}
            flyoutIsLoading={flyoutIsLoading}
            setTitle={setTitle}
            setFormOpen={setFormOpen}
            currency={currencies?.find?.(
              (c) => c.isoCode === teamDefaultCurrency,
            )}
          />
          {/* Content */}
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {/* Page header */}
            <div className="flex justify-between items-center mb-10">
              <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                {title}
              </h1>
              {!formOpen ? (
                <div className="sm:flex gap-4 items-center">
                  <SearchForm
                    placeholder="Search"
                    searchVal={searchValue}
                    setSearchVal={onSearch}
                    transaction
                  />
                  {checkPermission(
                    PermissionKeys.MANAGE_VENDORS,
                    accessType,
                    permissions,
                  ) && (
                    <div>
                      <div className="h-10 flex relative">
                        <button
                          className={`flex gap-2 items-center justify-center rounded-tl-[5px]
                                ${
                                  !buttonToggler ? 'rounded-bl-[5px]' : ''
                                }  h-full px-2 border border-indigo-500  bg-indigo-500 text-white disabled:bg-indigo-400`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveVendor(null);
                            setTitle('Add Vendor');
                            setFormOpen(true);
                          }}
                          type="button"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            className=""
                          >
                            <path
                              d="M10 4.16602V15.8327M4.16667 9.99935H15.8333"
                              stroke="white"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Add a Vendor
                        </button>
                        <button
                          onClick={() => setButtonToggler(!buttonToggler)}
                          className={`rounded-tr-[5px] ${
                            !buttonToggler ? 'rounded-br-[5px]' : ''
                          }  h-full px-2 border border-l-[#FFFFFFCC] border-indigo-500  bg-indigo-500 text-white`}
                          type="button"
                        >
                          <svg
                            className={`shrink-0 ml-1 text-white  ${
                              buttonToggler ? 'rotate-180' : ''
                            }`}
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                              stroke="#ffffff"
                              strokeOpacity="0.8"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                      {buttonToggler && (
                        <button
                          onClick={() => navigate('/import-vendors')}
                          className="absolute  w-[175px] rounded-bl-[5px] rounded-br-[5px] h-10 border border-t-[#FFFFFFCC] border-indigo-500  bg-indigo-500 text-white"
                          type="button"
                        >
                          Import Vendors
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => {
                    setActiveVendor(null);
                    setTitle('Vendors');
                    setFormOpen(false);
                  }}
                  className=""
                  type="button"
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="6.17688"
                      y="7.82422"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(-45 6.17688 7.82422)"
                      fill="#667085"
                    />
                    <rect
                      x="20.1771"
                      y="6.17676"
                      width="2.3292"
                      height="19.7982"
                      rx="1.1646"
                      transform="rotate(45 20.1771 6.17676)"
                      fill="#667085"
                    />
                  </svg>
                </button>
              )}
            </div>
            {formOpen && (
              <VendorForm
                setActiveVendor={setActiveVendor}
                activeVendor={activeVendor}
                handleUpdateVendor={handleUpdateVendor}
                handleUploadVendorLogo={handleUploadVendorLogo}
                handleAddVendor={handleAddVendor}
                setFormOpen={setFormOpen}
                setTitle={setTitle}
              />
            )}
            {!formOpen && (
              <>
                {/* Table */}
                {isLoading ? (
                  <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                    <svg
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="animate-spin w-10 h-10 fill-current shrink-0"
                    >
                      <path
                        d="M14.5 2.41699V7.25033"
                        stroke="#E48642"
                        strokeWidth="2.55882"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.5 21.749V26.5824"
                        stroke="#E48642"
                        strokeWidth="2.55882"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.95703 5.95703L9.37662 9.37662"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.623 19.6211L23.0426 23.0407"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.41699 14.5H7.25033"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21.749 14.5H26.5824"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.95703 23.0407L9.37662 19.6211"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.623 9.37662L23.0426 5.95703"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                      Fetching data, please wait...
                    </p>
                  </div>
                ) : (
                  <div className="bg-white flex-1">
                    {/* Table */}
                    <div className="overflow-x-auto h-full">
                      <table className="table-auto w-full dark:text-slate-300">
                        {/* Table header */}
                        <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                          <tr className="w-full py-[1.438rem] px-[1.375rem] flex items-center justify-between">
                            <th className="">
                              <div className="text-left 3xl:w-[20rem] w-[15rem] font-medium">
                                Name
                              </div>
                            </th>
                            <th className="pl-4">
                              <div className="text-left w-[8.75rem] font-medium">
                                Type
                              </div>
                            </th>
                            <th className="">
                              <div className="text-left w-[8.75rem] font-medium">
                                Account Number
                              </div>
                            </th>
                            <th className="">
                              <div className="text-left w-[12.5rem] font-medium">
                                Contact
                              </div>
                            </th>
                            <th className="max-w-[6rem] w-[6rem] ">
                              <div className="text-center font-medium">
                                Status
                              </div>
                            </th>
                            <th className="">
                              <div className="text-left w-[9rem] font-medium">
                                Actions
                              </div>
                            </th>
                          </tr>
                        </thead>
                        {/* Table body */}
                        {vendors?.vendors?.length > 0 && (
                          <tbody className="text-sm divide-y divide-gray-300 border-b border-gray-300">
                            {vendors?.vendors?.map((vendor) => (
                              <tr
                                key={vendor.id}
                                className={`w-full pl-[1.375rem] pr-[1.125rem] flex items-center justify-between h-20 hover:bg-[#a0cd85] hover:bg-opacity-5 cursor-pointer ${
                                  activeVendor?.id === vendor?.id
                                    ? 'bg-[#a0cd85] bg-opacity-5'
                                    : ''
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setActiveVendor(vendor);
                                  setFlyoutOpen(true);
                                }}
                              >
                                <td className="">
                                  <div className="flex items-center 3xl:w-[20rem] w-[15rem]">
                                    <div className="shrink-0 mr-[0.563rem]">
                                      {vendor?.logoUrl ? (
                                        <img
                                          className="w-[1.687rem] h-[1.687rem] rounded-[5.125rem]"
                                          src={vendor?.logoUrl}
                                          width="26.996"
                                          height="26.996"
                                          alt={vendor?.name}
                                        />
                                      ) : (
                                        <div className="w-[1.687rem] h-[1.687rem] rounded-[5.125rem] flex items-center justify-center bg-slate-400 dark:bg-slate-700">
                                          <div className="text-xs font-normal uppercase text-white">
                                            {vendor?.name
                                              ? vendor?.name
                                                  .replace(/[^\w\s]/gi, '')
                                                  .split(' ')
                                                  .filter(Boolean)
                                                  .slice(0, 2)
                                                  .map((word) => word.charAt(0))
                                                  .join('')
                                              : ''}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <Tooltip
                                      tooltipShow={
                                        vendor?.name?.length > vendorNameLength
                                      }
                                      content={`${vendor?.name}`}
                                      position="right"
                                      contentClassName=" w-[400px] border-none rounded-[10px] overflow-visible break-words text-wrap text-sm text-[#667085] relative"
                                    >
                                      <>
                                        <div className="capitalize text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                                          {trimText(
                                            vendor?.name,
                                            vendorNameLength,
                                          )?.toLowerCase()}
                                        </div>
                                        {vendor?.contractorId && (
                                          <div
                                            className={`capitalize  px-1.5 py-0.5 w-fit rounded-md  text-[10px] font-medium
                                           text-[#EDB900] bg-[#EDB9001A]`}
                                          >
                                            Linked Account
                                          </div>
                                        )}
                                      </>
                                    </Tooltip>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap pl-4">
                                  <div className="w-[8.75rem] text-left">
                                    {VendorType?.[vendor?.type]}
                                  </div>
                                </td>
                                <td className="">
                                  <div className="w-[8.75rem] text-left break-all pr-3">
                                    {vendor?.accountNumber
                                      ? vendor?.accountNumber
                                      : '---'}
                                  </div>
                                </td>
                                <td className="">
                                  <div className="text-left w-[12.5rem]">
                                    <p className="text-slate-600 text-[0.938rem] leading-6 font-medium">
                                      {vendor?.contacts[0]?.firstName &&
                                      vendor?.contacts[0]?.lastName
                                        ? `${vendor?.contacts[0]?.firstName} ${
                                            vendor?.contacts[0]?.lastName
                                          }`
                                        : vendor?.contacts[0]?.firstName
                                          ? `${vendor?.contacts[0]?.firstName} `
                                          : vendor?.contacts[0]?.lastName
                                            ? `${
                                                vendor?.contacts[0]?.lastName
                                              } `
                                            : '---'}
                                    </p>
                                    <p className="text-slate-600 break-words leading-tight  text-opacity-90 font-base text-sm">
                                      {vendor?.contacts[0]?.email
                                        ? vendor?.contacts[0].email
                                        : '---'}
                                    </p>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div
                                    className="h-7 rounded-[5px] capitalize bg-slate-100 inline-flex justify-center items-center px-2"
                                    style={{
                                      background: STATUS_OPTIONS?.find(
                                        (s) => s?.id === vendor?.status,
                                      )?.background,
                                      color: STATUS_OPTIONS?.find(
                                        (s) => s?.id === vendor?.status,
                                      )?.textColor,
                                    }}
                                  >
                                    {vendor?.status}
                                  </div>
                                </td>
                                <td className="whitespace-nowrap">
                                  {vendor?.contractorId ? (
                                    <div className="w-[9rem] inline-flex items-center gap-3.5">
                                      <Tooltip
                                        content="View"
                                        contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                        className="w-fit"
                                      >
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(
                                              `/people/${vendor?.contractorId}`,
                                            );
                                          }}
                                          className=" h-[27.5px] w-[26px] border border-[#D0D5DD99] rounded-md shadow-sm bg-white inline-flex justify-center items-center"
                                          type="button"
                                        >
                                          <svg
                                            className="  fill-[#9A9A9A] h-[14px] "
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 576 512"
                                          >
                                            <path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                                          </svg>
                                        </button>
                                      </Tooltip>
                                      <Tooltip
                                        content="View Transactions"
                                        contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                      >
                                        <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                          <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={() => {
                                              goToTransactions(vendor.id);
                                            }}
                                          >
                                            <path
                                              d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                              fill="white"
                                              stroke="#D0D5DD"
                                              strokeOpacity="0.6"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                              fill="white"
                                              stroke="#D0D5DD"
                                              strokeOpacity="0.6"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M12.2861 19.9902H17.8008C19.7445 19.9902 21.0404 18.6944 21.0404 16.7507V13.5111C21.0404 11.7617 19.9389 10.4659 18.3191 10.2715C18.1895 10.2715 17.9952 10.2715 17.8008 10.2715H11.3216C11.1272 10.2715 10.9977 10.2715 10.8033 10.3363C9.1187 10.5306 8.08203 11.7617 8.08203 13.5111C8.08203 13.7054 8.08203 13.9646 8.08203 14.159"
                                              stroke="#858D9D"
                                              strokeWidth="1.3"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M18.3191 10.272C18.1895 10.272 17.9951 10.272 17.8007 10.272H11.3216C11.1272 10.272 10.9976 10.272 10.8032 10.3368C10.868 10.1424 10.9976 10.0128 11.192 9.81843L13.2653 7.68031C14.1724 6.77323 15.5978 6.77323 16.5049 7.68031L17.6711 8.84655C18.0599 9.2353 18.2543 9.75365 18.3191 10.272Z"
                                              stroke="#858D9D"
                                              strokeWidth="1.3"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M20.7861 13.7363H19.5861C19.1461 13.7363 18.7861 14.4113 18.7861 15.2363C18.7861 16.0613 19.1461 16.7363 19.5861 16.7363H20.7861"
                                              stroke="#858D9D"
                                              strokeWidth="1.3"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M10.4367 20.6379C12.0373 20.6379 13.529 19.7705 14.5674 18.2693C14.9755 17.6813 14.9755 16.6929 14.5674 16.105C13.529 14.6037 12.0373 13.7363 10.4367 13.7363C8.83614 13.7363 7.34439 14.6037 6.30606 16.105C5.89798 16.6929 5.89798 17.6813 6.30606 18.2693C7.34439 19.7705 8.83614 20.6379 10.4367 20.6379Z"
                                              fill="white"
                                              stroke="#858D9D"
                                              strokeWidth="1.18355"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M11.2196 17.2041C11.2196 17.2436 11.1732 17.3975 10.9973 17.5735C10.8213 17.7494 10.6674 17.7958 10.6279 17.7958C10.5883 17.7958 10.4344 17.7494 10.2584 17.5735C10.0825 17.3975 10.0361 17.2436 10.0361 17.204C10.0361 17.1645 10.0825 17.0106 10.2584 16.8346C10.4344 16.6587 10.5883 16.6123 10.6279 16.6123C10.6674 16.6123 10.8213 16.6587 10.9973 16.8346C11.1732 17.0106 11.2196 17.1645 11.2196 17.2041Z"
                                              fill="#858D9D"
                                              stroke="#858D9D"
                                              strokeWidth="1.18355"
                                            />
                                          </svg>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    <div className="w-[9rem] inline-flex items-center gap-3.5">
                                      {checkPermission(
                                        PermissionKeys.MANAGE_VENDORS,
                                        accessType,
                                        permissions,
                                      ) && (
                                        <Tooltip
                                          content="Edit Vendor"
                                          contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                        >
                                          <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                            <svg
                                              width="28"
                                              height="28"
                                              viewBox="0 0 28 28"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setFlyoutOpen(false);
                                                setTitle('Edit Vendor');
                                                setActiveVendor(vendor);
                                                setFormOpen(true);
                                              }}
                                            >
                                              <path
                                                d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                                fill="white"
                                                stroke="#D0D5DD"
                                                strokeOpacity="0.6"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M13.416 7.83398H12.2493C9.33268 7.83398 8.16602 9.00065 8.16602 11.9173V15.4173C8.16602 18.334 9.33268 19.5007 12.2493 19.5007H15.7493C18.666 19.5007 19.8327 18.334 19.8327 15.4173V14.2507"
                                                stroke="#667085"
                                                strokeOpacity="0.8"
                                                strokeWidth="1.3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M16.3561 8.42905L11.7594 13.0257C11.5844 13.2007 11.4094 13.5449 11.3744 13.7957L11.1236 15.5515C11.0303 16.1874 11.4794 16.6307 12.1153 16.5432L13.8711 16.2924C14.1161 16.2574 14.4603 16.0824 14.6411 15.9074L19.2378 11.3107C20.0311 10.5174 20.4044 9.59572 19.2378 8.42905C18.0711 7.26238 17.1494 7.63572 16.3561 8.42905Z"
                                                stroke="#667085"
                                                strokeOpacity="0.8"
                                                strokeWidth="1.3"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M15.6973 9.08789C16.0881 10.4821 17.1789 11.5729 18.5789 11.9696"
                                                stroke="#858D9D"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </div>
                                        </Tooltip>
                                      )}
                                      {/*
                                      <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                        <svg
                                          width="28"
                                          height="28"
                                          viewBox="0 0 28 28"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                            fill="white"
                                            stroke="#D0D5DD"
                                            strokeOpacity="0.6"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M20.1539 9.58796V11.0349C20.1539 11.9796 19.556 12.5775 18.6113 12.5775H16.5664V8.3981C16.5664 7.73441 17.1105 7.19629 17.7742 7.19629C18.4259 7.20227 19.0238 7.46535 19.4543 7.89585C19.8848 8.33233 20.1539 8.93025 20.1539 9.58796Z"
                                            stroke="#858D9D"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M8.19531 10.1859V18.5567C8.19531 19.053 8.75735 19.334 9.15198 19.035L10.1744 18.2697C10.4136 18.0903 10.7484 18.1143 10.9637 18.3295L11.9562 19.328C12.1894 19.5612 12.5721 19.5612 12.8052 19.328L13.8098 18.3235C14.019 18.1142 14.3539 18.0903 14.587 18.2697L15.6095 19.035C16.0041 19.328 16.5661 19.047 16.5661 18.5567V8.39212C16.5661 7.73441 17.1043 7.19629 17.762 7.19629H11.1849H10.587C8.79323 7.19629 8.19531 8.26656 8.19531 9.58796V10.1859Z"
                                            stroke="#858D9D"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M12.3809 13.7793H14.1746"
                                            stroke="#858D9D"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M12.3809 11.3877H14.1746"
                                            stroke="#858D9D"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M10.584 13.7725H10.5894"
                                            stroke="#858D9D"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M10.584 11.3809H10.5894"
                                            stroke="#858D9D"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                        </div> */}
                                      <Tooltip
                                        content="View Transactions"
                                        contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                      >
                                        <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                          <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={() => {
                                              goToTransactions(vendor.id);
                                            }}
                                          >
                                            <path
                                              d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                              fill="white"
                                              stroke="#D0D5DD"
                                              strokeOpacity="0.6"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                              fill="white"
                                              stroke="#D0D5DD"
                                              strokeOpacity="0.6"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M12.2861 19.9902H17.8008C19.7445 19.9902 21.0404 18.6944 21.0404 16.7507V13.5111C21.0404 11.7617 19.9389 10.4659 18.3191 10.2715C18.1895 10.2715 17.9952 10.2715 17.8008 10.2715H11.3216C11.1272 10.2715 10.9977 10.2715 10.8033 10.3363C9.1187 10.5306 8.08203 11.7617 8.08203 13.5111C8.08203 13.7054 8.08203 13.9646 8.08203 14.159"
                                              stroke="#858D9D"
                                              strokeWidth="1.3"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M18.3191 10.272C18.1895 10.272 17.9951 10.272 17.8007 10.272H11.3216C11.1272 10.272 10.9976 10.272 10.8032 10.3368C10.868 10.1424 10.9976 10.0128 11.192 9.81843L13.2653 7.68031C14.1724 6.77323 15.5978 6.77323 16.5049 7.68031L17.6711 8.84655C18.0599 9.2353 18.2543 9.75365 18.3191 10.272Z"
                                              stroke="#858D9D"
                                              strokeWidth="1.3"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M20.7861 13.7363H19.5861C19.1461 13.7363 18.7861 14.4113 18.7861 15.2363C18.7861 16.0613 19.1461 16.7363 19.5861 16.7363H20.7861"
                                              stroke="#858D9D"
                                              strokeWidth="1.3"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M10.4367 20.6379C12.0373 20.6379 13.529 19.7705 14.5674 18.2693C14.9755 17.6813 14.9755 16.6929 14.5674 16.105C13.529 14.6037 12.0373 13.7363 10.4367 13.7363C8.83614 13.7363 7.34439 14.6037 6.30606 16.105C5.89798 16.6929 5.89798 17.6813 6.30606 18.2693C7.34439 19.7705 8.83614 20.6379 10.4367 20.6379Z"
                                              fill="white"
                                              stroke="#858D9D"
                                              strokeWidth="1.18355"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M11.2196 17.2041C11.2196 17.2436 11.1732 17.3975 10.9973 17.5735C10.8213 17.7494 10.6674 17.7958 10.6279 17.7958C10.5883 17.7958 10.4344 17.7494 10.2584 17.5735C10.0825 17.3975 10.0361 17.2436 10.0361 17.204C10.0361 17.1645 10.0825 17.0106 10.2584 16.8346C10.4344 16.6587 10.5883 16.6123 10.6279 16.6123C10.6674 16.6123 10.8213 16.6587 10.9973 16.8346C11.1732 17.0106 11.2196 17.1645 11.2196 17.2041Z"
                                              fill="#858D9D"
                                              stroke="#858D9D"
                                              strokeWidth="1.18355"
                                            />
                                          </svg>
                                        </div>
                                      </Tooltip>
                                      {checkPermission(
                                        PermissionKeys.MANAGE_VENDORS,
                                        accessType,
                                        permissions,
                                      ) && (
                                        <Tooltip
                                          content="Delete Vendor"
                                          contentClassName="border-none overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                        >
                                          <div className="w-[1.625rem] h-[1.625rem] cursor-pointer">
                                            <svg
                                              width="28"
                                              height="28"
                                              viewBox="0 0 28 28"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setFlyoutOpen(false);
                                                setActiveVendor(vendor);
                                                setModalOpen(true);
                                              }}
                                            >
                                              <path
                                                d="M10.1 26.667H17.9C24.4 26.667 27 24.067 27 17.567V9.76699C27 3.26699 24.4 0.666992 17.9 0.666992H10.1C3.6 0.666992 1 3.26699 1 9.76699V17.567C1 24.067 3.6 26.667 10.1 26.667Z"
                                                fill="white"
                                                stroke="#D0D5DD"
                                                strokeOpacity="0.6"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M19.6063 9.54173C17.4487 9.32792 15.2782 9.21777 13.1141 9.21777C11.8313 9.21777 10.5484 9.28257 9.26551 9.41215L7.94376 9.54173"
                                                stroke="#667085"
                                                strokeOpacity="0.8"
                                                strokeWidth="1.3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M11.5073 8.8872L11.6498 8.03843C11.7535 7.42291 11.8313 6.96289 12.9262 6.96289H14.6238C15.7188 6.96289 15.803 7.44883 15.9002 8.04491L16.0427 8.8872"
                                                stroke="#858D9D"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M18.2133 11.5889L17.7921 18.1134C17.7208 19.1306 17.6625 19.9211 15.8548 19.9211H11.6952C9.88752 19.9211 9.82921 19.1306 9.75794 18.1134L9.33679 11.5889"
                                                stroke="#667085"
                                                strokeOpacity="0.8"
                                                strokeWidth="1.3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M12.693 16.3574H14.8505"
                                                stroke="#667085"
                                                strokeOpacity="0.8"
                                                strokeWidth="1.3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                              <path
                                                d="M12.1552 13.7656H15.3948"
                                                stroke="#667085"
                                                strokeOpacity="0.8"
                                                strokeWidth="1.3"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </div>
                                        </Tooltip>
                                      )}
                                    </div>
                                  )}
                                  {/* <ElipsisMenu
                                  align="right"
                                  className="relative inline-flex"
                                  dropdownOpen={dropdownOpen}
                                  setDropdownOpen={setDropdownOpen}
                                >
                                  <li
                                    onClick={() => {
                                      setActiveVendor(merchant);
                                      setModalOpen(true);
                                      setDropdownOpen(false);
                                    }}
                                    className="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3 cursor-pointer"
                                  >
                                    Update
                                  </li>
                                </ElipsisMenu> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                      {!vendors?.vendors?.length > 0 && (
                        <div className="flex flex-col gap-5 items-center justify-center w-full">
                          <h6 className="text-center mt-6">No Vendors Found</h6>
                          <img
                            src={emptySearchImg}
                            alt="No vendors found"
                            className="h-[12.5rem]"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!isLoading && (
                  <div className="mt-10">
                    <PaginationClassic
                      setLimit={setLocalLimit}
                      onPageChange={getData}
                      pagination={{ limit: localLimit, page, totalRecords }}
                      pageLoad
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default Vendors;
