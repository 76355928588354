import BarChart from 'components/BarChart';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  formatNumber,
  getCurrencySymbolFromIso,
  TEAM_CURRENCY,
} from 'utils/Utils';

const CashFlowChart = ({ overviewLoadings, cashFlowData, cashStat }) => {
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const DefaultYear = moment().format('MMMM,YYYY');
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const chartData = useMemo(
    () =>
      Object.keys(cashFlowData?.monthOrDaylyData?.expenses || {})?.length > 0
        ? {
            labels: Object.keys(
              cashFlowData?.monthOrDaylyData?.income || {},
            )?.map(
              (key, index) =>
                `${(index + 1)?.toString()?.padStart(2, '0')}-01-${DefaultYear?.split(',')?.[1]}`,
            ),
            datasets: [
              // Green Bars => Income
              {
                label: 'Money In',
                data: Object.keys(
                  cashFlowData?.monthOrDaylyData?.income || {},
                )?.map(
                  (key, index) =>
                    cashFlowData?.monthOrDaylyData?.income?.[
                      `month_${index + 1}`
                    ]?.total,
                ),
                hoverBackgroundColor: '#78BD4F',
                barPercentage: 0.66,
                categoryPercentage: 0.66,
                borderRadius: 4,
                borderWidth: 2,
                borderColor: '#78BD4F',
              },
              // Orange bars => Expense
              {
                label: 'Money Out',
                data: Object.keys(
                  cashFlowData?.monthOrDaylyData?.expenses || {},
                )?.map(
                  (key, index) =>
                    cashFlowData?.monthOrDaylyData?.expenses?.[
                      `month_${index + 1}`
                    ]?.total,
                ),
                hoverBackgroundColor: '#E48642',
                barPercentage: 0.66,
                categoryPercentage: 0.66,
                borderRadius: 4,
                borderWidth: 2,
                borderColor: '#E48642',
              },
            ],
          }
        : null,
    [cashFlowData],
  );

  return (
    <div className="h-fit p-6 rounded-[12px] border border-[#D0D5DD] ">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-[22px] text-[#666] font-medium">
          Total Cash In and Out{' '}
        </h3>
      </div>
      {overviewLoadings?.cashFlow || overviewLoadings?.cashStat ? (
        <div className="flex flex-col h-[400px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-2 md:grid-cols-3 xxl:grid-cols-4 gap-4 ">
            <div className="py-5 border-r border-[#D0D5DD]">
              <p className="text-base text-[#667085] mb-3">Current Cash</p>
              <h3
                className="text-[28px] font-semibold text-[#101828]"
                style={
                  +cashStat?.currentCash < 0
                    ? {
                        color: '#FF4B4B',
                      }
                    : {}
                }
              >
                {cashStat?.currentCash?.toString().charAt(0) === '-' ? '-' : ''}
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {formatNumber(Math.abs(+cashStat?.currentCash))}
              </h3>
            </div>
            <div className="py-5 md:border-r border-[#D0D5DD]">
              <p className="text-base text-[#667085] mb-3">
                Net Burn (3 month avg)
              </p>
              <h3
                className="text-[28px] font-semibold text-[#101828]"
                style={
                  cashStat?.netBurn > 0
                    ? { color: '#78BD4F' }
                    : cashStat?.netBurn < 0
                      ? { color: '#FF4B4B' }
                      : {}
                }
              >
                {cashStat?.netBurn?.toString().charAt(0) === '-'
                  ? '-'
                  : cashStat?.netBurn > 0
                    ? '+'
                    : ''}
                {getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}
                {formatNumber(Math.abs(+cashStat?.netBurn))}
              </h3>
            </div>
            <div className="py-5 border-r md:border-r-0 xxl:border-r border-[#D0D5DD] pr-1">
              <p className="text-base text-[#667085] mb-3 flex items-center gap-2">
                Runway Run Rate
                <Tooltip
                  content={
                    <>
                      <p>
                        Runway run rate is a financial metric that refers to a
                      </p>
                      <p>
                        company's financial performance and how long it can stay
                      </p>
                      <p>
                        in operation before running out of cash. The formula is:
                      </p>
                      <p>Runway = Cash on Hand ÷ Burn Rate.</p>
                      {Math.abs(cashStat?.runWay) > 0 &&
                        +cashStat?.netBurn < 0 &&
                        cashStat?.currentCash > 0 && (
                          <p>
                            {parseInt(Math.abs(cashStat?.runWay))} months ={' '}
                            {getCurrencySymbolFromIso(
                              teamDefaultCurrency,
                              currencies,
                            )}
                            {formatNumber(Math.abs(+cashStat?.currentCash))} ÷{' '}
                            {getCurrencySymbolFromIso(
                              teamDefaultCurrency,
                              currencies,
                            )}
                            {formatNumber(Math.abs(+cashStat?.netBurn))}
                          </p>
                        )}
                    </>
                  }
                  contentClassName="overflow-visible relative translate-y-1"
                  isFixed
                  ids={['insightsPage']}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <g>
                      <path
                        d="M8 15.5625C9.48336 15.5625 10.9334 15.1226 12.1668 14.2985C13.4001 13.4744 14.3614 12.3031 14.9291 10.9326C15.4968 9.56218 15.6453 8.05418 15.3559 6.59933C15.0665 5.14447 14.3522 3.8081 13.3033 2.7592C12.2544 1.71031 10.918 0.996003 9.46318 0.706614C8.00832 0.417225 6.50032 0.56575 5.12987 1.13341C3.75943 1.70106 2.58809 2.66236 1.76398 3.89573C0.939867 5.1291 0.5 6.57914 0.5 8.0625C0.5 10.0516 1.29018 11.9593 2.6967 13.3658C4.10322 14.7723 6.01088 15.5625 8 15.5625ZM8 3.3725C8.19778 3.3725 8.39112 3.43115 8.55557 3.54103C8.72002 3.65092 8.84819 3.80709 8.92388 3.98982C8.99957 4.17255 9.01937 4.37361 8.98079 4.56759C8.9422 4.76157 8.84696 4.93976 8.70711 5.07961C8.56725 5.21946 8.38907 5.3147 8.19509 5.35329C8.00111 5.39187 7.80004 5.37207 7.61732 5.29638C7.43459 5.2207 7.27841 5.09252 7.16853 4.92807C7.05865 4.76362 7 4.57028 7 4.3725C7 4.10729 7.10536 3.85293 7.29289 3.6654C7.48043 3.47786 7.73478 3.3725 8 3.3725ZM7 8.0625C7 7.79729 7.10536 7.54293 7.29289 7.3554C7.48043 7.16786 7.73478 7.0625 8 7.0625C8.26522 7.0625 8.51957 7.16786 8.70711 7.3554C8.89464 7.54293 9 7.79729 9 8.0625V12.0625C9 12.3277 8.89464 12.5821 8.70711 12.7696C8.51957 12.9571 8.26522 13.0625 8 13.0625C7.73478 13.0625 7.48043 12.9571 7.29289 12.7696C7.10536 12.5821 7 12.3277 7 12.0625V8.0625Z"
                        fill="#949494"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_115_1502">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(0 0.0625)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Tooltip>
              </p>
              <div className="flex gap-1 items-center flex-wrap">
                {((cashStat?.netBurn < 0 && cashStat?.currentCash > 0) ||
                  (+cashStat?.runWay === 0 && +cashStat?.netBurn === 0)) && (
                  <h3 className="text-[28px] font-semibold text-[#101828]">
                    {Number.isNaN(cashStat?.runWay) ||
                    !Number.isFinite(cashStat?.runWay) ||
                    (cashStat?.runWay === 0 && +cashStat?.netBurn === 0)
                      ? '--'
                      : `${parseInt(Math.abs(cashStat?.runWay))} months`}{' '}
                  </h3>
                )}
                {Math.abs(cashStat?.runWay) > 0 &&
                  +cashStat?.netBurn < 0 &&
                  cashStat?.currentCash > 0 && (
                    <span className="text-base font-medium">
                      (
                      {`${moment()
                        .add(parseInt(Math.abs(cashStat?.runWay)), 'months')
                        .format('MMM YYYY')}
                    `}
                      )
                    </span>
                  )}
                {+cashStat?.currentCash < 0 && (
                  <span className="text-[28px] font-semibold text-[#FF4B4B]">
                    Out of Cash
                  </span>
                )}
                {+cashStat?.netBurn > 0 && +cashStat?.currentCash > 0 && (
                  <div className="text-[22px] inline-flex  items-center font-semibold text-[#78BD4F] min-h-[42px]">
                    Cash Flow Positive
                  </div>
                )}
              </div>
            </div>
            <div className="py-5">
              <p className="text-base text-[#667085] mb-3">Total Customers</p>
              <h3 className="text-[28px] font-semibold text-[#101828]">
                {cashStat?.numberOfCustomers}
              </h3>
            </div>
          </div>
          {chartData && (
            <BarChart chartData={chartData} width={595} height={248} />
          )}
        </>
      )}
    </div>
  );
};

export default CashFlowChart;
