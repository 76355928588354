import ImportBox from 'pages/appIntegrations/quickbooksSettings/ImportBox';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CsvImportTransactions = ({ updateOneStep }) => {
  const navigate = useNavigate();

  return (
    <div>
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Import Historical Transactions:
      </h3>
      <div className="grid">
        <ImportBox
          title="Import Historical Transactions"
          text="Import all of your accounts from CSV with a single click."
          buttonText="Import  Transactions"
          handleClick={() => navigate('/import-transactions?from=onboarding')}
          showLastSync={false}
        />
      </div>
      <footer className="flex justify-between mt-[60px] gap-4">
        <button
          type="button"
          onClick={updateOneStep}
          className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
        >
          Skip
        </button>
      </footer>
    </div>
  );
};

export default CsvImportTransactions;
