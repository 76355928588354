import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  formatDateLocally,
  getFormattedDate,
  TEAM_CURRENCY,
  TEAM_DATE_FORMAT,
} from 'utils/Utils';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import Tooltip from 'components/Tooltip';
import {
  getCountriesData,
  getCurrenciesData,
  getCustomers,
  getInvoices,
} from '../../API/backend_helper';
import DropdownFull from '../../components/DropdownFull';
import CustomerStatementBox from './CustomerStatementBox';
import SendModal from './SendModal';
import AppLayout from '../../components/AppLayout';
import downloadPdf from '../../components/DownloadPdf';
import DatePickerDash from '../../components/DatePickerDash';

const CustomerStatement = () => {
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const From = searchParams.get('from') || '';

  const { team } = useSelector((state) => state.Team);
  const navigate = useNavigate();
  const { accessType, permissions } = useSelector((state) => state.User);
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(id || null);
  const [countries, setCountries] = useState([]);
  const [currency, setCurrency] = useState(null);
  const [currencyIso, setCurrencyIso] = useState(null);
  const [data, setData] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [endDate, setEndDate] = useState(
    formatDateLocally(new Date(), teamDateFormat),
  );
  const [reportType, setReportType] = useState('outstanding');

  const options = useMemo(
    () => ({
      mode: 'single',
      static: true,
      monthSelectorType: 'static',
      onReady: (selectedDates, dateStr, instance) => {
        instance?.calendarContainer?.classList?.add(`flatpickr-left`);
      },
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(PermissionKeys.VIEW_REPORTS, accessType, permissions)
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  const getData = async ({ limit = 10000, loading = false }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getCustomers({
        limit,
      });
      setCustomers(res?.data);
    } catch (e) {
      setIsLoading(false);
      console.log('error', e);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCurrencies = async () => {
    try {
      const res = await getCurrenciesData();
      setCurrencies(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const getCountryName = () => {
    let country = null;
    if (countries?.length > 0) {
      country = countries?.find((cnt) => cnt?.isoCode === team?.country);
    }
    return country?.name;
  };

  const getCurrencyIso = (id) => {
    let currency = null;
    if (currencies?.length > 0) {
      currency = currencies?.find((cnt) => cnt?.id === id);
    }
    return currency?.isoCode;
  };

  useEffect(() => {
    getData({ loading: true });
    loadCurrencies();
    loadCountries();
  }, []);

  useEffect(() => {
    if (currencies?.length > 0 && teamDefaultCurrency) {
      const currency = currencies?.find(
        (curr) => curr?.isoCode === teamDefaultCurrency,
      );
      setCurrency(currency?.id);
      setCurrencyIso(currency?.isoCode);
    }
  }, [currencies, team]);

  const generateReport = async ({
    type = 'invoice',
    limit = 10000,
    page = 1,
    loading = false,
    customers = selectedCustomer || [],
    status,
    _currency = currencyIso,
    _endDate = getFormattedDate(endDate, teamDateFormat),
  }) => {
    if (loading) {
      setData(null);
      setGenerating(true);
    }
    try {
      const res = await getInvoices({
        type,
        limit,
        page,
        customers,
        status:
          reportType === 'all'
            ? ['paid', 'partial', 'unpaid', 'overdue']
            : ['partial', 'unpaid', 'overdue'],
        currency: _currency,
        isStatment: true,
        endDate: _endDate,
      });
      setData(res?.data);
      setGenerating(false);
    } catch (e) {
      setGenerating(false);
      console.log('error', e);
    }
  };
  const onCurrencyChange = (c) => {
    setCurrency(c);
    setCurrencyIso(getCurrencyIso(c));
  };

  const customerObj = useMemo(
    () => customers?.records?.find((cus) => cus?.id === +selectedCustomer),
    [selectedCustomer, customers],
  );

  const copyToClipboard = () => {
    const { protocol, host } = window.location;
    navigator.clipboard
      .writeText(
        `${protocol}//${host}/public/customer-statement/${data?.statmentId}`,
      )
      .then(() => {
        toast.success('Link Copied!');
        // setIsOpen(false);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <>
      <SendModal
        isOpen={sendModalOpen}
        setIsOpen={setSendModalOpen}
        data={data}
      />
      <AppLayout>
        <main className="relative grow ">
          {/* Content */}
          <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {/* Page header */}
            <div className="flex gap-2.5 items-center mb-4">
              <button
                className="mr-2"
                type="button"
                onClick={() =>
                  From ? navigate(`/${From}`) : navigate('/customers')
                }
              >
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="32"
                    height="33"
                    transform="translate(33) rotate(90)"
                    fill="#FBF3ED"
                  />
                  <path
                    d="M19 10.5L13 16.5L19 22.5"
                    stroke="#E48642"
                    strokeWidth="3.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <h3 className="text-[36px] font-medium text-[#00000099] leading-tight">
                Customer Statement
              </h3>
            </div>

            {isLoading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            ) : (
              <>
                {/* Dropdowns  */}
                <div className="w-full  flex justify-between items-center flex-wrap gap-2 mb-7">
                  <ul className="flex flex-wrap -m-1 py-2">
                    <li className="m-1 w-[300px] max-w-full inline-flex">
                      <div className="flex flex-col items-start gap-2.5 w-[18.438rem]">
                        <label
                          className="block text-sm text-slate-600 font-medium leading-5"
                          htmlFor="type"
                        >
                          Customer
                        </label>
                        <DropdownFull
                          options={customers?.records}
                          // selected={null}
                          name="customer"
                          setSelected={(name) => setSelectedCustomer(name)}
                          selected={+selectedCustomer}
                          placeholder="Select Customer"
                          scrollIntoView={false}
                        />
                      </div>
                    </li>
                    <li className="m-1 w-[300px] max-w-full inline-flex">
                      <div className="flex flex-col items-start gap-2.5 w-[18.438rem]">
                        <label
                          className="block text-sm text-slate-600 font-medium leading-5"
                          htmlFor="type"
                        >
                          Type
                        </label>
                        <DropdownFull
                          options={[
                            {
                              name: 'Outstanding Invoices',
                              id: 'outstanding',
                            },
                            {
                              name: 'All Invoices',
                              id: 'all',
                            },
                          ]}
                          selected={reportType}
                          name="name"
                          setSelected={(v) => {
                            setData(null);
                            setReportType(v);
                          }}
                          placeholder="Select Type"
                          scrollIntoView={false}
                        />
                      </div>
                    </li>
                    <li className="m-1 w-[300px] max-w-full inline-flex">
                      <div className="flex flex-col items-start gap-2.5 w-[18.438rem]">
                        <label
                          className="block text-sm text-slate-600 font-medium leading-5"
                          htmlFor="type"
                        >
                          Currencies
                        </label>
                        <DropdownFull
                          options={currencies}
                          selected={currency}
                          name="name"
                          setSelected={(name, obj) =>
                            onCurrencyChange(name, obj)
                          }
                          placeholder="Select Currency"
                          scrollIntoView={false}
                        />
                      </div>
                    </li>
                    <li className="m-1 w-[300px] max-w-full inline-flex">
                      <div className="flex flex-col items-start gap-2.5 w-full">
                        <label
                          className="block text-sm text-slate-600 font-medium leading-5"
                          htmlFor="type"
                        >
                          As of
                        </label>
                        <DatePickerDash
                          placeholder="Select Date"
                          value={endDate}
                          options={options}
                          onChange={(selectedDates) => {
                            setEndDate(selectedDates[0]);
                          }}
                          name="endDate"
                          id="endDate"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          width="w-full"
                          onBlur={(e) => {
                            if (
                              !e.target.classList?.value?.includes('active')
                            ) {
                              setEndDate(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </li>
                    <li className="m-1 w-fit max-w-full inline-flex items-end">
                      <Tooltip
                        content="Please select customer"
                        tooltipShow={!selectedCustomer}
                        contentClassName="text-sm border-none relative overflow-visible tooltipArrowDown"
                      >
                        <button
                          className="btn-sm h-12 px-6 text-base text-indigo-500 bg-[#E486420D]
                            border-[#E48642]  whitespace-nowrap    disabled:cursor-not-allowed"
                          onClick={() => generateReport({ loading: true })}
                          disabled={generating || !selectedCustomer}
                          type="button"
                        >
                          {generating && (
                            <svg
                              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                            </svg>
                          )}
                          Generate
                        </button>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
                {/* Send Button  */}
                {data?.invoices?.length > 0 && (
                  <div className="flex justify-center mb-4">
                    <button
                      className="btn-sm h-12 w-fit px-6 text-base bg-indigo-500 hover:bg-indigo-600 text-white
                    disabled:bg-indigo-400 whitespace-nowrap    disabled:cursor-not-allowed mr-4"
                      onClick={copyToClipboard}
                      type="button"
                    >
                      Shareable Link
                    </button>

                    <button
                      className="btn-sm h-12 w-fit px-6 text-base bg-indigo-500 hover:bg-indigo-600 text-white
                    disabled:bg-indigo-400 whitespace-nowrap    disabled:cursor-not-allowed"
                      onClick={() =>
                        downloadPdf(
                          'customerStatement',
                          customerObj?.customer
                            ? `${customerObj?.customer} Statement.pdf`
                            : `${customerObj?.contacts?.[0]?.firstName} ${customerObj?.contacts?.[0]?.lastName} Statement.pdf`,
                        )
                      }
                      type="button"
                    >
                      Download PDF
                    </button>
                  </div>
                )}
                {/* Customer Statement   */}
                <div className="xxl:w-[1300px] w-[1100px] max-w-full mx-auto px-5">
                  {data?.invoices?.length > 0 ? (
                    <>
                      <div
                        className="fixed top-0 left-0 -translate-x-full -translate-y-full rounded-[10px] bg-white border border-[#D0D5DD] rounded-[6px] shadow-button w-[900px]"
                        id="customerStatement"
                      >
                        <CustomerStatementBox
                          team={team}
                          customer={customerObj}
                          countryName={getCountryName()}
                          currency={currencies?.find(
                            (curr) => curr?.id === currency,
                          )}
                          data={data}
                          type={reportType}
                          isPdfView
                        />
                      </div>
                      <CustomerStatementBox
                        team={team}
                        customer={customerObj}
                        countryName={getCountryName()}
                        currency={currencies?.find(
                          (curr) => curr?.id === currency,
                        )}
                        data={data}
                        type={reportType}
                      />
                    </>
                  ) : (
                    data && (
                      <h6 className="text-sm text-[#667085B2] text-center mt-4 pb-3">
                        No Invoices Found
                      </h6>
                    )
                  )}
                </div>
              </>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default CustomerStatement;
