import React, { useEffect, useState } from 'react';
import { QUICK_BOOK_FEATURES } from 'pages/settings/appIntegrations';
import { useSelector } from 'react-redux';
import QuickBookLogo from 'pages/settings/appIntegrations/quickbooks-logo.png';
import { quickBookAuth } from 'API/backend_helper';
import { useNavigate } from 'react-router-dom';

const ConnectQuickbooks = ({ updateOneStep }) => {
  const navigate = useNavigate();

  const { team } = useSelector((state) => state.Team);

  const [quickBookLoading, setQuickBookLoading] = useState(false);

  const onQuickBookConnect = async () => {
    try {
      setQuickBookLoading(true);
      const res = await quickBookAuth({ isOnboarding: true });
      setQuickBookLoading(false);
      window.location = res?.redirectUrl;
    } catch (err) {
      setQuickBookLoading(false);
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (team?.app?.quickbooks?.[0]?.id) {
      updateOneStep();
    }
  }, [team]);

  return (
    <div>
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Connect Quickbooks:
      </h3>
      <div className="w-full rounded-[10px] border border-[#D0D5DD]">
        <div className="h-[89px] border-b border-[#D0D5DD]  w-full rounded-t-[inherit] pl-[22px] inline-flex items-center">
          <img src={QuickBookLogo} alt="square" className="h-20" />
        </div>
        <div className="min-h-[184px] h-fit px-6 py-4 w-full flex flex-col justify-center gap-4 border-b border-[#D0D5DD]">
          {QUICK_BOOK_FEATURES.map((f, index) => (
            <div key={index} className="flex gap-3">
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="min-h-[22px] min-w-[22px]"
              >
                <path
                  d="M6.5 11.5L9.5 14.5L15.5 8.5M21 11.5C21 17.0228 16.5228 21.5 11 21.5C5.47715 21.5 1 17.0228 1 11.5C1 5.97715 5.47715 1.5 11 1.5C16.5228 1.5 21 5.97715 21 11.5Z"
                  stroke="#78BD4F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-[#667085]">{f}</p>
            </div>
          ))}
        </div>

        <div className="pt-9 pb-7 px-7">
          {team?.app?.quickbooks?.[0]?.id ? (
            <div
              onClick={() => navigate('/app-integrations/quickbooks-settings')}
              className="font-medium cursor-pointer text-center w-fit mx-auto underline text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400"
              role="button"
              tabIndex="0"
            >
              Manage
            </div>
          ) : (
            <button
              className="h-[53px] w-full px-4 leading-tight bg-indigo-500 text-white rounded-[6px] inline-flex justify-center items-center gap-2 text-[16px] font-semibold disabled:bg-indigo-400 disabled:cursor-not-allowed"
              disabled={quickBookLoading}
              onClick={onQuickBookConnect}
              type="button"
            >
              {quickBookLoading ? (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              ) : (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="min-h-[18px] min-w-[18px]"
                >
                  <g clipPath="url(#clip0_4618_46369)">
                    <path
                      d="M7.2832 9.49851C7.5874 9.90518 7.9755 10.2417 8.42118 10.4852C8.86685 10.7287 9.35969 10.8735 9.86625 10.9097C10.3728 10.946 10.8812 10.8729 11.3571 10.6954C11.8329 10.5179 12.265 10.2402 12.624 9.88101L14.749 7.75601C15.3942 7.08804 15.7512 6.19341 15.7431 5.2648C15.735 4.33618 15.3625 3.44789 14.7059 2.79124C14.0492 2.13458 13.1609 1.76211 12.2323 1.75404C11.3037 1.74597 10.4091 2.10295 9.74112 2.74809L8.52279 3.95934"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.1163 8.08435C9.81207 7.67767 9.42398 7.34118 8.9783 7.09768C8.53262 6.85419 8.03979 6.70939 7.53323 6.67311C7.02667 6.63683 6.51823 6.70992 6.0424 6.88742C5.56657 7.06492 5.13448 7.34267 4.77544 7.70185L2.65044 9.82685C2.00529 10.4948 1.64831 11.3894 1.65638 12.3181C1.66445 13.2467 2.03693 14.135 2.69358 14.7916C3.35023 15.4483 4.23853 15.8207 5.16714 15.8288C6.09575 15.8369 6.99039 15.4799 7.65835 14.8348L8.8696 13.6235"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4618_46369">
                      <rect
                        width="17"
                        height="17"
                        fill="white"
                        transform="translate(0.199219 0.291016)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
              Connect
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConnectQuickbooks;
