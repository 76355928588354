import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { getStringSegments } from 'utils/Utils';
import {
  activateAutomation,
  deleteAutomation,
  getAutomations,
  getAutomationTemplates,
} from '../../API/backend_helper';
import { Loader } from '../../components/Svg';
import ActivationModal from './ActivationModal';
import Tooltip from '../../components/Tooltip';
import DeleteAutomationModal from './DeleteAutomationModal';
import AppLayout from '../../components/AppLayout';
import AutomationTable from './AutomationTable';
import AutomationHeader from './AutomationHeader';
import Tabs from './Tabs';
import TemplatesTab from './TemplatesTab';

const AUTOMATION_TAB = 'automationTab';
const TEMPLATES_TAB = 'templatesTab';

function Workflows() {
  const navigate = useNavigate();
  const { accessType, permissions } = useSelector((state) => state.User);

  const [activeTab, setActiveTab] = useState(AUTOMATION_TAB);
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rules, setRules] = useState([]);
  const [activationModalOpen, setActivationModalOpen] = useState(false);
  const [activeRule, setActiveRule] = useState({});
  const [descriptionLength, setDescriptionLength] = useState(16);
  const [actionLength, setActionLength] = useState(16);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [activeAutomation, setActiveAutomation] = useState({});

  document.title = 'COUNT | Automations';

  useEffect(() => {
    if (
      permissions?.length > 0 &&
      !checkPermission(PermissionKeys.VIEW_AUTOMATIONS, accessType, permissions)
    ) {
      navigate('/');
    }
  }, [accessType, permissions]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setDescriptionLength(55);
        setActionLength(70);
      }
      if (window.innerWidth > 1700) {
        setDescriptionLength(45);
        setActionLength(60);
      } else if (window.innerWidth > 992) {
        setDescriptionLength(29);
        setActionLength(22);
      } else {
        setDescriptionLength(16);
        setActionLength(16);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const loadRules = async () => {
    try {
      const res = await getAutomations();
      setRules(res?.data?.automations);
    } catch (error) {
      console.log(error);
    }
  };

  const loadAutomationTemplates = async () => {
    try {
      const res = await getAutomationTemplates();
      setTemplates(res?.data?.automationTemplates);
    } catch (err) {
      console.log(err);
    }
  };

  const loadData = async (loading = false) => {
    if (loading) {
      setIsLoading(true);
    }
    await Promise.all([loadAutomationTemplates(), loadRules()]);
    if (loading) {
      setIsLoading(false);
    }
  };

  const handleDeleteRule = async (id) => {
    try {
      await deleteAutomation(id);
      loadData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleActivateRule = async (id, bool, automateBool) => {
    try {
      await activateAutomation(id, {
        enabled: bool,
        automatePastRecords: automateBool,
      });
      loadData();
    } catch (error) {
      console.log(error);
    }
  };

  const trimText = (text, maxLength) =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  const operatorConversion = (operator) => {
    // eslint-disable-next-line default-case
    switch (operator) {
      case 'eq':
        return 'equals';
      case 'neq':
        return "doesn't equal";
      case 'ls':
        return 'less than';
      case 'lse':
        return 'less than or equal to';
      case 'gt':
        return 'greater than';
      case 'gte':
        return 'greater than or equal to';
      case 'ct':
        return 'contains';
      case 'nct':
        return "doesn't contain";
    }
  };

  useEffect(() => {
    loadData(true);
  }, []);

  const createConditionText = (condition, entity) => {
    if (entity === 'transaction') {
      switch (condition?.key) {
        case 'customerId':
          const customerNames = condition?.customers
            .map((customer) => customer?.customer)
            .join(' or ');
          return (
            <p>
              Is assigned to customer{' '}
              <Tooltip
                className="inline"
                tooltipShow={customerNames?.length > actionLength}
                content={
                  customerNames?.length > 30
                    ? (() => {
                        const segments = getStringSegments(customerNames, 40);
                        return segments.map((segment, index) => (
                          <p key={index}>{segment}</p>
                        ));
                      })()
                    : customerNames
                }
                contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown translate-y-1.5"
                isFixed
                ids={['automationsPage', 'automationsTableContainer']}
              >
                <span className="font-medium text-indigo-500">
                  {trimText(customerNames, actionLength)}
                </span>
              </Tooltip>
            </p>
          );
        case 'amount':
          return (
            <p>
              Amount {operatorConversion(condition?.operator)}{' '}
              <Tooltip
                className="inline"
                tooltipShow={condition?.value?.length > actionLength}
                content={
                  condition?.value?.length > 30
                    ? (() => {
                        const segments = getStringSegments(
                          condition?.value,
                          40,
                        );
                        return segments.map((segment, index) => (
                          <p key={index}>{segment}</p>
                        ));
                      })()
                    : condition?.value
                }
                contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown translate-y-1.5"
                isFixed
                ids={['automationsPage', 'automationsTableContainer']}
              >
                <span className="font-medium text-indigo-500">
                  {trimText(condition?.value, actionLength)}
                </span>
              </Tooltip>
            </p>
          );
        case 'description':
          return (
            <p>
              Description {operatorConversion(condition?.operator)}{' '}
              <Tooltip
                className="inline"
                tooltipShow={condition?.value?.length > actionLength}
                content={
                  condition?.value?.length > 30
                    ? (() => {
                        const segments = getStringSegments(
                          condition?.value,
                          40,
                        );
                        return segments.map((segment, index) => (
                          <p key={index}>{segment}</p>
                        ));
                      })()
                    : condition?.value
                }
                contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown translate-y-1.5"
                isFixed
                ids={['automationsPage', 'automationsTableContainer']}
              >
                <span className="font-medium text-indigo-500">
                  {trimText(condition?.value, actionLength)}
                </span>
              </Tooltip>
            </p>
          );
        case 'vendorId':
          if (condition?.vendors?.length > 0) {
            const vendorNames = condition?.vendors
              ?.map((vendor) => vendor?.name)
              ?.join(' or ');
            return (
              <p>
                Is assigned to vendor{' '}
                <Tooltip
                  className="inline"
                  tooltipShow={vendorNames?.length > actionLength}
                  content={
                    vendorNames?.length > 30
                      ? (() => {
                          const segments = getStringSegments(vendorNames, 40);
                          return segments.map((segment, index) => (
                            <p key={index}>{segment}</p>
                          ));
                        })()
                      : vendorNames
                  }
                  contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown translate-y-1.5"
                  isFixed
                  ids={['automationsPage', 'automationsTableContainer']}
                >
                  <span className="font-medium text-indigo-500">
                    {trimText(vendorNames, actionLength)}
                  </span>
                </Tooltip>
              </p>
            );
          }
          break;
        default:
          return null;
      }
    } else if (entity === 'estimateStatus') {
      return (
        <p>
          Status is{' '}
          <span className="font-medium text-indigo-500">
            {condition?.value?.toUpperCase()}
          </span>
        </p>
      );
    } else {
      return (
        <p>
          Is {operatorConversion(condition?.operator)}{' '}
          <span className="font-medium text-indigo-500">
            {condition?.value?.toUpperCase()}
          </span>
        </p>
      );
    }
  };

  const createActionText = (action, entity) => {
    if (entity === 'transaction') {
      // eslint-disable-next-line default-case
      switch (action?.key) {
        case 'categoryAccountId':
          return (
            <p>
              Assign Category{' '}
              <span className="font-medium text-indigo-500">
                {action?.categoryAccount?.name}
              </span>
            </p>
          );
        case 'description':
          return (
            <p>
              Update Transaction Description{' '}
              <Tooltip
                className="inline"
                tooltipShow={action?.value?.length > descriptionLength}
                content={
                  action?.value?.length > 30
                    ? (() => {
                        const segments = getStringSegments(action?.value, 40);
                        return segments.map((segment, index) => (
                          <p key={index}>{segment}</p>
                        ));
                      })()
                    : action?.value
                }
                contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown translate-y-1.5"
                isFixed
                ids={['automationsPage', 'automationsTableContainer']}
              >
                <span className="font-medium text-indigo-500">
                  {trimText(action?.value, descriptionLength)}
                </span>
              </Tooltip>
            </p>
          );
        case 'vendorId':
          return (
            <p>
              Assign Vendor{' '}
              <span className="font-medium text-indigo-500">
                {action?.vendor?.name}
              </span>
            </p>
          );
        case 'customerId':
          return (
            <p>
              Assign Customer{' '}
              <span className="font-medium text-indigo-500">
                {action?.customer?.customer}
              </span>
            </p>
          );
        case 'reviewed':
          return (
            <p>
              Mark Transaction{' '}
              <span className="font-medium text-indigo-500">Reviewed</span>
            </p>
          );
        case 'excluded':
          return (
            <p>
              Mark Transaction{' '}
              <span className="font-medium text-indigo-500">Excluded</span>
            </p>
          );
        case 'tags':
          const tagNames = action?.tags
            ?.map(
              (tag, i) =>
                `${i > 0 && i === action?.tags?.length - 1 ? ' and ' : i > 0 ? ', ' : ''}${tag?.name}`,
            )
            .join('');
          return (
            <p>
              Assign Tags{' '}
              <Tooltip
                className="inline"
                tooltipShow={tagNames?.length > descriptionLength}
                content={
                  tagNames?.length > 30
                    ? (() => {
                        const segments = getStringSegments(tagNames, 40);
                        return segments.map((segment, index) => (
                          <p key={index}>{segment}</p>
                        ));
                      })()
                    : tagNames
                }
                contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown translate-y-1.5"
                isFixed
                ids={['automationsPage', 'automationsTableContainer']}
              >
                <span className="font-medium text-indigo-500">
                  {trimText(tagNames, descriptionLength)}
                </span>
              </Tooltip>
            </p>
          );
      }
    } else if (entity === 'estimateStatus') {
      return (
        <p>
          Send Email to{' '}
          <span className="font-medium text-indigo-500">{action?.value}</span>
        </p>
      );
    } else {
      return (
        <p>
          Send Email to{' '}
          <span className="font-medium text-indigo-500">{action?.value}</span>
        </p>
      );
    }
  };

  return (
    <>
      <ActivationModal
        open={activationModalOpen}
        setOpen={setActivationModalOpen}
        handleActivateRule={handleActivateRule}
        activeRule={activeRule}
      />
      <DeleteAutomationModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        handleDeleteAutomation={handleDeleteRule}
        activeAutomation={activeAutomation}
      />
      <AppLayout pageId="automationsPage">
        <main className="w-full h-full grow flex justify-center">
          {accessType && (
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <AutomationHeader />
              <div className="mb-6 mt-10 flex justify-between">
                {/* Title */}
                <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                  Automations
                </h1>
              </div>
              <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                AUTOMATION_TAB={AUTOMATION_TAB}
                TEMPLATES_TAB={TEMPLATES_TAB}
              />
              {/* Page header */}

              {/* Content */}
              {isLoading ? (
                <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                  <Loader />
                  <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                    Fetching data, please wait...
                  </p>{' '}
                </div>
              ) : (
                <>
                  {activeTab === AUTOMATION_TAB && (
                    <AutomationTable
                      rules={rules}
                      handleActivateRule={handleActivateRule}
                      setActivationModalOpen={setActivationModalOpen}
                      createConditionText={createConditionText}
                      createActionText={createActionText}
                      setActiveRule={setActiveRule}
                      setActiveAutomation={setActiveAutomation}
                      setDeleteModalOpen={setDeleteModalOpen}
                    />
                  )}
                  {activeTab === TEMPLATES_TAB && (
                    <TemplatesTab templates={templates} />
                  )}
                </>
              )}
            </div>
          )}
        </main>
      </AppLayout>
    </>
  );
}

export default Workflows;
