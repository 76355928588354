import React, { useState } from 'react';
import moment from 'moment';

import AlertBox from 'components/AlertBox';
import { useSelector } from 'react-redux';
import { quickBookAuth } from 'API/backend_helper';

const QuickBookReauth = ({ reauthIssue, setReauthIssue }) => {
  const { team } = useSelector((state) => state.Team);
  const [quickBookLoading, setQuickBookLoading] = useState(false);

  const onQuickBookConnect = async () => {
    try {
      setQuickBookLoading(true);
      const res = await quickBookAuth({});
      setQuickBookLoading(false);
      window.location = res?.redirectUrl;
    } catch (err) {
      setQuickBookLoading(false);
      console.log('err', err);
    }
  };

  const isDateInPast = () => {
    // Parse the date string and convert it to the local timezone
    const userLocalTime = moment(
      team?.app?.quickbooks?.[0]?.refreshTokenExpiresAt,
    );

    // Get the current local date and time
    const now = moment();

    // Check if the date is in the past
    return userLocalTime.isBefore(now);
  };

  if (
    team?.app?.quickbooks?.[0]?.refreshTokenExpiresAt &&
    (isDateInPast() || reauthIssue)
  )
    return (
      <div className="mt-2 mb-4">
        <AlertBox
          className="!items-center px-4 !gap-2"
          type="error"
          heading="Quickbooks Authentication Error"
          btnSm
          text="Your connection to QuickBooks has expired. Please reauthenticate your account to continue accessing your data"
          hideActionButton={false}
          actionButtonText="Restore Connection"
          onActionButton={onQuickBookConnect}
          actionLoading={quickBookLoading}
        />
      </div>
    );
};

export default QuickBookReauth;
