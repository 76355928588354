import AlertBox from 'components/AlertBox';
import ImportBox from 'pages/appIntegrations/quickbooksSettings/ImportBox';
import useQuickbooksSettings from 'pages/appIntegrations/quickbooksSettings/useQuickbooksSettings';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ImportContactList = ({ updateOneStep }) => {
  const { team } = useSelector((state) => state.Team);

  const {
    customerLoading,
    vendorLoading,
    productLoading,
    syncCustomers,
    syncVendors,
    syncProducts,
  } = useQuickbooksSettings();

  const [showSuccess, setShowSuccess] = useState(false);
  const [importedCustomersSuccessfully, setImportedCustomersSuccessfully] =
    useState(false);
  const [importedVendorsSuccessfully, setImportedVendorsSuccessfully] =
    useState(false);
  const [importedProductsSuccessfully, setImportedProductsSuccessfully] =
    useState(false);

  const handleImportCustomers = async () => {
    try {
      const res = await syncCustomers();
      if (res?.data?.success) {
        setShowSuccess(true);
        setImportedCustomersSuccessfully(true);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleImportVendors = async () => {
    try {
      const res = await syncVendors();
      if (res?.data?.success) {
        setShowSuccess(true);
        setImportedVendorsSuccessfully(true);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleImportProducts = async () => {
    try {
      const res = await syncProducts();
      if (res?.data?.success) {
        setShowSuccess(true);
        setImportedProductsSuccessfully(true);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <div>
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Import Contacts List:
      </h3>
      {showSuccess && (
        <AlertBox
          type="success"
          heading={`${importedCustomersSuccessfully ? 'Customers' : ''}${importedVendorsSuccessfully ? `${importedCustomersSuccessfully ? ',' : ''} Vendors` : ''}${importedProductsSuccessfully ? `${importedCustomersSuccessfully || importedVendorsSuccessfully ? ',' : ''} Products & Services ` : ''} 
            Imported Successfully`}
          onActionButton={() => {
            setShowSuccess(false);
          }}
          className="mb-6"
          isCross
          text={`Your ${importedCustomersSuccessfully ? 'Customers' : ''}${importedVendorsSuccessfully ? `${importedCustomersSuccessfully ? ',' : ''} Vendors` : ''}${importedProductsSuccessfully ? `${importedCustomersSuccessfully || importedVendorsSuccessfully ? ',' : ''} Products & Services ` : ''} 
            data is being imported in the background. We’re handling everything for you. You will be informed by email when the import is complete.`}
          // hideActionButton
        />
      )}
      <div className="grid gap-6">
        <ImportBox
          title="Import Customers"
          text="Import all of your customers from Quickbooks with a single click."
          buttonText="Import Customers"
          handleClick={handleImportCustomers}
          loading={customerLoading}
          lastSync={team?.app?.quickbooks?.[0]?.customerLastImportedAt}
          toggleText="If an imported record has a name that exactly matches an existing Customer, the records will be automatically merged."
        />
        <ImportBox
          title="Import Vendors"
          text="Import all of your vendors from Quickbooks with a single click."
          buttonText="Import Vendors"
          handleClick={handleImportVendors}
          loading={vendorLoading}
          lastSync={team?.app?.quickbooks?.[0]?.vendorLastImportedAt}
          toggleText="If an imported record has a name that exactly matches an existing Vendor, the records will be automatically merged."
        />
        <ImportBox
          title="Import Products & Services"
          text="Import all of your products & services from Quickbooks with a single click."
          buttonText="Import Products & Services"
          handleClick={handleImportProducts}
          loading={productLoading}
          lastSync={team?.app?.quickbooks?.[0]?.productLastImportedAt}
          toggleText="If an imported record has a name that exactly matches an existing Product or Service, the records will be automatically merged."
        />
      </div>
      <footer className="flex justify-between mt-[60px] gap-4">
        {!importedCustomersSuccessfully ||
        !importedVendorsSuccessfully ||
        !importedProductsSuccessfully ? (
          <button
            type="button"
            onClick={updateOneStep}
            className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
          >
            Skip
          </button>
        ) : (
          <button
            type="button"
            className="h-[38px]  w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
            onClick={updateOneStep}
          >
            Next
          </button>
        )}
      </footer>
    </div>
  );
};

export default ImportContactList;
