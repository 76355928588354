import MerchantsDropdown from 'components/MerchantsDropdown';
import { Loader } from 'components/Svg';
import Tooltip from 'components/Tooltip';
import moment from 'moment';
import PaymentMethod from 'pages/component/custom/PaymentMethod';
import CategoriesDropdown from 'pages/transactions/CategoriesDropdown';
import React from 'react';
import {
  getContrast,
  getCurrencySymbolFromIso,
  panelAmountColor,
} from 'utils/Utils';
import UploadFile from 'components/UploadFile';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import {
  AnimatedCheckmark,
  PreviewDeleteIcon,
  ReviewedIcon,
  UnreviewedIcon,
} from '../Svg';

const ActionTab = ({
  isLoading,
  task,
  transaction,
  actionLoading,
  taskComplete,
  categories,
  allExpenseCategories,
  handleUpdateTransaction,
  vendors,
  setVendors,
  files,
  removeFile,
  setFiles,
  handleUploadAttachment,
  reviewedLoading,
  handleReviewTransaction,
  displayText,
  bill,
  setTransactionsModalOpen,
}) => {
  const { currencies } = useSelector((state) => state.Currencies);
  const { accessType, permissions } = useSelector((state) => state.User);

  const formatFileSize = (size) => {
    if (size < 1024) {
      return `${size} B`;
    }
    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    }
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  };

  return (
    <div className="my-[1.875rem] mx-6">
      {isLoading ? (
        <div className="w-full flex items-center justify-center">
          <Loader width="w-10" height="h-10" />
        </div>
      ) : task?.transactionId ? (
        <>
          <div
            className="flex flex-col w-full  border border-black border-opacity-5 py-4 px-[1rem] bg-white rounded-xl"
            style={{
              boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
            }}
          >
            <div className="flex gap-4 items-center w-full mb-3">
              <div className="text-center">
                {transaction?.vendor?.logoUrl ||
                transaction?.plaidCategoryIconUrl ? (
                  <img
                    className="inline-flex w-[2rem] h-[2rem] rounded-full"
                    src={
                      transaction?.vendor?.logoUrl ||
                      transaction?.plaidCategoryIconUrl
                    }
                    width="53"
                    height="53"
                    alt="Transaction 04"
                  />
                ) : (
                  <div
                    className="w-[2rem] h-[2rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                    style={{
                      backgroundColor:
                        transaction?.categoryAccount?.group?.color || '',
                    }}
                  >
                    <div
                      className={`${
                        transaction?.categoryAccount?.group?.color
                          ? getContrast(
                              transaction?.categoryAccount?.group?.color,
                              'text-black',
                              'text-white',
                            )
                          : 'text-black'
                      } text-sm font-normal uppercase`}
                    >
                      {transaction?.description
                        ? transaction.description
                            .replace(/[^\w\s]/gi, '')
                            .split(' ')
                            .slice(0, 2)
                            .map((word) => word.charAt(0))
                            .join('')
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`text-2xl font-medium leading-6 ${panelAmountColor(
                transaction?.amount,
              )}`}
            >
              {transaction?.amount?.toString().charAt(0) === '-' ? '+' : '-'}$
              {transaction?.amount?.toString().charAt(0) === '-'
                ? parseFloat(
                    transaction?.amount?.toString().replace('-', ''),
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : parseFloat(transaction?.amount)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </div>
            <p className="text-sm leading-6 text-slate-600 break-all font-normal mt-3 min-h-full w-full">
              {transaction?.description}
            </p>
            <div className="w-full flex items-center justify-between mt-3">
              <div className="text-sm leading-6 text-slate-600 font-medium ">
                {/* 22/01/2022, 8:56 PM */}
                {moment
                  ?.utc(transaction?.authorizedDate)
                  ?.format('MMM DD, YYYY')}
              </div>
            </div>
            <svg
              className="w-full mt-3"
              width="400"
              height="2"
              viewBox="0 0 400 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.16309 1.5L399.163 0.5"
                stroke="#D0D5DD"
                strokeOpacity="0.8"
                strokeLinecap="round"
                strokeDasharray="6 6"
              />
            </svg>
            <div className="w-full flex mt-3">
              <PaymentMethod
                details={transaction}
                institution={transaction?.account?.institution}
                dropdown={false}
                type="panel"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-full flex flex-col mt-4">
              {actionLoading ? (
                <div className="w-full flex items-center justify-center">
                  <Loader />
                </div>
              ) : (
                <>
                  {task?.type === 'transactionCategory' && !taskComplete && (
                    <Tooltip
                      content={
                        transaction?.reconciled
                          ? 'Cannot be edited while reconciled'
                          : transaction?.pending
                            ? 'Cannot be edited while pending'
                            : transaction?.billTransaction?.billInstanceId
                              ? 'Cannot be edited while assigned to a bill'
                              : transaction?.invoiceTransaction?.invoiceId
                                ? 'Cannot be edited while assigned to an invoice'
                                : transaction?.reviewed
                                  ? 'Cannot be edited once reviewed'
                                  : 'You do not have permission to edit'
                      }
                      className="w-full"
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                      size="sm"
                      position="bottom"
                      tooltipShow={
                        transaction?.reconciled ||
                        transaction?.reviewed ||
                        transaction?.pending ||
                        !!transaction?.billTransaction?.billInstanceId ||
                        !!transaction?.invoiceTransaction?.invoiceId ||
                        !checkPermission(
                          PermissionKeys.MANAGE_TRANSACTIONS,
                          accessType,
                          permissions,
                        )
                      }
                    >
                      <div className="flex flex-col gap-2">
                        <p className="text-slate-600 text-[0.875rem] font-medium leading-5">
                          Assign Category
                        </p>
                        <CategoriesDropdown
                          allCategories={
                            transaction?.type === 'Income'
                              ? categories
                              : allExpenseCategories
                          }
                          selectedCategory={transaction?.categoryAccount}
                          selectedCategoryId={transaction?.categoryAccountId}
                          flyout
                          isSetCategoryStyle
                          setSelectedCategoryId={(id) => {
                            handleUpdateTransaction(transaction?.id, {
                              categoryAccountId: id,
                            });
                          }}
                          type={transaction?.type}
                          disabled={
                            transaction?.reconciled ||
                            transaction?.reviewed ||
                            transaction?.pending ||
                            !!transaction?.billTransaction?.billInstanceId ||
                            !!transaction?.invoiceTransaction?.invoiceId ||
                            !checkPermission(
                              PermissionKeys.MANAGE_TRANSACTIONS,
                              accessType,
                              permissions,
                            )
                          }
                        />
                      </div>
                    </Tooltip>
                  )}
                  {task?.type === 'transactionVendor' && !taskComplete && (
                    <Tooltip
                      content={
                        transaction?.reconciled
                          ? 'Cannot be edited while reconciled'
                          : transaction?.pending
                            ? 'Cannot be edited while pending'
                            : transaction?.billTransaction?.billInstanceId
                              ? 'Cannot be edited while assigned to a bill'
                              : transaction?.invoiceTransaction?.invoiceId
                                ? 'Cannot be edited while assigned to an invoice'
                                : transaction?.reviewed
                                  ? 'Cannot be edited once reviewed'
                                  : 'You do not have permission to edit'
                      }
                      className="w-full"
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                      size="sm"
                      position="bottom"
                      tooltipShow={
                        transaction?.reconciled ||
                        transaction?.reviewed ||
                        transaction?.pending ||
                        !!transaction?.billTransaction?.billInstanceId ||
                        !!transaction?.invoiceTransaction?.invoiceId ||
                        !checkPermission(
                          PermissionKeys.MANAGE_TRANSACTIONS,
                          accessType,
                          permissions,
                        )
                      }
                    >
                      <div className="flex flex-col gap-2">
                        <p className="text-slate-600 text-[0.875rem] font-medium leading-5">
                          Assign Vendor
                        </p>
                        <MerchantsDropdown
                          vendors={vendors}
                          setVendors={setVendors}
                          inActiveVendor={
                            transaction?.vendor?.status === 'inactive'
                              ? transaction?.vendor
                              : {}
                          }
                          selectedMerchant={transaction?.vendorId}
                          setSelectedMerchant={(value) => {
                            handleUpdateTransaction(transaction?.id, {
                              vendorId: value,
                            });
                          }}
                          isSetCategoryStyle
                          disabled={
                            transaction?.reconciled ||
                            transaction?.reviewed ||
                            transaction?.pending ||
                            !!transaction?.billTransaction?.billInstanceId ||
                            !!transaction?.invoiceTransaction?.invoiceId ||
                            !checkPermission(
                              PermissionKeys.MANAGE_TRANSACTIONS,
                              accessType,
                              permissions,
                            )
                          }
                        />
                      </div>
                    </Tooltip>
                  )}
                  {task?.type === 'transactionAttachment' && !taskComplete && (
                    <div className="flex flex-col gap-2">
                      {checkPermission(
                        PermissionKeys.MANAGE_TRANSACTIONS,
                        accessType,
                        permissions,
                      ) ? (
                        <>
                          {files.map((file, index) => (
                            <div
                              key={index}
                              className="w-full h-[9.5rem] rounded-xl border border-slate-600 mt-4 relative"
                            >
                              <PreviewDeleteIcon
                                onClick={() => removeFile(index)}
                              />
                              {file.type === 'application/pdf' ? (
                                <iframe
                                  title="Task PDF View"
                                  src={file.preview}
                                  className="w-full h-full object-cover rounded-lg"
                                />
                              ) : (
                                <img
                                  src={file.preview}
                                  alt="receipt"
                                  className="w-full h-full object-cover rounded-lg"
                                />
                              )}
                              <div className="absolute bottom-0 left-0 w-full h-[2.375rem] rounded-b-xl bg-black bg-opacity-60 flex justify-between items-center px-4">
                                <p className="text-white text-xs leading-5 tracking-[-0.015rem]">
                                  {file.name.length > 47
                                    ? `${file.name.substring(0, 47)} ....`
                                    : file.name}
                                </p>
                                <p className="text-white text-xs leading-5 tracking-[-0.015rem]">
                                  {formatFileSize(file.size)}
                                </p>
                              </div>
                            </div>
                          ))}
                          <div>
                            <UploadFile
                              name="receipt"
                              id="receipt"
                              title="Attachments - Upload an Image or a PDF"
                              onFilesAdded={(files) =>
                                setFiles((prevFiles) => [
                                  ...prevFiles,
                                  ...files,
                                ])
                              }
                              pdf
                            />
                          </div>
                          {files.length > 0 && (
                            <button
                              className="bg-indigo-500 text-white leading-6 px-6 py-2.5 rounded-[0.313rem]"
                              type="button"
                              onClick={handleUploadAttachment}
                            >
                              Upload
                            </button>
                          )}
                        </>
                      ) : (
                        <div>
                          <label className="text-sm font-medium leading-5 text-slate-600">
                            Attachments - Upload an Image or a PDF
                          </label>
                          <p className="text-sm mt-4 text-center">
                            {' '}
                            You don't have permission to perform this action
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {task?.type === 'reviewTransaction' && !taskComplete && (
                    <Tooltip
                      content={`${
                        transaction?.reconciled
                          ? 'Transaction Reconciled'
                          : transaction?.pending
                            ? 'Cannot be reviewed while pending'
                            : transaction?.billTransaction?.billInstanceId
                              ? 'Cannot be edited while assigned to a bill'
                              : transaction?.invoiceTransaction?.invoiceId
                                ? 'Cannot be edited while assigned to an invoice'
                                : !transaction?.categoryAccountId
                                  ? 'Cannot be reviewed if uncategorized'
                                  : !checkPermission(
                                        PermissionKeys.MANAGE_TRANSACTIONS,
                                        accessType,
                                        permissions,
                                      )
                                    ? "You don't have permission to perform this action"
                                    : ''
                      } `}
                      contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                      position="bottom"
                      size="sm"
                      tooltipShow={
                        transaction?.pending ||
                        !transaction?.categoryAccountId ||
                        transaction?.reconciled ||
                        reviewedLoading ||
                        !!transaction?.billTransaction?.billInstanceId ||
                        !!transaction?.invoiceTransaction?.invoiceId ||
                        !checkPermission(
                          PermissionKeys.MANAGE_TRANSACTIONS,
                          accessType,
                          permissions,
                        )
                      }
                    >
                      <div className="flex items-center gap-2">
                        <button
                          aria-controls="feedback-modal"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleReviewTransaction(!transaction?.reviewed);
                          }}
                          disabled={
                            reviewedLoading ||
                            transaction?.pending ||
                            !transaction?.categoryAccountId ||
                            !!transaction?.billTransaction?.billInstanceId ||
                            !!transaction?.invoiceTransaction?.invoiceId ||
                            !checkPermission(
                              PermissionKeys.MANAGE_TRANSACTIONS,
                              accessType,
                              permissions,
                            )
                          }
                          className="border  border-gray-300 border-opacity-50 shadow-lg w-[1.938rem] h-[1.938rem] inline-flex justify-center items-center rounded-lg"
                          type="button"
                        >
                          {reviewedLoading ? (
                            <div className="flex items-center justify-center">
                              <Loader width="w-4" height="h-4" />
                            </div>
                          ) : transaction?.reviewed ? (
                            <ReviewedIcon />
                          ) : (
                            <UnreviewedIcon />
                          )}
                        </button>
                        <p className="text-slate-600 text-[0.875rem] font-medium leading-5">
                          Mark Transaction as Reviewed
                        </p>
                      </div>
                    </Tooltip>
                  )}
                  {taskComplete && (
                    <div className="w-full flex items-center justify-center gap-2">
                      <AnimatedCheckmark />
                      {displayText ? (
                        <p className="text-[#40AA00]">
                          Task marked as complete.
                        </p>
                      ) : (
                        <p className="opacity-0">Task marked as complete.</p>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="flex flex-col w-full  border border-black border-opacity-5 py-4 px-[1rem] bg-white rounded-xl"
            style={{
              boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.06)',
            }}
          >
            <div className="flex gap-4 items-center w-full mb-3">
              <div className="text-center">
                {bill?.vendor?.logoUrl ? (
                  <img
                    className="inline-flex w-[2rem] h-[2rem] rounded-full"
                    src={bill?.vendor?.logoUrl}
                    width="53"
                    height="53"
                    alt="Transaction 04"
                  />
                ) : (
                  <div className="w-[2rem] h-[2rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600">
                    <div className="text-white text-sm font-normal uppercase">
                      {bill?.vendor?.name
                        ? bill.vendor.name
                            .replace(/[^\w\s]/gi, '')
                            .split(' ')
                            .slice(0, 2)
                            .map((word) => word.charAt(0))
                            .join('')
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="text-2xl font-medium leading-6 text-[#333333]">
              Amount Due: {getCurrencySymbolFromIso(bill?.currency, currencies)}
              {transaction?.amount?.toString().charAt(0) === '-'
                ? parseFloat(
                    bill?.total?.toString().replace('-', ''),
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : parseFloat(bill?.total)?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
            </div>
            <p className="text-sm leading-6 text-slate-600 break-all font-normal mt-3 min-h-full w-full">
              {bill?.vendor?.name}
            </p>
            <div className="w-full flex items-center justify-between mt-3">
              <div className="text-sm leading-6 text-slate-600 font-medium ">
                {/* 22/01/2022, 8:56 PM */}
                Due Date:{' '}
                {moment?.utc(bill?.firstDueDate)?.format('MMM DD, YYYY')}
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-6 justify-center items-center">
            {taskComplete ? (
              <div className="w-full flex items-center justify-center gap-2">
                <AnimatedCheckmark />
                {displayText ? (
                  <p className="text-[#40AA00]">Task marked as complete.</p>
                ) : (
                  <p className="opacity-0">Task marked as complete.</p>
                )}
              </div>
            ) : (
              <Tooltip
                content={
                  bill?.approvalStatus === 'draft'
                    ? 'Draft bill cannot be paid'
                    : 'You do not have permission to pay bill'
                }
                className="w-fit"
                contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                size="sm"
                tooltipShow={
                  !checkPermission(
                    PermissionKeys.APPROVE_BILLS,
                    accessType,
                    permissions,
                  ) || bill?.approvalStatus === 'draft'
                }
              >
                <button
                  type="button"
                  onClick={() => {
                    setTransactionsModalOpen(true);
                  }}
                  className="bg-indigo-500 text-white leading-6 px-6 py-2.5 rounded-[0.313rem] flex items-center justify-center disabled:bg-indigo-300 disabled:cursor-not-allowed"
                  disabled={
                    !checkPermission(
                      PermissionKeys.APPROVE_BILLS,
                      accessType,
                      permissions,
                    ) || bill?.approvalStatus === 'draft'
                  }
                >
                  Pay Bill
                </button>
              </Tooltip>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ActionTab;
