import DropdownFull from 'components/DropdownFull';
import useCustomerSearch from 'hooks/useCustomerSearch';
import BasicCheckboxDropdown from 'pages/component/BasicCheckboxDropdown';
import React, { useEffect, useState } from 'react';

const TableEntryItemsDropdowns = ({
  b,
  i,
  handleUpdateValue,
  customers = [],
  setCustomers,
  projects = [],
  tags = [],
}) => {
  const {
    dropdownCustomers,
    handleCustomerSearch,
    resetCustomerSearch,
    searchCustomerLoading,
  } = useCustomerSearch(customers, setCustomers);

  const [dropdownProjects, setDropdownProjects] = useState([...projects]);

  useEffect(() => {
    if (projects?.length > 0) {
      setDropdownProjects([...projects]);
    }
  }, [projects]);

  const handleProjectSearch = (value, selectedProjectId) => {
    if (projects?.length > 0) {
      const tempProject = [...projects];
      const filteredProjects = tempProject?.filter((project) =>
        project?.name?.toLowerCase()?.includes(value?.toLowerCase()),
      );
      const selectedProject = filteredProjects?.filter(
        (project) => project?.id === selectedProjectId,
      );
      if (!selectedProjectId || selectedProject?.length > 0) {
        setDropdownProjects(filteredProjects);
      } else {
        const selectedProject = projects?.find(
          (project) => project?.id === selectedProjectId,
        );
        setDropdownProjects([selectedProject, ...filteredProjects]);
      }
    }
  };

  const resetProjectSearch = () => {
    if (projects?.length > 0) {
      setDropdownProjects([...projects]);
    }
  };

  return (
    <div className="flex items-center gap-2">
      {!b?.projectId?.value && (
        <div className="w-max max-w-[500px]  gap-2 flex items-center justify-between">
          <div
            className={`${b?.customerId?.value ? 'w-max max-w-[460px]' : 'w-full'}`}
          >
            <DropdownFull
              options={
                b?.customerId?.customer?.status === 'inactive'
                  ? [{ ...b?.customerId?.customer }, ...dropdownCustomers]
                  : dropdownCustomers
              }
              disabled={b?.projectId?.value}
              selected={b?.customerId?.value || null}
              setSelected={(value) => {
                handleUpdateValue(i, 'customerId', value);
              }}
              name="customer"
              scrollIntoView={false}
              search
              height={`h-fit  border-none !px-0 !py-1 hover:underline w-full ${!b?.customerId?.value ? '!text-indigo-500' : ''}`}
              buttonTextClassSub={`!text-sm ${!b?.customerId?.value ? '!text-indigo-500' : '!text-black'}`}
              flyout
              placeholder="Customer"
              handleSearch={(value) =>
                handleCustomerSearch(value, b?.customerId?.value)
              }
              resetSearch={resetCustomerSearch}
              searchPlaceholder="Type To Search"
              searchLoading={searchCustomerLoading}
              noMinW
              bottomOfTable
              showDropdownChevron={false}
              isTableDropdown
              ids={['billsPageContainer', 'createBillTableContainer']}
            />
          </div>
          {b?.customerId?.value && (
            <button
              className="text-sm text-indigo-500 cursor-pointer hover:underline"
              type="button"
              onClick={() => handleUpdateValue(i, 'customerId', null)}
            >
              reset
            </button>
          )}
        </div>
      )}
      {!b?.customerId?.value && !b?.projectId?.value && (
        <span className="text-black text-sm">|</span>
      )}

      {!b?.customerId?.value && (
        <div className="w-max max-w-[500px] gap-2 flex items-center justify-between">
          <div
            className={`${b?.projectId?.value ? 'w-max max-w-[460px]' : 'w-full'}`}
          >
            <DropdownFull
              options={dropdownProjects}
              selected={b?.projectId?.value || null}
              setSelected={(value) => {
                handleUpdateValue(i, 'projectId', value);
              }}
              name="name"
              scrollIntoView={false}
              search
              height={`h-fit  border-none !px-0 !py-1 hover:underline w-full ${!b?.projectId?.value ? '!text-indigo-500' : ''}`}
              buttonTextClassSub={`!text-sm ${!b?.projectId?.value ? '!text-indigo-500' : '!text-black'}`}
              flyout
              noMinW
              placeholder="Project"
              bottomOfTable
              disabled={b?.customerId?.value}
              className="whitespace-nowrap truncate !h-fit"
              isTableDropdown
              ids={['billsPageContainer', 'createBillTableContainer']}
              showDropdownChevron={false}
              handleSearch={(value) =>
                handleProjectSearch(value, b?.projectId?.value)
              }
              resetSearch={resetProjectSearch}
            />
          </div>
          {b?.projectId?.value && (
            <button
              className="text-sm text-indigo-500 cursor-pointer hover:underline"
              type="button"
              onClick={() => handleUpdateValue(i, 'projectId', null)}
            >
              reset
            </button>
          )}
        </div>
      )}
      <span className="text-black text-sm">|</span>

      <div className="w-max max-w-[500px] gap-2 flex items-center justify-between">
        <div
          className={`${b?.tags?.value?.length > 0 ? 'w-max max-w-[460px]' : 'w-full'}`}
        >
          <BasicCheckboxDropdown
            isUseStateHook={false}
            selectedOptions={b?.tags?.value || []}
            setSelectedOptions={(v) => {
              handleUpdateValue(i, 'tags', v);
            }}
            options={
              tags?.map((t) => ({
                label: t?.name,
                value: t?.id,
              })) || []
            }
            showDropdownChevron={false}
            height={`h-fit  border-none !px-0 !py-1 hover:underline w-full ${!(b?.tags?.value?.length > 0) ? '!text-indigo-500 font-medium' : '!text-slate-600'}`}
            buttonTextClassSub={`!text-sm ${!(b?.tags?.value?.length > 0) ? '!text-indigo-500' : '!text-black'}`}
            label="Tags"
            type="tag"
            flyout
            bottomOfTable
            ids={['billsPageContainer', 'createBillTableContainer']}
            noMinW
            isTableDropdown
            dropdownBoxWidth="min-w-44 w-full max-w-[300px]"
          />
        </div>
        {b?.tags?.value?.length > 0 && (
          <button
            className="text-sm text-indigo-500  cursor-pointer hover:underline"
            type="button"
            onClick={() => handleUpdateValue(i, 'tags', [])}
          >
            reset
          </button>
        )}
      </div>
    </div>
  );
};

export default TableEntryItemsDropdowns;
