import React from 'react';
import { useNavigate } from 'react-router-dom';
import ModalBasic from '../../components/ModalBasic';

const WarningLine = ({ text, onView, isDuplicate }) => (
  <div className="w-full px-3 py-1 bg-white">
    <div
      className={`flex justify-between items-center w-full py-3.5 px-3 border-b ${isDuplicate ? 'border-[#D0D5DD]' : 'border-blue-500'}  last:border-none`}
    >
      <div className="flex gap-2 items-center">
        {/* Warning Icon  */}
        <svg
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99935 6.40332V9.73665M9.99935 13.07H10.0077M18.3327 9.73665C18.3327 14.339 14.6017 18.07 9.99935 18.07C5.39698 18.07 1.66602 14.339 1.66602 9.73665C1.66602 5.13428 5.39698 1.40332 9.99935 1.40332C14.6017 1.40332 18.3327 5.13428 18.3327 9.73665Z"
            stroke={isDuplicate ? '#FF4B4B' : '#3b82f6'}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p
          className={`text-sm font-medium ${isDuplicate ? 'text-[#FF4B4B]' : 'text-blue-500'}`}
        >
          {text}
        </p>
      </div>
      <button
        type="button"
        className={`${isDuplicate ? 'text-[#FF4B4B]' : 'text-blue-500 font-semibold'} text-base hover:underline`}
        onClick={onView}
      >
        View
      </button>
    </div>
  </div>
);

const ErrorsModal = ({
  isOpen,
  setIsOpen,
  numberOfDuplicates,
  numberOfMatching,
  numberOfUndepositMatching,
  numberOfInvoicesMatching,
  numberOfBillsMatching,
}) => {
  const navigate = useNavigate();
  return (
    <ModalBasic
      title="Suggestions Found"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      smallModal
    >
      {numberOfDuplicates > 0 && (
        <WarningLine
          text={`${numberOfDuplicates} Possible Duplicate Transactions Found`}
          onView={() => {
            navigate('/suggestions/', {
              state: { activeTab: 'Duplicate' },
            });
          }}
          isDuplicate
        />
      )}
      {numberOfMatching > 0 && (
        <WarningLine
          text={`${numberOfMatching} Possible Matching Transactions Found`}
          onView={() => {
            navigate('/suggestions/', {
              state: { activeTab: 'Matching' },
            });
          }}
        />
      )}
      {numberOfInvoicesMatching > 0 && (
        <WarningLine
          text={`${numberOfInvoicesMatching} Possible Invoice Matching Transactions Found`}
          onView={() => {
            navigate('/suggestions/', {
              state: { activeTab: 'Invoice Matching' },
            });
          }}
        />
      )}
      {numberOfBillsMatching > 0 && (
        <WarningLine
          text={`${numberOfBillsMatching} Possible Bill Matching Transactions Found`}
          onView={() => {
            navigate('/suggestions/', {
              state: { activeTab: 'Bill Matching' },
            });
          }}
        />
      )}

      {numberOfUndepositMatching > 0 && (
        <WarningLine
          text={`${numberOfUndepositMatching} Possible Undeposited Transactions Found`}
          onView={() => {
            navigate('/suggestions/', {
              state: { activeTab: 'Undeposited Transfer' },
            });
          }}
        />
      )}
    </ModalBasic>
  );
};

export default ErrorsModal;
