import AlertBox from 'components/AlertBox';
import ImportBox from 'pages/appIntegrations/quickbooksSettings/ImportBox';
import useQuickbooksSettings from 'pages/appIntegrations/quickbooksSettings/useQuickbooksSettings';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ImportTransactions = ({ updateOneStep }) => {
  const { team } = useSelector((state) => state.Team);

  const { transactionsLoading, syncTransactions } = useQuickbooksSettings();
  const [showSuccess, setShowSuccess] = useState(false);
  const [importedSuccessfully, setImportedSuccessfully] = useState(false);

  const handleImportTransactions = async () => {
    try {
      const res = await syncTransactions(true);
      if (res?.status === 'success') {
        setShowSuccess(true);
        setImportedSuccessfully(true);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <div>
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Import Historical Transactions:
      </h3>
      {showSuccess && (
        <AlertBox
          type="success"
          heading="Transactions Imported Successfully"
          onActionButton={() => {
            setShowSuccess(false);
          }}
          className="mb-6"
          isCross
          text="Your Transactions data is being imported in the background. We’re handling everything for you. You will be informed by email when the import is complete."
          // hideActionButton
        />
      )}
      <div className="grid">
        <ImportBox
          title="Import Historical Transactions"
          text="Import all of your transactions from Quickbooks with a single click."
          buttonText="Import Transactions"
          handleClick={handleImportTransactions}
          loading={transactionsLoading}
          lastSync={team?.app?.quickbooks?.[0]?.transactionLastImportedAt}
          // toggleText="Enable this option to deactivate all default accounts and replace them with the imported chart of accounts. Note: Certain default accounts are required for COUNT's automation features and cannot be deactivated."
        />
      </div>
      <footer className="flex justify-between mt-[60px] gap-4">
        {!importedSuccessfully && (
          <button
            type="button"
            onClick={updateOneStep}
            className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
          >
            Skip
          </button>
        )}
        {importedSuccessfully && (
          <button
            type="button"
            className="h-[38px]  w-24 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
            onClick={updateOneStep}
          >
            Next
          </button>
        )}
      </footer>
    </div>
  );
};

export default ImportTransactions;
