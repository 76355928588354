import React, { useEffect, useMemo, useState } from 'react';
import DatePickerDash from 'components/DatePickerDash';
import { useSelector } from 'react-redux';
import {
  formatDateLocally,
  getFormattedDate,
  TEAM_DATE_FORMAT,
} from 'utils/Utils';
import moment from 'moment';
import ModalBasic from '../../../components/ModalBasic';
import DropdownFull from '../../../components/DropdownFull';
import { plaidSyncStart } from '../../../API/backend_helper';

const ChooseAccountsModal = ({
  isOpen,
  setIsOpen,
  accessToken,
  afterSuccess,
  useOnBoardingCutoverDate = false,
}) => {
  const { team } = useSelector((state) => state.Team);
  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [transactionRange, setTransactionRange] = useState('jan1');
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);

  const [cutoverDateError, setCutoverDateError] = useState(false);
  const [cutoverDate, setCutoverDate] = useState('');

  const options = useMemo(
    () => ({
      mode: 'single',
      enableTime: false,
      static: false,
      onReady: (selectedDates, dateStr, instance) => {
        instance?.calendarContainer?.classList?.add(`flatpickr-right`);
      },
      monthSelectorType: 'static',
      allowInput: true,
      maxDate: new Date(),
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  const onChecked = (id) => {
    let temp = [...selectedAccounts];
    if (temp?.includes(id)) {
      temp = temp.filter((t) => t !== id);
    } else {
      temp?.push(id);
    }
    setSelectedAccounts(temp);
  };

  useEffect(() => {
    if (isOpen?.accounts?.length > 0) {
      const temp = [];
      isOpen?.accounts?.forEach((acc) => {
        temp?.push(acc?.account_id);
      });
      setSelectedAccounts(temp);
    }
  }, [isOpen?.accounts]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isOpen) {
        setActiveStep(1);
        setCutoverDate('');
        setCutoverDateError(false);
        event.preventDefault();
        event.returnValue = ''; // This line is necessary for some browsers
        return ''; // This line is necessary for other browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isOpen]);

  const onSubmit = async () => {
    if (activeStep === 1 && !useOnBoardingCutoverDate) {
      setActiveStep(2);
      return;
    }

    // if (transactionRange === 'custom' && !cutoverDate) {
    //   setCutoverDateError(true);
    //   return;
    // }

    try {
      setIsLoading(true);

      const res = await plaidSyncStart(accessToken, {
        connectionId: isOpen?.id,
        transactionRange: useOnBoardingCutoverDate
          ? getFormattedDate(
              formatDateLocally(
                team?.defaults?.cutoverDate ||
                  moment().subtract(1, 'year').toDate(),
                teamDateFormat,
              ),
              teamDateFormat,
            )
          : transactionRange,
        accounts: selectedAccounts,
      });
      afterSuccess();
      setIsLoading(false);
      setTransactionRange('jan1');
      setSelectedAccounts([]);
      setIsOpen(false);
    } catch (e) {
      setIsLoading(false);
      console.log('error', e);
    }
  };

  return (
    <ModalBasic
      title="Connect Accounts"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      width="max-w-[600px]"
      noClick
      hideCross
    >
      {!useOnBoardingCutoverDate && (
        <div className="flex justify-between mx-6 relative mb-4 mt-6">
          <div
            className="absolute h-[1px] bg-[#667085] top-[11px] mx-auto left-1/2 -translate-x-1/2"
            style={{
              width: 'calc(100% - 8rem)',
            }}
          />
          <div className="flex flex-col items-center z-10 relative">
            <div
              className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 1
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
            >
              1
            </div>
            <h3>Select Accounts</h3>
          </div>
          <div className="flex flex-col items-center z-10 relative">
            <div
              className={`h-6 w-6 rounded-full  inline-flex
                 justify-center items-center border   text-sm ${
                   activeStep === 2
                     ? 'bg-indigo-500 text-white border-indigo-500'
                     : 'border-[#667085] bg-white'
                 }`}
            >
              2
            </div>
            <h3>Select Time Period</h3>
          </div>
        </div>
      )}

      <div className="px-[30px] py-4">
        {/* Step 1  */}
        {activeStep === 1 && (
          <div className="space-y-2 min-h-[300px]">
            {useOnBoardingCutoverDate && (
              <p className="mb-4">
                <span className="font-medium">Cutover Date:</span>{' '}
                {moment(team?.defaults?.cutoverDate).format('DD MMM, YYYY')}
              </p>
            )}
            <p className="!mb-4">
              Choose the accounts from which you wish to receive transactions.
              You can enable or disable accounts in team settings later if
              needed.
            </p>
            {isOpen?.accounts?.length > 0 &&
              isOpen?.accounts?.map((account, index) => (
                <div
                  key={index}
                  className="flex justify-between gap-4 items-center cursor-pointer border-b border-dashed pb-2 last:border-none last:mb-0"
                >
                  <div
                    onClick={() => onChecked(account?.account_id)}
                    role="button"
                    tabIndex="0"
                  >
                    <h3 className="text-[18px] font-medium">
                      {account?.name}{' '}
                      {account?.mask && `(....${account?.mask})`}
                    </h3>
                    <p className="text-sm">{account?.official_name}</p>
                  </div>
                  <div className="form-switch">
                    <input
                      type="checkbox"
                      id={account?.account_id}
                      className="sr-only"
                      checked={selectedAccounts?.includes(account?.account_id)}
                      onChange={(e) => onChecked(e.target.id)}
                    />
                    <label
                      className="bg-white border border-gray-300 border-opacity-50 shadow-sm"
                      htmlFor={account?.account_id}
                    >
                      <span className="bg-gray-300" aria-hidden="true" />
                      <span className="sr-only">Switch label</span>
                    </label>
                  </div>
                </div>
              ))}
          </div>
        )}

        {/* Step 2  */}
        {activeStep === 2 && (
          <div className="min-h-[300px]">
            <p className="mb-2">
              Choose the time period from which you wish to receive transactions
            </p>
            <DropdownFull
              options={[
                {
                  id: 'none',
                  name: 'Do not sync any historical transactions',
                },
                {
                  id: 'last90',
                  name: 'Sync last 90 days of transactions',
                },
                {
                  id: 'jan1',
                  name: `Sync transactions from January 1st, ${new Date().getFullYear()}`,
                },
                {
                  id: 'all',
                  name: ' Sync all historical transaction available (this is often the last 24 months)',
                },
                // {
                //   id: 'custom',
                //   name: 'Custom cutover  date',
                // },
              ]}
              className="w-full !h-fit"
              name="name"
              setSelected={(id) => {
                setTransactionRange(id);
              }}
              selected={transactionRange}
              scrollIntoView={false}
            />

            {/* {transactionRange === 'custom' && (
              <DatePickerDash
                width="max-w-[300px] mt-6"
                label="Select Cutover Date"
                placeholder="Select Cutover Date"
                value={cutoverDate}
                options={options}
                onChange={(selectedDates) => {
                  setCutoverDate(selectedDates[0]);
                }}
                error={
                  !cutoverDate && cutoverDateError
                    ? 'Please select cutover date'
                    : ''
                }
                name="cutoverDate"
                id="cutoverDate"
                onBlur={(e) => {
                  if (!e.target.classList?.value?.includes('active')) {
                    setCutoverDate(e.target.value);
                  }
                }}
              />
            )} */}
          </div>
        )}
      </div>

      <footer className="flex justify-between items-center gap-4 mt-4 border-t px-[30px] py-4">
        {activeStep === 2 && (
          <button
            type="button"
            className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
            onClick={() => setActiveStep(1)}
          >
            Back
          </button>
        )}
        <button
          className="h-[38px] min-w-24 ml-auto inline-flex justify-center items-center  px-6 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
          onClick={onSubmit}
          disabled={isLoading}
          type="button"
        >
          {isLoading && (
            <svg
              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          )}
          {activeStep === 2 || useOnBoardingCutoverDate
            ? 'Connect Accounts'
            : 'Next'}
        </button>
      </footer>
    </ModalBasic>
  );
};

export default ChooseAccountsModal;
