import React, { useEffect, useRef } from 'react';

const FlexibleHeightTextarea = ({ defaultHeightAdd = 5, value, ...rest }) => {
  const textareaRef = useRef();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight + defaultHeightAdd}px`;
    }
  }, [value]);

  return <textarea type="text" {...rest} value={value} ref={textareaRef} />;
};

export default FlexibleHeightTextarea;
