import React, { useRef, useState } from 'react';
import { createPlaidToken } from 'API/backend_helper';
import Tooltip from 'components/Tooltip';
import AkahuLink from 'pages/settings/AkhahuLink/AkahuLink';
import PlaidLink from 'pages/settings/PlaidLink';
import { useSelector } from 'react-redux';
import { TEAM_CURRENCY } from 'utils/Utils';

const AkahuLinkButton = () => (
  <button
    className="h-[38px] inline-flex justify-center items-center px-5 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
    type="button"
  >
    Connect
  </button>
);

const ConnectBankAccounts = ({
  updateOneStep,
  useOnBoardingCutoverDate = false,
}) => {
  const PlaidButtonRef = useRef();

  const { team } = useSelector((state) => state.Team);
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const onBankClick = async () => {
    setLoading(true);
    try {
      const plaidRes = await createPlaidToken(null, {}, '');
      const { linkToken } = plaidRes;
      setToken(linkToken);
      setTimeout(() => {
        setLoading(false);
        PlaidButtonRef.current.click();
      }, 1000);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
    }
  };

  const updateStateAfterConnection = () => {
    updateOneStep();
  };

  return (
    <div>
      <h3 className="text-[20px] font-medium text-[#101828] mb-4">
        Connect Bank Accounts:
      </h3>
      <PlaidLink
        loadData={updateStateAfterConnection}
        setIsLoading={() => {}}
        token={token}
        team={team}
        accountPage
        buttonRef={PlaidButtonRef}
        cb={() => {}}
        shouldNavigate={false}
        useOnBoardingCutoverDate={useOnBoardingCutoverDate}
      />
      <footer className="flex justify-between mt-[40px] gap-4">
        <button
          type="button"
          onClick={updateOneStep}
          className="h-[38px]  w-24 border border-indigo-500 text-indigo-500 text-base rounded-[6px]"
        >
          Skip
        </button>
        {team?.country === 'NZ' ? (
          <AkahuLink ButtonUI={AkahuLinkButton} />
        ) : (
          <Tooltip
            content={`Live bank feeds for ${team?.country || ''} is currently not supported. Manually import transactions instead.`}
            contentClassName="border-none w-[250px] text-wrap overflow-visible text-sm text-[#667085] relative"
            position="left"
            size="sm"
            tooltipShow={
              teamDefaultCurrency !== 'USD' && teamDefaultCurrency !== 'CAD'
            }
          >
            <button
              className="h-[38px] inline-flex justify-center items-center px-5 border border-indigo-500 bg-indigo-500 text-white text-base rounded-[6px] disabled:!bg-indigo-300 disabled:cursor-not-allowed"
              type="button"
              onClick={() => {
                // setInstitutionModalOpen(true);
                onBankClick();
              }}
              disabled={
                (teamDefaultCurrency !== 'USD' &&
                  teamDefaultCurrency !== 'CAD') ||
                loading
              }
            >
              {loading && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Connect
            </button>
          </Tooltip>
        )}
      </footer>
    </div>
  );
};

export default ConnectBankAccounts;
