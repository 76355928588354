import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import WarningBox from '../../components/WarningBox';
import {
  assignTransactionToBill,
  onIgnoreMatchingTransaction,
} from '../../API/backend_helper';
import {
  amountColor,
  getBillStatusColor,
  getContrast,
  getCurrencySymbolFromIso,
  TEAM_CURRENCY,
} from '../../utils/Utils';
import Tooltip from '../../components/Tooltip';
import { Loader } from '../../components/Svg';
import PaginationClassic from '../../components/PaginationClassic';

const BillMatchingTransactions = ({
  matchings,
  currencies,
  selectedTransactions,
  setSelectedTransactions,
  loadTransactionsApi,
  localLimit,
  setLocalLimit,
  setTransactionFlyoutOpen,
  setTransaction,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [descriptionLength, setDescriptionLength] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [keepAllloading, setKeepAllLoading] = useState({});
  const [keepSelectedLoading, setKeepSelectedLoading] = useState({});
  const [keepAllSelectedLoading, setKeepAllSelectedLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1800) {
        setDescriptionLength(100);
      }
      if (window.innerWidth > 1700) {
        setDescriptionLength(80);
      } else if (window.innerWidth > 1300) {
        setDescriptionLength(60);
      } else if (window.innerWidth > 992) {
        setDescriptionLength(50);
      } else {
        setDescriptionLength(20);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const findCurrencySymbol = (isoCode) => {
    const currency = currencies?.find?.(
      (currency) => currency.isoCode === isoCode,
    );
    return currency?.symbol === '$' && currency?.isoCode != 'USD'
      ? currency.symbol
      : currency?.symbol;
  };

  const handleTransactionCheckbox = (e, matchId, instanceId) => {
    e.stopPropagation();
    const { id } = e.target;
    const parsedId = parseInt(id);
    setSelectedTransactions((prev) => {
      const currentMatchTransactions = prev[`${matchId}_${instanceId}`] || [];
      if (currentMatchTransactions.includes(parsedId)) {
        return {
          ...prev,
          [`${matchId}_${instanceId}`]: currentMatchTransactions.filter(
            (item) => item !== parsedId,
          ),
        };
      }
      return {
        ...prev,
        [`${matchId}_${instanceId}`]: [parsedId],
      };
    });
  };

  const handleKeepAll = async (id, _matching) => {
    setKeepAllLoading((prev) => ({ ...prev, [_matching?.bill?.id]: true }));
    const matching = matchings?.matchings?.find(
      (matching) => matching.id === id,
    );
    if (matching) {
      try {
        await onIgnoreMatchingTransaction(id);
        await loadTransactionsApi({});
        setSelectedTransactions((prev) => {
          const { [id]: _, ...rest } = prev;
          return rest;
        });
      } catch (error) {
        console.log(error);
      } finally {
        setKeepAllLoading((prev) => ({
          ...prev,
          [_matching?.bill?.id]: false,
        }));
      }
    }
  };

  const handleKeepSelected = async (id, instanceId) => {
    setKeepSelectedLoading((prev) => ({ ...prev, [id]: true }));
    if (selectedTransactions[`${id}_${instanceId}`]?.length > 0) {
      try {
        await assignTransactionToBill(id, instanceId, {
          transactionId: selectedTransactions[`${id}_${instanceId}`][0],
        });
        await loadTransactionsApi({});

        setSelectedTransactions((prev) => {
          const { [id]: _, ...rest } = prev;
          return rest;
        });
      } catch (error) {
        console.log(error);
      } finally {
        setKeepSelectedLoading((prev) => ({ ...prev, [id]: false }));
      }
    } else {
      setKeepSelectedLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleKeepAllSelected = async () => {
    setKeepAllSelectedLoading(true);
    setKeepSelectedLoading((prev) => {
      const loadingState = {};
      Object.keys(selectedTransactions).forEach((id) => {
        loadingState[id] = true;
      });
      return { ...prev, ...loadingState };
    });

    try {
      for (const id of Object.keys(selectedTransactions)) {
        if (selectedTransactions[id]?.length > 0) {
          await assignTransactionToBill(
            id?.split('_')?.[0],
            id?.split('_')?.[1],
            {
              transactionId: selectedTransactions[id][0],
            },
          );
        }
      }
      await loadTransactionsApi({});
      setSelectedTransactions({});
    } catch (error) {
      console.log(error);
    } finally {
      setKeepSelectedLoading((prev) => {
        const loadingState = {};
        Object.keys(selectedTransactions).forEach((id) => {
          loadingState[id] = false;
        });
        return { ...prev, ...loadingState };
      });
      setKeepAllSelectedLoading(false);
    }
  };

  const { page, totalRecords } = matchings;

  const isMatchAllDisable = () => {
    let bool = false;
    if (Object.keys(selectedTransactions)?.length === 0) {
      bool = true;
    } else {
      matchings?.matchings?.forEach?.((match) => {
        if (
          selectedTransactions[`${match?.bill?.id}_${match?.billInstanceId}`]
            ?.length === 0
        ) {
          bool = true;
        }
      });
    }
    return bool;
  };

  return (
    <>
      {matchings?.matchings?.length > 0 && !isLoading && (
        <WarningBox content="Please review potential matching transactions below. You can choose to confirm or ignore each match." />
      )}
      {isLoading ? (
        <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
          <Loader />
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        matchings?.matchings?.length > 0 && (
          <>
            {matchings?.matchings?.map((matching, index) => (
              <>
                <div className="overflow-x-auto h-full">
                  <table className="table-fixed w-full dark:text-slate-300">
                    {/* Table header */}
                    <thead className="w-full shadow-md rounded-[0.313rem] text-sm leading-[1.125rem] text-slate-600">
                      <tr className="w-full py-[1.438rem] px-[1.375rem] h-16">
                        <th className="px-4 w-[6rem] max-w-[6rem]">
                          <div className="text-left w-[6.25rem] font-medium">
                            Bill #
                          </div>
                        </th>
                        <th className="w-[8rem] max-w-[8rem]">
                          <div className="text-left font-medium">Status</div>
                        </th>
                        <th className="w-[7rem] max-w-[7rem]">
                          <div className="text-left font-medium">Date</div>
                        </th>
                        <th className="w-[7rem] max-w-[7rem]">
                          <div className="text-left font-medium">Due</div>
                        </th>
                        <th className="w-[14rem] max-w-[14rem]">
                          <div className="text-left w-[8.75rem] font-medium">
                            Vendor
                          </div>
                        </th>
                        <th className="w-[8rem] max-w-[8rem]">
                          <div className="text-left font-medium">Total</div>
                        </th>
                        <th className="w-[8rem] max-w-[8rem]">
                          <div className="text-left font-medium">Paid</div>
                        </th>
                        <th className="w-[8rem] max-w-[8rem]">
                          <div className="text-left font-medium">
                            Amount Due
                          </div>
                        </th>
                      </tr>
                    </thead>
                    {/* Table body */}

                    <tbody className="text-sm divide-y divide-gray-300">
                      <tr
                        key={index}
                        className="w-full pl-[1.375rem]  pr-[1.125rem] h-[55px]"
                      >
                        <td className="px-4">
                          <div className=" break-words text-[0.938rem] font-medium leading-6 text-left text-slate-600">
                            {matching?.bill?.billNumber || '--'}
                          </div>
                        </td>
                        <td className="pr-4">
                          <div
                            className={`capitalize  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                      ${getBillStatusColor(
                        matching?.bill?.approvalStatus === 'draft'
                          ? 'draft'
                          : matching?.billInstance?.status,
                      )}
                    `}
                          >
                            {matching?.bill?.approvalStatus === 'draft'
                              ? 'Draft'
                              : matching?.billInstance?.status === 'unpaid'
                                ? 'Approved'
                                : matching?.billInstance?.status || '--'}
                          </div>
                        </td>
                        <td className="pr-4">
                          <div className=" text-left text-slate-600 text-[0.938rem] leading-6 break-words">
                            {moment(matching?.bill?.firstBillDate).format(
                              'MMM DD, YYYY',
                            )}
                          </div>
                        </td>
                        <td className="whitespace-nowrap">
                          <div className="text-left">
                            <p className="text-slate-600 text-[0.938rem] leading-6">
                              {moment(
                                matching?.bill?.dueDate
                                  ? matching?.bill?.dueDate
                                  : matching?.bill?.firstDueDate,
                              ).format('MMM DD, YYYY')}
                            </p>
                          </div>
                        </td>
                        <td className="pr-4">
                          <div className="flex items-center">
                            <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                              {matching?.bill?.vendor?.logoUrl ? (
                                <img
                                  className="rounded-full"
                                  src={matching?.bill?.vendor?.logoUrl}
                                  alt={matching?.bill?.vendor?.name}
                                />
                              ) : (
                                <div className="w-[1.687rem] h-[1.687rem] rounded-[5.125rem] flex items-center justify-center bg-slate-400 dark:bg-slate-700">
                                  <div className="text-xs font-normal uppercase text-white">
                                    {matching?.bill?.vendor?.name
                                      ? matching?.bill?.vendor.name
                                          .replace(/[^\w\s]/gi, '')
                                          .split(' ')
                                          .filter(Boolean)
                                          .slice(0, 2)
                                          .map((word) => word.charAt(0))
                                          .join('')
                                      : ''}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="font-normal text-slate-600 leading-6 mr-2">
                              {matching?.bill?.vendor?.name?.length > 12 ? (
                                <Tooltip
                                  content={matching?.bill?.vendor?.name}
                                  contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                >
                                  <span>{`${matching?.bill?.vendor?.name?.slice(0, 12)}...`}</span>
                                </Tooltip>
                              ) : (
                                <span>{matching?.bill?.vendor?.name}</span>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap pr-4">
                          {getCurrencySymbolFromIso(
                            matching?.bill?.currency,
                            currencies,
                          )}
                          {parseFloat(
                            matching?.billInstance?.total ?? 0,
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="whitespace-nowrap pr-4">
                          {getCurrencySymbolFromIso(
                            matching?.bill?.currency,
                            currencies,
                          )}
                          {parseFloat(
                            matching?.billInstance?.paidAmount ?? 0,
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className="whitespace-nowrap pr-4">
                          {getCurrencySymbolFromIso(
                            matching?.bill?.currency,
                            currencies,
                          )}
                          {parseFloat(
                            matching?.billInstance?.total -
                              matching?.billInstance?.paidAmount ?? 0,
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  className="w-full flex items-start mt-0"
                  key={matching?.id}
                >
                  <div className="w-full flex-col flex gap-4 items-start">
                    <div className="w-full flex flex-col border-b">
                      <div className="flex pl-3 text-slate-600 text-[16px] font-medium">
                        Possible Matches
                      </div>
                      <div className="w-full mt-2 rounded-[5px] shadow flex items-center px-6 self-stretch gap-11 h-[3.5rem]">
                        <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[10%]">
                          Date
                        </div>
                        <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[35%]">
                          Description
                        </div>
                        <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[20%]">
                          Account
                        </div>
                        <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[15%]">
                          Amount
                        </div>
                        <div className="text-slate-600 text-[14px] leading-[1.125rem] font-medium w-[10%]">
                          Status
                        </div>
                      </div>
                      {matching?.billTransactions?.map((transaction) => {
                        const anySelected =
                          selectedTransactions[
                            `${matching?.bill?.id}_${matching?.billInstanceId}`
                          ]?.length > 0;
                        const isSelected =
                          selectedTransactions[
                            `${matching?.bill?.id}_${matching?.billInstanceId}`
                          ]?.includes(transaction.id) || false;
                        return (
                          <div
                            key={transaction?.id}
                            className="w-full rounded-[5px] flex items-center pl-6 self-stretch gap-11 py-4 px-6 relative"
                          >
                            <div
                              className={`text-[14px] leading-[1.125rem] w-[10%] flex gap-4 items-center whitespace-nowrap ${
                                !isSelected && anySelected
                                  ? 'text-slate-300'
                                  : 'text-slate-600 '
                              }`}
                            >
                              {checkPermission(
                                PermissionKeys.MANAGE_TRANSACTIONS,
                                accessType,
                                permissions,
                              ) && (
                                <div className="flex items-center">
                                  <label className="inline-flex">
                                    <span className="sr-only">Select</span>
                                    <input
                                      id={transaction.id}
                                      className="form-checkbox cursor-pointer h-5 w-5"
                                      type="checkbox"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      onChange={(e) =>
                                        handleTransactionCheckbox(
                                          e,
                                          matching?.bill?.id,
                                          matching?.billInstanceId,
                                        )
                                      }
                                      checked={
                                        selectedTransactions[
                                          `${matching?.bill?.id}_${matching?.billInstanceId}`
                                        ]?.includes(transaction.id) || false
                                      }
                                    />
                                  </label>
                                </div>
                              )}
                              <p>
                                {moment(transaction?.authorizedDate).format(
                                  'MMM DD, YYYY',
                                )}
                              </p>
                            </div>
                            <div
                              className={`${
                                !isSelected && anySelected
                                  ? 'text-slate-300'
                                  : 'text-slate-600 '
                              } text-[14px] leading-[1.125rem] w-[35%]`}
                            >
                              <div className="flex items-center">
                                <div className="w-[1.687rem] h-[1.687rem] flex-shrink-0 mr-2 sm:mr-3 ">
                                  {transaction?.vendor?.logoUrl ||
                                  transaction?.plaidCategoryIconUrl ? (
                                    <img
                                      className="rounded-full"
                                      src={
                                        transaction?.vendor?.logoUrl ||
                                        transaction?.plaidCategoryIconUrl
                                      }
                                      alt={transaction?.name}
                                    />
                                  ) : (
                                    <div
                                      className={`w-[1.687rem] h-[1.687rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600 ${
                                        !isSelected && anySelected
                                          ? '!bg-opacity-50'
                                          : ' '
                                      }`}
                                      style={{
                                        backgroundColor: transaction
                                          ?.categoryAccount?.group?.color
                                          ? transaction?.categoryAccount?.group
                                              ?.color
                                          : '',
                                      }}
                                    >
                                      <div
                                        className={`${
                                          transaction?.categoryAccount?.group
                                            ?.color
                                            ? getContrast(
                                                transaction?.categoryAccount
                                                  ?.group?.color,
                                                'text-black',
                                                'text-white',
                                              )
                                            : 'text-black'
                                        } text-xs font-normal uppercase ${
                                          !isSelected && anySelected
                                            ? 'text-opacity-30'
                                            : ' '
                                        }`}
                                      >
                                        {transaction?.description
                                          ? transaction.description
                                              .replace(/[^\w\s]/gi, '')
                                              .split(' ')
                                              .filter(Boolean)
                                              .slice(0, 2)
                                              .map((word) => word.charAt(0))
                                              .join('')
                                          : ''}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="font-normal leading-6 mr-2">
                                  <span
                                    className="text-indigo-500 cursor-pointer"
                                    onClick={() => {
                                      setTransaction(transaction);
                                      setTransactionFlyoutOpen(true);
                                    }}
                                  >
                                    {transaction?.description}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`${
                                !isSelected && anySelected
                                  ? 'text-slate-300'
                                  : 'text-slate-600 '
                              } text-[14px] leading-[1.125rem] w-[20%]`}
                            >
                              {`${transaction?.account?.name} ${
                                transaction?.account?.mask
                                  ? `(...${transaction?.account?.mask})`
                                  : ``
                              }`}
                            </div>
                            <div className="text-slate-600 text-[14px] leading-[1.125rem] w-[15%]">
                              <div
                                className={`font-semibold leading-6 text-sm  ${amountColor(
                                  transaction?.amount?.toString(),
                                )} ${
                                  !isSelected && anySelected
                                    ? '!text-slate-300'
                                    : ' '
                                }`}
                              >
                                {transaction?.amount?.toString().charAt(0) ===
                                '-'
                                  ? ''
                                  : '-'}
                                {findCurrencySymbol(transaction?.currency)}
                                {transaction?.amount?.toString().charAt(0) ===
                                '-'
                                  ? parseFloat(
                                      transaction?.amount
                                        ?.toString()
                                        .replace('-', ''),
                                    )?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : parseFloat(
                                      transaction?.amount,
                                    )?.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                {transaction?.currency !==
                                  teamDefaultCurrency &&
                                  ` (${transaction?.currency})`}
                              </div>
                            </div>
                            <div
                              className={`text-[14px] leading-[1.125rem] w-[10%] flex gap-4 items-center whitespace-nowrap ${
                                !isSelected && anySelected
                                  ? 'text-slate-300'
                                  : 'text-slate-600 '
                              }`}
                            >
                              <p>
                                {transaction?.reconciled
                                  ? 'Reconciled'
                                  : transaction?.pending
                                    ? 'Pending'
                                    : transaction?.excluded
                                      ? 'Excluded'
                                      : transaction?.reviewed
                                        ? 'Reviewed'
                                        : 'Unreviewed'}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="w-full flex justify-end items-center pb-4 gap-4 border-b-2 border-[#D0D5DD]">
                      <Tooltip
                        contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                        content="You do not have permission"
                        tooltipShow={
                          !checkPermission(
                            PermissionKeys.MANAGE_TRANSACTIONS,
                            accessType,
                            permissions,
                          )
                        }
                        position="top-left"
                      >
                        <button
                          type="button"
                          onClick={() => handleKeepAll(matching?.id, matching)}
                          className="whitespace-nowrap h-11 w-[7.813rem] py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-white text-slate-600 disabled:cursor-not-allowed"
                          disabled={
                            keepSelectedLoading[matching?.bill?.id] ||
                            keepAllloading[matching?.bill?.id] ||
                            !checkPermission(
                              PermissionKeys.MANAGE_TRANSACTIONS,
                              accessType,
                              permissions,
                            )
                          }
                        >
                          {keepAllloading[matching?.bill?.id] ? (
                            <Loader width="w-4" height="w-4" />
                          ) : (
                            'Skip'
                          )}
                        </button>
                      </Tooltip>
                      <Tooltip
                        contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative"
                        tooltipShow={
                          selectedTransactions[
                            `${matching?.bill?.id}_${matching?.billInstanceId}`
                          ]?.length === 0 ||
                          !selectedTransactions[
                            `${matching?.bill?.id}_${matching?.billInstanceId}`
                          ]?.length ||
                          !checkPermission(
                            PermissionKeys.MANAGE_TRANSACTIONS,
                            accessType,
                            permissions,
                          )
                        }
                        content={
                          !checkPermission(
                            PermissionKeys.MANAGE_TRANSACTIONS,
                            accessType,
                            permissions,
                          )
                            ? 'You do not have permission'
                            : 'Please select at least one transaction to confirm match.'
                        }
                        position="top-left"
                      >
                        <button
                          type="button"
                          onClick={() =>
                            handleKeepSelected(
                              matching?.bill?.id,
                              matching?.billInstanceId,
                            )
                          }
                          className={`whitespace-nowrap h-11 w-[7.813rem] py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white disabled:cursor-not-allowed ${
                            selectedTransactions[
                              `${matching?.bill?.id}_${matching?.billInstanceId}`
                            ]?.length === 0 ||
                            !selectedTransactions[
                              `${matching?.bill?.id}_${matching?.billInstanceId}`
                            ]?.length
                              ? 'bg-indigo-400'
                              : ''
                          }`}
                          disabled={
                            keepSelectedLoading[matching?.bill?.id] ||
                            keepAllloading[matching?.bill?.id] ||
                            selectedTransactions[
                              `${matching?.bill?.id}_${matching?.billInstanceId}`
                            ]?.length === 0 ||
                            !selectedTransactions[
                              `${matching?.bill?.id}_${matching?.billInstanceId}`
                            ]?.length ||
                            !checkPermission(
                              PermissionKeys.MANAGE_TRANSACTIONS,
                              accessType,
                              permissions,
                            )
                          }
                        >
                          {keepSelectedLoading[matching?.bill?.id] ? (
                            <Loader width="w-4" height="w-4" color="#FFFFFF" />
                          ) : (
                            'Confirm Match'
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </>
            ))}
            <div className="w-full flex justify-center mt-4">
              <Tooltip
                tooltipShow={
                  isMatchAllDisable() ||
                  !checkPermission(
                    PermissionKeys.MANAGE_TRANSACTIONS,
                    accessType,
                    permissions,
                  )
                }
                content={
                  !checkPermission(
                    PermissionKeys.MANAGE_TRANSACTIONS,
                    accessType,
                    permissions,
                  )
                    ? 'You do not have permission'
                    : 'Please select at least one transaction to confirm match.'
                }
                contentClassName="border-none rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                size="sm"
              >
                <button
                  type="button"
                  onClick={() => handleKeepAllSelected()}
                  disabled={isMatchAllDisable() || keepAllSelectedLoading}
                  className="whitespace-nowrap w-[11.25rem] h-11 py-2.5 px-[1.87rem] flex justify-center items-center rounded-[5px] border leading-6 border-indigo-500 bg-indigo-500 text-white disabled:cursor-not-allowed"
                >
                  {keepAllSelectedLoading ? (
                    <Loader width="w-4" height="w-4" color="#FFFFFF" />
                  ) : (
                    'Match All'
                  )}
                </button>
              </Tooltip>
            </div>
          </>
        )
      )}
      {matchings?.matchings?.length === 0 && !isLoading && (
        <div className="flex flex-col gap-5 items-center justify-center w-full h-full">
          <h6 className="text-center mt-4">No Matching Transactions Found</h6>
          <button
            className="h-11 bg-indigo-500 rounded-[5px] text-white whitespace-nowrap w-[30%] max-w-[200px]"
            onClick={() => {
              navigate('/transactions');
            }}
            type="button"
          >
            Back to Transactions
          </button>
        </div>
      )}
      {!isLoading && totalRecords > 0 && (
        <div className="mt-10">
          <PaginationClassic
            pagination={{ limit: localLimit, page, totalRecords }}
            setLimit={setLocalLimit}
            onPageChange={loadTransactionsApi}
          />
        </div>
      )}
    </>
  );
};

export default BillMatchingTransactions;
