import React, { useState, useRef, useEffect, useMemo } from 'react';
import Transition from '../../utils/Transition';
import useClickOutside from '../../utils/useClickOutside';
import { areArraysEqual, extractValuesByKey } from '../../utils/Utils';

const ReusableAccount = ({
  category,
  selectedCategoryId,
  localSelected,
  group,
  onChangeValues,
}) => (
  <div key={category?.id} className="ml-2 cursor-pointer">
    <label className="whitespace-nowrap  text-[13px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 flex items-center py-1 px-3 cursor-pointer ml-2 font-normal leading-6">
      <input
        type="checkbox"
        checked={
          selectedCategoryId?.length > 0 &&
          (localSelected?.[group?.id]?.filter((acc) => !acc?.parentAccountId)
            ?.length === group?.categories?.length ||
            localSelected?.[group?.id]?.filter((ct) => ct?.id === category?.id)
              ?.length > 0 ||
            selectedCategoryId.includes(category.id))
        }
        onChange={(e) => {
          e.stopPropagation();
          // eslint-disable-next-line no-prototype-builtins
          if (localSelected?.hasOwnProperty(group?.id)) {
            if (
              localSelected?.[group?.id]?.filter(
                (ct) => ct?.id === category?.id,
              )?.length > 0
            ) {
              onChangeValues({
                ...localSelected,
                [group?.id]: localSelected?.[group?.id]?.filter(
                  (ct) => ct?.id !== category?.id,
                ),
              });
            } else {
              onChangeValues({
                ...localSelected,
                [group?.id]: [...localSelected?.[group?.id], category],
              });
            }
          } else {
            onChangeValues({
              ...localSelected,
              [group.id]: [category],
            });
          }
        }}
        className="form-checkbox mr-2"
      />
      {category?.name} {category?.mask && `(...${category?.mask})`}
    </label>
  </div>
);

const AccountsDropdown = ({
  align,
  allCategories,
  selectedCategoryId,
  setSelectedCategoryId,
  isSetCategoryStyle = false,
  apiFilter,
  type = null,
  dropdownOpen: propDropdownOpen,
  setDropdownOpen: propSetDropdownOpen,
  localSelected,
  setLocalSelected,
  allAccount = false,
  report = false,
  ids = [],
  isTableDropdown = false,
  bottomOfTable = false,
  setNoClick = () => {},
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([...allCategories]);
  const [isResultFound, setIsResultFound] = useState(true);
  const [collapsedIds, setCollapsedIds] = useState([]);
  const [fixedDropdownPositions, setFixedDropdownPositions] = useState({});

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const searchInput = useRef(null);

  const filterCategories = useMemo(() => {
    if (allCategories?.length > 0) {
      const categories = [...allCategories];
      return categories;
    }
    return [];
  }, [allCategories]);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
      setSearchValue('');
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
      setSearchValue('');
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useClickOutside(dropdown, (event) => {
    if (!trigger.current.contains(event.target))
      if (dropdownOpen) {
        setDropdownOpen(false);
        setSearchValue('');
      }
  });

  const onChangeValues = (val = {}) => {
    setLocalSelected(val);
    setSelectedCategoryId(extractValuesByKey(val, 'id'));
  };

  useEffect(() => {
    if (dropdownOpen) {
      setNoClick(true);
      setSearchValue('');
      if (filterCategories?.length > 0) {
        setFilteredData([...filterCategories]);
      } else {
        setFilteredData([]);
      }
      if (filterCategories?.length) {
        setIsResultFound(true);
      }
      searchInput.current?.focus();
    } else {
      setNoClick(false);
    }
  }, [dropdownOpen]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      if (filterCategories?.length > 0) {
        setFilteredData([...filterCategories]);
      } else {
        setFilteredData([]);
      }
      if (filterCategories?.length) {
        setIsResultFound(true);
      }
      return;
    }
    setIsResultFound(false);
    const formattedValue = e.target.value.replace(/\s/g, '').toLowerCase();
    const result = filterCategories.map((group) => {
      const filteredCategories = group.categories.filter((category) => {
        const categoryNameMatch = category?.name
          ?.replace(/\s/g, '')
          .toLowerCase()
          .includes(formattedValue);

        // Check if any subAccount name matches
        const subAccountNameMatch = category?.subAccounts?.some((subAccount) =>
          subAccount?.name
            ?.replace(/\s/g, '')
            .toLowerCase()
            .includes(formattedValue),
        );
        // Return true if either matches
        return categoryNameMatch || subAccountNameMatch;
      });
      if (filteredCategories?.length > 0) {
        setIsResultFound(true);
      }
      return { ...group, categories: filteredCategories };
    });

    setFilteredData(result);
  };

  const findCategoryById = (id) => {
    let res = null;
    filterCategories?.forEach((group) => {
      group?.categories?.forEach?.((val) => {
        // Check if the account IDs match
        if (val?.id === id) {
          res = val;
          return true;
        }
        // Check within subAccounts if they exist
        const subAccount = val?.subAccounts?.find(
          (subAccount) => subAccount?.id === id,
        );
        if (subAccount?.id) {
          res = subAccount;
        }
      });
    });
    return res;
  };

  // Prop Dropdown Controls
  useEffect(() => {
    if (propDropdownOpen !== undefined) {
      setDropdownOpen(propDropdownOpen);
    }
  }, [propDropdownOpen]);

  useEffect(() => {
    if (propSetDropdownOpen) {
      propSetDropdownOpen(dropdownOpen);
    }
  }, [dropdownOpen, propSetDropdownOpen]);

  useEffect(() => {
    if (filterCategories?.length > 0) {
      setFilteredData([...filterCategories]);
    } else {
      setFilteredData([]);
    }
    if (filterCategories?.length === 0) {
      setIsResultFound(false);
    } else {
      setIsResultFound(true);
    }
  }, [filterCategories]);

  useEffect(() => {
    if (selectedCategoryId?.length > 0 && filterCategories?.length > 0) {
      const categoryMap = {};
      selectedCategoryId.forEach((id) => {
        const category = findCategoryById(id);
        const group = category?.categoryGroupId
          ? category?.categoryGroupId
          : filterCategories?.find((fc) => fc?.name === category?.type)?.id ||
            null;

        if (group) {
          if (!categoryMap[group]) {
            categoryMap[group] = [];
          }
          categoryMap[group].push(category);
        }
      });
      setLocalSelected(categoryMap);
    }
  }, [filterCategories]);

  const getFixedPositionValues = () => {
    const { x, y, height } = trigger.current?.getBoundingClientRect();
    const dropdownDimensions = dropdown.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const values = {
      left:
        x + dropdownDimensions?.width > windowWidth
          ? windowWidth - dropdownDimensions?.width - 20
          : x,
      top:
        y + height + dropdownDimensions?.height > windowHeight
          ? windowHeight - dropdownDimensions?.height - 20
          : y + height,
    };
    setFixedDropdownPositions(values);
  };

  useEffect(() => {
    if (bottomOfTable) {
      if (dropdownOpen) {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            // ele.style.overflow = 'hidden';
            ele.style.pointerEvents = 'none';
          }
        });
      } else {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            // ele.style.overflow = 'auto';
            ele.style.pointerEvents = 'auto';
          }
        });
      }
      getFixedPositionValues();
      return () => {
        ids?.forEach((id) => {
          const ele = document.getElementById(id);
          if (ele) {
            // ele.style.overflow = 'auto';
            ele.style.pointerEvents = 'auto';
          }
        });
      };
    }
  }, [dropdownOpen]);

  useEffect(() => {
    if (isTableDropdown) {
      const mouseMoveHandler = ({ clientX, clientY }) => {
        if (!dropdown.current) {
          return;
        }
        const { left, top, width, height } =
          dropdown?.current?.getBoundingClientRect();
        const offsetX = Math.max(clientX - (left + width), left - clientX);
        const offsetY = Math.max(clientY - (top + height), top - clientY);
        const distance = Math.sqrt(offsetX ** 2 + offsetY ** 2);
        if (dropdownOpen && distance > 300) {
          setDropdownOpen(false);
        }
      };
      document.addEventListener('mousemove', mouseMoveHandler);
      return () => document.removeEventListener('mousemove', mouseMoveHandler);
    }
  }, [dropdownOpen]);

  const getGroupCategoriesCount = (group) => {
    let count = group?.categories?.length || 0;
    group?.categories?.forEach((cat) => {
      if (cat?.subAccounts?.length > 0) {
        count += cat?.subAccounts?.length;
      }
    });
    return count;
  };

  return (
    <div
      className={`relative inline-flex max-w-full w-full min-w-[200px] ${
        isSetCategoryStyle ? 'w-full' : ''
      }`}
      onClick={(e) => e.stopPropagation()}
      role="button"
      tabIndex="0"
    >
      <button
        type="button"
        ref={trigger}
        className={`inline-flex items-center justify-center w-full text-sm font-medium leading-6 rounded-md px-3 h-12 border border-[#D0D5DD]  hover:border-slate-400 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out
        ${report ? '!text-base' : ''}
        `}
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen(!dropdownOpen);
        }}
        aria-expanded={dropdownOpen}
      >
        <div
          className={`flex items-center justify-between truncate w-full ${
            isSetCategoryStyle ? 'w-full justify-between' : ''
          }`}
        >
          <span
            className={`text-[#667085CC] ${
              report && selectedCategoryId?.length === 0 ? 'text-slate-600' : ''
            } font-normal truncate  group-hover:text-indigo-600 dark:group-hover:text-indigo-400`}
          >
            {Boolean(allAccount) === false
              ? selectedCategoryId?.length > 0
                ? `${findCategoryById(selectedCategoryId?.[0])?.name} ${
                    selectedCategoryId?.length > 1
                      ? `+${selectedCategoryId?.length - 1}`
                      : ''
                  }`
                : type === 'account'
                  ? 'Choose Account'
                  : 'Choose Category'
              : selectedCategoryId?.length > 0
                ? `${findCategoryById(selectedCategoryId?.[0])?.name} ${
                    selectedCategoryId?.length > 1
                      ? `+${selectedCategoryId?.length - 1}`
                      : ''
                  }`
                : type === 'account'
                  ? 'All Accounts'
                  : 'Choose Category'}
          </span>

          <svg
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`shrink-0 ml-1 stroke-[#667085CC] ${
              selectedCategoryId?.length > 0 &&
              areArraysEqual(selectedCategoryId, apiFilter)
                ? 'text-indigo-500'
                : selectedCategoryId?.length > 0
                  ? ' text-indigo-300'
                  : ''
            } ${dropdownOpen ? 'rotate-180' : ''} `}
          >
            <path
              d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
              stroke="#667085"
              strokeOpacity="0.8"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="stroke-current"
            />
          </svg>
        </div>
      </button>

      <Transition
        className={`${
          bottomOfTable
            ? 'fixed !w-fit min-w-44'
            : 'origin-top-right z-10 absolute'
        } top-full mt-1 min-w-44 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 pt-1.5 rounded shadow-lg overflow-hidden ${
          align === 'right' ? 'right-0' : 'left-0'
        } ${isSetCategoryStyle ? ' w-full' : ''}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        style={
          bottomOfTable
            ? {
                top: fixedDropdownPositions?.top,
                left: fixedDropdownPositions?.left,
                zIndex: '1000',
              }
            : {}
        }
      >
        {dropdownOpen && (
          <div
            ref={dropdown}
            // onFocus={() => setDropdownOpen(true)}
            // onBlur={() => setDropdownOpen(false)}
            className="pointer-events-auto"
          >
            <div className="relative flex-1 px-3 mb-2">
              <input
                type="text"
                placeholder="Search..."
                className={` form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block ${
                  isSetCategoryStyle ? ' flex-1 height-fit' : ''
                }`}
                value={searchValue}
                onChange={handleSearch}
                autoFocus
                ref={searchInput}
                onClick={(e) => e.stopPropagation()}
              />
              <svg
                className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                  stroke="#E48642"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              className={
                isSetCategoryStyle
                  ? 'max-h-40 overflow-auto w-full flex flex-col scrollbar'
                  : 'max-h-60 overflow-auto scrollbar'
              }
            >
              {filteredData?.map(
                (group) =>
                  group?.categories?.length > 0 && (
                    <div
                      key={group.id}
                      className="cursor-default	mb-4"
                      onClick={(e) => e.stopPropagation()}
                      role="button"
                      tabIndex="0"
                    >
                      <label className="whitespace-nowrap font-semibold text-[10px] text-[#515A6C] leading-5 uppercase flex items-center pt-1 px-3 mb-1.5 filter-label">
                        <input
                          type="checkbox"
                          checked={
                            selectedCategoryId?.length > 0 &&
                            localSelected?.[group?.id]?.length ===
                              getGroupCategoriesCount(group)
                          }
                          className="form-checkbox mr-2"
                          onChange={() => {
                            if (
                              localSelected?.[group?.id]?.length ===
                              getGroupCategoriesCount(group)
                            ) {
                              onChangeValues({
                                ...localSelected,
                                [group.id]: [],
                              });
                            } else {
                              let subAccounts = [];
                              group?.categories?.forEach((c) => {
                                if (c?.subAccounts?.length > 0) {
                                  subAccounts = [
                                    ...subAccounts,
                                    ...c?.subAccounts,
                                  ];
                                }
                              });
                              onChangeValues({
                                ...localSelected,
                                [group.id]: [
                                  ...group?.categories,
                                  ...subAccounts,
                                ],
                              });
                            }
                          }}
                        />
                        {group?.name}
                        <svg
                          width="10"
                          height="5"
                          viewBox="0 0 10 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className={`w-4 shrink-0 text-current text-slate-400 ml-2 cursor-pointer ${
                            !collapsedIds?.filter((id) => id === group?.id)
                              ?.length > 0
                              ? 'rotate-180'
                              : ''
                          } `}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (
                              collapsedIds?.filter((id) => id === group?.id)
                                ?.length > 0
                            ) {
                              setCollapsedIds(
                                collapsedIds?.filter((id) => id !== group?.id),
                              );
                            } else {
                              setCollapsedIds([...collapsedIds, group?.id]);
                            }
                          }}
                        >
                          <path
                            d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
                            stroke="#667085"
                            strokeOpacity="0.8"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="stroke-current"
                          />
                        </svg>
                      </label>
                      {!collapsedIds?.filter((id) => id === group?.id)?.length >
                        0 &&
                        group?.categories?.map((category) => (
                          <>
                            <ReusableAccount
                              key={category?.id}
                              category={category}
                              selectedCategoryId={selectedCategoryId}
                              localSelected={localSelected}
                              group={group}
                              onChangeValues={onChangeValues}
                            />
                            <div className="ml-4">
                              {category?.subAccounts?.map((subCategory) => (
                                <ReusableAccount
                                  key={subCategory?.id}
                                  category={subCategory}
                                  selectedCategoryId={selectedCategoryId}
                                  localSelected={localSelected}
                                  group={group}
                                  onChangeValues={onChangeValues}
                                />
                              ))}
                            </div>
                          </>
                        ))}
                    </div>
                  ),
              )}
              {!isResultFound && (
                <span className="whitespace-nowrap text-center font-bold pr-4 text-sm text-slate-600 dark:text-slate-300 py-1 px-4">
                  No Option
                </span>
              )}
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default AccountsDropdown;
