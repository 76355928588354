import React, { useEffect, useState } from 'react';
import ModalBasic from '../../../components/ModalBasic';
import { getAllAccountantFirms } from '../../../API/backend_helper';
import FirmsDropdown from './FirmsDropdown';

let searchTimeout = null;

const FirmMembersModal = ({ isOpen, setIsOpen, inviteFirmToWorkspace }) => {
  const [noClick, setNoClick] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dataLoading, setDataLoading] = useState(false);
  const [bookkeepers, setBookkeepers] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState(null);
  const [searchFirmLoading, setSearchFirmLoading] = useState(false);
  const [dropdownFirms, setDropdownFirms] = useState([]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await inviteFirmToWorkspace(selectedFirm);
      setLoading(false);
      setIsOpen(false);
    } catch (err) {
      console.log('err', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
      setSelectedFirm(null);
    }
  }, [isOpen]);

  const loadAllFirms = async ({
    page = 1,
    limit = 10000,
    search = '',
    loading = false,
  } = {}) => {
    try {
      if (loading) {
        setDataLoading(true);
      }
      const res = await getAllAccountantFirms({
        page,
        limit,
        all: true,
        search,
      });
      if (search) {
        return res?.data?.result?.accountingFirms;
      }
      setBookkeepers(res?.data?.result?.accountingFirms);
      setDropdownFirms(res?.data?.result?.accountingFirms);
    } catch (err) {
      console.log('err', err);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    loadAllFirms({ loading: true });
  }, []);

  const handleFirmSearch = (value) => {
    clearTimeout(searchTimeout);
    if (value) {
      searchTimeout = setTimeout(async () => {
        setSearchFirmLoading(true);
        const data = await loadAllFirms({
          search: encodeURIComponent(value || ''),
        });
        setSearchFirmLoading(false);
        const filteredFirms = [...data];
        const isInSearchData = data?.filter((c) => c?.id === selectedFirm);
        const tempFirm = dropdownFirms?.find((c) => c?.id === selectedFirm);
        if (isInSearchData?.length === 0 && tempFirm) {
          setDropdownFirms([tempFirm, ...filteredFirms]);
        } else {
          setDropdownFirms(filteredFirms);
        }
      }, 500);
    } else {
      let tempFirm = null;
      tempFirm = dropdownFirms?.find((c) => c?.id === selectedFirm);
      if (selectedFirm && tempFirm) {
        setDropdownFirms([...bookkeepers]);
      } else {
        setDropdownFirms([...bookkeepers]);
      }
    }
  };

  const resetSearch = (selectedOption) => {
    // setDropdownFirms([selectedOption]);
    const isAlreadyInCustomer =
      bookkeepers?.find((cus) => cus?.id === selectedOption?.id)?.id ===
      selectedOption?.id;
    if (isAlreadyInCustomer) {
      setDropdownFirms([...bookkeepers]);
    } else {
      setDropdownFirms([selectedOption, ...bookkeepers]);
      setBookkeepers([selectedOption, ...bookkeepers]);
    }
  };

  return (
    <ModalBasic
      id="basic-modal"
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Invite Accountant/Bookkeeper"
      width="!w-[600px]"
      noClick={noClick}
    >
      {dataLoading ? (
        <div className="flex min-h-[200px] flex-col gap-2 items-center pb-2 justify-center">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin w-10 h-10 fill-current shrink-0"
          >
            <path
              d="M14.5 2.41699V7.25033"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 21.749V26.5824"
              stroke="#E48642"
              strokeWidth="2.55882"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 5.95703L9.37662 9.37662"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 19.6211L23.0426 23.0407"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.41699 14.5H7.25033"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.749 14.5H26.5824"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.95703 23.0407L9.37662 19.6211"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.623 9.37662L23.0426 5.95703"
              stroke="#E48642"
              strokeWidth="1.58955"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-slate-600 font-medium text-[0.875rem] text-center">
            Fetching data, please wait...
          </p>
        </div>
      ) : (
        <>
          <div className="mb-16 pb-16 px-8 py-5">
            <FirmsDropdown
              options={dropdownFirms}
              name="name"
              height="h-12"
              placeholder="Select Firm"
              selected={selectedFirm}
              setSelected={setSelectedFirm}
              scrollIntoView={false}
              setNoClick={setNoClick}
              bottomOfTable
              search
              handleSearch={handleFirmSearch}
              resetSearch={resetSearch}
              searchPlaceholder="Search by name or email"
              searchLoading={searchFirmLoading}
            />
          </div>
          {/* Modal footer */}
          <div className="flex flex-wrap  justify-end space-x-2 px-5 py-4 mt-3">
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white
            disabled:!bg-indigo-100  disabled:text-slate-400  disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Send Invite
            </button>
          </div>
        </>
      )}
    </ModalBasic>
  );
};

export default FirmMembersModal;
