import DropdownFull from 'components/DropdownFull';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Svg';
import { formatDateLocally, TEAM_DATE_FORMAT, TEAM_ID } from 'utils/Utils';
import DatePickerDash from 'components/DatePickerDash';
import useClickOutside from 'utils/useClickOutside';
import { getAllProjects } from 'API/backend_helper';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import AssigneeDropdown from '../AssigneeDropdown';
import TasksDropdown from '../TasksDropdown';
import ActionsDropdown from './ActionsDropdown';

const types = [
  { name: 'To-Do', id: 'toDo' },
  { name: 'Review Transaction', id: 'reviewTransaction' },
  { name: 'Set Transaction Vendor', id: 'transactionVendor' },
  { name: 'Set Transaction Category', id: 'transactionCategory' },
  { name: 'Pay Bill', id: 'payBill' },
  { name: 'Upload Transaction Attachment', id: 'transactionAttachment' },
];

const RightBar = ({
  task,
  findTaskType,
  handleUpdateTask,
  assigneeLoading,
  statusOptions,
  handleActionDropdown,
  setNoClick,
  noClick,
  projects = [],
  hideTaskType,
  showAccountantAssignee,
  showClient,
  showAccountantProjects,
  clients = [],
  setFlyoutOpen,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const taskDeadlineContainerRef = useRef();
  const taskProjectIdContainerRef = useRef();
  const { team } = useSelector((state) => state.Team);
  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [isEditingProjectId, setIsEditingProjectId] = useState(false);
  const [isEditDeadline, setIsEditDeadline] = useState(false);
  const [taskDeadline, setTaskDeadline] = useState(
    task?.deadlineDate
      ? formatDateLocally(task?.deadlineDate, teamDateFormat)
      : null,
  );
  const [clientProjects, setClientProjects] = useState([]);
  const [clientProjectsLoading, setClientProjectsLoading] = useState(false);

  useEffect(() => {
    if (task?.deadlineDate !== taskDeadline) {
      setTaskDeadline(
        task?.deadlineDate
          ? formatDateLocally(task?.deadlineDate, teamDateFormat)
          : null,
      );
    }
  }, [task]);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: false,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      position: 'bottom',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
      onOpen() {
        setNoClick(true);
      },
      onClose() {
        setNoClick(false);
      },
    }),
    [team],
  );

  const handleDeadlineUpdate = (date) => {
    setTaskDeadline(date);
    if (date !== taskDeadline) {
      handleUpdateTask(task?.id, { ...task, deadlineDate: date });
    }
    setIsEditDeadline(false);
  };

  useClickOutside(taskDeadlineContainerRef, () => {
    if (!noClick && isEditDeadline) {
      handleDeadlineUpdate(taskDeadline);
    }
  });

  useClickOutside(taskProjectIdContainerRef, () => {
    if (!task?.projectId) {
      setIsEditingProjectId(false);
    }
  });

  const loadClientProjects = async (teamId) => {
    setClientProjectsLoading(true);
    localStorage.setItem(TEAM_ID, teamId);
    try {
      const res = await getAllProjects({});
      const newProjects = res?.map((project) => ({
        ...project,
        name: `${project?.name} ${project?.customId ? `(${project.customId})` : ''}`,
      }));
      setClientProjects(newProjects);
    } catch (err) {
      console.log('err', err);
    } finally {
      setClientProjectsLoading(false);
      localStorage.setItem(TEAM_ID, '');
    }
  };

  useEffect(() => {
    if (task?.teamId && showAccountantProjects) {
      loadClientProjects(task?.teamId);
    }
  }, [task?.teamId]);

  return (
    <div className=" flex flex-col px-4 py-6 space-y-4">
      <div className="relative pt-6">
        <button
          className="hidden sm:inline-flex items-center gap-1 ml-auto absolute -top-1 right-1"
          type="button"
          onClick={() => setFlyoutOpen(false)}
        >
          <svg
            viewBox="0 0 28 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hover:fill-slate-500 fill-slate-400 h-[28px]"
          >
            <rect
              x="6.17676"
              y="8.6582"
              width="2.3292"
              height="19.7982"
              rx="1.1646"
              transform="rotate(-45 6.17676 8.6582)"
              fill="#667085"
            />
            <rect
              x="20.1768"
              y="7.01172"
              width="2.3292"
              height="19.7982"
              rx="1.1646"
              transform="rotate(45 20.1768 7.01172)"
              fill="#667085"
            />
          </svg>
        </button>
        <div className="absolute right-9 -top-[2px]">
          <ActionsDropdown handleOption={handleActionDropdown} />
        </div>
        <h4 className="text-sm font-medium text-black mb-1">Task ID</h4>
        <p className="text-base text-slate-500">
          {' '}
          {task?.customId || `TODO-${task?.id}`}
        </p>
      </div>
      {!hideTaskType && (
        <div>
          <h4 className="text-sm font-medium text-black mb-1">Task Type</h4>
          <DropdownFull
            options={types}
            placeholder="Select Task Type"
            name="name"
            selected={task?.type}
            setSelected={(value) =>
              handleUpdateTask(task?.id, { ...task, type: value })
            }
            scrollIntoView={false}
            height="h-10"
            disabled={
              !checkPermission(
                PermissionKeys.MANAGE_TASKS,
                accessType,
                permissions,
              )
            }
          />
        </div>
      )}
      <div>
        <h4 className="text-sm font-medium text-black mb-1">Created by</h4>
        <div className="flex gap-1 items-center mt-1">
          {task?.createdBy?.avatarUrl ? (
            <img
              src={task?.createdBy?.avatarUrl}
              className="h-6 w-6 rounded-[42px]"
              alt="avatar"
            />
          ) : (
            <div className="min-h-8 min-w-8 rounded-[42px] bg-slate-400 text-white text-sm flex items-center justify-center">
              {task?.createdBy?.firstName[0] + task?.createdBy?.lastName[0]}
            </div>
          )}
          <p className="text-slate-600 leading-6 text-base">
            {`${task?.createdBy?.firstName} ${task?.createdBy?.lastName}`}
          </p>
        </div>
      </div>
      {showClient && (
        <div>
          <h4 className="text-sm font-medium text-black mb-1">Client</h4>
          <p className="w-full">
            {clients?.find?.((c) => c?.id === task?.teamId)?.name}{' '}
            {/* <DropdownFull
              noMinW
              selected={task?.teamId}
              options={clients}
              name="name"
              height="!h-10 min-w-[120px] w-full"
              transitionClass="min-w-fit"
              setSelected={(value) =>
                handleUpdateTask(
                  task?.id,
                  {
                    ...task,
                    teamId: value,
                    assigneeId:
                      value !== task?.teamId ? null : task?.assigneeId,
                    assignee: null,
                  },
                  'teamId',
                )
              }
              scrollIntoView={false}
            /> */}
          </p>
        </div>
      )}
      <div>
        <h4 className="text-sm font-medium text-black mb-1">Assigned To</h4>
        <div className="">
          {task?.assigneeId || showAccountantAssignee ? (
            assigneeLoading[task?.id] ? (
              <div className="w-full flex h-12 items-center">
                <Loader width="w-6" height="h-6" />
              </div>
            ) : (
              <AssigneeDropdown
                options={
                  showAccountantAssignee
                    ? [{ ...task?.assignee }]
                    : team?.accountingFirms?.length > 0
                      ? [
                          {
                            name: 'Workspace Users',
                            users: team?.users,
                          },
                          ...team?.accountingFirms?.map((a) => ({
                            name: a?.name,
                            users: a?.users,
                            accountingFirmId: a?.id,
                          })),
                        ]
                      : team?.users
                }
                accountingFirmView={
                  !showAccountantAssignee && team?.accountingFirms?.length > 0
                }
                selected={task?.assigneeId}
                setSelected={(value, user, accountingFirmId = null) =>
                  showAccountantAssignee
                    ? handleUpdateTask(
                        task?.id,
                        {
                          ...task,
                          assigneeId: value,
                          assignee: { ...user },
                        },
                        'assignee',
                      )
                    : handleUpdateTask(
                        task?.id,
                        {
                          ...task,
                          assigneeId: value,
                          accountingFirmId,
                        },
                        'assignee',
                      )
                }
                users
                height="h-10 !p-2"
                scrollIntoView={false}
                showAccountantAssignee={showAccountantAssignee}
                teamId={task?.teamId}
                disabled={
                  !checkPermission(
                    PermissionKeys.MANAGE_TASKS,
                    accessType,
                    permissions,
                  )
                }
              />
            )
          ) : (
            <p className="text-slate-600 leading-6 text-[0.875rem]">--</p>
          )}
        </div>
      </div>
      <div>
        <h4 className="text-sm font-medium text-black mb-1">Priority</h4>
        <p className="w-[130px]">
          {' '}
          <DropdownFull
            noMinW
            selected={task?.priority}
            options={[
              { id: 'high', name: 'High' },
              { id: 'medium', name: 'Medium' },
              { id: 'low', name: 'Low' },
            ]}
            name="name"
            height="!h-8 min-w-[120px]"
            priorityDot
            transitionClass="min-w-fit"
            setSelected={(value) =>
              handleUpdateTask(
                task?.id,
                {
                  ...task,
                  priority: value,
                },
                'priority',
              )
            }
            scrollIntoView={false}
            disabled={
              !checkPermission(
                PermissionKeys.MANAGE_TASKS,
                accessType,
                permissions,
              )
            }
          />
        </p>
      </div>

      <div>
        <h4 className="text-sm font-medium text-black mb-1">Status</h4>
        <TasksDropdown
          statusOptions={statusOptions}
          task={task}
          handleUpdateTask={handleUpdateTask}
          disabled={
            !checkPermission(
              PermissionKeys.MANAGE_TASKS,
              accessType,
              permissions,
            )
          }
        />
      </div>
      <div>
        <h4 className="text-sm font-medium text-black mb-1">
          Project
          {task?.projectId &&
            checkPermission(
              PermissionKeys.MANAGE_TASKS,
              accessType,
              permissions,
            ) && (
              <span
                className="text-indigo-500 text-xs font-medium ml-2 underline cursor-pointer"
                onClick={() =>
                  handleUpdateTask(task?.id, { ...task, projectId: null })
                }
                tabIndex="0"
                role="button"
              >
                Clear
              </span>
            )}
        </h4>

        {clientProjectsLoading ? (
          <div className="w-full flex justify-center">
            <Loader width="w-6" height="h-6" />
          </div>
        ) : task?.projectId || isEditingProjectId ? (
          <div ref={taskProjectIdContainerRef}>
            <DropdownFull
              options={showAccountantProjects ? clientProjects : projects}
              selected={task?.projectId}
              setSelected={(value) => {
                handleUpdateTask(task?.id, { ...task, projectId: value });
              }}
              name="name"
              scrollIntoView={false}
              height="h-10"
              placeholder="Select Project"
              disabled={
                !checkPermission(
                  PermissionKeys.MANAGE_TASKS,
                  accessType,
                  permissions,
                )
              }
            />
          </div>
        ) : (
          <div
            className="text-base text-slate-500 h-10 hover:bg-slate-100 p-2 inline-flex items-center w-full cursor-pointer"
            onClick={() => {
              if (
                checkPermission(
                  PermissionKeys.MANAGE_TASKS,
                  accessType,
                  permissions,
                )
              ) {
                setIsEditingProjectId(true);
              }
            }}
            tabIndex="0"
            role="button"
          >
            None
          </div>
        )}
      </div>
      <div>
        <h4 className="text-sm font-medium text-black mb-1">Deadline</h4>
        <div
          className={!isEditDeadline ? 'hidden' : ''}
          ref={taskDeadlineContainerRef}
        >
          <DatePickerDash
            required
            placeholder="Select Date"
            value={taskDeadline}
            options={options}
            onChange={(selectedDates) => {
              handleDeadlineUpdate(selectedDates[0]);
            }}
            name="deadlineDate"
            id="deadlineDate"
            inputClass="!h-10"
            crossIcon
            handleCrossIcon={() => handleDeadlineUpdate(null)}
            crossIconClass="!top-[10px]"
            onBlur={(e) => {
              if (!e.target.classList?.value?.includes('active')) {
                handleDeadlineUpdate(e.target.value);
              }
            }}
            disabled={
              !checkPermission(
                PermissionKeys.MANAGE_TASKS,
                accessType,
                permissions,
              )
            }
          />
        </div>

        {!isEditDeadline && (
          <div
            className="text-base text-slate-500 h-10 hover:bg-slate-100 p-2 inline-flex items-center w-full cursor-pointer"
            onClick={() => {
              if (
                checkPermission(
                  PermissionKeys.MANAGE_TASKS,
                  accessType,
                  permissions,
                )
              ) {
                setIsEditDeadline(true);
              }
            }}
            tabIndex="0"
            role="button"
          >
            {task?.deadlineDate
              ? moment(task?.deadlineDate).format('MMM DD, YYYY')
              : 'None'}
          </div>
        )}
      </div>
    </div>
  );
};

export default RightBar;
