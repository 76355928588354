import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { getCurrencySymbol } from 'utils/Utils';
import { assignInvoiceToBill, getInvoices } from 'API/backend_helper';
import { toast } from 'react-toastify';
import { Loader } from 'components/Svg';

const MatchInvoiceTab = ({
  transaction,
  reloadTransaction,
  reloadTransactions,
  setClose,
}) => {
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [loadInvoice, setLoadInvoice] = useState(true);

  const onAssingInvoice = async () => {
    try {
      setLoading(true);
      await assignInvoiceToBill(selectedInvoice, {
        transactions: [transaction?.id],
      });
      // await reloadTransaction({});
      toast.success('Invoice matched successfully');
      setLoading(false);
      reloadTransactions({});
      setClose(false);
    } catch (e) {
      setLoading(false);
      console.log('error', e);
    }
  };

  const getInvoice = async () => {
    setLoadInvoice(true);
    try {
      const res = await getInvoices({
        type: 'invoice',
        limit: 1000,
        status: ['partial', 'unpaid', 'overdue'],
        currency: transaction?.currency,
        amount: Math.abs(transaction?.amount),
      });
      setInvoices(res?.data?.invoices);
      setLoadInvoice(false);
    } catch (e) {
      setLoadInvoice(false);
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (!transaction?.invoiceId) {
      getInvoice();
    } else {
      setLoadInvoice(false);
    }
  }, [transaction?.invoiceId]);

  return (
    <div>
      <div className="px-10">
        <div
          className="flex flex-col mb-6 overflow-auto scrollbar"
          style={{
            maxHeight: 'calc(100dvh - 332px)',
            height: 'calc(100dvh - 332px)',
          }}
        >
          {transaction?.matchingInvoices?.length > 0 && (
            <div className="grid md:grid-cols-2 gap-4 pb-4 pr-2">
              {transaction?.matchingInvoices?.map((invoice) => (
                <div
                  key={invoice?.id}
                  className="border border-[#D0D5DD] p-4 rounded-[5px]"
                >
                  <label
                    className="w-fit text-base text-[#667085] flex items-center gap-4 mb-4 cursor-pointer"
                    htmlFor={invoice?.id}
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5"
                      id={invoice?.id}
                      checked={selectedInvoice === invoice?.id}
                      onChange={() =>
                        setSelectedInvoice(
                          invoice?.id === selectedInvoice ? null : invoice?.id,
                        )
                      }
                    />

                    <p className="text-[20px] text-[#101828]">
                      {invoice?.invoiceTitle}
                    </p>
                  </label>

                  <div className="pl-9 flex flex-col gap-y-2">
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24">Date: </p>
                      <p>
                        {DateTime.fromISO(invoice?.date, {
                          setZone: true,
                        }).toFormat('MMM dd, yyyy')}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24">Due Date: </p>
                      <p>
                        {DateTime.fromISO(invoice?.dueDate, {
                          setZone: true,
                        }).toFormat('MMM dd, yyyy')}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24 text-nowrap">Amount Due: </p>
                      <p>
                        {getCurrencySymbol(invoice?.currency)}
                        {parseFloat(invoice?.amountDue ?? 0)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24 text-nowrap">
                        Total Amount:{' '}
                      </p>
                      <p>
                        {getCurrencySymbol(invoice?.currency)}
                        {parseFloat(invoice?.total ?? 0)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4">
                      <p className="w-24 min-w-24"> Invoice # </p>
                      <p>{invoice?.invoiceNumber}</p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4">
                      <p className="w-24 min-w-24"> Customer: </p>
                      <p>{invoice?.customer?.customer || '--'}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {invoices?.length > 0 &&
            transaction?.matchingInvoices?.length > 0 && (
              <div className="min-h-[1px]  !border-none bg-indigo-500 w-full" />
            )}

          {!invoices?.length > 0 && loadInvoice && (
            <div
              className="flex flex-col gap-2 items-center pb-2 justify-center mt-8"
              style={{
                maxHeight: 'calc(100dvh - 223px)',
                height: 'calc(100dvh - 223px)',
              }}
            >
              <Loader />
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          )}

          {!invoices?.length > 0 &&
            !transaction?.matchingInvoices?.length &&
            !loadInvoice && (
              <div
                className="text-[18px] text-slate-500 text-center  flex items-center justify-center"
                style={{
                  maxHeight: 'calc(100dvh - 223px)',
                  height: 'calc(100dvh - 223px)',
                }}
              >
                No Matching Invoice found
              </div>
            )}

          {invoices?.length > 0 && (
            <div
              className={`grid md:grid-cols-2 gap-4 pr-2 ${transaction?.matchingInvoices?.length > 0 ? 'pt-4' : ''}`}
            >
              {invoices?.map((invoice) => (
                <div
                  key={invoice?.id}
                  className=" border border-[#D0D5DD] p-4 rounded-[5px]"
                >
                  <label
                    className="w-fit text-base text-[#667085] flex items-center gap-4 mb-4 cursor-pointer"
                    htmlFor={invoice?.id}
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5"
                      id={invoice?.id}
                      checked={selectedInvoice === invoice?.id}
                      onChange={() =>
                        setSelectedInvoice(
                          invoice?.id === selectedInvoice ? null : invoice?.id,
                        )
                      }
                    />

                    <p className="text-[20px] text-[#101828]">
                      {invoice?.invoiceTitle}
                    </p>
                  </label>

                  <div className="pl-9 flex flex-col gap-y-2">
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24">Date: </p>
                      <p>
                        {DateTime.fromISO(invoice?.date, {
                          setZone: true,
                        }).toFormat('MMM dd, yyyy')}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24">Due Date: </p>
                      <p>
                        {DateTime.fromISO(invoice?.dueDate, {
                          setZone: true,
                        }).toFormat('MMM dd, yyyy')}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24">Amount Due: </p>
                      <p>
                        {getCurrencySymbol(invoice?.currency)}
                        {parseFloat(invoice?.amountDue ?? 0)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4 ">
                      <p className="w-24 min-w-24">Tolal Amount: </p>
                      <p>
                        {getCurrencySymbol(invoice?.currency)}
                        {parseFloat(invoice?.total ?? 0)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )}
                      </p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4">
                      <p className="w-24 min-w-24"> Invoice # </p>
                      <p>{invoice?.invoiceNumber}</p>
                    </div>
                    <div className="text-base text-[#667085] flex gap-4">
                      <p className="w-24 min-w-24"> Customer: </p>
                      <p>{invoice?.customer?.customer || '--'}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <footer className="px-10 py-6 border-t border-[#D0D5DD]">
        <div className="flex gap-4 justify-between items-center flex-wrap">
          {/* <p className="text-[#667085]">Remaining Amount: $100</p> */}
          <div className="flex gap-4 ml-auto">
            <button
              type="button"
              onClick={() => setClose(null)}
              className="h-11 w-[90px] inline-flex items-center justify-center gap-2 border border-[#667085] rounded-[5px] text-[#667085]"
            >
              Close
            </button>
            {(transaction?.matchingInvoices?.length > 0 ||
              invoices?.length > 0) && (
              <button
                type="button"
                disabled={!selectedInvoice || loading}
                onClick={onAssingInvoice}
                className="h-11 px-7 inline-flex items-center justify-center gap-2 border border-indigo-500 bg-indigo-500 rounded-[5px] text-white disabled:cursor-not-allowed disabled:bg-indigo-300"
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Apply Payment to Invoice
                {/* Apply Payment to Invoice(s) */}
              </button>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MatchInvoiceTab;
