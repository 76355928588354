import React from 'react';
import { Navigate } from 'react-router-dom';
// Import pages
import ProfitAndLossByTags from 'pages/reports/types/profitAndLossByTags';
import Bookkeepers from 'pages/bookkeepers';
import ImportVendors from 'pages/ImportVendors';
import ImportCustomers from 'pages/ImportCustomers';
import ImportProductAndServices from 'pages/ImportProductAndServices';
import ProjectsTimeSheet from 'pages/reports/types/projectsTimeSheet';
import SaleTaxLiability from 'pages/reports/types/saleTaxLiability';
import IncomeByTaxablNonTaxable from 'pages/reports/types/IncomeByTaxablNonTaxable';
import Dashboard from '../pages/dashboard';
import Customers from '../pages/customers';
import TransactionsLocal from '../pages/transactions';
import Signin from '../pages/authPages/Signin';
import ResetPassword from '../pages/authPages/ResetPassword';
import Settings from '../pages/settings';
import Accounts from '../pages/accounts';
import Onboardings from '../pages/onboarding';
import Vendors from '../pages/vendors';
import Bills from '../pages/bills';
import ForgotPassword from '../pages/authPages/ForgotPassword';
import VerifyEmail from '../pages/authPages/VerifyEmail';
import ManageWorspace from '../pages/manageWorkspace';
import GuestSignUp from '../pages/authPages/GuestSignUp';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfServices from '../pages/TermsOfServices';
import Products from '../pages/products';
import Signout from '../pages/authPages/Signout';
import Invoices from '../pages/invoices';
import Workflows from '../pages/workflows';
import AutomationBuilder from '../pages/workflows/AutomationBuilder';
import Reports from '../pages/reports';
import ReconcileAccounts from '../pages/accounts/reconcileAccounts/ReconcileAccounts';
import Insights from '../pages/insights';
import AccountsTable from '../pages/accounts/reconcileAccounts/AccountsTable';
import AccountTransaction from '../pages/reports/types/accountTransaction';
import Akahu from '../pages/akahu';
import ManageWorkspaces from '../pages/manageWorkspaces';
import Workspace from '../pages/createworkspace';
import AccountDetails from '../pages/accountDetails';
import JournalEntries from '../pages/journalEntries';
import BalanceSheet from '../pages/reports/types/balanceSheet';
import InvoicePayment from '../pages/invoicePayment';
import WorkspaceWelcome from '../pages/WorkspaceWelcome';
import People from '../pages/people';
import Inbox from '../pages/inbox';
import CustomerIncome from '../pages/reports/types/customerIncome';
import AgedReceivables from '../pages/reports/types/agedReceivables';
import CategoryBreakdown from '../pages/reports/types/categoryBreakdown';
import ImportAccounts from '../pages/ImportAccounts';
import AuditLog from '../pages/settings/auditLog';
import ExpenseReporting from '../pages/expenseReporting';
import Suggestions from '../pages/suggestions';
import ImportTransactions from '../pages/ImportTransactions';
import Projects from '../pages/projects';
import GustoSettings from '../pages/appIntegrations/gustoSettings';
import GustoAuth from '../pages/appIntegrations/gustoAuth';
import SquareSettings from '../pages/appIntegrations/squareSettings';
import SquareAuth from '../pages/appIntegrations/squareAuth';
import PeopleDetails from '../pages/people/peopleDetails';
import Payroll from '../pages/payroll';
import InvoicePublicView from '../pages/invoices/InvoicePublicView';
import ProjectDetails from '../pages/projects/ProjectDetails';
import CustomerStatement from '../pages/customerStatement';
import PublicViewCustomerStatement from '../pages/customerStatement/PublicViewCustomerStatement';
import PeopleSignin from '../pages/peopleAuthPages/Signin';
import PeopleForgotPassword from '../pages/peopleAuthPages/ForgotPassword';
import PeopleResetPassword from '../pages/peopleAuthPages/ResetPassword';
import InvoicesBySalesRep from '../pages/reports/types/invoicesBySalesRep';
import QuickBooksSettings from '../pages/appIntegrations/quickbooksSettings';
import CustomerDetails from '../pages/customerDetails';
import AccountantFirm from '../pages/accountantFirm';
import ExpenseClaimReport from '../pages/reports/types/expenseClaim';
import Signup from '../pages/authPages/Signup';
import AccountantFirmForm from '../pages/accountantFirmForm';
import ProfitAndLossReport from '../pages/reports/types/profitAndLoss';

const personRoutes = [
  { path: '/person/invoices', component: <Invoices /> },
  { path: '/person/account-details', component: <AccountDetails /> },
];

const authProtectedRoutes = [
  // { path: '/', component: <Dashboard /> },
  { path: '/', component: <Insights /> },
  { path: '/settings', component: <Settings /> },
  { path: '/settings/:tab', component: <Settings /> },
  { path: '/accounts', component: <Accounts /> },
  { path: '/accounts/:type', component: <Accounts /> },
  { path: '/account/reconcile/:accountId', component: <ReconcileAccounts /> },
  { path: '/transactions', component: <TransactionsLocal /> },
  { path: '/onboarding', component: <Onboardings /> },
  { path: '/vendors', component: <Vendors /> },
  { path: '/bills', component: <Bills /> },
  { path: '/workspace', component: <Workspace /> },
  { path: '/workspace/:type', component: <Workspace /> },
  { path: '/manage-workspace', component: <ManageWorspace /> },
  { path: '/manage-workspace/:id', component: <ManageWorspace /> },
  { path: '/manage-workspaces', component: <ManageWorkspaces /> },
  { path: '/manage-workspaces/:id', component: <ManageWorkspaces /> },
  { path: '/account-details', component: <AccountDetails /> },
  { path: '/customers', component: <Customers /> },
  { path: '/customer/:id', component: <CustomerDetails /> },
  { path: '/people', component: <People /> },
  { path: '/people/:id', component: <PeopleDetails /> },
  { path: '/products', component: <Products /> },
  { path: '/invoices', component: <Invoices /> },
  { path: '/automations', component: <Workflows /> },
  { path: '/automation-builder', component: <AutomationBuilder /> },
  { path: '/logout', component: <Signout /> },
  { path: '/reports', component: <Reports /> },
  { path: '/reports/profit-loss', component: <ProfitAndLossReport /> },
  { path: '/reports/profit-loss-tags', component: <ProfitAndLossByTags /> },
  { path: '/reports/account-transaction', component: <AccountTransaction /> },
  { path: '/reports/balance-sheet', component: <BalanceSheet /> },
  { path: '/reports/customer-income', component: <CustomerIncome /> },
  { path: '/reports/aged-receivables', component: <AgedReceivables /> },
  { path: '/reports/sales-rep-invoices', component: <InvoicesBySalesRep /> },
  { path: '/reports/expense-claim', component: <ExpenseClaimReport /> },
  { path: '/reports/time-sheets', component: <ProjectsTimeSheet /> },

  { path: '/reports/category-breakdown', component: <CategoryBreakdown /> },
  { path: '/reports/sales-tax-liability', component: <SaleTaxLiability /> },
  {
    path: '/reports/income-by-taxabl-non-taxable',
    component: <IncomeByTaxablNonTaxable />,
  },

  { path: '/insights', component: <Insights /> },
  { path: '/reconciliation', component: <AccountsTable /> },
  { path: '/provider/akahu/oauth', component: <Akahu /> },
  { path: '/integration/gusto/oauth', component: <GustoAuth /> },
  { path: '/integration/square/oauth', component: <SquareAuth /> },
  { path: '/journal-entries', component: <JournalEntries /> },
  { path: '/welcome', component: <WorkspaceWelcome /> },
  { path: '/inbox', component: <Inbox /> },
  { path: '/ws/:slug/task/:taskId', component: <Inbox /> },
  { path: '/import-accounts', component: <ImportAccounts /> },
  { path: '/settings/audit-log', component: <AuditLog /> },
  { path: '/suggestions', component: <Suggestions /> },
  { path: '/expense-claims', component: <ExpenseReporting /> },
  { path: '/import-transactions', component: <ImportTransactions /> },
  { path: '/import-vendors', component: <ImportVendors /> },
  { path: '/import-customers', component: <ImportCustomers /> },
  { path: '/import-product-services', component: <ImportProductAndServices /> },

  { path: '/projects', component: <Projects /> },
  { path: '/projects/:id', component: <ProjectDetails /> },
  { path: '/app-integrations/gusto-settings', component: <GustoSettings /> },
  { path: '/app-integrations/square-settings', component: <SquareSettings /> },
  {
    path: '/app-integrations/quickbooks-settings',
    component: <QuickBooksSettings />,
  },
  { path: '/payroll', component: <Payroll /> },
  { path: '/customer-statement', component: <CustomerStatement /> },
  { path: '/customer-statement/:id', component: <CustomerStatement /> },
  { path: '/accountant', component: <AccountantFirm /> },
  { path: '/accountant/:tab', component: <AccountantFirm /> },
  { path: '/practice/:id', component: <AccountantFirmForm /> },
  { path: '/bookkeepers', component: <Bookkeepers /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/organizations" />,
  // },
  { path: '*', component: <Navigate to="/" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: '/signin', component: <Signin /> },
  { path: '/signup', component: <Signup /> },
  { path: '/verify-email', component: <VerifyEmail /> },
  { path: '/reset-password/:token', component: <ResetPassword /> },
  { path: '/forgot-password', component: <ForgotPassword /> },
  { path: '/guest-signup', component: <GuestSignUp /> },
  { path: '/oauth', component: null },
  { path: '/privacy-policy', component: <PrivacyPolicy /> },
  { path: '/privacy', component: <PrivacyPolicy /> },
  { path: '/terms', component: <TermsOfServices /> },
  { path: '/invoice-payment/:invoice', component: <InvoicePayment /> },
  {
    path: '/public/invoice-estimate/:invoice',
    component: <InvoicePublicView />,
  },
  {
    path: '/public/customer-statement/:id',
    component: <PublicViewCustomerStatement />,
  },
  { path: '/person/signin', component: <PeopleSignin /> },
  { path: '/person/forgot-password', component: <PeopleForgotPassword /> },
  { path: '/person/reset-password/:token', component: <PeopleResetPassword /> },
];

export { authProtectedRoutes, publicRoutes, personRoutes };
