import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PaperClipSquare, SmallCheckedSquare, TrashSquare } from './Svg';
import { Loader } from '../../components/Svg';
import {
  deleteNotification,
  markNotificationAsRead,
} from '../../API/backend_helper';
import { VIEWER } from '../../utils/Utils';

const Notifications = ({
  isLoading,
  notifications,
  loadData,
  loadSingleTransaction,
  setCurrentTab,
  loadSingleTask,
  setTaskFlyoutOpen,
  setSelectedTask,
  accessType,
}) => {
  const [, setSearchParams] = useSearchParams();

  const [mapNotifications, setMapNotifications] = useState([]);
  const [taskLoader, setTaskLoader] = useState({});
  const [markAsReadLoader, setMarkAsReadLoader] = useState({});
  const [deleteLoader, setDeleteLoader] = useState({});

  useEffect(() => {
    const sortedNotifications = [...notifications].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    );
    setMapNotifications(sortedNotifications);
  }, [notifications]);

  const handleMarkAsRead = async (id, index) => {
    setMarkAsReadLoader((prev) => ({ ...prev, [index]: true }));
    try {
      await markNotificationAsRead(id);
      await loadData(false);
    } catch (err) {
      console.log('err', err);
    } finally {
      setMarkAsReadLoader((prev) => {
        delete prev[index];
        return prev;
      });
    }
  };

  const handleDeleteNotification = async (id, index) => {
    setDeleteLoader((prev) => ({ ...prev, [index]: true }));
    try {
      await deleteNotification(id);
      await loadData(false);
      toast.success('Notification deleted successfully');
    } catch (err) {
      console.log('err', err);
    } finally {
      setDeleteLoader((prev) => {
        delete prev[index];
        return prev;
      });
    }
  };

  const loadTask = async (id, index, type) => {
    setTaskLoader((prev) => ({ ...prev, [index]: true }));
    try {
      if (type === 'transaction') {
        await loadSingleTransaction(id);
        setCurrentTab('comments');
      } else if (type === 'task') {
        setSearchParams({
          id,
        });
        if (mapNotifications?.[index]?.task?.id) {
          setSelectedTask(mapNotifications?.[index]?.task);
          setTaskFlyoutOpen(true);
        } else {
          await loadSingleTask(id);
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setTaskLoader((prev) => {
        delete prev[index];
        return prev;
      });
    }
  };

  return isLoading ? (
    <div className="w-full mt-10 flex justify-center items-center">
      <Loader />
    </div>
  ) : (
    <div className="flex flex-col items-end gap-5 self-stretch">
      {!isLoading && mapNotifications.length === 0 ? (
        <div className="flex w-full items-center justify-center flex-col mt-10">
          <p className="text-slate-600 text-[20px] text-center">
            No Notifications
          </p>
          <p className="text-slate-600 text-[16px] text-center">
            You don't have any notifications right now
          </p>
        </div>
      ) : (
        <>
          {mapNotifications.map((notification, index) => (
            <div
              key={notification?.id}
              className="flex w-full pt-2 pb-4 items-center border-b border-[#D0D5DD] justify-between"
            >
              <div className="flex gap-5 items-center">
                <PaperClipSquare />
                <p
                  className={`text-slate-600 leading-6 ${!notification?.seen && accessType !== VIEWER ? 'font-semibold' : ''} `}
                >
                  {notification?.notificationType === 'transaction'
                    ? `You were tagged in a comment in Transaction #${notification?.associationId}. `
                    : notification?.notificationType === 'task'
                      ? `${notification?.message}. `
                      : ''}
                  {taskLoader?.[index] ? (
                    <Loader height="h-4 inline-block mx-1" />
                  ) : (
                    <span
                      className="text-indigo-500 cursor-pointer"
                      onClick={() => {
                        loadTask(
                          notification?.associationId,
                          index,
                          notification?.notificationType,
                        );
                      }}
                      role="button"
                      tabIndex="0"
                    >
                      Click here
                    </span>
                  )}{' '}
                  to view details
                </p>
              </div>
              <div className="flex gap-2 items-center">
                {markAsReadLoader?.[index] ? (
                  <Loader height="h-4 w-4 ml-4 mr-8" />
                ) : !notification?.seen ? (
                  <div className="flex items-center gap-1.5">
                    <SmallCheckedSquare />
                    <p
                      className="text-indigo-500 font-medium text-sm leading-[18px] cursor-pointer whitespace-nowrap"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMarkAsRead(notification.id, index);
                      }}
                    >
                      Mark as Read
                    </p>
                  </div>
                ) : null}

                {deleteLoader?.[index] ? (
                  <Loader height="h-4 w-4 mx-1" />
                ) : (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleDeleteNotification(notification.id, index);
                    }}
                    role="button"
                    tabIndex="0"
                  >
                    <TrashSquare />
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Notifications;
