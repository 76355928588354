import React, { useState, useRef, useEffect } from "react";
import Transition from "../../utils/Transition";
import ModalBlank from "../../components/ModalBlank";
import CategoriesDropdown from "./CategoriesDropdown";
import ModalBasic from "../../components/ModalBasic";
import Select from "react-select";

const HideTransactionsModal = ({
  isOpen,
  setIsOpen,
  handleHideSelected,
  isIncludeModal = false,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <ModalBlank modalOpen={isOpen} setModalOpen={setIsOpen}>
      <div className="p-5 flex space-x-4">
        <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-indigo-100 dark:bg-indigo-500/30">
          <svg
            className="w-4 h-4 shrink-0 fill-current text-indigo-500"
            viewBox="0 0 16 16"
          >
            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
          </svg>
        </div>
        {/* Content */}
        <div className="flex-1">
          {/* Modal header */}
          <div className="mb-2">
            <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">
              {isIncludeModal ? "Include" : "Exclude"} Transactions
            </div>
          </div>
          {/* Modal content */}
          <div className="text-sm mb-10">
            <div className="space-y-2">
              <p>
                Are you sure want to {isIncludeModal ? "include" : "exclude"}{" "}
                selected transactions?
              </p>
            </div>
          </div>
          {/* Modal footer */}
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                setLoading(true);
                await handleHideSelected(isIncludeModal);
                setLoading(false);
                setIsOpen(false);
              }}
              className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white
              disabled:bg-indigo-400    disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Yes
            </button>
          </div>
        </div>
      </div>
    </ModalBlank>
  );
};

const SetCategoryModal = ({
  isOpen,
  setIsOpen,
  allCategories,
  handleCategorySelected,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Set Category"
    >
      {/* Modal content */}
      <div className="px-5 pt-4 pb-1 h-48 ">
        <CategoriesDropdown
          allCategories={allCategories}
          isSetCategoryStyle
          selectedCategoryId={selectedCategory}
          setSelectedCategoryId={setSelectedCategory}
        />
      </div>
      {/* Modal footer */}
      <div className="px-5 py-4">
        <div className="flex flex-wrap justify-end space-x-2">
          <button
            className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          >
            Close
          </button>
          <button
            onClick={async () => {
              setLoading(true);
              await handleCategorySelected(selectedCategory);
              setSelectedCategory(null);
              setLoading(false);
              setIsOpen(false);
            }}
            className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white
              disabled:bg-indigo-400    disabled:cursor-not-allowed"
            disabled={loading || !selectedCategory}
          >
            {loading && (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}
            Update
          </button>
        </div>
      </div>
    </ModalBasic>
  );
};

const SetTagModal = ({ isOpen, setIsOpen, tags, handleTagSelected }) => {
  const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  return (
    <ModalBasic modalOpen={isOpen} setModalOpen={setIsOpen} title="Add Tag">
      {/* Modal content */}
      <div className="px-5 pt-4 pb-1 h-48 ">
        <Select
          classNamePrefix="reactSelect"
          name="tags"
          options={tags}
          className="basic-multi-select bg-white dark:bg-slate-700 rounded"
          classNames={{
            control: () =>
              "border-slate-200 dark:border-slate-900 text-slate-500 dark:text-slate-300",
            input: () => "text-slate-500 dark:text-slate-300",
            singleValue: () => "text-slate-500 dark:text-slate-300",
            menu: () =>
              "dark:bg-slate-800 border border-slate-200 dark:border-slate-700 ",
            menuList: () => "max-h-40 overflow-auto",
          }}
          isSearchable={false}
          placeholder="Select Tag"
          getOptionValue={(option) => option?.id}
          onChange={setSelectedTag}
          value={selectedTag}
          formatOptionLabel={(tag) => (
            <div className="flex items-center gap-1">
              <span
                className="h-4 w-4 inline-block	 rounded-full border-full bg-slate-200"
                style={{ backgroundColor: tag?.color }}
              ></span>
              <span className="">{tag?.name}</span>
            </div>
          )}
        />
      </div>
      {/* Modal footer */}
      <div className="px-5 py-4">
        <div className="flex flex-wrap justify-end space-x-2">
          <button
            className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          >
            Close
          </button>
          <button
            onClick={async () => {
              setLoading(true);
              await handleTagSelected(selectedTag.id);
              setSelectedTag(null);
              setLoading(false);
              setIsOpen(false);
            }}
            className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white
              disabled:bg-indigo-400    disabled:cursor-not-allowed"
            disabled={loading || !selectedTag}
          >
            {loading && (
              <svg
                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
              </svg>
            )}
            Update
          </button>
        </div>
      </div>
    </ModalBasic>
  );
};

const MarkReviewedModal = ({
  isOpen,
  setIsOpen,
  handleMarkReviewedSelected,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <ModalBlank modalOpen={isOpen} setModalOpen={setIsOpen}>
      <div className="p-5 flex space-x-4">
        <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-indigo-100 dark:bg-indigo-500/30">
          <svg
            className="w-4 h-4 shrink-0 fill-current text-indigo-500"
            viewBox="0 0 16 16"
          >
            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
          </svg>
        </div>
        {/* Content */}
        <div className="flex-1">
          {/* Modal header */}
          <div className="mb-2">
            <div className="text-lg font-semibold text-slate-800 dark:text-slate-100">
              Mark Reviewed Transactions
            </div>
          </div>
          {/* Modal content */}
          <div className="text-sm mb-10">
            <div className="space-y-2">
              <p>Are you sure want to mark reviewed selected transactions?</p>
            </div>
          </div>
          {/* Modal footer */}
          <div className="flex flex-wrap justify-end space-x-2">
            <button
              className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                setLoading(true);
                await handleMarkReviewedSelected();
                setLoading(false);
                setIsOpen(false);
              }}
              className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white
              disabled:bg-indigo-400    disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading && (
                <svg
                  className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                </svg>
              )}
              Yes
            </button>
          </div>
        </div>
      </div>
    </ModalBlank>
  );
};

function ItemsSelectedDropdown({
  handleHideSelected,
  allCategories,
  handleCategorySelected,
  tags,
  handleTagSelected,
  handleMarkReviewedSelected,
}) {
  const options = [
    {
      id: 1,
      period: "Exclude",
    },
    {
      id: 4,
      period: "Include",
    },
    {
      id: 2,
      period: "Add Tag",
    },
    {
      id: 3,
      period: "Set Category",
    },
    {
      id: 5,
      period: "Mark Reviewed",
    },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpenHideModal, setIsOpenHideModal] = useState(false);
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);
  const [isOpenTagModal, setIsOpenTagModal] = useState(false);
  const [isIncludeModal, setIsIncludeModal] = useState(false);
  const [markReviewedModal, setMarkReviewedModal] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <>
      <HideTransactionsModal
        isOpen={isOpenHideModal}
        setIsOpen={setIsOpenHideModal}
        handleHideSelected={handleHideSelected}
        isIncludeModal={isIncludeModal}
      />
      <SetCategoryModal
        isOpen={isOpenCategoryModal}
        setIsOpen={setIsOpenCategoryModal}
        allCategories={allCategories}
        handleCategorySelected={handleCategorySelected}
      />
      <SetTagModal
        isOpen={isOpenTagModal}
        setIsOpen={setIsOpenTagModal}
        tags={tags}
        handleTagSelected={handleTagSelected}
      />
      <MarkReviewedModal
        isOpen={markReviewedModal}
        setIsOpen={setMarkReviewedModal}
        handleMarkReviewedSelected={handleMarkReviewedSelected}
      />
      <div className="relative">
        <button
          ref={trigger}
          className="btn justify-between min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
          aria-label="Select date range"
          aria-haspopup="true"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          aria-expanded={dropdownOpen}
        >
          <span className="flex items-center">
            <span>Select Option...</span>
          </span>
          <svg
            className="shrink-0 ml-1 fill-current text-slate-400"
            width="11"
            height="7"
            viewBox="0 0 11 7"
          >
            <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
          </svg>
        </button>
        <Transition
          show={dropdownOpen}
          tag="div"
          className="z-10 absolute top-full right-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
          enter="transition ease-out duration-100 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            className="font-medium text-sm text-slate-600 dark:text-slate-300"
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          >
            {options.map((option) => {
              return (
                <button
                  key={option.id}
                  tabIndex="0"
                  className={`flex items-center w-full hover:bg-slate-50 hover:dark:bg-slate-700/20 py-1 px-3 cursor-pointer`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDropdownOpen(false);
                    if (option.id === 1 || option.id === 4) {
                      setIsOpenHideModal(true);
                      if (option.id === 4) {
                        setIsIncludeModal(true);
                      }
                    }
                    if (option.id === 2) {
                      setIsOpenTagModal(true);
                    }
                    if (option.id === 3) {
                      setIsOpenCategoryModal(true);
                    }
                    if (option.id === 5) {
                      setMarkReviewedModal(true);
                    }
                  }}
                >
                  <span>{option.period}</span>
                </button>
              );
            })}
          </div>
        </Transition>
      </div>
    </>
  );
}

export default ItemsSelectedDropdown;
