import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import Transition from '../../../utils/Transition';
import useClickOutside from '../../../utils/useClickOutside';
import { areArraysEqual, extractValuesByKey } from '../../../utils/Utils';
import { all } from 'axios';

const ReusableCategory = ({
  category,
  localSelected,
  group,
  selectedCategoryId,
  onChangeValues,
}) => (
  <div className="ml-2 cursor-pointer">
    <label className=" text-[15px] text-[#667085] hover:text-slate-800 dark:hover:text-slate-200 flex items-center py-1 px-3 cursor-pointer ml-2 font-normal leading-6">
      <input
        type="checkbox"
        checked={
          localSelected?.[group?.id]?.filter((ct) => ct?.id === category?.id)
            ?.length > 0 || selectedCategoryId?.includes(category.id)
        }
        onChange={(e) => {
          e.stopPropagation();
          if (localSelected?.hasOwnProperty(group?.id)) {
            if (
              localSelected?.[group?.id]?.filter(
                (ct) => ct?.id === category?.id,
              )?.length > 0
            ) {
              onChangeValues({
                ...localSelected,
                [group?.id]: localSelected?.[group?.id]?.filter(
                  (ct) => ct?.id !== category?.id,
                ),
              });
            } else {
              onChangeValues({
                ...localSelected,
                [group?.id]: [...localSelected?.[group?.id], category],
              });
            }
          } else {
            onChangeValues({
              ...localSelected,
              [group.id]: [category],
            });
          }
        }}
        className="form-checkbox mr-2"
      />
      {category?.name}
      {category?.mask && ` (...${category?.mask})`}
    </label>
  </div>
);

let Timeout = null;
const CategoriesFilter = ({
  align,
  allCategories,
  selectedCategoryId,
  setSelectedCategoryId,
  getTransactionsApi = null,
  isSetCategoryStyle = false,
  inlineMenu = false,
  apiFilter,
  isReset,
  type = null,
  dropdownOpen: propDropdownOpen,
  setDropdownOpen: propSetDropdownOpen,
  localSelected: propLocalSelected,
  setLocalSelected: propSetLocalSelected,
  allAccount = false,
  report = false,
  journalEntries = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([...allCategories]);
  const [isResultFound, setIsResultFound] = useState(true);
  const [collapsedIds, setCollapsedIds] = useState([]);
  const [localSelected, setLocalSelected] = useState({});
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const searchInput = useRef(null);

  const filterCategories = useMemo(() => {
    if (allCategories?.length > 0) {
      let categories = [...allCategories];
      return categories;
    } else {
      return [];
    }
  }, [allCategories]);

  useClickOutside(dropdown, (event) => {
    if (!trigger.current.contains(event.target)) setDropdownOpen(false);
  });

  // Prop Dropdown Controls
  useEffect(() => {
    if (propDropdownOpen !== undefined) {
      setDropdownOpen(propDropdownOpen);
    }
  }, [propDropdownOpen]);

  useEffect(() => {
    if (propSetDropdownOpen) {
      propSetDropdownOpen(dropdownOpen);
    }
  }, [dropdownOpen, propSetDropdownOpen]);

  // Prop Local Selected Controls
  useEffect(() => {
    if (propLocalSelected !== undefined) {
      setLocalSelected(propLocalSelected);
    }
  }, [propLocalSelected]);

  useEffect(() => {
    if (isReset) {
      setLocalSelected({});
      setCollapsedIds([]);
      setSelectedCategoryId([]);
    }
  }, [isReset]);

  useEffect(() => {
    if (filterCategories?.length > 0) {
      setFilteredData([...filterCategories]);
    } else {
      setFilteredData([]);
    }
    if (filterCategories?.length === 0) {
      setIsResultFound(false);
    } else {
      setIsResultFound(true);
    }
  }, [filterCategories]);

  useEffect(() => {
    if (selectedCategoryId?.length > 0 && filterCategories?.length > 0) {
      const categoryMap = {};
      selectedCategoryId.forEach((id) => {
        const category = findCategoryById(id);
        const group = category?.categoryGroupId;
        if (group) {
          if (!categoryMap[group]) {
            categoryMap[group] = [];
          }
          categoryMap[group].push(category);
        }
      });
      setLocalSelected(categoryMap);
    }
  }, [filterCategories]);

  const onApply = (accountCategories) => {
    clearTimeout(Timeout);
    Timeout = null;

    const apiParams = {
      page: 1,
      loading: true,
    };

    if (type === 'account') {
      apiParams.accounts = accountCategories;
    } else {
      apiParams.accountCategories = accountCategories;
    }

    Timeout = setTimeout(async () => {
      getTransactionsApi(apiParams);
    }, 900);
    // setDropdownOpen(false);
  };

  const onChangeValues = (val = {}) => {
    setLocalSelected(val);
    setSelectedCategoryId(extractValuesByKey(val, 'id'));
    onApply(extractValuesByKey(val, 'id'));
  };

  useEffect(() => {
    if (dropdownOpen) {
      setSearchValue('');
      if (filterCategories?.length > 0) {
        setFilteredData([...filterCategories]);
      } else {
        setFilteredData([]);
      }
      if (filterCategories?.length) {
        setIsResultFound(true);
      }
      if (window.innerWidth >= 768) {
        searchInput.current?.focus();
      }
    }
  }, [dropdownOpen]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      if (filterCategories?.length > 0) {
        setFilteredData([...filterCategories]);
      } else {
        setFilteredData([]);
      }
      if (filterCategories?.length) {
        setIsResultFound(true);
      }
      return;
    }
    setIsResultFound(false);
    const formattedValue = e.target.value.replace(/\s/g, '').toLowerCase();
    const result = filterCategories.map((group) => {
      const filteredCategories = group.categories.filter((category) => {
        if (type === 'account') {
          const categoryNameMatch =
            category?.name
              ?.replace(/\s/g, '')
              .toLowerCase()
              .includes(formattedValue) ||
            category?.mask
              ?.replace(/\s/g, '')
              .toLowerCase()
              .includes(formattedValue);

          // Check if any subAccount name matches
          const subAccountNameMatch = category?.subAccounts?.some(
            (subAccount) =>
              subAccount?.name
                ?.replace(/\s/g, '')
                .toLowerCase()
                .includes(formattedValue) ||
              subAccount?.mask
                ?.replace(/\s/g, '')
                .toLowerCase()
                .includes(formattedValue),
          );
          // Return true if either matches
          return categoryNameMatch || subAccountNameMatch;
        }
        const categoryNameMatch = category?.name
          ?.replace(/\s/g, '')
          .toLowerCase()
          .includes(formattedValue);
        const subAccountNameMatch = category?.subAccounts?.some((subAccount) =>
          subAccount?.name
            ?.replace(/\s/g, '')
            .toLowerCase()
            .includes(formattedValue),
        );
        return categoryNameMatch || subAccountNameMatch;
      });
      if (filteredCategories?.length > 0) {
        setIsResultFound(true);
      }
      return { ...group, categories: filteredCategories };
    });

    setFilteredData(result);
  };

  const onClear = () => {
    setSelectedCategoryId([]);
    setLocalSelected({});
    if (!journalEntries) {
      getTransactionsApi({
        page: 1,
        accountCategories: [],
        loading: true,
      });
    } else if (journalEntries) {
      getTransactionsApi({
        page: 1,
        accounts: [],
        loading: true,
      });
    }
    setDropdownOpen(false);
  };

  const findCategoryById = (selectedCategoryId) => {
    const selectedCategory = allCategories
      ?.map((grp) => grp.categories)
      .flat()
      .reduce((result, category) => {
        if (result) return result; // Stop searching once a match is found

        if (category.id === selectedCategoryId) {
          return category; // Return category if ID matches
        }

        const matchingSubAccount = category.subAccounts?.find(
          (subAcc) => subAcc.id === selectedCategoryId,
        );

        return matchingSubAccount || result; // Return subAccount if ID matches
      }, null);
    return selectedCategory;
  };

  return (
    <div
      className={`relative inline-flex max-w-full w-full min-w-[200px] ${
        isSetCategoryStyle ? 'w-full' : ''
      }`}
      onClick={(e) => e.stopPropagation()}
      ref={dropdown}
      role="button"
      tabIndex="0"
    >
      <button
        ref={trigger}
        className={`inline-flex items-center justify-center w-full text-sm font-medium leading-6 rounded-md px-3 h-10 border ${
          selectedCategoryId?.length > 0 &&
          areArraysEqual(selectedCategoryId, apiFilter)
            ? 'border-indigo-500 shadow-sm  text-indigo-500 duration-150 ease-in-out bg-[#E4864205]'
            : selectedCategoryId?.length > 0
              ? '  text-indigo-300 border-indigo-300 dark:border-white'
              : 'border-[#D0D5DD]  hover:border-slate-400 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out'
        }
        ${report ? '!text-base' : ''}
        `}
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen(!dropdownOpen);
        }}
        aria-expanded={dropdownOpen}
        type="button"
      >
        <div
          className={`flex items-center justify-between truncate w-full ${
            isSetCategoryStyle ? 'w-full justify-between' : ''
          }`}
        >
          <span
            className={`text-[#667085CC] ${report && selectedCategoryId?.length === 0 ? 'text-slate-600' : ''} font-normal truncate  group-hover:text-indigo-600 dark:group-hover:text-indigo-400
            ${
              selectedCategoryId?.length > 0 &&
              areArraysEqual(selectedCategoryId, apiFilter)
                ? 'text-indigo-500'
                : selectedCategoryId?.length > 0
                  ? ' text-indigo-300'
                  : ''
            } `}
          >
            {allAccount == false
              ? selectedCategoryId?.length > 0
                ? `${findCategoryById(selectedCategoryId?.[0])?.name || ''} ${
                    selectedCategoryId?.length > 1
                      ? `+${selectedCategoryId?.length - 1}`
                      : ''
                  }`
                : type === 'account'
                  ? 'Choose Account'
                  : 'Choose Category'
              : selectedCategoryId?.length > 0
                ? `${findCategoryById(selectedCategoryId?.[0])?.name || ''} ${
                    selectedCategoryId?.length > 1
                      ? `+${selectedCategoryId?.length - 1}`
                      : ''
                  }`
                : type === 'account'
                  ? 'All Accounts'
                  : 'Choose Category'}
          </span>

          <svg
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`shrink-0 ml-1 stroke-[#667085CC] ${
              selectedCategoryId?.length > 0 &&
              areArraysEqual(selectedCategoryId, apiFilter)
                ? 'text-indigo-500'
                : selectedCategoryId?.length > 0
                  ? ' text-indigo-300'
                  : ''
            } ${dropdownOpen ? 'rotate-180' : ''} `}
          >
            <path
              d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
              stroke="#667085"
              stroke-opacity="0.8"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="stroke-current"
            />
          </svg>
        </div>
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 pt-1.5 rounded shadow-lg overflow-hidden ${
          align === 'right' ? 'right-0' : 'left-0'
        } ${isSetCategoryStyle ? ' w-full' : ''}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div

        // onFocus={() => setDropdownOpen(true)}
        // onBlur={() => setDropdownOpen(false)}
        >
          <div className="flex justify-end pt-1 pr-1.5">
            <button
              className="text-slate-400 dark:text-slate-500 hover:text-slate-500 dark:hover:text-slate-400"
              onClick={(e) => {
                e.stopPropagation();
                setDropdownOpen(false);
              }}
              type="button"
            >
              <div className="sr-only">Close</div>
              <svg className="w-4 h-4 fill-current">
                <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
              </svg>
            </button>
          </div>
          <div className="relative flex-1 px-3 mb-2">
            <input
              type="text"
              placeholder="Search..."
              className={` form-input text-indigo-600  placeholder:!text-indigo-600 !border-indigo-600  my-2 pl-8 w-full inline-block ${
                isSetCategoryStyle ? ' flex-1 height-fit' : ''
              }`}
              value={searchValue}
              onChange={handleSearch}
              autoFocus
              ref={searchInput}
              onClick={(e) => e.stopPropagation()}
            />
            <svg
              className="absolute left-3 top-[50%] -translate-y-[50%] pl-2 w-6 h-6"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 14.5L11.6667 12.1667M13.3333 8.16667C13.3333 11.2963 10.7963 13.8333 7.66667 13.8333C4.53705 13.8333 2 11.2963 2 8.16667C2 5.03705 4.53705 2.5 7.66667 2.5C10.7963 2.5 13.3333 5.03705 13.3333 8.16667Z"
                stroke="#E48642"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            className={
              isSetCategoryStyle
                ? 'max-h-40 overflow-auto w-full flex flex-col scrollbar'
                : 'max-h-60 overflow-auto scrollbar'
            }
          >
            {filteredData?.map(
              (group) =>
                group?.categories?.length > 0 && (
                  <div
                    key={group.id}
                    className="cursor-default	mb-4"
                    onClick={(e) => e.stopPropagation()}
                    role="button"
                    tabIndex="0"
                  >
                    <label className="whitespace-nowrap font-semibold text-[13px] text-[#515A6C] leading-5 uppercase flex items-center pt-1 px-3 mb-1.5 filter-label">
                      <input
                        type="checkbox"
                        checked={areArraysEqual(
                          localSelected?.[group?.id]?.map((c) => c?.id),
                          group?.categories?.map((c) => c?.id),
                        )}
                        className="form-checkbox mr-2"
                        onChange={() => {
                          if (
                            areArraysEqual(
                              localSelected?.[group?.id]?.map((c) => c?.id),
                              group?.categories?.map((c) => c?.id),
                            )
                          ) {
                            onChangeValues({
                              ...localSelected,
                              [group.id]: [],
                            });
                          } else {
                            onChangeValues({
                              ...localSelected,
                              [group.id]: group?.categories,
                            });
                          }
                        }}
                      />
                      {group?.name}
                      <svg
                        width="10"
                        height="5"
                        viewBox="0 0 10 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-4 shrink-0 text-current text-slate-400 ml-2 cursor-pointer ${
                          !collapsedIds?.filter((id) => id === group?.id)
                            ?.length > 0
                            ? 'rotate-180'
                            : ''
                        } `}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if (
                            collapsedIds?.filter((id) => id === group?.id)
                              ?.length > 0
                          ) {
                            setCollapsedIds(
                              collapsedIds?.filter((id) => id !== group?.id),
                            );
                          } else {
                            setCollapsedIds([...collapsedIds, group?.id]);
                          }
                        }}
                      >
                        <path
                          d="M9 1L5.70707 3.7559C5.31818 4.08137 4.68182 4.08137 4.29293 3.7559L1 1"
                          stroke="#667085"
                          stroke-opacity="0.8"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="stroke-current"
                        />
                      </svg>
                    </label>
                    {!collapsedIds?.filter((id) => id === group?.id)?.length >
                      0 &&
                      group?.categories?.map((category) => (
                        <>
                          <ReusableCategory
                            key={category?.id}
                            category={category}
                            localSelected={localSelected}
                            group={group}
                            selectedCategoryId={selectedCategoryId}
                            onChangeValues={onChangeValues}
                          />
                          {category?.subAccounts?.length > 0 && (
                            <div className="ml-4">
                              {category?.subAccounts?.map((subAccount) => (
                                <ReusableCategory
                                  key={subAccount?.id}
                                  category={subAccount}
                                  localSelected={localSelected}
                                  group={group}
                                  selectedCategoryId={selectedCategoryId}
                                  onChangeValues={onChangeValues}
                                />
                              ))}
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                ),
            )}
            {!isResultFound && (
              <span className="whitespace-nowrap text-center font-bold pr-4 text-sm text-slate-600 dark:text-slate-300 py-1 px-4">
                No Option
              </span>
            )}
          </div>
          <div className="py-2 px-3 border-t border-slate-200 dark:border-slate-700 bg-slate-50 dark:bg-slate-700/20">
            <ul className="flex items-center justify-between">
              <li>
                <button
                  type="button"
                  onClick={onClear}
                  className="btn-xs bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 dark:text-slate-300 hover:text-slate-600 dark:hover:text-slate-200"
                >
                  Clear
                </button>
              </li>
              {/* <li>
                <button
                  className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white"
                  onClick={onApply}
                >
                  Apply
                </button>
              </li> */}
            </ul>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default CategoriesFilter;
