import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Expense, Income } from 'utils/Utils';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { useSelector } from 'react-redux';
import {
  CreateBillIcon,
  CreateInvoiceIcon,
  ManagePeopleIcon,
  ManageVendorsIcon,
  MangeCustomersIcon,
  NewTaskIcon,
  ProductServiceIcon,
  RecordExpenseIcon,
  RecordIncomeIcon,
  TimeEntryIcon,
  UploadReceiptIcon,
} from './Icons';
import QuickLinksModal from './QuickLinksModal';

export const LinkBox = ({
  name = '',
  Icon = () => {},
  handleClick = () => {},
}) => (
  <div
    onClick={handleClick}
    className="py-6 flex flex-col gap-4 items-center border border-[#D0D5DD] rounded-[12px]"
    role="button"
    tabIndex="0"
  >
    <div className="h-12 w-12 bg-[#FCF1E9] rounded inline-flex justify-center items-center">
      <Icon />
    </div>
    <p className="text-[#101828] font-medium text-center">{name}</p>
  </div>
);

const QuickLinksBox = ({
  setAddTransactionModalOpen,
  setAddTransactionType,
  setTimeTrackingModalOpen,
}) => {
  const { accessType, permissions } = useSelector((state) => state.User);

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <QuickLinksModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        LinkBox={LinkBox}
        setTimeTrackingModalOpen={setTimeTrackingModalOpen}
        setAddTransactionType={setAddTransactionType}
        setAddTransactionModalOpen={setAddTransactionModalOpen}
      />
      <div className="p-6 rounded-[12px] border border-[#D0D5DD] ">
        <div className="h-11 mb-4 flex justify-between items-center">
          <h3 className="text-[22px] text-[#666] font-medium">Quick Links</h3>
        </div>
        <div className="grid grid-cols-3 gap-6">
          {checkPermission(
            PermissionKeys.MANAGE_INVOICES,
            accessType,
            permissions,
          ) && (
            <LinkBox
              handleClick={() =>
                navigate('/invoices?tab=invoices&new=invoice&from=dashboard')
              }
              name="Create Invoice"
              Icon={CreateInvoiceIcon}
            />
          )}
          {checkPermission(
            PermissionKeys.MANAGE_BILLS,
            accessType,
            permissions,
          ) && (
            <LinkBox
              handleClick={() => navigate('/bills?id=new')}
              name="Create Bill"
              Icon={CreateBillIcon}
            />
          )}
          {checkPermission(
            PermissionKeys.MANAGE_PROJECTS,
            accessType,
            permissions,
          ) && (
            <LinkBox
              handleClick={() => setTimeTrackingModalOpen(true)}
              name="Add Time Entry"
              Icon={TimeEntryIcon}
            />
          )}
          {checkPermission(
            PermissionKeys.MANAGE_TRANSACTIONS,
            accessType,
            permissions,
          ) && (
            <LinkBox
              handleClick={() => {
                setAddTransactionType(Expense);
                setAddTransactionModalOpen(true);
              }}
              name="Record Expense"
              Icon={RecordExpenseIcon}
            />
          )}
          {checkPermission(
            PermissionKeys.MANAGE_TRANSACTIONS,
            accessType,
            permissions,
          ) && (
            <LinkBox
              handleClick={() => {
                setAddTransactionType(Income);
                setAddTransactionModalOpen(true);
              }}
              name="Record Income"
              Icon={RecordIncomeIcon}
            />
          )}

          {checkPermission(
            PermissionKeys.MANAGE_TASKS,
            accessType,
            permissions,
          ) && (
            <LinkBox
              handleClick={() => navigate('/inbox?id=new')}
              name="New Task"
              Icon={NewTaskIcon}
            />
          )}

          {accessType === 'read only' && (
            <>
              {checkPermission(
                PermissionKeys.VIEW_PRODUCTS,
                accessType,
                permissions,
              ) && (
                <LinkBox
                  handleClick={() => navigate('/products')}
                  name="Product and Services"
                  Icon={ProductServiceIcon}
                />
              )}

              {checkPermission(
                PermissionKeys.VIEW_VENDORS,
                accessType,
                permissions,
              ) && (
                <LinkBox
                  handleClick={() => navigate('/vendors')}
                  name="Manage Vendors"
                  Icon={ManageVendorsIcon}
                />
              )}
              {checkPermission(
                PermissionKeys.VIEW_CUSTOMERS,
                accessType,
                permissions,
              ) && (
                <LinkBox
                  handleClick={() => navigate('/customers')}
                  name="Manage Customers"
                  Icon={MangeCustomersIcon}
                />
              )}

              {checkPermission(
                PermissionKeys.VIEW_PEOPLE,
                accessType,
                permissions,
              ) && (
                <LinkBox
                  handleClick={() => navigate('/people')}
                  name="Manage People"
                  Icon={ManagePeopleIcon}
                />
              )}
            </>
          )}

          {/* <LinkBox
          handleClick={() => navigate('/products')}
          name="Product and Services"
          Icon={ProductServiceIcon}
        />
        <LinkBox name="Upload Receipt" Icon={UploadReceiptIcon} /> */}
        </div>
        <div className="mt-4 flex justify-end">
          <button
            className="text-sm text-[#667085cc] inline-flex gap-2 items-center"
            type="button"
            onClick={() => setModalOpen(true)}
          >
            More links
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="7"
              viewBox="0 0 14 7"
              fill="none"
            >
              <path
                d="M13 1L8.06061 5.59317C7.47727 6.13561 6.52273 6.13561 5.93939 5.59317L1 1"
                stroke="#667085"
                strokeOpacity="0.8"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default QuickLinksBox;
