/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import TextInputDash from '../../components/TextInputDash';
import CustomersBox from './CustomersBox';
import DatePickerDash from '../../components/DatePickerDash';
import CreateInvoicesTable from './CreateInvoicesTable';
import InvoicePanel from './InvoicePanel';
import AddCustomerModal from './AddCustomerModal';
import {
  createInvoice,
  generateInvoiceNumber,
  getAccounts,
  getCountriesData,
  getInvoiceById,
  getProducts,
  getTeamById,
  updateInvoice,
  updateInvoiceTags,
  uploadInvoiceDoc,
} from '../../API/backend_helper';
import {
  formatDateLocally,
  getCurrencySymbolFromIso,
  getFormattedDate,
  getInvoiceStatusColor,
  getMomentDateFormat,
  getStringSegments,
  hasUpToTwoDecimalPlaces,
  INVOICE_TAX_TYPES_OPTIONS,
  IS_PERSON,
  TEAM_DATE_FORMAT,
  transformAccounts,
  trimText,
} from '../../utils/Utils';
import Tooltip from '../../components/Tooltip';
import WorkspaceLogoModal from './AddWorkspaceLogo';
import AssignedTransactions from './AssignedTransactions';
import TaxModal from '../settings/taxes/TaxModal';
import ReceiptModal from '../transactions/ReceiptModal';
import TeamInfoModal from './TeamInfoModal';
import ConvertEstimateModal from './ConvertEstimateModal';
import FontLoader from '../../components/FontLoader';
import DropdownFull from '../../components/DropdownFull';
import TagsBoard from './TagsBoard';
import SendHistoryBox from './SendHistoryBox';
import downloadPdf from '../../components/DownloadPdf';
import LiveInvoiceDownload from './InvoiceLiveDownloader';
import { setTeam } from '../../redux/Team/reducer';

const DownloadIcon = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 12.5V15.8333C18 16.2754 17.8244 16.6993 17.5118 17.0118C17.1993 17.3244 16.7754 17.5 16.3333 17.5H4.66667C4.22464 17.5 3.80072 17.3244 3.48816 17.0118C3.17559 16.6993 3 16.2754 3 15.8333V12.5"
      stroke="#E48642"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.33301 8.33203L10.4997 12.4987L14.6663 8.33203"
      stroke="#E48642"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 12.5V2.5"
      stroke="#E48642"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RecurrencePatternOptions = [
  {
    id: 'daily',
    name: 'Daily',
  },
  {
    id: 'weekly',
    name: 'Weekly',
  },
  {
    id: 'monthly',
    name: 'Monthly',
  },
  {
    id: 'yearly',
    name: 'Yearly',
  },
];

// Helper function to round a number to 2 decimal places
const roundToTwo = (num) => Math.round(num * 100) / 100;

const adjustValues = (
  quantity,
  unitPrice,
  total,
  restrictQuantityToInt = false,
) => {
  // If the flag is set, round the quantity to the nearest integer
  if (restrictQuantityToInt) {
    quantity = Math.round(quantity);
  }

  // Round the initial values to 2 decimal places
  const roundedQuantity = roundToTwo(quantity);
  const roundedUnitPrice = roundToTwo(unitPrice);

  // Calculate the total based on the possibly rounded quantity and unit price
  const recalculatedTotal = roundToTwo(roundedQuantity * roundedUnitPrice);

  // Check if any value exceeds 2 decimal places
  const quantityExceeds = quantity !== roundedQuantity;
  const unitPriceExceeds = unitPrice !== roundedUnitPrice;
  const totalExceeds = total !== roundToTwo(total);

  // Adjust values based on the conditions
  if (restrictQuantityToInt) {
    total = recalculatedTotal;
  } else if (unitPriceExceeds) {
    unitPrice = roundedUnitPrice;
    total = roundToTwo(unitPrice * roundedQuantity);
  } else if (quantityExceeds || totalExceeds) {
    total = recalculatedTotal;
    quantity = roundToTwo(total / roundedUnitPrice);
  }

  return {
    quantity: roundToTwo(quantity),
    unitPrice: roundToTwo(unitPrice),
    total: roundToTwo(total),
  };
};

const InvoiceForm = ({
  setIsActive,
  type,
  setType,
  getData,
  isPreview,
  setIsPreview,
  customers,
  getCustomersApi,
  setSendInvoiceModalOpen,
  setActiveTab,
  fetchTeam,
  isAssignedTransTab,
  setIsAssignedTransTab,
  setTransactionsModalOpen,
  setPaymentModalOpen,
  onSetActiveTab,
  currencies,
  projects,
  sendEmailAction,
  taxes,
  loadTaxes,
  setTaxes,
  localInvoice,
  setLocalInvoice,
  defaultAttachments = [],
  salesRep,
  ESTIMATES_TAB,
  INVOICES_TAB,
  tags,
  reloadInvoice = false,
  setReloadInvoice = null,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { team } = useSelector((state) => state.Team);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const isRecurringByDefault = searchParams.get('isRecurring') || '';
  const From = searchParams.get('from') || '';

  const dispatch = useDispatch();
  const IsPerson = localStorage.getItem(IS_PERSON)
    ? JSON.parse(localStorage.getItem(IS_PERSON))
    : null;
  const { accessType, permissions } = useSelector((state) => state.User);
  const navigate = useNavigate();

  const [invoicePanelOpen, setInvoicePanelOpen] = useState(false);
  const [editInvoice, setEditInvoice] = useState(null);
  const [fontColorLoader, setFontColorLoader] = useState(false);
  const [activeConvertEstimate, setActiveConvertEstimate] = useState(null);
  const [convertEstimateModalOpen, setConvertEstimateModalOpen] =
    useState(false);
  const [teamInfoModalOpen, setTeamInfoModalOpen] = useState(false);

  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrencePattern, setRecurrencePattern] = useState('daily');
  const [occurrenceCount, setOccurrenceCount] = useState(1);
  const [recurrenceEndDate, setRecurrenceEndDate] = useState(null);
  const [inAdvanceCreationDays, setInAdvanceCreationDays] = useState('');
  const [emailCustomer, setEmailCustomer] = useState(false);
  const [endRecurringType, setEndRecurringType] = useState('');

  // Modals State
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [editBusinessModalOpen, setEditBusinessModalOpen] = useState(false);

  // Panel State
  const [selectedColor, setSelectedColor] = useState('#667085');
  const [imgPreview, setImgPreview] = useState('');
  const [files, setFiles] = useState([]);
  const [fontFamily, setFontFamily] = useState(localInvoice?.font || 'Outfit');

  // content Fields
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [previousInvoiceNumber, setPreviousInvoiceNumber] = useState('');
  const [invoiceNumberLoading, setInvoiceNumberLoading] = useState(true);
  const [invoiceNumberTouched, setInvoiceNumberTouched] = useState(false);
  const [psoNumber, setPsoNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(
    formatDateLocally(new Date(), teamDateFormat),
  );
  const [invoiceDueDate, setInvoiceDueDate] = useState(
    type === 'Invoice' && !editInvoice?.id
      ? formatDateLocally(new Date(), teamDateFormat)
      : '',
  );

  const [paymentTerm, setPaymentTerm] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectObj, setSelectedProjectObj] = useState(null);
  const [selectedTax, setSelectedTax] = useState([]);
  const [salesRepCharLength, setSalesRepCharLength] = useState(15);
  const [customerCharLength, setCustomerCharLength] = useState(15);
  const [projectCharLength, setProjectCharLength] = useState(15);
  const [tagsCharLength, setTagsCharLength] = useState(15);

  // Discount
  const [discount, setDiscount] = useState('');
  const [discountError, setDiscountError] = useState('');
  const [discountDescription, setDiscountDescription] = useState('');

  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  // supporting API's data
  const [productsPriceType, setProductsPriceType] = useState('tax-exclusive');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [inActiveProducts, setInActiveProducts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [isDiscountAdded, setIsDiscountAdded] = useState(false);

  const [attachFiles, setAttachFiles] = useState([]);
  const [attachLoader, setAttachLoader] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const [activeReceipt, setActiveReceipt] = useState(null);
  const [invoiceSalesRep, setInvoiceSalesRep] = useState(null);
  const [invoiceSalesRepObj, setInvoiceSalesRepObj] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [discInPercentage, setDiscInPercentage] = useState(false);
  const [discountPercentageValue, setDiscountPercentageValue] = useState(0);

  const options = useMemo(
    () => ({
      mode: 'single',
      static: true,
      monthSelectorType: 'static',
      onReady: (selectedDates, dateStr, instance) => {
        instance?.calendarContainer?.classList?.add(`flatpickr-right`);
      },
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  const handleResize = () => {
    setSalesRepCharLength(
      parseInt(
        (document.getElementById('salesRepBoxId')?.clientWidth - 40) / 11,
      ),
    );
    setCustomerCharLength(
      parseInt(
        (document.getElementById('customerBoxId')?.clientWidth - 10) / 11,
      ),
    );
    setProjectCharLength(
      parseInt(
        (document.getElementById('projectBoxId')?.clientWidth - 10) / 11,
      ),
    );
    setTagsCharLength(
      parseInt((document.getElementById('tagsBoxId')?.clientWidth - 30) / 10),
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [editInvoice]);

  useEffect(() => {
    if (isRecurringByDefault === 'true') {
      setIsRecurring(true);
    }
  }, [isRecurringByDefault]);
  // supporting API's

  const getProductsApi = async () => {
    try {
      const res = await getProducts({ limit: 10000, status: 'active' });
      setProducts(res?.message?.products);
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadCountries = async () => {
    try {
      const res = await getCountriesData();
      setCountries(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadAccounts = async () => {
    try {
      const res = await getAccounts();
      const incomeCategories = transformAccounts(
        res,
        'CATEGORY_PHYSICAL_ACCOUNT',
        'income',
      );
      const expenseCategories = transformAccounts(
        res,
        'CATEGORY_PHYSICAL_ACCOUNT',
        'expense',
      );
      setCategories(incomeCategories);
      setExpenseCategories(expenseCategories);
    } catch (err) {
      console.log('err', err);
    }
  };

  const genInvoiceNumber = async () => {
    try {
      const res = await generateInvoiceNumber();
      setInvoiceNumber(
        `${typeof res?.data?.result?.number === 'number' ? res?.data?.result?.number : ''}` ||
          '',
      );
      setPreviousInvoiceNumber(res?.data?.result?.lastNumber || '');
    } catch (err) {
      console.log('err', err);
    } finally {
      setInvoiceNumberLoading(false);
    }
  };

  useEffect(() => {
    getProductsApi();
    loadCountries();
    loadAccounts();
  }, []);

  useEffect(
    () => () =>
      attachFiles.forEach((file) => URL.revokeObjectURL(file.preview)),
    [files],
  );

  useEffect(() => {
    if (!editInvoice && !localInvoice) {
      genInvoiceNumber();
    } else {
      setInvoiceNumberLoading(false);
    }
  }, []);

  const getInvoiceByIdApi = async (id, loader = true) => {
    if (loader) {
      setLoader(true);
    }
    try {
      const res = await getInvoiceById(id);
      setEditInvoice(res?.data?.invoice);
      const invoiceType = res?.data?.invoice?.invoiceType;
      setType(invoiceType?.charAt(0)?.toUpperCase() + invoiceType?.slice(1));
      if (loader) {
        setLoader(false);
      }
    } catch (err) {
      console.log('err', err);
      if (loader) {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    if (localInvoice?.id && editInvoice === null) {
      getInvoiceByIdApi(localInvoice?.id, true);
    }
  }, [localInvoice]);

  // Update Invoice Without Loading
  useEffect(() => {
    if (localInvoice?.id && reloadInvoice) {
      getInvoiceByIdApi(localInvoice?.id, false);
      setReloadInvoice(false);
    }
  }, [reloadInvoice]);

  useEffect(() => {
    if (editInvoice?.salesRepId) {
      const selectedRep = salesRep?.find(
        (s) => s?.id === editInvoice?.salesRepId,
      );
      const name = `${selectedRep?.firstName || ''} ${selectedRep?.lastName || ''} `;
      setInvoiceSalesRepObj({ ...selectedRep, name });
    }
  }, [editInvoice?.salesRepId, salesRep]);

  useEffect(() => {
    if (editInvoice?.projectId) {
      const selectedProject = projects?.find(
        (p) => p?.id === editInvoice?.projectId,
      );
      setSelectedProjectObj(selectedProject);
    }
  }, [editInvoice?.projectId, projects]);

  const handleSetInvoiceData = () => {
    if (editInvoice) {
      setTitle(editInvoice?.invoiceTitle);
      setSummary(editInvoice?.summary);
      setInvoiceNumber(editInvoice?.invoiceNumber);
      setPsoNumber(editInvoice?.poSoNumber);
      setInvoiceDate(
        formatDateLocally(editInvoice?.date?.split?.('T')?.[0], teamDateFormat),
      );
      setInvoiceDueDate(
        formatDateLocally(
          editInvoice?.dueDate?.split?.('T')?.[0],
          teamDateFormat,
        ),
      );

      if (
        parseFloat(editInvoice?.discount) > 0 ||
        editInvoice?.discountDescription
      ) {
        setIsDiscountAdded(true);
      }
      setDiscount(parseFloat(editInvoice?.discount || 0)?.toFixed(2));
      setDiscountPercentageValue(
        roundToTwo(
          (parseFloat(editInvoice?.discount || 0) /
            (editInvoice?.totalBeforeTaxAndDiscount +
              parseFloat(editInvoice?.tax || 0))) *
            100,
        ),
      );
      setDiscountDescription(editInvoice?.discountDescription || '');
      setSelectedCustomer(editInvoice?.customer);
      setProductsPriceType(editInvoice?.productsPriceType);
      setFontFamily(editInvoice?.font);
      setSelectedColor(`#${editInvoice?.color}`);
      setSelectedProject(editInvoice?.projectId);
      editInvoice?.invoiceProducts?.forEach((invoiceProduct) => {
        if (invoiceProduct?.product?.status === 'inactive') {
          setInActiveProducts((prev) => {
            if (!prev?.some((p) => p?.id === invoiceProduct?.product?.id)) {
              prev?.push(invoiceProduct?.product);
            }
            return prev;
          });
        }
      });
      if (editInvoice?.tags?.length > 0) {
        setSelectedTags(editInvoice?.tags?.map((tag) => tag?.id));
      }
      if (editInvoice?.salesRepId) {
        setInvoiceSalesRep(editInvoice?.salesRepId);
      }
      setIsRecurring(
        editInvoice?.isDraft
          ? editInvoice?.createdTemplateId || false
          : editInvoice?.recurringTemplateId || false,
      );
      setRecurrencePattern(
        (editInvoice?.isDraft
          ? editInvoice?.createdInvoiceTemplate?.recurrencePattern
          : editInvoice?.recurringInvoiceTemplate?.recurrencePattern) ||
          'daily',
      );
      setOccurrenceCount(
        (editInvoice?.isDraft
          ? editInvoice?.createdInvoiceTemplate?.occurrenceCount
          : editInvoice?.recurringInvoiceTemplate?.occurrenceCount) || 1,
      );
      setEmailCustomer(
        (editInvoice?.isDraft
          ? editInvoice?.createdInvoiceTemplate?.emailCustomer
          : editInvoice?.recurringInvoiceTemplate?.emailCustomer) || false,
      );
      setPaymentTerm(editInvoice?.paymentTerm || 0);
      setRecurrenceEndDate(
        editInvoice?.isDraft
          ? editInvoice?.createdInvoiceTemplate?.recurrenceEndDate
          : editInvoice?.recurringInvoiceTemplate?.recurrenceEndDate,
      );

      setInAdvanceCreationDays(
        editInvoice?.isDraft
          ? editInvoice?.createdInvoiceTemplate?.inAdvanceCreationDays
          : editInvoice?.recurringInvoiceTemplate?.inAdvanceCreationDays,
      );
      if (
        editInvoice?.isDraft
          ? editInvoice?.createdInvoiceTemplate?.recurrenceEndDate
          : editInvoice?.recurringInvoiceTemplate?.recurrenceEndDate
      ) {
        setEndRecurringType('by');
      } else if (
        (editInvoice?.isDraft
          ? editInvoice?.createdInvoiceTemplate?.occurrenceCount
          : editInvoice?.recurringInvoiceTemplate?.occurrenceCount) > 0
      ) {
        setEndRecurringType('after');
      }
      const products = [];
      if (editInvoice?.invoiceProducts?.length > 0) {
        editInvoice?.invoiceProducts?.forEach((eI, id) => {
          let taxPercentage = 0;

          if (eI?.taxes?.length > 0) {
            eI?.taxes?.forEach((t) => {
              taxPercentage += parseFloat(t?.percentage ?? 0);
            });
          }
          const taxes = [];
          if (eI?.taxes?.length > 0) {
            eI?.taxes?.forEach((t) => taxes?.push(t?.taxId));
          }
          products?.push({
            invoiceProductId: eI?.id,
            id: { value: eI?.product?.id },
            name: { value: eI?.product?.name },
            description: {
              value:
                eI?.description ||
                eI?.priceDetails?.description ||
                eI?.product?.description,
              error: false,
            },
            currency: { value: eI?.product?.currency },

            // categoryId: { value: null, error: false },
            quantity: { value: eI?.quantity, error: false },
            unitPrice: {
              value: parseFloat(eI?.price || eI?.product?.unitPrice),
              error: false,
            },
            taxPercentage: { value: taxPercentage, error: false },
            total: {
              value: parseFloat(eI?.price) * parseFloat(eI?.quantity),
              error: false,
            },
            localId: {
              value: `${new Date().getTime().toString()}${id}`,
              error: false,
            },
            taxIds: {
              value: taxes,
              error: false,
            },
            categoryAccountId: {
              value: eI?.categoryAccountId,
              error: false,
              categoryAccount: {
                id: eI?.categoryAccountId,
                name: eI?.categoryAccount?.name,
              },
            },
            stockQuantity: eI?.product?.stockQuantity
              ? editInvoice?.invoiceType === 'estimate' &&
                editInvoice?.paymentStatus === 'expired'
                ? parseInt(eI?.product?.stockQuantity)
                : parseInt(eI?.product?.stockQuantity) + parseInt(eI?.quantity)
              : null,
            pricingMethod: eI?.product?.pricingMethod,
          });
        });
      }
      setSelectedProducts(products);
    }
  };

  useEffect(() => {
    handleSetInvoiceData();
  }, [editInvoice]);

  useEffect(() => {
    if (team?.id && !editInvoice) {
      if (team?.defaults?.font) {
        setFontFamily(team?.defaults?.font);
      }
      if (team?.defaults?.color) {
        setSelectedColor(`#${team?.defaults?.color}`);
      }
      if (
        team?.defaults?.invoiceDefaultSummary ||
        team?.defaults?.estimateDefaultSummary
      ) {
        setSummary(
          type === 'Invoice'
            ? team?.defaults?.invoiceDefaultSummary
            : team?.defaults?.estimateDefaultSummary,
        );
      }
      if (team?.defaults?.invoiceDefaultTitle && type === 'Invoice') {
        setTitle(team?.defaults?.invoiceDefaultTitle);
      } else {
        setTitle(team?.defaults?.estimateDefaultTitle);
      }
    }
  }, [team]);

  const getCountryName = () => {
    let country = null;
    if (countries?.length > 0) {
      country = countries?.find((cnt) => cnt?.isoCode === team?.country);
    }
    return country?.name;
  };

  const checkProductRows = () => {
    let isError = false;
    if (discountError) {
      return true;
    }
    if (selectedProducts?.length === 0) {
      return true;
    }
    const tempRows = [...selectedProducts];
    selectedProducts.forEach((d, i) => {
      Object.keys(d).forEach((key) => {
        const tempRow = { ...selectedProducts[i] };
        if (key === 'quantity' || key === 'unitPrice' || key === 'total') {
          if (hasUpToTwoDecimalPlaces(parseFloat(d[key].value))) {
            tempRow[key].error = '';
            if (key === 'quantity' && tempRow?.pricingMethod === 'item') {
              if (
                tempRow.quantity.value &&
                tempRow.unitPrice.value &&
                tempRow.total.value
              ) {
                const { quantity, unitPrice, total } = adjustValues(
                  parseFloat(tempRow.quantity.value),
                  parseFloat(tempRow.unitPrice.value),
                  parseFloat(tempRow.total.value),
                  d?.stockQuantity,
                );
                tempRow.quantity.value = quantity;
                tempRow.unitPrice.value = unitPrice;
                tempRow.total.value = total;
              }
            }
          } else {
            // if (key === "quantity"){
            // tempRow[key].error = "  ";
            // } else {
            //   tempRow[key].error = "up to 2 decimal places";
            // }
            isError = true;
            if (
              tempRow.quantity.value &&
              tempRow.unitPrice.value &&
              tempRow.total.value
            ) {
              const { quantity, unitPrice, total } = adjustValues(
                parseFloat(tempRow.quantity.value),
                parseFloat(tempRow.unitPrice.value),
                parseFloat(tempRow.total.value),
                d?.stockQuantity,
              );
              tempRow.quantity.value = quantity;
              tempRow.unitPrice.value = unitPrice;
              tempRow.total.value = total;
            }
          }
        }
        if (key === 'quantity' && !d[key].value) {
          tempRow[key].error = ' ';
          isError = true;
        }
        if (key === 'quantity' && d[key].value <= 0) {
          tempRow[key].error = ' ';
          isError = true;
        }
        if (key === 'quantity' && d?.stockQuantity) {
          if (d[key].value > d?.stockQuantity) {
            tempRow.quantity.error = 'Overselling';
            isError = false;
          }
        }
        if (key === 'unitPrice' && !d[key].value) {
          tempRow[key].error = 'Price is required';
          isError = true;
        }
        if (key === 'unitPrice' && d[key].value <= 0) {
          tempRow[key].error = 'Price should be above 0';
          isError = true;
        }
        if (key === 'total' && d[key].value <= 0) {
          tempRow[key].error = 'Amount should be above 0';
          isError = true;
        }
        if (key === 'total' && !d[key].value) {
          tempRow[key].error = 'Amount is required';
          isError = true;
        }
        if (
          roundToTwo(parseFloat(d.total.value)) !==
          roundToTwo(
            parseFloat(d.unitPrice.value) * parseFloat(d.quantity.value),
          )
        ) {
          tempRow.total.error = 'Amount is not correct';
          isError = true;
        }
      });
    });
    setSelectedProducts(tempRows);
    return isError;
  };

  const getTaxById = (id) => {
    const tax = taxes?.find((t) => t?.id === id);
    return tax;
  };

  const handleInvoiceAttach = async (
    // eslint-disable-next-line default-param-last
    id = editInvoice?.id,
    file,
    fetchInvoice = true,
  ) => {
    if (id) {
      setAttachLoader(true);
      const formData = new FormData();
      formData.append('documents', file);
      try {
        const res = await uploadInvoiceDoc(id, formData);
        if (res && fetchInvoice) {
          getInvoiceByIdApi(localInvoice?.id, false);
        }
        setAttachLoader(false);
      } catch (error) {
        setAttachLoader(false);
        console.log('error', error);
      }
    }
  };

  const updateTags = async (id, data) => {
    try {
      await updateInvoiceTags(id, { tags: data });
    } catch (err) {
      console.log('err', err);
    }
  };

  const onSubmit = async ({ isDraft = true }) => {
    if (checkProductRows()) {
      return;
    }
    if (isDraft) {
      setDraftLoading(true);
    } else {
      setLoading(true);
    }
    try {
      const products = [];
      if (selectedProducts?.length > 0) {
        selectedProducts?.forEach((sP) => {
          const productTaxes = [];
          if (productsPriceType === 'zero-rated') {
            const t = taxes?.find((t) => t?.name === 'Zero Rated');
            productTaxes?.push({
              originalTaxId: t?.id,
              percentage: t?.percentage,
            });
          } else if (
            productsPriceType !== 'no-tax' &&
            sP?.taxIds?.value?.length > 0
          ) {
            sP?.taxIds?.value?.forEach((id) => {
              const t = getTaxById(id);
              productTaxes?.push({
                originalTaxId: id,
                percentage: t?.percentage,
              });
            });
          }
          const product = {
            id: sP?.id?.value,
            price: sP?.unitPrice?.value,
            quantity: sP?.quantity?.value,
            description: sP?.description?.value,
            taxes: productTaxes,
            categoryAccountId: sP?.categoryAccountId?.value,
          };
          if (sP?.invoiceProductId) {
            product.invoiceProductId = sP.invoiceProductId;
          }
          products?.push(product);
        });
      }
      const data = {
        invoiceType: type?.toLowerCase(),
        isDraft,
        invoiceTitle: title,
        summary,
        invoiceNumber,
        // date: moment(invoiceDate).format("YYYY-MM-DD"),
        // dueDate: moment(invoiceDueDate).format("YYYY-MM-DD"),
        date: getFormattedDate(invoiceDate, teamDateFormat),
        dueDate: getFormattedDate(invoiceDueDate, teamDateFormat),
        color: selectedColor?.replace('#', ''),
        font: fontFamily,
        discountDescription,
        customer: selectedCustomer?.id || '',
        productsPriceType,
        salesRepId: invoiceSalesRep,
        paymentTerm,
        isRecurring,
        recurrencePattern: isRecurring ? recurrencePattern : null,
        occurrenceCount:
          isRecurring && endRecurringType === 'after' ? occurrenceCount : 0,
        emailCustomer: isRecurring ? emailCustomer : false,
        recurrenceEndDate:
          isRecurring && endRecurringType === 'by' ? recurrenceEndDate : null,
        inAdvanceCreationDays: isRecurring ? +inAdvanceCreationDays || 0 : 0,
      };
      if (editInvoice?.isDraft && !isRecurring) {
        data.isRecurringTemplate = false;
      }
      if (products?.length > 0) {
        data.products = products;
      }
      if (psoNumber) {
        data.poSoNumber = psoNumber;
      }
      if (discount) {
        data.discount = parseFloat(discount || 0).toFixed(2);
      }
      if (selectedProject) {
        data.projectId = selectedProject;
      } else {
        data.projectId = null;
      }
      if (editInvoice) {
        data.invoiceType = editInvoice?.invoiceType;
        updateTags(editInvoice?.id, selectedTags);
        const updatedInvoice = await updateInvoice(data, editInvoice?.id);
        await getData({ type: editInvoice?.invoiceType });
        if (isDraft) {
          toast.success(`${type} saved as Draft`);
        } else {
          toast.success(`${type} updated successfully`);
        }
        setEditInvoice(updatedInvoice?.data?.updatedInvoice);
        setLocalInvoice(updatedInvoice?.data?.updatedInvoice);
        setIsPreview(true);
      } else {
        const res = await createInvoice(data);
        if (selectedTags?.length > 0 && res?.data?.invoice?.id) {
          updateTags(res?.data?.invoice?.id, selectedTags);
        }
        if (res?.data?.invoice?.id && attachFiles?.length > 0) {
          await Promise.all(
            attachFiles?.map((file) =>
              handleInvoiceAttach(
                res?.data?.invoice?.id || editInvoice?.id,
                file,
                false,
              ),
            ),
          );
          getInvoiceByIdApi(res?.data?.invoice?.id, false);
        }
        setActiveTab(type === 'Estimate' ? ESTIMATES_TAB : INVOICES_TAB);
        onSetActiveTab(type === 'Estimate' ? ESTIMATES_TAB : INVOICES_TAB);
        getData({ type: type?.toLowerCase() });
        if (isDraft) {
          toast.success(`${type} saved as Draft`);
        } else {
          toast.success(`${type} created successfully`);
        }
        if (!isDraft) {
          if (team?.stripeAccountId && parseInt(team?.onboardingCount) === 0) {
            setSendInvoiceModalOpen(res?.data?.invoice);
          } else if (
            team?.country === 'US' ||
            team?.country === 'CA' ||
            team?.country === 'NZ'
          ) {
            if (type !== 'Estimate' && !IsPerson) {
              setPaymentModalOpen(res?.data?.invoice);
            } else {
              setSendInvoiceModalOpen(res?.data?.invoice);
            }
          } else {
            setSendInvoiceModalOpen(res?.data?.invoice);
          }
        }
      }
      if (!editInvoice) {
        setIsActive('');
      }
      if (isDraft) {
        setDraftLoading(false);
      } else {
        setLoading(false);
      }
      // console.log("response", res);
    } catch (e) {
      if (isDraft) {
        setDraftLoading(false);
      } else {
        setLoading(false);
      }
      console.log('error', e);
    }
  };

  const onColorFontSave = async () => {
    setFontColorLoader(true);
    try {
      const data = {
        color: selectedColor?.replace('#', ''),
        font: fontFamily,
      };
      if (editInvoice) {
        await updateInvoice(data, editInvoice?.id);
        getData({ type: editInvoice?.invoiceType });
        if (team?.id) {
          const res = await getTeamById(team?.id);
          dispatch(setTeam(res));
        }
        setInvoicePanelOpen(false);
      }
      setFontColorLoader(false);
    } catch (e) {
      setFontColorLoader(false);
      console.log('error', e);
    }
  };

  useEffect(() => {
    if (type === 'Invoice' && !editInvoice?.id) {
      if (paymentTerm > 0) {
        setInvoiceDueDate(
          formatDateLocally(
            moment(invoiceDate, getMomentDateFormat(teamDateFormat))
              .add(paymentTerm, 'days')
              .format('YYYY-MM-DD'),
            teamDateFormat,
          ),
        );
      } else if (paymentTerm === -1) {
        setInvoiceDueDate(null);
      } else {
        setInvoiceDueDate(invoiceDate);
      }
    }
  }, [paymentTerm, invoiceDate]);

  const getSaveButtonTooltip = () => {
    const isNewInvoice = type === 'Invoice' && !editInvoice?.id;
    let str = '';
    if (!selectedCustomer) {
      str = `Customer required for ${type}`;
    } else if (selectedProducts?.length === 0) {
      str = `${type} needs at least one Product or Service.`;
    } else if (!invoiceNumber) {
      str = `${type} number is required`;
    } else if (invoiceNumber?.length > 25) {
      str = `${type} number can be 25 characters long`;
    } else if (!invoiceDueDate) {
      str =
        type === 'Invoice'
          ? `${type} Due Date is require`
          : 'Estimate Valid Until Date is required';
    } else if (
      isNewInvoice &&
      isRecurring &&
      endRecurringType === 'after' &&
      !occurrenceCount
    ) {
      str = `Number of occurrences is required`;
    } else if (
      isNewInvoice &&
      isRecurring &&
      endRecurringType === 'after' &&
      occurrenceCount < 1
    ) {
      str = 'Number of occurrences should be greater than 0';
    } else if (
      isNewInvoice &&
      isRecurring &&
      endRecurringType === 'by' &&
      !recurrenceEndDate
    ) {
      str = 'Recurrence End Date is required';
    }
    return str;
  };

  useEffect(() => {
    if (selectedCustomer?.id) {
      setPaymentTerm(selectedCustomer?.paymentTerm || 0);
    } else {
      setPaymentTerm(0);
    }
  }, [selectedCustomer]);

  return (
    <>
      {fontFamily && <FontLoader font={fontFamily?.replace(/\s/g, '+')} />}
      <TeamInfoModal
        isOpen={teamInfoModalOpen}
        setIsOpen={setTeamInfoModalOpen}
        team={team}
        fetchTeam={fetchTeam}
      />
      <AddCustomerModal
        isOpen={customerModalOpen}
        setIsOpen={setCustomerModalOpen}
        refreshCustomers={getCustomersApi}
        setSelectedCustomer={setSelectedCustomer}
        activeCustomer={selectedCustomer}
        salesRep={salesRep}
      />
      <ReceiptModal
        isOpen={receiptModalOpen}
        setIsOpen={setReceiptModalOpen}
        receipt={activeReceipt?.documentUrl || activeReceipt?.preview}
        isExpenseReporting
        type={activeReceipt?.type}
        title={activeReceipt?.title || activeReceipt?.name}
      />
      <ConvertEstimateModal
        isOpen={convertEstimateModalOpen}
        setIsOpen={setConvertEstimateModalOpen}
        activeEstimate={activeConvertEstimate}
        getData={getData}
        navigateToInvoices
        setIsActive={setIsActive}
        projects={projects}
      />
      {/* <EditBusinessModal
        isOpen={editBusinessModalOpen}
        setIsOpen={setEditBusinessModalOpen}
        team={team}
        countries={countries}
      /> */}
      <WorkspaceLogoModal
        isOpen={editBusinessModalOpen}
        setIsOpen={setEditBusinessModalOpen}
        team={team}
        fetchTeam={fetchTeam}
      />
      <InvoicePanel
        invoicePanelOpen={invoicePanelOpen}
        setInvoicePanelOpen={setInvoicePanelOpen}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
        imgPreview={imgPreview}
        setImgPreview={setImgPreview}
        fontFamily={fontFamily}
        setFontFamily={setFontFamily}
        files={files}
        setFiles={setFiles}
        type={type}
        editInvoice={editInvoice}
        onColorFontSave={onColorFontSave}
        fontColorLoader={fontColorLoader}
        handleInvoiceAttach={handleInvoiceAttach}
        attachFiles={attachFiles}
        setAttachFiles={setAttachFiles}
        attachLoader={attachLoader}
        setAttachLoader={setAttachLoader}
        getInvoiceById={getInvoiceByIdApi}
        setReceiptModalOpen={setReceiptModalOpen}
        setActiveReceipt={setActiveReceipt}
        accessType={accessType}
        receiptModalOpen={receiptModalOpen}
        defaultAttachments={defaultAttachments}
      />

      <div className=" pt-12 pb-16 ">
        {loader ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <div className=" px-5">
            <LiveInvoiceDownload
              IsPerson={IsPerson}
              attachFiles={attachFiles}
              editInvoice={editInvoice}
              invoiceDate={invoiceDate}
              psoNumber={psoNumber}
              invoiceNumber={invoiceNumber}
              getCountryName={getCountryName}
              title={title}
              type={type}
              customers={customers}
              team={team}
              currencies={currencies}
              projects={projects}
              taxes={taxes}
              setTaxes={setTaxes}
              defaultAttachments={defaultAttachments}
              salesRep={salesRep}
              tags={tags}
              selectedColor={selectedColor}
              setCustomerModalOpen={setCustomerModalOpen}
              fontFamily={fontFamily}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              setInvoiceSalesRep={setInvoiceSalesRep}
              products={[...inActiveProducts, ...products]}
              setSelectedProducts={setSelectedProducts}
              selectedProducts={selectedProducts}
              refreshProducts={getProductsApi}
              discount={discount}
              setDiscount={setDiscount}
              discountDescription={discountDescription}
              setDiscountDescription={setDiscountDescription}
              categories={categories}
              expenseCategories={expenseCategories}
              isDiscountAdded={isDiscountAdded}
              setIsDiscountAdded={setIsDiscountAdded}
              checkProductRows={checkProductRows}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              selectedTax={selectedTax}
              setSelectedTax={setSelectedTax}
              getTaxById={getTaxById}
              summary={summary}
              setSummary={setSummary}
              setInvoicePanelOpen={setInvoicePanelOpen}
              setReceiptModalOpen={setReceiptModalOpen}
              setActiveReceipt={setActiveReceipt}
              invoiceSalesRep={invoiceSalesRep}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              discountError={discountError}
              setDiscountError={setDiscountError}
              isRecurring={isRecurring}
              setIsRecurring={setIsRecurring}
              recurrencePattern={recurrencePattern}
              setRecurrencePattern={setRecurrencePattern}
              occurrenceCount={occurrenceCount}
              setOccurrenceCount={setOccurrenceCount}
              recurrenceEndDate={recurrenceEndDate}
              setRecurrenceEndDate={setRecurrenceEndDate}
              RecurrencePatternOptions={RecurrencePatternOptions}
              inAdvanceCreationDays={inAdvanceCreationDays}
              setInAdvanceCreationDays={setInAdvanceCreationDays}
              emailCustomer={emailCustomer}
              setEmailCustomer={setEmailCustomer}
              endRecurringType={endRecurringType}
              setEndRecurringType={setEndRecurringType}
              invoiceDueDate={invoiceDueDate}
              productsPriceType={productsPriceType}
              INVOICE_TAX_TYPES_OPTIONS={INVOICE_TAX_TYPES_OPTIONS}
            />
            <div className="w-[1600px] max-w-full mx-auto">
              {isPreview && editInvoice?.id && (
                <div className="pb-5">
                  <button
                    onClick={() => {
                      if (From) {
                        navigate(-1);
                      } else {
                        setIsAssignedTransTab(false);
                        setIsActive('');
                        setEditInvoice(null);
                        setLocalInvoice(null);
                        setSearchParams({
                          tab: type === 'Invoice' ? 'invoices' : 'estimates',
                        });
                      }
                    }}
                    type="button"
                  >
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="32"
                        height="33"
                        transform="translate(33) rotate(90)"
                        fill="#FBF3ED"
                      />
                      <path
                        d="M19 10.5L13 16.5L19 22.5"
                        stroke="#E48642"
                        strokeWidth="3.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>

                  {editInvoice?.invoiceType === 'invoice' && (
                    <div className="flex border-b-[2px] border-[#D0D5DD] gap-x-3 sm:gap-8 mb-0 flex-wrap">
                      <div
                        onClick={() => setIsAssignedTransTab(false)}
                        className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    !isAssignedTransTab &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                        role="button"
                        tabIndex="0"
                      >
                        View {type?.replace('s', '')}
                      </div>
                      {!editInvoice?.isDraft && (
                        <div
                          onClick={() => setIsAssignedTransTab(true)}
                          className={`text-sm border-b-[3px] translate-y-[2px] border-transparent  cursor-pointer text-[#667085] pb-3
                  ${
                    isAssignedTransTab &&
                    ' !border-indigo-500  font-medium !text-[#101828]'
                  } `}
                          role="button"
                          tabIndex="0"
                        >
                          Assigned Transactions
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              {!isAssignedTransTab && isPreview && editInvoice?.id && (
                <>
                  {/* Header  */}
                  <header
                    className={`flex md:flex-row flex-col gap-6 mb-10 ${editInvoice?.invoiceType === 'invoice' ? 'mt-6' : ''}`}
                  >
                    <h3 className="text-[36px] font-medium text-[#666666] leading-tight">
                      {type} Overview
                    </h3>
                    <div className="flex-1 flex flex-wrap md:justify-end gap-4">
                      {checkPermission(
                        PermissionKeys.MANAGE_INVOICES,
                        accessType,
                        permissions,
                      ) &&
                        editInvoice?.paymentStatus !== 'paid' &&
                        editInvoice?.paymentStatus !== 'accepted' &&
                        !editInvoice?.isDraft && (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                team?.country === 'US' ||
                                team?.country === 'CA' ||
                                team?.country === 'NZ'
                              ) {
                                if (team?.stripeAccountId || IsPerson) {
                                  sendEmailAction(editInvoice);
                                } else {
                                  setPaymentModalOpen(editInvoice);
                                }
                              } else {
                                sendEmailAction(editInvoice);
                              }
                            }}
                            className="h-11 px-[18px] bg-indigo-500 text-white rounded-[5px] border border-indigo-500 text-base "
                          >
                            {`Send ${editInvoice?.lastSendDate ? 'Reminder' : editInvoice?.invoiceType === 'invoice' ? 'Invoice' : 'Estimate'}`}{' '}
                          </button>
                        )}
                      {checkPermission(
                        PermissionKeys.APPROVE_INVOICES,
                        accessType,
                        permissions,
                      ) &&
                        !IsPerson &&
                        editInvoice?.paymentStatus !== 'paid' &&
                        !editInvoice?.isDraft &&
                        editInvoice?.invoiceType !== 'estimate' && (
                          <button
                            type="button"
                            className="h-11 px-[18px] bg-indigo-500 text-white rounded-[5px] border border-indigo-500 text-base "
                            onClick={() => {
                              setTransactionsModalOpen(editInvoice);
                            }}
                          >
                            Record Payment
                          </button>
                        )}
                      {checkPermission(
                        PermissionKeys.APPROVE_INVOICES,
                        accessType,
                        permissions,
                      ) &&
                        !IsPerson &&
                        editInvoice?.paymentStatus !== 'paid' &&
                        !editInvoice?.isDraft &&
                        editInvoice?.invoiceType === 'estimate' && (
                          <button
                            type="button"
                            className="h-11 px-[18px] bg-indigo-500 text-white rounded-[5px] border border-indigo-500 text-base "
                            onClick={() => {
                              setActiveConvertEstimate(editInvoice);
                              setConvertEstimateModalOpen(true);
                            }}
                          >
                            Convert To Invoice
                          </button>
                        )}
                      {checkPermission(
                        PermissionKeys.MANAGE_INVOICES,
                        accessType,
                        permissions,
                      ) &&
                        editInvoice?.isDraft &&
                        checkPermission(
                          PermissionKeys.APPROVE_INVOICES,
                          accessType,
                          permissions,
                        ) && (
                          <button
                            type="button"
                            className="h-11 inline-flex items-center justify-center px-[18px] text-indigo-500 rounded-[5px] border border-indigo-500 text-base disabled:cursor-not-allowed disabled:opacity-70"
                            onClick={() => onSubmit({ isDraft: false })}
                            disabled={loading}
                          >
                            {loading && (
                              <svg
                                className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                              </svg>
                            )}
                            Approve {type}
                          </button>
                        )}
                      {(checkPermission(
                        PermissionKeys.APPROVE_INVOICES,
                        accessType,
                        permissions,
                      ) ||
                        (checkPermission(
                          PermissionKeys.MANAGE_INVOICES,
                          accessType,
                          permissions,
                        ) &&
                          editInvoice?.isDraft)) &&
                        (editInvoice?.isDraft ||
                          !editInvoice ||
                          (editInvoice?.id &&
                            editInvoice?.paymentStatus !== 'paid' &&
                            !(
                              editInvoice?.invoiceType === 'estimate' &&
                              editInvoice?.paymentStatus === 'accepted'
                            ))) && (
                          <button
                            type="button"
                            className="h-11 px-[18px] text-indigo-500 rounded-[5px] border border-indigo-500 text-base "
                            onClick={() => setIsPreview(!isPreview)}
                          >
                            Edit {type}
                          </button>
                        )}
                      {checkPermission(
                        PermissionKeys.MANAGE_INVOICES,
                        accessType,
                        permissions,
                      ) &&
                        (editInvoice?.isDraft ||
                          !editInvoice ||
                          (editInvoice?.id &&
                            editInvoice?.paymentStatus !== 'paid' &&
                            !(
                              editInvoice?.invoiceType === 'estimate' &&
                              editInvoice?.paymentStatus === 'accepted'
                            ))) && (
                          <button
                            type="button"
                            className="h-11 px-[18px] text-indigo-500 rounded-[5px] border border-indigo-500 text-base "
                            onClick={() => {
                              setInvoicePanelOpen('customize');
                              // setIsPreview(true);
                            }}
                          >
                            Customize Design
                          </button>
                        )}
                      {!editInvoice?.isDraft && (
                        <Link
                          to={`/public/invoice-estimate/${editInvoice?.publicToken || editInvoice?.id}`}
                          target="_blank"
                        >
                          <button
                            type="button"
                            className="h-11 px-[18px] text-indigo-500 rounded-[5px] border border-indigo-500 text-base "
                          >
                            View PDF
                          </button>
                        </Link>
                      )}

                      <button
                        type="button"
                        onClick={() =>
                          downloadPdf(
                            'invoiceCreatePrint',
                            `${invoiceNumber}-${title}.pdf`,
                          )
                        }
                        className="h-11 w-11 inline-flex justify-center items-center text-indigo-500 rounded-[5px] border border-indigo-500 text-base "
                      >
                        <DownloadIcon />
                      </button>
                    </div>
                  </header>

                  {/* Summary Box  */}
                  <div className="grid grid-cols-2 xl:grid-cols-5 divide-x p-6 divide-[#D0D5DD]  mb-7 border border-[#D0D5DD] rounded-[12px]">
                    <div className="divide-y divide-[#D0D5DD]">
                      <div className="p-6 pl-0 flex h-[130px] justify-between">
                        <div className="h-full flex flex-col justify-between">
                          <h3 className="text-base font-medium text-[#667085]">
                            {type} Status
                          </h3>
                          <Tooltip
                            className="w-fit"
                            position="right"
                            contentClassName="border-none rounded-[10px] overflow-visible font-normal text-sm text-[#667085] relative tooltipArrowLeft"
                            content={
                              <p onClick={(e) => e.stopPropagation()}>
                                {invoiceNumber}
                              </p>
                            }
                            onClick={(e) => e.stopPropagation()}
                            tooltipShow={invoiceNumber?.length > 8}
                          >
                            <div className="w-fit text-base font-semibold text-[#101828]">
                              {invoiceNumber?.length > 8
                                ? trimText(invoiceNumber, 8)
                                : /^\d+$/.test(invoiceNumber)
                                  ? invoiceNumber?.toString()?.padStart(4, '0')
                                  : invoiceNumber}
                            </div>
                          </Tooltip>
                        </div>
                        <div className="h-full flex flex-col justify-end">
                          <div
                            className={` capitalize h-fit min-h-[30px]  px-2.5 py-1 w-fit rounded-md  text-sm font-medium
                      ${getInvoiceStatusColor(
                        editInvoice?.isDraft
                          ? 'Draft'
                          : editInvoice?.paymentStatus,
                      )}
                    `}
                          >
                            {editInvoice?.isDraft
                              ? 'Draft'
                              : editInvoice?.paymentStatus === 'unpaid'
                                ? 'Approved'
                                : editInvoice?.paymentStatus}
                          </div>
                        </div>
                      </div>
                      <div className="p-6 pl-0 xl:border-y-0 xl:!border-b-0 !border-b border-[#D0D5DD]">
                        <h3 className="text-base font-medium text-[#667085] mb-7">
                          {type} Date
                        </h3>
                        <div className="ml-auto w-fit text-base font-semibold text-[#101828]">
                          {invoiceDate
                            ? moment(
                                invoiceDate,
                                getMomentDateFormat(teamDateFormat),
                              ).format('MMM DD, yyyy')
                            : '--'}
                        </div>
                      </div>
                    </div>

                    <div className="divide-y divide-[#D0D5DD]">
                      <div className="p-6 pl-10 flex flex-col h-[130px] justify-between">
                        <h3 className="text-base font-medium text-[#667085]">
                          Send {type}
                        </h3>
                        <div className="ml-auto w-fit min-h-[30px] text-base text-right font-semibold ">
                          {editInvoice?.lastSendDate ? (
                            <>
                              {' '}
                              <span className="text-[#101828] mr-1">
                                Shared on{' '}
                              </span>
                              <span className="text-[#101828] sm:whitespace-nowrap">
                                {moment(editInvoice?.lastSendDate)
                                  .local()
                                  .format('MMM DD, YYYY')}
                              </span>
                              {false && editInvoice?.sentData?.length > 0 && (
                                <SendHistoryBox data={editInvoice?.sentData} />
                              )}
                            </>
                          ) : checkPermission(
                              PermissionKeys.MANAGE_INVOICES,
                              accessType,
                              permissions,
                            ) &&
                            editInvoice?.paymentStatus !== 'paid' &&
                            editInvoice?.paymentStatus !== 'accepted' &&
                            !editInvoice?.isDraft ? (
                            <div>
                              <span className="text-base text-[#667085] mr-1">
                                Never:
                              </span>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    team?.country === 'US' ||
                                    team?.country === 'CA' ||
                                    team?.country === 'NZ'
                                  ) {
                                    if (team?.stripeAccountId || IsPerson) {
                                      sendEmailAction(editInvoice);
                                    } else {
                                      setPaymentModalOpen(editInvoice);
                                    }
                                  } else {
                                    sendEmailAction(editInvoice);
                                  }
                                }}
                                className=" text-indigo-500 hover:underline cursor-pointer "
                              >
                                {`Send Now`}{' '}
                                {/* ${editInvoice?.invoiceType === 'invoice' ? 'Invoice' : 'Estimate'} */}
                              </button>
                            </div>
                          ) : (
                            <span>--</span>
                          )}
                        </div>
                      </div>
                      <div className="p-6 pl-10 xl:border-t-0 xl:!border-b-0 !border-b border-[#D0D5DD]">
                        <h3 className="text-base font-medium text-[#667085] mb-7">
                          {type === 'Invoice'
                            ? `${type} Due Date`
                            : 'Estimate Valid Until'}
                        </h3>
                        <div className="ml-auto w-fit text-base font-semibold text-[#101828]">
                          {invoiceDueDate
                            ? moment(
                                invoiceDueDate,
                                getMomentDateFormat(teamDateFormat),
                              ).format('MMM DD, yyyy')
                            : '--'}
                        </div>
                      </div>
                    </div>

                    <div className="divide-y divide-[#D0D5DD] xl:!border-l-[1px] !border-l-0">
                      <div className="p-6 xl:pl-10 pl-0">
                        <h3
                          className="text-base font-medium text-[#667085] mb-7"
                          id="customerBoxId"
                        >
                          Customer
                        </h3>
                        <div className="ml-auto min-h-[30px] w-fit text-base font-semibold text-indigo-500">
                          {editInvoice?.customer?.id ? (
                            <Tooltip
                              tooltipShow={
                                editInvoice?.customer?.customer?.length >
                                customerCharLength
                              }
                              content={
                                editInvoice?.customer?.customer?.length > 20
                                  ? (() => {
                                      const segments = getStringSegments(
                                        editInvoice?.customer?.customer,
                                        25,
                                      );
                                      return segments.map((segment, index) => (
                                        <p key={index}>{segment}</p>
                                      ));
                                    })()
                                  : editInvoice?.customer?.customer
                              }
                              className="w-fit ml-auto"
                              contentClassName="border-none font-normal rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            >
                              <span
                                className="cursor-pointer hover:underline"
                                onClick={() =>
                                  navigate(
                                    `/customer/${editInvoice?.customer?.id}`,
                                  )
                                }
                                role="button"
                                tabIndex="0"
                              >
                                {editInvoice?.customer?.customer?.length >
                                customerCharLength
                                  ? trimText(
                                      editInvoice?.customer?.customer,
                                      customerCharLength,
                                    )
                                  : editInvoice?.customer?.customer}
                              </span>
                            </Tooltip>
                          ) : (
                            '--'
                          )}
                        </div>
                      </div>
                      <div className="p-6 xl:pl-10 pl-0 xl:border-t-0 xl:!border-b-0 !border-b border-[#D0D5DD] flex flex-col gap-2 justify-between">
                        <div
                          className={`${editInvoice?.salesRepId ? '' : 'mb-5'}`}
                        >
                          <h3 className="text-base font-medium text-[#667085] ">
                            {type} Total
                          </h3>
                          {editInvoice?.salesRepId && (
                            <div className=" w-fit text-sm font-medium text-indigo-500 flex gap-1">
                              <span className="text-[#667085]">Sales Rep:</span>
                              <Tooltip
                                tooltipShow={
                                  invoiceSalesRepObj?.name?.length >
                                  salesRepCharLength
                                }
                                content={
                                  invoiceSalesRepObj?.name?.length > 20
                                    ? (() => {
                                        const segments = getStringSegments(
                                          invoiceSalesRepObj?.name,
                                          25,
                                        );
                                        return segments.map(
                                          (segment, index) => (
                                            <p key={index}>{segment}</p>
                                          ),
                                        );
                                      })()
                                    : invoiceSalesRepObj?.name
                                }
                                className="w-fit ml-auto"
                                contentClassName="border-none font-normal rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                              >
                                <span
                                  className="cursor-pointer hover:underline"
                                  onClick={() =>
                                    navigate(
                                      `/people/${invoiceSalesRepObj?.id}`,
                                    )
                                  }
                                  role="button"
                                  tabIndex="0"
                                >
                                  {invoiceSalesRepObj?.name?.length >
                                  salesRepCharLength
                                    ? trimText(
                                        invoiceSalesRepObj?.name,
                                        salesRepCharLength,
                                      )
                                    : invoiceSalesRepObj?.name}
                                </span>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                        <div className="ml-auto w-fit text-base font-semibold text-[#101828]">
                          {getCurrencySymbolFromIso(
                            editInvoice?.currency,
                            currencies,
                          )}
                          {parseFloat(editInvoice?.total ?? 0)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="divide-y divide-[#D0D5DD]">
                      <div className="p-6 pl-10">
                        <h3
                          className="text-base font-medium text-[#667085] mb-7"
                          id="projectBoxId"
                        >
                          Project
                        </h3>
                        <div className="text-right w-fit ml-auto min-h-[30px] text-base font-semibold text-indigo-500">
                          {editInvoice?.projectId ? (
                            <Tooltip
                              tooltipShow={
                                selectedProjectObj?.name?.length >
                                projectCharLength
                              }
                              content={
                                selectedProjectObj?.name?.length > 20
                                  ? (() => {
                                      const segments = getStringSegments(
                                        selectedProjectObj?.name,
                                        25,
                                      );
                                      return segments.map((segment, index) => (
                                        <p key={index}>{segment}</p>
                                      ));
                                    })()
                                  : selectedProjectObj?.name
                              }
                              className=""
                              contentClassName="border-none font-normal rounded-[10px] overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                            >
                              <span
                                className="cursor-pointer hover:underline"
                                onClick={() =>
                                  navigate(
                                    `/projects/${selectedProjectObj?.id}?tab=overview`,
                                  )
                                }
                                role="button"
                                tabIndex="0"
                              >
                                {selectedProjectObj?.name?.length >
                                projectCharLength
                                  ? trimText(
                                      selectedProjectObj?.name,
                                      projectCharLength,
                                    )
                                  : selectedProjectObj?.name}
                              </span>
                            </Tooltip>
                          ) : (
                            '--'
                          )}
                        </div>
                      </div>
                      <div className="p-6 pl-10 xl:border-t-0 xl:!border-b-0 !border-b border-[#D0D5DD]">
                        <h3 className="text-base font-medium text-[#667085] mb-7">
                          Amount Paid
                        </h3>
                        <div className="ml-auto w-fit text-base font-semibold text-[#101828]">
                          {getCurrencySymbolFromIso(
                            editInvoice?.currency,
                            currencies,
                          )}
                          {parseFloat(
                            +editInvoice?.total - +editInvoice?.amountDue ?? 0,
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="divide-y divide-[#D0D5DD] xl:!border-l-[1px] !border-l-0">
                      <div className="p-6 xl:pl-10 pl-0">
                        <h3
                          className="text-base font-medium text-[#667085] mb-7"
                          id="tagsBoxId"
                        >
                          Tags
                        </h3>
                        {editInvoice?.tags?.length > 0 ? (
                          <TagsBoard
                            tags={editInvoice?.tags}
                            tagsCharLength={tagsCharLength}
                          />
                        ) : (
                          <div className="ml-auto min-h-[30px] w-fit text-base font-semibold text-[#101828]">
                            --
                          </div>
                        )}
                      </div>
                      <div className="p-6 xl:pl-10 pl-0 hidden xl:block">
                        <h3 className="text-base font-medium text-[#667085] mb-7">
                          Outstanding Balance{' '}
                        </h3>
                        <div className="ml-auto w-fit text-base font-semibold text-[#101828]">
                          {getCurrencySymbolFromIso(
                            editInvoice?.currency,
                            currencies,
                          )}
                          {parseFloat(
                            editInvoice?.amountDue ?? 0,
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="divide-y divide-[#D0D5DD] xl:hidden">
                      <div className="p-6 pl-10 ">
                        <h3 className="text-base font-medium text-[#667085] mb-7">
                          Outstanding Balance{' '}
                        </h3>
                        <div className="ml-auto w-fit text-base font-semibold text-[#101828]">
                          {getCurrencySymbolFromIso(
                            editInvoice?.currency,
                            currencies,
                          )}
                          {parseFloat(
                            editInvoice?.amountDue ?? 0,
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="xxl:w-[1380px] w-[1100px] max-w-full mx-auto">
              {/* Assigned transactions tab  */}
              {isPreview && isAssignedTransTab && (
                <AssignedTransactions
                  invoice={editInvoice}
                  setTransactionsModalOpen={setTransactionsModalOpen}
                  getInvoiceByIdApi={getInvoiceByIdApi}
                  accessType={accessType}
                  getData={getData}
                  currencies={currencies}
                />
              )}
              {/* Header for Invoice Title in Edit Mode  */}
              {!isAssignedTransTab && (
                <header className="flex justify-between items-center  mb-6">
                  {!isPreview && (
                    <div className="flex gap-5">
                      <button
                        onClick={() => {
                          if (From) {
                            navigate(-1);
                          } else {
                            setIsAssignedTransTab(false);
                            setIsActive('');
                            setEditInvoice(null);
                            setLocalInvoice(null);
                            setSearchParams({
                              tab:
                                type === 'Invoice' ? 'invoices' : 'estimates',
                            });
                          }
                        }}
                        type="button"
                      >
                        <svg
                          width="33"
                          height="32"
                          viewBox="0 0 33 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="32"
                            height="33"
                            transform="translate(33) rotate(90)"
                            fill="#FBF3ED"
                          />
                          <path
                            d="M19 10.5L13 16.5L19 22.5"
                            stroke="#E48642"
                            strokeWidth="3.6"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <h3 className="text-[36px] font-medium text-[#00000099] leading-tight capitalize">
                        {isPreview
                          ? `View An ${type}`
                          : editInvoice?.id
                            ? `Updating An ${type}`
                            : `Creating An ${type}`}
                      </h3>
                    </div>
                  )}
                  {checkPermission(
                    PermissionKeys.MANAGE_INVOICES,
                    accessType,
                    permissions,
                  ) && (
                    <div className="flex items-center gap-4 ml-auto">
                      {/* {!isPreview && (
                      <button
                        onClick={() => setIsPreview(!isPreview)}
                        className="h-11 px-4 border border-indigo-500  bg-indigo-500 text-white rounded-[5px]"
                      >
                        {isPreview ? "Edit" : "Preview"}
                      </button>
                    )} */}
                    </div>
                  )}
                </header>
              )}

              {/* Here from Invoice Box start  */}
              {/* Content  */}
              {!isAssignedTransTab && (
                <div className="rounded-[10px] shadow-button">
                  {/* Section 1  */}
                  <div className="p-6 grid gap-4 grid-cols-12 border-b border-[#D0D5DD]">
                    <div className="col-span-full md:col-span-6 space-y-2.5">
                      {!isPreview ? (
                        <div className="flex  gap-2.5">
                          <label className=" mt-[14px] text-sm font-medium text-[#667085] text-nowrap capitalize-first">
                            {type} title
                          </label>
                          <TextInputDash
                            paddingBottom="pb-0"
                            placeholder={`${type} Title`}
                            width="flex-1"
                            // inputClass="placeholder:italic"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      ) : (
                        <div
                          style={
                            isPreview && fontFamily
                              ? { fontFamily: fontFamily || '' }
                              : {}
                          }
                        >
                          {/* {imgPreview && isPreview && ( */}
                          {/* {team?.logoUrl && isPreview && (
                        <img
                          src={team?.logoUrl}
                          alt="logo"
                          className="h-[52px] w-[52px] rounded-full mb-5"
                        />
                      )} */}
                          <h3
                            style={
                              selectedColor && isPreview
                                ? { color: selectedColor }
                                : {}
                            }
                            className="text-[20px] font-medium leading-tight"
                          >
                            {title}
                          </h3>
                          {/* <p>{summary}</p> */}
                        </div>
                      )}
                    </div>
                    <div className="col-span-full md:col-span-6 md:text-right flex flex-col justify-end h-full">
                      {team?.logoUrl && (
                        <img
                          src={team?.logoUrl}
                          alt="logo"
                          className="h-[100px] w-[100px] rounded-full mb-1 ml-auto"
                        />
                      )}
                      <h3
                        style={
                          selectedColor && isPreview
                            ? {
                                color: selectedColor,
                                fontFamily: fontFamily || '',
                              }
                            : {}
                        }
                        className="mb-1 text-sm leading-tight font-medium text-[#667085]"
                      >
                        {team?.name}
                      </h3>
                      {team?.addressId && (
                        <div
                          className="text-sm text-[#667085CC] leading-tight space-y-1 mt-1 mb-1"
                          style={
                            isPreview && fontFamily
                              ? { fontFamily: fontFamily || '' }
                              : {}
                          }
                        >
                          <p>
                            {team?.businessAddress?.street}{' '}
                            {team?.businessAddress?.street2
                              ? `, ${team?.businessAddress?.street2}`
                              : ''}
                          </p>
                          <p>
                            {team?.businessAddress?.city}
                            {team?.businessAddress?.city && ','} {team?.state}{' '}
                            {team?.businessAddress?.zipCode}
                          </p>
                          <p>{getCountryName() || ''}</p>
                        </div>
                      )}
                      {team?.businessTaxNumber && (
                        <p
                          style={
                            isPreview && fontFamily
                              ? { fontFamily: fontFamily || '' }
                              : {}
                          }
                          className="my-1 mt-4 text-sm text-[#667085CC] leading-tight"
                        >
                          Tax Number: {team?.businessTaxNumber}
                        </p>
                      )}
                      <p
                        style={
                          isPreview && fontFamily
                            ? { fontFamily: fontFamily || '' }
                            : {}
                        }
                        className={`${team?.businessTaxNumber ? 'my-1' : 'mt-4 my-1'} text-sm text-[#667085CC] leading-tight`}
                      >
                        {team?.email}
                      </p>
                      <p
                        style={
                          isPreview && fontFamily
                            ? { fontFamily: fontFamily || '' }
                            : {}
                        }
                        className="my-1 text-sm text-[#667085CC] leading-tight"
                      >
                        {team?.phone}
                      </p>
                      {/* <p
                  className={`text-sm text-[#667085CC] leading-tight ${
                    fontFamily ? fontFamily : ""
                  }`}
                >
                  Email Address
                </p> */}
                      {!IsPerson && !isPreview && (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setTeamInfoModalOpen(true);
                          }}
                          className="cursor-pointer ml-auto w-fit text-indigo-500 underline mt-0 md:text-right text-sm"
                          role="button"
                          tabIndex="0"
                        >
                          Update Business Details
                        </div>
                      )}
                      {!IsPerson && !isPreview && (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setEditBusinessModalOpen(true);
                          }}
                          className="text-indigo-500 w-fit  ml-auto underline mt-0 md:text-right text-sm"
                          type="button"
                        >
                          {team?.logoUrl ? 'Edit ' : 'Add '}
                          Business Logo
                        </button>
                      )}
                    </div>
                  </div>
                  {/* Section 2  */}
                  <div className="p-6 grid gap-4 grid-cols-12 justify-between">
                    <div className="flex flex-col justify-between gap-4 col-span-full md:col-span-6 ">
                      <CustomersBox
                        isPreview={isPreview}
                        selectedColor={selectedColor}
                        setCustomerModalOpen={setCustomerModalOpen}
                        fontFamily={fontFamily}
                        customers={customers}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                        setInvoiceSalesRep={setInvoiceSalesRep}
                        selectedProject={selectedProject}
                        invoiceCustomer={
                          editInvoice?.customer?.id ? editInvoice?.customer : {}
                        }
                      />
                      <div className="flex  gap-2.5  md:justify-start justify-between">
                        <label
                          style={
                            isPreview
                              ? {
                                  color: selectedColor,
                                  marginTop: '0',
                                  fontFamily: fontFamily || '',
                                }
                              : {}
                          }
                          className="mt-[14px] text-sm font-medium text-[#667085] text-nowrap"
                        >
                          Amounts are
                        </label>
                        {!isPreview ? (
                          <div className="flex flex-col gap-4">
                            <DropdownFull
                              options={
                                team?.country === 'NZ'
                                  ? [
                                      ...INVOICE_TAX_TYPES_OPTIONS,
                                      {
                                        id: 'zero-rated',
                                        name: 'Zero Rated Tax',
                                      },
                                    ]
                                  : INVOICE_TAX_TYPES_OPTIONS
                              }
                              name="name"
                              selected={productsPriceType}
                              setSelected={(name) => setProductsPriceType(name)}
                              placeholder="Select"
                              scrollIntoView={false}
                              height="!w-[256px] h-12"
                            />
                          </div>
                        ) : (
                          <div
                            className="text-[#667085CC] text-sm text-right"
                            style={
                              isPreview && fontFamily
                                ? { fontFamily: fontFamily || '' }
                                : {}
                            }
                          >
                            {productsPriceType
                              ? (team?.country === 'NZ'
                                  ? [
                                      ...INVOICE_TAX_TYPES_OPTIONS,
                                      {
                                        id: 'zero-rated',
                                        name: 'Zero Rated Tax',
                                      },
                                    ]
                                  : INVOICE_TAX_TYPES_OPTIONS
                                )?.find(
                                  (option) => option.id === productsPriceType,
                                )?.name
                              : ''}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="hidden xxl:block xxl:col-span-2" />
                    <div className="col-span-full md:col-span-6 xxl:col-span-4 space-y-2.5">
                      <div className="flex  gap-2.5 md:justify-end justify-between">
                        <label
                          style={
                            isPreview
                              ? {
                                  color: selectedColor,
                                  marginTop: '0',
                                  fontFamily: fontFamily || '',
                                }
                              : {}
                          }
                          className="mt-[14px] text-sm font-medium text-[#667085] text-nowrap"
                        >
                          {type} Number{' '}
                          {!isPreview && (
                            <span className="text-rose-500">*</span>
                          )}
                        </label>
                        {!isPreview ? (
                          <div className="flex flex-col">
                            <TextInputDash
                              paddingBottom={
                                !invoiceNumberLoading &&
                                (!invoiceNumber ||
                                  invoiceNumber?.length > 25) &&
                                invoiceNumberTouched
                                  ? 'pb-4'
                                  : 'pb-0'
                              }
                              placeholder={`${type} Number`}
                              width="w-[256px]"
                              error={
                                !invoiceNumberLoading &&
                                invoiceNumberTouched &&
                                (!invoiceNumber
                                  ? `${type} number is required`
                                  : invoiceNumber?.length > 25
                                    ? `${type} number can be 25 characters long`
                                    : '')
                              }
                              value={invoiceNumber}
                              onChange={(e) => setInvoiceNumber(e.target.value)}
                              onBlur={() => setInvoiceNumberTouched(true)}
                            />
                            {previousInvoiceNumber && (
                              <div className="text-sm max-w-[256px]">
                                Last #: {previousInvoiceNumber}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div
                            className="text-[#667085CC] text-sm w-[256px] text-right flex-1 md:w-fit"
                            style={
                              isPreview && fontFamily
                                ? { fontFamily: fontFamily || '' }
                                : {}
                            }
                          >
                            {invoiceNumber?.toString()?.padStart(4, '0')}
                          </div>
                        )}
                      </div>
                      {((isPreview && psoNumber) || !isPreview) && (
                        <div className="flex  gap-2.5 md:justify-end justify-between">
                          <label
                            style={
                              isPreview
                                ? {
                                    color: selectedColor,
                                    marginTop: '0',
                                    fontFamily: fontFamily || '',
                                  }
                                : {}
                            }
                            className="mt-[14px] text-sm font-medium text-[#667085] text-nowrap"
                          >
                            P.O./S.O. Number
                          </label>
                          {!isPreview ? (
                            <TextInputDash
                              paddingBottom="pb-0"
                              placeholder="P.O./S.O. Number"
                              width="w-[256px]"
                              // inputClass="placeholder:italic"
                              value={psoNumber}
                              onChange={(e) => setPsoNumber(e.target.value)}
                            />
                          ) : (
                            <div
                              className="text-[#667085CC] text-sm w-[256px] text-right flex-1 md:w-fit"
                              style={
                                isPreview && fontFamily
                                  ? { fontFamily: fontFamily || '' }
                                  : {}
                              }
                            >
                              {psoNumber}
                            </div>
                          )}
                        </div>
                      )}
                      <div className="flex  gap-2.5  md:justify-end justify-between">
                        <label
                          style={
                            isPreview
                              ? {
                                  color: selectedColor,
                                  marginTop: '0',
                                  fontFamily: fontFamily || '',
                                }
                              : {}
                          }
                          className="mt-[14px] text-sm font-medium text-[#667085] text-nowrap"
                        >
                          {type} Date{' '}
                          {!isPreview && (
                            <span className="text-rose-500">*</span>
                          )}
                        </label>
                        {!isPreview ? (
                          <DatePickerDash
                            placeholder={`${type} Date`}
                            value={invoiceDate || null}
                            onChange={(selectedDates) => {
                              setInvoiceDate(selectedDates[0]);
                            }}
                            // options={options}
                            options={
                              invoiceDueDate
                                ? {
                                    ...options,
                                    maxDate: invoiceDueDate,
                                  }
                                : options
                            }
                            // inputClass="placeholder:italic"
                            width="w-[256px]"
                            onBlur={(e) => {
                              if (
                                !e.target.classList?.value?.includes('active')
                              ) {
                                setInvoiceDate(e.target.value);
                              }
                            }}
                          />
                        ) : (
                          <div
                            className="text-[#667085CC] text-sm w-[256px] text-right flex-1 md:w-fit"
                            style={
                              isPreview && fontFamily
                                ? { fontFamily: fontFamily || '' }
                                : {}
                            }
                          >
                            {invoiceDate
                              ? moment(
                                  invoiceDate,
                                  getMomentDateFormat(teamDateFormat),
                                ).format('MMM DD, yyyy')
                              : ''}
                          </div>
                        )}
                      </div>
                      <div className="flex  gap-2.5 md:justify-end justify-between">
                        <label
                          style={
                            isPreview
                              ? {
                                  color: selectedColor,
                                  marginTop: '0',
                                  fontFamily: fontFamily || '',
                                }
                              : {}
                          }
                          className={`mt-[14px] text-sm font-medium text-[#667085] text-nowrap `}
                        >
                          {type === 'Invoice'
                            ? `${type} Due Date`
                            : 'Estimate Valid Until'}{' '}
                          {!isPreview && (
                            <span className="text-rose-500">*</span>
                          )}
                        </label>
                        {!isPreview ? (
                          <div className="flex flex-col gap-4">
                            {type === 'Invoice' && !editInvoice?.id && (
                              <DropdownFull
                                options={[
                                  {
                                    id: 0,
                                    name: 'Due on receipt',
                                  },
                                  {
                                    id: 15,
                                    name: 'Net 15',
                                  },
                                  {
                                    id: 30,
                                    name: 'Net 30',
                                  },
                                  {
                                    id: 60,
                                    name: 'Net 60',
                                  },
                                  {
                                    id: -1,
                                    name: 'Custom',
                                  },
                                ]}
                                name="name"
                                selected={paymentTerm}
                                setSelected={(name) => setPaymentTerm(name)}
                                placeholder="Select"
                                scrollIntoView={false}
                                height="!w-[256px] h-12"
                              />
                            )}
                            <DatePickerDash
                              placeholder={
                                type === 'Invoice'
                                  ? `${type} Due Date`
                                  : 'Estimate Valid Until'
                              }
                              value={invoiceDueDate || null}
                              onChange={(selectedDates) => {
                                setInvoiceDueDate(selectedDates[0]);
                              }}
                              options={
                                invoiceDate
                                  ? {
                                      ...options,
                                      minDate: invoiceDate,
                                    }
                                  : options
                              }
                              // options={options}
                              // inputClass="placeholder:italic"
                              width="w-[256px]"
                              disabled={
                                type === 'Invoice' &&
                                !editInvoice?.id &&
                                paymentTerm !== -1
                              }
                              onBlur={(e) => {
                                if (
                                  !e.target.classList?.value?.includes('active')
                                ) {
                                  setInvoiceDueDate(e.target.value);
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            className="text-[#667085CC] text-sm w-[256px] text-right flex-1 md:w-fit"
                            style={
                              isPreview && fontFamily
                                ? { fontFamily: fontFamily || '' }
                                : {}
                            }
                          >
                            {invoiceDueDate
                              ? moment(
                                  invoiceDueDate,
                                  getMomentDateFormat(teamDateFormat),
                                ).format('MMM DD, yyyy')
                              : ''}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Section 3  */}
                  <div className="">
                    <CreateInvoicesTable
                      isPreview={isPreview}
                      selectedColor={selectedColor}
                      fontFamily={fontFamily}
                      products={[...inActiveProducts, ...products]}
                      setSelectedProducts={setSelectedProducts}
                      selectedProducts={selectedProducts}
                      refreshProducts={getProductsApi}
                      discount={discount}
                      setDiscount={setDiscount}
                      discountDescription={discountDescription}
                      setDiscountDescription={setDiscountDescription}
                      categories={categories}
                      expenseCategories={expenseCategories}
                      isDiscountAdded={isDiscountAdded}
                      setIsDiscountAdded={setIsDiscountAdded}
                      type={type}
                      currencies={currencies}
                      checkProductRows={checkProductRows}
                      projects={projects}
                      selectedProject={selectedProject}
                      setSelectedProject={setSelectedProject}
                      team={team}
                      taxes={taxes}
                      setTaxes={setTaxes}
                      selectedTax={selectedTax}
                      setSelectedTax={setSelectedTax}
                      getTaxById={getTaxById}
                      summary={summary}
                      setSummary={setSummary}
                      setInvoicePanelOpen={setInvoicePanelOpen}
                      attachments={
                        editInvoice?.id
                          ? editInvoice?.attachments
                          : [...defaultAttachments, ...attachFiles]
                      }
                      setReceiptModalOpen={setReceiptModalOpen}
                      setActiveReceipt={setActiveReceipt}
                      invoiceSalesRep={invoiceSalesRep}
                      setInvoiceSalesRep={setInvoiceSalesRep}
                      salesRep={salesRep}
                      tags={tags}
                      selectedTags={selectedTags}
                      setSelectedTags={setSelectedTags}
                      discountError={discountError}
                      setDiscountError={setDiscountError}
                      isRecurring={isRecurring}
                      setIsRecurring={setIsRecurring}
                      recurrencePattern={recurrencePattern}
                      setRecurrencePattern={setRecurrencePattern}
                      occurrenceCount={occurrenceCount}
                      setOccurrenceCount={setOccurrenceCount}
                      recurrenceEndDate={recurrenceEndDate}
                      setRecurrenceEndDate={setRecurrenceEndDate}
                      RecurrencePatternOptions={RecurrencePatternOptions}
                      inAdvanceCreationDays={inAdvanceCreationDays}
                      setInAdvanceCreationDays={setInAdvanceCreationDays}
                      emailCustomer={emailCustomer}
                      setEmailCustomer={setEmailCustomer}
                      endRecurringType={endRecurringType}
                      setEndRecurringType={setEndRecurringType}
                      invoiceDueDate={invoiceDueDate}
                      isNewInvoice={type === 'Invoice' && !editInvoice?.id}
                      isDraft={type === 'Invoice' && editInvoice?.isDraft}
                      discInPercentage={discInPercentage}
                      setDiscInPercentage={setDiscInPercentage}
                      percentageValue={discountPercentageValue}
                      setPercetageValue={setDiscountPercentageValue}
                      setSelectedCustomer={setSelectedCustomer}
                      customers={customers}
                      productsPriceType={productsPriceType}
                      loadTaxes={loadTaxes}
                    />
                  </div>
                </div>
              )}
              {!isPreview &&
                (editInvoice?.id
                  ? !isAssignedTransTab &&
                    (editInvoice?.paymentStatus !== 'paid' ||
                      editInvoice?.isDraft)
                  : true) && (
                  <div className="flex gap-4 justify-end w-full mt-12">
                    <button
                      onClick={() => {
                        if (editInvoice?.id) {
                          setIsPreview(true);
                          handleSetInvoiceData();
                        } else {
                          setIsActive('');
                          setType('');
                        }
                      }}
                      disabled={loading || draftLoading}
                      className="h-11 px-4 border border-indigo-500 text-indigo-500 rounded-[5px] disabled:cursor-not-allowed"
                      type="button"
                    >
                      Cancel
                    </button>
                    {(!editInvoice?.id || editInvoice?.isDraft) && (
                      <Tooltip
                        content={
                          getSaveButtonTooltip()?.length > 20
                            ? (() => {
                                const segments = getStringSegments(
                                  getSaveButtonTooltip(),
                                  20,
                                );
                                return segments.map((segment, index) => (
                                  <p key={index}>{segment}</p>
                                ));
                              })()
                            : getSaveButtonTooltip()
                        }
                        contentClassName={`border-none w-fit rounded-[10px] overflow-visible leading-tight text-sm text-[#667085] relative tooltipArrowDown `}
                        position="top"
                        tooltipShow={getSaveButtonTooltip()}
                      >
                        <button
                          className="flex items-center justify-center h-11 px-4 border border-indigo-500 text-indigo-500 rounded-[5px] disabled:border-indigo-400 disabled:text-indigo-400 disabled:cursor-not-allowed"
                          onClick={onSubmit}
                          disabled={
                            loading || draftLoading || getSaveButtonTooltip()
                          }
                          type="button"
                        >
                          {draftLoading && (
                            <svg
                              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                            </svg>
                          )}
                          Save As Draft
                        </button>
                      </Tooltip>
                    )}
                    <div>
                      <div className="h-11 flex ">
                        <Tooltip
                          content={
                            getSaveButtonTooltip()?.length > 20
                              ? (() => {
                                  const segments = getStringSegments(
                                    getSaveButtonTooltip(),
                                    20,
                                  );
                                  return segments.map((segment, index) => (
                                    <p key={index}>{segment}</p>
                                  ));
                                })()
                              : getSaveButtonTooltip()
                          }
                          contentClassName={`border-none w-fit rounded-[10px] overflow-visible leading-tight text-sm text-[#667085] relative tooltipArrowDown `}
                          position="top"
                          tooltipShow={getSaveButtonTooltip()}
                        >
                          {checkPermission(
                            PermissionKeys.MANAGE_INVOICES,
                            accessType,
                            permissions,
                          ) &&
                            checkPermission(
                              PermissionKeys.APPROVE_INVOICES,
                              accessType,
                              permissions,
                            ) && (
                              <button
                                className="flex items-center justify-center rounded-tl-[5px] rounded-[5px]   h-full px-4 border border-indigo-500  bg-indigo-500 text-white disabled:bg-indigo-400 disabled:cursor-not-allowed"
                                onClick={() => onSubmit({ isDraft: false })}
                                disabled={
                                  loading ||
                                  draftLoading ||
                                  getSaveButtonTooltip()
                                }
                                type="button"
                              >
                                {loading && (
                                  <svg
                                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                                  </svg>
                                )}
                                {editInvoice?.id
                                  ? `Update ${editInvoice?.isDraft ? '& Approve' : ''}`
                                  : 'Save & Approve'}{' '}
                                {type}
                              </button>
                            )}
                        </Tooltip>
                        {/* <button
                  onClick={() => setButtonToggler(!buttonToggler)}
                  className={`rounded-tr-[5px] ${
                    !buttonToggler ? "rounded-br-[5px]" : ""
                  }  h-full px-2 border border-l-[#FFFFFFCC] border-indigo-500  bg-indigo-500 text-white`}
                >
                  <svg
                    className={`shrink-0 ml-1 text-white  ${
                      buttonToggler ? "rotate-180" : ""
                    }`}
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 1.83398L5.70707 4.58988C5.31818 4.91535 4.68182 4.91535 4.29293 4.58988L1 1.83398"
                      stroke="#ffffff"
                      strokeOpacity="0.8"
                      strokeWidth="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button> */}
                      </div>
                      {/* {buttonToggler && (
                <button className="w-[155px] rounded-bl-[5px] rounded-br-[5px] h-11 border border-t-[#FFFFFFCC] border-indigo-500  bg-indigo-500 text-white">
                  Download As PDF
                </button>
              )} */}
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InvoiceForm;
