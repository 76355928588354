import React from 'react';
import { useSelector } from 'react-redux';
import AlertBox from 'components/AlertBox';
import AppLayout from '../../../components/AppLayout';
import ImportBox from './ImportBox';
import QuickBookReauth from './quickBookReauth';
import useQuickbooksSettings from './useQuickbooksSettings';

const QuickBooksSettings = () => {
  const { team } = useSelector((state) => state.Team);

  const {
    reauthIssue,
    setReauthIssue,
    transactionsLoading,
    accountLoading,
    customerLoading,
    vendorLoading,
    productLoading,
    activeImport,
    setActiveImport,
    response,
    setResponse,
    syncAccounts,
    syncTransactions,
    syncCustomers,
    syncVendors,
    syncProducts,
  } = useQuickbooksSettings();

  return (
    <AppLayout>
      <main className="relative grow ">
        {/* Content */}
        <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
          {/* Page header */}
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between mb-10">
            <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
              Quickbooks Settings
            </h1>
          </div>
          <QuickBookReauth
            reauthIssue={reauthIssue}
            setReauthIssue={setReauthIssue}
          />
          {Object.keys(response || {})?.length > 0 && activeImport && (
            <AlertBox
              type="success"
              heading={`${activeImport} Imported Successfully`}
              onActionButton={() => {
                setResponse(null);
                setActiveImport('');
              }}
              isCross
              text={
                `Your ${activeImport} data is being imported in the background. We’re handling everything for you. You will be informed by email when the import is complete.`
                //       activeImport === ACCOUNT
                //         ? `Accounts Created : ${response?.createdAccounts} |
                //     Accounts Skipped : ${response?.skip} |
                //     Accounts Disabled : ${response?.disabled}
                //     `
                //         : activeImport === TRANSACTION
                //           ? `Transactions Created : ${response?.createdTransactions} |
                // Transactions Unmatched with any Account : ${response?.transactionNotMatchedWithAccount} |
                // Transactions Not Supported : ${response?.transactionsNotSupported}
                // `
                //           : `${activeImport} Created  : ${response?.created ?? response?.createdVendors ?? response?.createdItems} |
                //    ${activeImport} Skipped  : ${response?.skip} |
                //      ${activeImport} Updated : ${response?.updated}
                //     `
              }
              // hideActionButton
            />
          )}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <ImportBox
              title="Import Chart of Accounts"
              text="Import all of your accounts from Quickbooks with a single click."
              buttonText="Import Chart of Accounts"
              handleClick={syncAccounts}
              loading={accountLoading}
              lastSync={team?.app?.quickbooks?.[0]?.accountLastUpdateAt}
              toggleText="Enable this option to deactivate all default accounts and replace them with the imported chart of accounts. Note: Certain default accounts are required for COUNT's automation features and cannot be deactivated."
            />
            <ImportBox
              title="Import Customers"
              text="Import all of your customers from Quickbooks with a single click."
              buttonText="Import Customers"
              handleClick={syncCustomers}
              loading={customerLoading}
              lastSync={team?.app?.quickbooks?.[0]?.customerLastImportedAt}
              toggleText="If an imported record has a name that exactly matches an existing Customer, the records will be automatically merged."
            />
            <ImportBox
              title="Import Vendors"
              text="Import all of your vendors from Quickbooks with a single click."
              buttonText="Import Vendors"
              handleClick={syncVendors}
              loading={vendorLoading}
              lastSync={team?.app?.quickbooks?.[0]?.vendorLastImportedAt}
              toggleText="If an imported record has a name that exactly matches an existing Vendor, the records will be automatically merged."
            />
            <ImportBox
              title="Import Products & Services"
              text="Import all of your products & services from Quickbooks with a single click."
              buttonText="Import Products & Services"
              handleClick={syncProducts}
              loading={productLoading}
              lastSync={team?.app?.quickbooks?.[0]?.productLastImportedAt}
              toggleText="If an imported record has a name that exactly matches an existing Product or Service, the records will be automatically merged."
            />
            <ImportBox
              title="Import Transactions"
              text="Import all of your transactions from Quickbooks with a single click."
              buttonText="Import Transactions"
              handleClick={() => syncTransactions(false)}
              loading={transactionsLoading}
              lastSync={team?.app?.quickbooks?.[0]?.transactionLastImportedAt}
              // toggleText="Enable this option to deactivate all default accounts and replace them with the imported chart of accounts. Note: Certain default accounts are required for COUNT's automation features and cannot be deactivated."
            />
          </div>
        </div>
      </main>
    </AppLayout>
  );
};

export default QuickBooksSettings;
