import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import { toast } from 'react-toastify';
import {
  getMerchants,
  getPeople,
  getAccounts,
  getExpenseReporterTrans,
  updateExpenseReportStatus,
  startExpenseTypeImport,
  getExpenseReporters,
  createExpenseReporter,
  deleteExpenseReporter,
  bulkUpdateExpenseReporting,
  importExpenseTypes,
  createExpenseType,
  getExpenseTypes,
  updateExpenseType,
  deleteExpenseType,
  updateExpenseReport,
  createPeople,
  getTeamById,
  getPendingUnmatchedReceipts,
  getTags,
  getAllProjects,
} from '../../API/backend_helper';
import DeleteReporterModal from './DeleteReporterModal';
import { TEAM_ID, transformAccounts } from '../../utils/Utils';
import TobTabs from './TobTabs';
import { REPORTERS_DATA } from './data';
import TransactionsFlyout from './TransactionsFlyout';
import CreateExpenseReporterModal from './CreateExpenseReporterModal';
import ReportersTable from './ReportersTable';
import ReporterTransactionsTable from './ReporterTransactionsTable';
import MapExpenseTypes from './mapExpenseTypes';
import ExpenseTypeModal from './ExpenseTypeModal';
import ExpenseTypesTable from './ExpenseTypesTable';
import GetStartedStep from './GetStartedStep';
import ModalBasic from '../../components/ModalBasic';
import PeopleForm from '../people/PeopleForm';
import AppLayout from '../../components/AppLayout';
import { setTeam } from '../../redux/Team/reducer';
import GetStartedBox from '../../components/GetStartedBox';
import UnmatchedReceiptsTab from './UnmatchedReceiptsTab';

export const REPORTERS_TRANSACTIONS_TAB = 'Transactions';
export const UNMATCHED_RECEIPTS_TAB = 'unmatchedReceipts';
export const MANAGE_REPORTERS_TAB = 'manageReporters';
export const EXPENSE_TYPES_TAB = 'expenseTypes';
export const EDIT_INVOICE = 'editInvoice';
export const INVOICES_TAB = 'invoicesTab';
export const ESTIMATES_TAB = 'estimatesTab';

const ExpenseReporting = () => {
  const TeamId = localStorage.getItem(TEAM_ID);
  const dispatch = useDispatch();

  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const [mainLoading, setMainLoading] = useState(true);
  const [isGetStarted, setIsGetStarted] = useState(false);
  const [isMappingStep, setIsMappingStep] = useState(false);
  const [getStartedLoading, setGetStartedLoading] = useState(false);

  // Expense reports
  const [expenseReports, setExpenseReports] = useState({});
  const [reportStatusLoader, setReportStatusLoader] = useState(false);
  const [expenseAccounts, setExpenseAccounts] = useState([]);
  const [expenseCategoryAccounts, setExpenseCategoryAccounts] = useState([]);
  const [expenseReportMapping, setExpenseReportMapping] = useState();
  const [isEmptyRowsError, setIsEmptyRowsError] = useState(false);
  const [localExpenseReportLimit, setLocalExpenseReportLimit] = useState(50);

  // Filters

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(REPORTERS_TRANSACTIONS_TAB);
  // Transactions
  const [tags, setTags] = useState([]);
  const [projects, setProjects] = useState([]);
  const [activeTransaction, setActiveTransaction] = useState(-1);
  const [selectAllTransactions, setSelectAllTransactions] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [transactionFlyoutOpen, setTransactionFlyoutOpen] = useState(false);
  const [bulkStatusLoading, setBulkStatusLoading] = useState(false);
  const [vendors, setVendors] = useState([]);

  // Filters
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [reportsTypesFilter, setReportsTypesFilter] = useState(['Submitted']);
  const [isResetAccount, setIsResetAccount] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [selectedReporters, setSelectedReporters] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedExpenseTypes, setSelectedExpenseTypes] = useState([]);

  // Unmatched Receipts
  const [unmatchedReceipts, setUnmatchedReceipts] = useState({});
  const [unmatchedReceiptsLimit, setUnmatchedReceiptsLimit] = useState(50);

  // Expense Reporter
  const [expenseReporterModalOpen, setExpenseReporterModalOpen] =
    useState(false);
  const [people, setPeople] = useState([]);
  const [reporters, setReporters] = useState(REPORTERS_DATA);
  const [activeReporter, setActiveReporter] = useState(false);
  const [deleteReporterModalOpen, setDeleteReporterModalOpen] = useState(false);
  const [peopleModalOpen, setPeopleModalOpen] = useState(false);
  const [newlyAddedPeople, setNewlyAddedPeople] = useState(null);
  const [localReportersLimit, setLocalReportersLimit] = useState(50);

  // Expese Type
  const [expenseTypeModalOpen, setExpenseTypeModalOpen] = useState(false);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [activeExpenseType, setActiveExpenseType] = useState({});

  const [deleteTitle, setDeleteTitle] = useState('');
  const [deleteType, setDeleteType] = useState('');
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [mappingLoader, setMappingLoader] = useState(false);

  const [errMessage, setErrMessage] = useState('');

  const { accessType, permissions } = useSelector((state) => state.User);

  const startExpenseType = async () => {
    setGetStartedLoading(true);
    try {
      const res = await startExpenseTypeImport();
      if (res?.data) {
        const temp = [];
        Object?.keys(res?.data)?.forEach((map) => {
          temp?.push({ ...res?.data?.[map], error: '' });
        });
        setExpenseReportMapping(temp);
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setGetStartedLoading(false);
    }
  };

  const handleGetStarted = async () => {
    await startExpenseType();
    setIsGetStarted(true);
    setIsMappingStep(true);
  };

  const getData = async ({
    limit = localExpenseReportLimit,
    page = reporters?.page,
    search = '',
    loading = false,
    type = reportsTypesFilter,
    accounts = selectedAccount || [],
    projects = selectedProjects || [],
    tags = selectedTags || [],
    expenseTypes = selectedExpenseTypes || [],
    filterReporters = selectedReporters || [],
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getExpenseReporterTrans({
        page,
        search,
        limit,
        type,
        accounts,
        projects,
        tags,
        expenseTypes,
        reporters: filterReporters,
      });
      setSelectedTransactions([]);
      setSelectAllTransactions(false);
      setExpenseReports(res?.data);
      setIsResetAccount(false);
      if (loading) {
        setIsLoading(false);
      }
    } catch (e) {
      if (loading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const getMerchantsApi = async () => {
    try {
      const res = await getMerchants({ status: 'active' });
      setVendors(res?.vendors);
    } catch (e) {
      console.log('error', e);
    }
  };

  // Expense Reporters

  const loadExpenseReporters = async ({
    page = reporters?.paginateReports?.page,
    limit = localReportersLimit,
    search = '',
  }) => {
    try {
      const res = await getExpenseReporters({
        page,
        search,
        limit,
      });
      setReporters(res);
    } catch (e) {
      console.log('error', e);
    }
  };

  const addExpenseReporter = async (peopleId, accountsId) => {
    try {
      await createExpenseReporter(peopleId, accountsId);
      await loadExpenseReporters({ loading: false });
    } catch (e) {
      console.log('error', e);
      throw new Error(e);
    }
  };

  const loadExpenseTypes = async ({ loading = false }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getExpenseTypes();
      setExpenseTypes(res?.data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const checkRowsError = () => {
    let isError = false;
    if (expenseReportMapping?.length === 0) {
      setIsEmptyRowsError(true);
      return true;
    }
    const tempRows = [...expenseReportMapping];
    expenseReportMapping.forEach((d, i) => {
      const tempRow = { ...d };
      if (!tempRow?.name) {
        tempRow.nameError = 'Expense should not be empty';
        tempRows[i] = tempRow;
        isError = true;
      } else if (
        tempRows?.filter((r, y) => i !== y && r?.name === tempRow?.name)
          ?.length > 0
      ) {
        tempRow.nameError = 'Expense should be unique';
        tempRows[i] = tempRow;
        isError = true;
      } else {
        tempRow.nameError = '';
        tempRows[i] = tempRow;
      }
      if (!tempRow.accountId) {
        tempRow.error = 'Account field should not be empty';
        tempRows[i] = tempRow;
        isError = true;
      } else {
        tempRow.error = '';
        tempRows[i] = tempRow;
      }
    });
    setExpenseReportMapping(tempRows);
    return isError;
  };

  const fetchTeam = async () => {
    try {
      const res = await getTeamById(TeamId);
      dispatch(setTeam(res));
      return res;
    } catch (e) {
      console.log('error', e);
    }
  };

  const importExpenseReporters = async () => {
    if (checkRowsError()) {
      return;
    }
    try {
      setMappingLoader(true);
      await importExpenseTypes({
        expenseTypes: expenseReportMapping,
      });
      await loadExpenseTypes({ loading: false });
      setIsLoading(true);
      setActiveTab(EXPENSE_TYPES_TAB);
      setMappingLoader(false);
      setIsMappingStep(false);
      fetchTeam();
    } catch (e) {
      setMappingLoader(false);
      console.log('error', e);
    }
  };

  const updateExpenseReportStatusApi = async (id, status) => {
    setReportStatusLoader(id);
    try {
      await updateExpenseReportStatus(id, {
        status,
      });
      if (status === 'Approved') {
        toast.success('Transaction approved successfully');
      } else if (status === 'Denied') {
        toast.success('Transaction denied successfully');
      }
      getData({});
      setReportStatusLoader('');
    } catch (e) {
      setReportStatusLoader('');
      console.log('error', e);
    }
  };

  const handleDeleteReporter = async (activeRepoerter = activeReporter) => {
    setDeleteModalLoading(true);
    try {
      await deleteExpenseReporter(activeRepoerter.id);
      loadExpenseReporters({ loading: false });
      setDeleteReporterModalOpen(false);
    } catch (err) {
      if (err.message) {
        setErrMessage(JSON.parse(err.message));
        setDeleteReporterModalOpen(true);
      } else {
        setDeleteReporterModalOpen(false);
      }
    } finally {
      setDeleteModalLoading(false);
    }
  };

  const getAccountsApi = async () => {
    try {
      const accountsRes = await getAccounts();
      setExpenseAccounts(
        transformAccounts(accountsRes, 'EXPENSE_PHYSICAL_ACCOUNT'),
      );
      setExpenseCategoryAccounts(
        transformAccounts(accountsRes, 'bills', 'expense'),
      );
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadTags = async () => {
    try {
      const resTags = await getTags();
      setTags(resTags);
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadAllProjects = async () => {
    try {
      const res = await getAllProjects({});
      setProjects(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (team?.id) {
      if (team?.featureExpenseReport) {
        setIsGetStarted(true);
      }
      setMainLoading(false);
      getAccountsApi();
      getData({ loading: true });
      loadExpenseReporters({});
      getMerchantsApi();
      loadTags();
      loadAllProjects();
    }
  }, [team]);

  document.title = 'COUNT | Reporters';

  const loadPeopleData = async ({ search = '' }) => {
    try {
      const res = await getPeople({
        search,
        isNotExpenseReporter: true,
      });
      setPeople(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleAddPeople = async (data) => {
    try {
      const response = await createPeople(data);
      await loadPeopleData({});
      setNewlyAddedPeople(response?.data?.people?.id);
      setExpenseReporterModalOpen(true);
      return response;
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  };

  const loadAccounts = async () => {
    try {
      const accountsRes = await getAccounts();

      if (accountsRes) {
        setExpenseAccounts(
          transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT', 'expense'),
        );
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const loadUnmatchedReceipts = async ({
    page = unmatchedReceipts?.page || 1,
    limit = unmatchedReceiptsLimit,
    loading = true,
  }) => {
    if (loading) {
      setIsLoading(true);
    }
    try {
      const res = await getPendingUnmatchedReceipts({ page, limit });
      setUnmatchedReceipts(res?.data);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadPeopleData({});
    loadAccounts();
    loadExpenseTypes({ loading: true });
    loadUnmatchedReceipts({});
  }, []);

  const handleSelectAllTransactions = () => {
    setSelectAllTransactions(!selectAllTransactions);
    const filteredTransactions = expenseReports?.expenseReport?.filter((t) =>
      reportsTypesFilter?.includes(t?.status),
    );
    setSelectedTransactions(filteredTransactions?.map((li) => li.id));
    if (selectAllTransactions) {
      setSelectedTransactions([]);
    }
  };

  const handleTransactionCheckbox = (e) => {
    e.stopPropagation();
    const { id } = e.target;
    setSelectAllTransactions(false);
    const parsedId = parseInt(id);
    if (selectedTransactions.includes(parsedId)) {
      setSelectedTransactions(
        selectedTransactions.filter((item) => item !== parsedId),
      );
    } else {
      setSelectedTransactions((prev) => [...prev, parsedId]);
    }
  };

  const handleBulkStatusUpdate = async (status) => {
    const data = {
      status,
      expenseReportIDs: selectedTransactions,
    };
    try {
      setBulkStatusLoading(true);
      await bulkUpdateExpenseReporting(data);
      await getData({});
      setSelectedTransactions([]);
    } catch (err) {
      console.log('err', err);
    } finally {
      setBulkStatusLoading(false);
    }
  };

  const handleTransactionUpdate = async (data) => {
    try {
      await updateExpenseReport(activeTransaction?.id, data);
      await getData({});
    } catch (err) {
      console.log('err', err);
      throw new Error(err);
    }
  };

  const handleCreateExpenseType = async (data) => {
    try {
      await createExpenseType(data);
      loadExpenseTypes({});
    } catch (err) {
      console.log('err', err);
      throw new Error(err);
    }
  };

  const handleUpdateExpenseType = async (id, data) => {
    try {
      await updateExpenseType(id, data);
      loadExpenseTypes({});
      setActiveExpenseType({});
    } catch (err) {
      console.log('err', err);
      throw new Error(err);
    }
  };

  const handleDeleteExpenseType = async (expenseType = activeExpenseType) => {
    setDeleteModalLoading(true);
    try {
      await deleteExpenseType(expenseType?.id);
      loadExpenseTypes({});
      setActiveExpenseType({});
      setDeleteReporterModalOpen(false);
    } catch (err) {
      console.log('err', err);
      throw new Error(err);
    } finally {
      setDeleteModalLoading(false);
    }
  };

  const findCurrencySymbol = (isoCode) => {
    if (currencies?.length > 0) {
      const currency = currencies?.find?.(
        (currency) => currency.isoCode === isoCode,
      );
      return currency?.symbol === '$' && currency?.isoCode !== 'USD'
        ? currency.symbol
        : currency?.symbol;
    }
    return '';
  };

  const handleResetTransactionsFilters = () => {
    setReportsTypesFilter(['Submitted']);
    setIsResetAccount(true);
    setSelectedAccount([]);
    setSelectedReporters([]);
    setSelectedTags([]);
    setSelectedProjects([]);
    setSelectedExpenseTypes([]);
    getData({
      loading: true,
      type: ['Submitted'],
      accounts: [],
      projects: [],
      tags: [],
      expenseTypes: [],
      filterReporters: [],
    });
  };

  return (
    <>
      {/*
      <ReporterModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        handleAddReporter={handleAddReporter}
        activeReporter={activeReporter}
        handleUpdateReporter={handleUpdateReporter}
        handleUploadReporterLogo={handleUploadReporterLogo}
        setActiveReporter={setActiveReporter}
        setTitle={setTitle}
      /> */}
      <CreateExpenseReporterModal
        open={expenseReporterModalOpen}
        setOpen={setExpenseReporterModalOpen}
        people={people}
        allAccounts={expenseAccounts}
        addExpenseReporter={addExpenseReporter}
        activeReporter={activeReporter}
        setActiveReporter={setActiveReporter}
        loadExpenseReporters={loadExpenseReporters}
        setPeopleModalOpen={setPeopleModalOpen}
        newlyAddedPeople={newlyAddedPeople}
        setNewlyAddedPeople={setNewlyAddedPeople}
      />
      <ExpenseTypeModal
        open={expenseTypeModalOpen}
        setOpen={setExpenseTypeModalOpen}
        allAccounts={expenseCategoryAccounts}
        activeExpenseType={activeExpenseType}
        handleCreateExpenseType={handleCreateExpenseType}
        handleUpdateExpenseType={handleUpdateExpenseType}
      />
      <ModalBasic
        modalOpen={peopleModalOpen}
        setModalOpen={setPeopleModalOpen}
        title="Add Person"
        width="!w-[1200px]"
      >
        {peopleModalOpen && (
          <PeopleForm
            handleAddPeople={handleAddPeople}
            isModalView
            setFormOpen={setPeopleModalOpen}
            team={team}
            preSelectExpenseReport
          />
        )}
      </ModalBasic>
      <DeleteReporterModal
        isOpen={deleteReporterModalOpen}
        setIsOpen={setDeleteReporterModalOpen}
        handleDeleteReporter={
          deleteType === 'expense'
            ? handleDeleteExpenseType
            : handleDeleteReporter
        }
        activeReporter={
          deleteType === 'expense' ? activeExpenseType : activeReporter
        }
        errMessage={errMessage}
        title={deleteTitle}
        type={deleteType}
        loading={deleteModalLoading}
      />

      <AppLayout mainClass="relative">
        <main className="grow relative">
          {mainLoading ? (
            <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
              <svg
                width="29"
                height="29"
                viewBox="0 0 29 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin w-10 h-10 fill-current shrink-0"
              >
                <path
                  d="M14.5 2.41699V7.25033"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 21.749V26.5824"
                  stroke="#E48642"
                  strokeWidth="2.55882"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 5.95703L9.37662 9.37662"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 19.6211L23.0426 23.0407"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.41699 14.5H7.25033"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.749 14.5H26.5824"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.95703 23.0407L9.37662 19.6211"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.623 9.37662L23.0426 5.95703"
                  stroke="#E48642"
                  strokeWidth="1.58955"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                Fetching data, please wait...
              </p>
            </div>
          ) : !isGetStarted ? (
            // <GetStartedStep
            //   getStartedLoading={getStartedLoading}
            //   handleGetStarted={handleGetStarted}
            // />
            <div className="flex-1 flex flex-col pt-[10%] pb-[5%] items-center px-4 md:px-8">
              <GetStartedBox
                heading="Effortless Expense Claims"
                subHeading="We make it easy for you and employees to manage expense claims."
                points={[
                  'Download the COUNT Go app on iOS and Android.',
                  'Snap a photo of the receipt and upload instantly.',
                  'Advanced AI matches receipts to bank transactions automatically.',
                  'Transactions are matched even if they post several days later.',
                ]}
                handleClick={handleGetStarted}
                loading={getStartedLoading}
              />
            </div>
          ) : isMappingStep ? (
            <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
              <MapExpenseTypes
                headersObj={expenseReportMapping}
                setHeadersObj={setExpenseReportMapping}
                accounts={expenseCategoryAccounts}
                checkRowsError={checkRowsError}
                onSubmit={importExpenseReporters}
                isEmptyRowsError={isEmptyRowsError}
                loading={mappingLoader}
              />
            </div>
          ) : (
            <>
              <TransactionsFlyout
                isOpen={transactionFlyoutOpen}
                setIsOpen={setTransactionFlyoutOpen}
                activeTransaction={activeTransaction}
                findCurrencySymbol={findCurrencySymbol}
                team={team}
                vendors={vendors}
                setVendors={setVendors}
                expenseTypes={expenseTypes}
                handleTransactionUpdate={handleTransactionUpdate}
              />
              {/* Content */}
              <div className=" px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
                {/* Page header */}
                <div className="flex justify-between items-center mb-10">
                  <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                    Expense Claims
                  </h1>
                  <div className="sm:flex gap-4 items-center">
                    {/* <SearchForm
                        placeholder="Search"
                        transaction
                      /> */}
                    {checkPermission(
                      PermissionKeys.MANAGE_EXPENSE_CLAIMS,
                      accessType,
                      permissions,
                    ) &&
                      (activeTab === MANAGE_REPORTERS_TAB ||
                        activeTab === EXPENSE_TYPES_TAB) && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (activeTab === MANAGE_REPORTERS_TAB) {
                              setActiveReporter({});
                              setExpenseReporterModalOpen(true);
                            } else {
                              setActiveExpenseType({});
                              setExpenseTypeModalOpen(true);
                            }
                          }}
                          type="button"
                          className="bg-indigo-500 h-10 border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 4.16699V15.8337M4.16669 10.0003H15.8334"
                              stroke="white"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Add{' '}
                          {activeTab === MANAGE_REPORTERS_TAB
                            ? ' Expense Reporter'
                            : ' Expense Type'}
                        </button>
                      )}
                  </div>
                </div>
                <TobTabs
                  activeTab={activeTab}
                  setActiveTab={(t) => {
                    setActiveTab(t);
                    // getData({
                    //   type: t === INVOICES_TAB ? "invoice" : "estimate",
                    //   loading: true,
                    // });
                    // onSetActiveTab(t);
                  }}
                  MANAGE_REPORTERS_TAB={MANAGE_REPORTERS_TAB}
                  REPORTERS_TRANSACTIONS_TAB={REPORTERS_TRANSACTIONS_TAB}
                  EXPENSE_TYPES_TAB={EXPENSE_TYPES_TAB}
                  UNMATCHED_RECEIPTS_TAB={UNMATCHED_RECEIPTS_TAB}
                />
                {/* Show Loader in Case of REPORTERS_TRANSACTIONS_TAB in Component  */}
                {activeTab !== REPORTERS_TRANSACTIONS_TAB && isLoading ? (
                  <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                    <svg
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="animate-spin w-10 h-10 fill-current shrink-0"
                    >
                      <path
                        d="M14.5 2.41699V7.25033"
                        stroke="#E48642"
                        strokeWidth="2.55882"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.5 21.749V26.5824"
                        stroke="#E48642"
                        strokeWidth="2.55882"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.95703 5.95703L9.37662 9.37662"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.623 19.6211L23.0426 23.0407"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.41699 14.5H7.25033"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21.749 14.5H26.5824"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.95703 23.0407L9.37662 19.6211"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.623 9.37662L23.0426 5.95703"
                        stroke="#E48642"
                        strokeWidth="1.58955"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                      Fetching data, please wait...
                    </p>
                  </div>
                ) : (
                  <>
                    {/* Table */}
                    {activeTab === REPORTERS_TRANSACTIONS_TAB && (
                      <ReporterTransactionsTable
                        isLoading={isLoading}
                        reporters={expenseReports}
                        activeTransaction={activeTransaction}
                        selectAllTransactions={selectAllTransactions}
                        handleSelectAllTransactions={
                          handleSelectAllTransactions
                        }
                        selectedTransactions={selectedTransactions}
                        handleTransactionCheckbox={handleTransactionCheckbox}
                        setTransactionFlyoutOpen={setTransactionFlyoutOpen}
                        setActiveTransaction={setActiveTransaction}
                        team={team}
                        currencies={currencies}
                        updateReportStatus={updateExpenseReportStatusApi}
                        statusLoader={reportStatusLoader}
                        handleBulkStatusUpdate={handleBulkStatusUpdate}
                        bulkStatusLoading={bulkStatusLoading}
                        reportsTypesFilter={reportsTypesFilter}
                        setReportsTypesFilter={setReportsTypesFilter}
                        getData={getData}
                        findCurrencySymbol={findCurrencySymbol}
                        setLocalExpenseReportLimit={setLocalExpenseReportLimit}
                        showAllFilters={showAllFilters}
                        setShowAllFilters={setShowAllFilters}
                        allAccounts={expenseAccounts}
                        isResetAccount={isResetAccount}
                        selectedAccount={selectedAccount}
                        setSelectedAccount={setSelectedAccount}
                        tags={tags}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        projects={projects}
                        selectedProjects={selectedProjects}
                        setSelectedProjects={setSelectedProjects}
                        expenseTypes={expenseTypes}
                        selectedExpenseTypes={selectedExpenseTypes}
                        setSelectedExpenseTypes={setSelectedExpenseTypes}
                        allReporters={reporters?.expenseReporters}
                        selectedReporters={selectedReporters}
                        setSelectedReporters={setSelectedReporters}
                        handleResetTransactionsFilters={
                          handleResetTransactionsFilters
                        }
                      />
                    )}
                    {activeTab === UNMATCHED_RECEIPTS_TAB && (
                      <UnmatchedReceiptsTab
                        unmatchedReceipts={unmatchedReceipts}
                        unmatchedReceiptsLimit={unmatchedReceiptsLimit}
                        setUnmatchedReceiptsLimit={setUnmatchedReceiptsLimit}
                        loadUnmatchedReceipts={loadUnmatchedReceipts}
                      />
                    )}
                    {activeTab === MANAGE_REPORTERS_TAB && (
                      <ReportersTable
                        reporters={reporters}
                        setActiveReporter={setActiveReporter}
                        setExpenseReporterModalOpen={
                          setExpenseReporterModalOpen
                        }
                        setDeleteReporterModalOpen={setDeleteReporterModalOpen}
                        setDeleteTitle={setDeleteTitle}
                        setDeleteType={setDeleteType}
                        setLocalReportersLimit={setLocalReportersLimit}
                        loadExpenseReporters={loadExpenseReporters}
                      />
                    )}
                    {activeTab === EXPENSE_TYPES_TAB && (
                      <ExpenseTypesTable
                        expenseTypes={expenseTypes}
                        allAccounts={expenseAccounts}
                        setActiveExpenseType={setActiveExpenseType}
                        setExpenseTypeModalOpen={setExpenseTypeModalOpen}
                        setDeleteReporterModalOpen={setDeleteReporterModalOpen}
                        setDeleteTitle={setDeleteTitle}
                        setDeleteType={setDeleteType}
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </main>
      </AppLayout>
    </>
  );
};

export default ExpenseReporting;
