import React, { useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import ModalBasic from '../../../components/ModalBasic';
import TextInputDash from '../../../components/TextInputDash';
import { firmInviteBusinessOwner } from '../../../API/backend_helper';

const InviteClientModal = ({
  isOpen,
  setIsOpen,
  accountantFirmId,
  loadAccountantFirm,
}) => {
  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, formikRef) => {
    try {
      setLoading(true);

      const res = await firmInviteBusinessOwner(accountantFirmId, {
        email: values.email,
      });
      loadAccountantFirm({});
      setLoading(false);
      setIsOpen(false);
      formikRef.resetForm();
    } catch (e) {
      setLoading(false);
      console.log('error', e);
    }
  };

  return (
    <ModalBasic
      modalOpen={isOpen}
      setModalOpen={setIsOpen}
      title="Invite Business Owner"
      width="!w-[500px]"
    >
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              'Please enter valid email',
            )
            .required('Please Enter Email')
            .max(100, 'Email must be at most 100 characters'),
        })}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(validation) => (
          <Form>
            <div className="grid grid-cols-12 p-4 gap-x-5 px-8">
              <div className="col-span-full">
                <TextInputDash
                  label="Business Owner Email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ''}
                  error={validation.touched.email && validation.errors.email}
                  required
                />
              </div>
            </div>

            {/* Close/Add Buttons */}
            <div className="w-full flex justify-end items-center gap-4 py-4 px-8 border-t border-[#D0D5DD]">
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem]  bg-white border border-gray-300 border-opacity-40 shadow w-[6.875rem] h-11 text-slate-600 leading-6"
                onClick={(event) => {
                  event.preventDefault();
                  setIsOpen(false);
                }}
                type="button"
              >
                Close
              </button>
              <button
                className="flex items-center justify-center py-2.5 px-[1.875rem] rounded-[0.313rem] bg-indigo-500 shadow w-[6.875rem] h-11 text-white leading-6
                disabled:!bg-indigo-300 disabled:text-white disabled:cursor-not-allowed"
                type="submit"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                Invite
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBasic>
  );
};

export default InviteClientModal;
