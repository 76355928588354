import React, { useEffect, useState } from 'react';
import {
  formatNumber,
  getContrast,
  getCurrencySymbolFromIso,
  recurringTransStatusColor,
  TEAM_CURRENCY,
  trimText,
} from 'utils/Utils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Tooltip from 'components/Tooltip';
import { Loader } from 'components/Svg';

const RecurringTransactions = ({
  recurringTransactions,
  overviewLoadings,
  setActiveTab,
}) => {
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [descriptionLength, setDescriptionLength] = useState(20);

  const handleResize = () => {
    const descriptionCell = document.getElementById('descriptionCellId0');
    if (descriptionCell) {
      setDescriptionLength(parseInt((descriptionCell?.clientWidth - 2) / 10));
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [recurringTransactions]);

  return (
    <div className="p-6 border border-[#D0D5DD] rounded-[12px]">
      <h3 className="text-[22px] font-medium text-[#666] mb-4">
        Upcoming Recurring Transactions
      </h3>
      {overviewLoadings?.recurringTransactions ? (
        <div className="flex flex-col h-[180px] justify-center items-center">
          <Loader height="h-8 w-8" />
          <p>Fetching data please wait...</p>
        </div>
      ) : recurringTransactions?.length > 0 ? (
        <>
          <div
            className="overflow-auto scrollbar"
            id="insightsOverviewRecurringTransactionsTable"
          >
            <table className="w-full table-auto">
              <tbody className="divide-y divide-[#D0D5DD]">
                {recurringTransactions?.map(
                  (transaction, i) =>
                    i < 3 && (
                      <tr key={i} className="">
                        <td className="py-3 pr-2">
                          <div className="flex gap-2.5">
                            <div className="h-10 w-10 min-h-10 min-w-10 rounded-full">
                              {transaction?.vendorLogo ||
                              transaction?.plaidCategoryIconUrl ? (
                                <img
                                  className="rounded-full"
                                  src={
                                    transaction?.vendorLogo ||
                                    transaction?.plaidCategoryIconUrl
                                  }
                                  alt={transaction?.name}
                                />
                              ) : (
                                <div
                                  className="w-10 h-10 rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                                  style={{
                                    backgroundColor: transaction
                                      ?.categoryAccount?.group?.color
                                      ? transaction?.categoryAccount?.group
                                          ?.color
                                      : '',
                                  }}
                                >
                                  <div
                                    className={`${
                                      transaction?.categoryAccount?.group?.color
                                        ? getContrast(
                                            transaction?.categoryAccount?.group
                                              ?.color,
                                            'text-black',
                                            'text-white',
                                          )
                                        : 'text-black'
                                    } text-sm font-normal uppercase`}
                                  >
                                    {transaction?.description
                                      ? transaction.description
                                          .replace(/[^\w\s]/gi, '')
                                          .split(' ')
                                          .filter(Boolean)
                                          .slice(0, 2)
                                          .map((word) => word.charAt(0))
                                          .join('')
                                      : ''}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="flex-1">
                              <p className="text-xs text-[#66708580]">
                                {moment(transaction?.expectedDate).format(
                                  'MMM DD, YYYY',
                                )}
                              </p>
                              <Tooltip
                                tooltipShow={
                                  transaction?.description?.length >
                                  descriptionLength
                                }
                                content={transaction?.description}
                                contentClassName="border-none rounded-[10px] translate-y-1 overflow-visible text-sm text-[#667085] relative tooltipArrowDown"
                                isFixed
                                ids={[
                                  'insightsOverviewRecurringTransactionsTable',
                                  'insightsPage',
                                ]}
                              >
                                <h4
                                  className="text-[18px] my-0 leading-normal text-[#667085]"
                                  id={`descriptionCellId${i}`}
                                >
                                  {trimText(
                                    transaction?.description,
                                    descriptionLength,
                                  )}
                                </h4>
                              </Tooltip>
                            </div>
                          </div>
                        </td>
                        <td className="py-3 px-2 min-w-[8rem] ">
                          <div className="flex flex-col items-end">
                            <div
                              className={`text-[10px] w-fit font-medium capitalize inline-flex items-center gap-1  px-2 h-4 rounded-[5px]   ${recurringTransStatusColor(transaction?.paidStatus)}`}
                            >
                              {/* <div className="h-[5px] w-[5px] rounded-full bg-[#FF4B4B]" />{' '} */}
                              {transaction?.transactions?.[0]?.type ===
                                'Income' && transaction?.paidStatus === 'paid'
                                ? 'Received'
                                : transaction?.transactions?.[0]?.type ===
                                      'Expense' &&
                                    transaction?.paidStatus === 'expected'
                                  ? 'Owing'
                                  : transaction?.paidStatus}
                            </div>
                            <div className="text-[18px] text-[#667085] font-medium text-right">
                              {getCurrencySymbolFromIso(
                                teamDefaultCurrency,
                                currencies,
                              )}
                              {formatNumber(
                                Math.abs(transaction.amount.toFixed(2)),
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ),
                )}
              </tbody>
            </table>
          </div>

          <button
            type="button"
            className="w-full h-[38px] border border-indigo-500 mt-4 rounded-[6px] text-indigo-500"
            onClick={() => setActiveTab('recurring')}
          >
            View All
          </button>
        </>
      ) : (
        <div className="flex w-full items-center justify-center flex-col my-8">
          <p className="text-slate-600 text-[16px] text-center">
            COUNT AI is on the lookout for recurring transactions. When one is
            detected, you'll see it here.
          </p>
        </div>
      )}
    </div>
  );
};

export default RecurringTransactions;
