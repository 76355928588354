import React, { useRef, useEffect, useState } from 'react';
import {
  Chart,
  BarController,
  BarElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  tailwindConfig,
  formatValue,
  getCurrencySymbolFromIso,
  formatNumber,
  TEAM_CURRENCY,
} from '../utils/Utils';

Chart.register(
  BarController,
  BarElement,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend,
);

function BarChart({ chartData, width, height }) {
  const { team } = useSelector((state) => state.Team);

  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);
  const { currencies } = useSelector((state) => state.Currencies);

  const [chart, setChart] = useState(null);
  const canvas = useRef(null);
  const legend = useRef(null);

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.fontSize = '12px';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    const monthName = moment()
      .month(tooltip?.dataPoints?.[0]?.dataIndex)
      .format('MMMM YYYY');
    const moneyIn =
      chartData?.datasets?.[0]?.data?.[tooltip?.dataPoints?.[0]?.dataIndex];
    const moneyOut =
      chartData?.datasets?.[1]?.data?.[tooltip?.dataPoints?.[0]?.dataIndex];
    const difference = moneyIn - moneyOut;
    const MoneyIn = `${getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}${formatNumber(moneyIn)}`;
    const MoneyOut = `${getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}${formatNumber(moneyOut)}`;
    const Difference = `${difference < 0 ? '-' : ''}${getCurrencySymbolFromIso(teamDefaultCurrency, currencies)}${formatNumber(Math.abs(difference))}`;
    // Set Text
    if (tooltip.body) {
      while (tooltipEl.firstChild) {
        tooltipEl.firstChild.remove();
      }
      // tooltipEl.appendChild(tooltipTitle);
      const data = `
      <div>
        <div class="mb-2 flex px-2">
          <div class="w-5"></div>
          <div class="text-[18px] text-slate-600 font-medium leading-tight">${monthName}</div>
        </div>
        <div class="flex justify-between items-center px-2 mb-2 gap-4">
          <div class="flex gap-2 items-center">
            <div class="h-3 w-3 rounded bg-[#78BD4F]" ></div>
            <p class="text-slate-500 text-base" >Money In</p>
          </div>
          <p class="text-base text-slate-500">${MoneyIn}</p>
        </div>
        <div class="flex justify-between items-center px-2 gap-4">
          <div class="flex gap-2 items-center">
            <div class="h-3 w-3 rounded bg-indigo-500" ></div>
            <p class="text-slate-500 text-base" >Money Out</p>
          </div>
          <p class="text-base text-slate-500">${MoneyOut}</p>
        </div>
        <div class="my-2 flex px-2">
          <div class="w-5"></div>
          <div class="h-[1px] flex-1 bg-slate-300"></div>
        </div>
         <div class="flex justify-between items-center px-2 gap-4">
          <div class="flex gap-2 items-center pl-5">
            <p class="text-slate-500 text-base" >Difference</p>
          </div>
          <p class="text-base text-slate-500">${Difference}</p>
        </div>
      </div>`;
      tooltipEl.innerHTML = data;
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Hidden if formatted Value is 0
    if (parseFloat(tooltip?.body?.[0]?.lines?.[0]?.split(': ')?.[1]) === 0) {
      tooltipEl.style.display = 'none';
    } else {
      tooltipEl.style.display = 'block';
    }

    // Display, position, and set styles for font
    tooltipEl.classList.add('down-arrow-tooltip');
    tooltipEl.style.backgroundColor = '#ffffff';
    tooltipEl.style.boxShadow = `0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01)`;
    tooltipEl.style.border = `1px solid #e2e8f0`;
    tooltipEl.style.color = '#64748b';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
    tooltipEl.style.top = `${-10 + positionY + tooltip.caretY}px`;
    tooltipEl.style.transform = 'translate(-50%, -100%)';
    tooltipEl.style.maxWidth = '300px';
    tooltipEl.style.minWidth = '200px';

    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
  };

  useEffect(() => {
    const ctx = canvas.current.getContext('2d');

    const gradient1 = ctx?.createLinearGradient(0, 0, 0, 300);
    gradient1.addColorStop(0, '#78BD4F'); // Starting color for first dataset
    gradient1.addColorStop(1, '#E9FFDD'); // Ending color for first dataset

    const gradient2 = ctx?.createLinearGradient(0, 0, 0, 300);
    gradient2.addColorStop(0, '#E48642'); // Starting color for second dataset
    gradient2.addColorStop(1, '#FFF0E6');
    // eslint-disable-next-line no-unused-vars
    const newChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: chartData?.labels,
        datasets: [
          // Indigo bars
          {
            ...chartData?.datasets?.[0],
            backgroundColor: gradient1,
          },
          // Grey bars
          {
            ...chartData?.datasets?.[1],
            backgroundColor: gradient2,
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 12,
            bottom: 16,
            left: 20,
            right: 20,
          },
        },
        scales: {
          y: {
            border: {
              display: false,
            },
            ticks: {
              maxTicksLimit: 5,
              callback: (value) => formatValue(value, teamDefaultCurrency),
              color: '#94a3b8',
            },
            grid: {
              color: '#D0D5DD',
            },
          },
          x: {
            type: 'time',
            time: {
              parser: 'MM-DD-YYYY',
              unit: 'month',
              displayFormats: {
                month: 'MMM YY',
              },
            },
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              color: '#94a3b8',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => formatValue(context.parsed.y),
            },
            titleColor: 'black',
            bodyColor: '#94a3b8',
            backgroundColor: '#fff',
            borderColor: '#cbd5e1',
            enabled: false,
            external: externalTooltipHandler,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [
        {
          id: 'htmlLegend',
          afterUpdate(c, args, options) {
            const ul = legend.current;
            if (!ul) return;
            // Remove old legend items
            while (ul.firstChild) {
              ul.firstChild.remove();
            }
            // Reuse the built-in legendItems generator
            const items = c.options.plugins.legend.labels.generateLabels(c);
            items.forEach((item) => {
              const li = document.createElement('li');
              li.style.marginRight = tailwindConfig().theme.margin[4];
              // Button element
              const button = document.createElement('button');
              button.style.display = 'inline-flex';
              button.style.alignItems = 'center';
              button.style.opacity = item.hidden ? '.3' : '';
              button.onclick = () => {
                c.setDatasetVisibility(
                  item.datasetIndex,
                  !c.isDatasetVisible(item.datasetIndex),
                );
                c.update();
              };
              // Color box
              const box = document.createElement('span');
              box.style.display = 'block';
              box.style.width = tailwindConfig().theme.width[3];
              box.style.height = tailwindConfig().theme.height[3];
              box.style.borderRadius = tailwindConfig().theme.borderRadius.full;
              box.style.marginRight = tailwindConfig().theme.margin[2];
              box.style.borderWidth = '3px';
              box.style.borderColor = item.strokeStyle;
              box.style.pointerEvents = 'none';
              // Label
              const label = document.createElement('span');
              label.classList.add('text-slate-500', 'dark:text-slate-400');
              label.style.fontSize = tailwindConfig().theme.fontSize.sm[0];
              label.style.lineHeight =
                tailwindConfig().theme.fontSize.sm[1].lineHeight;
              const labelText = document.createTextNode(item.text);
              label.appendChild(labelText);
              li.appendChild(button);
              button.appendChild(box);
              button.appendChild(label);
              ul.appendChild(li);
            });
          },
        },
      ],
    });
    setChart(newChart);
    return () => newChart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chart) return;

    chart.options.plugins.tooltip.titleColor = 'black';
    chart.options.plugins.tooltip.bodyColor = '#e2e8f0';
    chart.options.plugins.tooltip.backgroundColor = '#e2e8f0';
    chart.options.plugins.tooltip.borderColor = '#64748b';

    chart.update('none');
  }, []);

  return (
    <>
      <div className="px-5 py-3">
        <div className="flex flex-wrap justify-between items-center">
          <div className="grow ml-2">
            <ul ref={legend} className="flex flex-wrap justify-end" />
          </div>
        </div>
      </div>
      <div className="grow">
        <canvas ref={canvas} width={width} height={height} />
      </div>
    </>
  );
}

export default BarChart;
