import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { boot, shutdown } from '@intercom/messenger-js-sdk';
import { useSelector } from 'react-redux';
import TransactionsTable from './TransactionsTable';
import {
  completeAccountReconcilation,
  deleteTransactionsByIDs,
  getAccountById,
  getAccounts,
  getAccountTransDetails,
  getCustomers,
  getMerchants,
  getTags,
  updateTransactionCategory,
  getPossibleReconcileAccounts,
  getAccountOpeningBalance,
  deleteAccountReconcilation,
  updateAccountReconcilationDraft,
  checkAutoReconcile,
  getReconciliationHistory,
  getJournalEntries,
  bulkUpdateReviewJournalEntry,
  getAllProjects,
  getTaxes,
  changeJournalEntryCategory,
} from '../../../API/backend_helper';
import PaginationClassic from '../../../components/PaginationClassic';
import EndingStatementModal from './EndingStatementModal';
import DeleteTransactionModal from '../../transactions/DeleteTransactionModal';
import AddTransactionModal from '../../transactions/AddTransactionModal';
import {
  calculatePercentage,
  getContrast,
  getCurrencySymbolFromIso,
  Income,
  TEAM_CURRENCY,
  transformAccounts,
} from '../../../utils/Utils';
import AddTransactionButton from '../../transactions/AddTransactionButton';
import AdjustmentModal from './AdjustmentModal';
import Tooltip from '../../../components/Tooltip';
import AutoReconcileModal from './AutoReconcileModal';
import TransactionDetails from '../../transactions/TransactionPanel';
import SearchForm from '../../../partials/actions/SearchForm';
import { BackBtn, Checkmark } from './Svg';
import CreateRuleModal from '../../../components/CreateRuleModal';
import CreateRulePopup from '../../../components/CreateRulePopup';
import AddTaskModal from '../../inbox/AddTaskModal';
import AppLayout from '../../../components/AppLayout';
import DeleteModal from './DeleteModal';

let searchTimeout = null;

const ReconcileAccounts = () => {
  const pageRef = useRef();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { accountId } = useParams();
  const id = searchParams.get('id');

  const { accessType } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);
  const { currencies } = useSelector((state) => state.Currencies);
  const teamDefaultCurrency =
    team?.currency || localStorage.getItem(TEAM_CURRENCY);

  const [projects, setProjects] = useState([]);

  //  Transactions Table
  const [localLimit, setLocalLimit] = useState(50);
  const [allTransactions, setAllTransactions] = useState([]);
  const [deleteTransactionModal, setDeleteTransactionModal] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [reviewLoading, setReviewLoading] = useState({});

  //  Supporting Data to populate Reconcile
  const [reconcileDetail, setReconcileDetail] = useState(null);
  const [accountTransDetails, setAccountTransDetail] = useState();
  const [account, setAccount] = useState(null);
  const [possibleAccounts, setPossibleAccounts] = useState([]);
  const [openingBalance, setOpeningBalance] = useState(null);

  // Modals logic
  const [showOnlyUnrev, setShowOnlyUnrev] = useState(false);
  const [addTransactionModalOpen, setAddTransactionModalOpen] = useState(false);
  const [transType, setTransType] = useState(Income);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [adjustmentModalOpen, setAdjustmentModalOpen] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [autoReconcileModal, setAutoReconcileModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Data needed for Adding new transaction and changing categories
  const [accounts, setAccounts] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allExpenseCategories, setAllExpenseCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);

  // Supporting Data needed to sync plaform together
  const [vendors, setVendors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [tags, setTags] = useState([]);
  const [taxes, setTaxes] = useState([]);

  // Transaction Panel
  const [selectedRow, setSelectedRow] = useState(null);
  const [transactionPanelOpen, setTransactionPanelOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('details');
  const [fromDropdown, setFromDropdown] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);

  // Rule Popup
  const [popupOpen, setPopupOpen] = useState(false);
  const [ruleModalOpen, setRuleModalOpen] = useState(false);
  const [popupCategory, setPopupCategory] = useState(null);
  const [popupTransaction, setPopupTransaction] = useState(null);

  // Delete Modal Loader
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);

  useEffect(() => {
    if (transactionPanelOpen) {
      shutdown();
    } else {
      boot();
    }
  }, [transactionPanelOpen]);

  const loadTags = async () => {
    try {
      const res = await getTags();
      setTags(res);
    } catch (err) {
      if (isLoading) {
        setIsLoading(false);
      }
      console.log('err', err);
    }
  };

  const loadAllProjects = async () => {
    try {
      const res = await getAllProjects({});
      setProjects(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handlePopupLogic = (category, transaction) => {
    setPopupCategory(category);
    setPopupTransaction(transaction);
    setPopupOpen(true);
  };

  const getTransactionsApi = async ({
    limit = localLimit,
    page = allTransactions?.page || 1,
    status = '',
    search = searchVal || '',
    startDate = id
      ? reconcileDetail?.draftOpeningBalanceDate
      : openingBalance?.openingBalanceDate
        ? openingBalance?.openingBalanceDate
        : '',
    endDate = reconcileDetail?.endingBalanceDate,
  }) => {
    if (status === 'review') {
      status = 'false';
    } else {
      status = '';
    }
    if (startDate === '1900-01-01' || startDate === endDate) {
      startDate = '';
    }

    try {
      const transRes = await getJournalEntries({
        accounts: [accountId],
        startDate,
        endDate,
        limit,
        page,
        search,
        pending: false,
        reviewed: status,
        reconciled: id ? 'true' : 'false',
      });
      setAllTransactions(transRes?.data);
    } catch (e) {
      if (isLoading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const onSearch = (val) => {
    setSearchVal(val);
    clearTimeout(searchTimeout);
    searchTimeout = null;
    searchTimeout = setTimeout(async () => {
      getTransactionsApi({
        search: encodeURIComponent(val || ''),
        page: 1,
      });
    }, 900);
  };

  const onShowUnreviewed = (bool) => {
    setShowOnlyUnrev(bool);
    getTransactionsApi({
      status: bool ? 'review' : '',
    });
  };

  const getMerchantsApi = async () => {
    try {
      const res = await getMerchants({ status: 'active' });
      setVendors(res?.vendors);
    } catch (e) {
      if (isLoading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const getPossibleAccounts = async () => {
    try {
      const res = await getPossibleReconcileAccounts();
      setPossibleAccounts(res);
    } catch (err) {
      if (isLoading) {
        setIsLoading(false);
      }
      console.log('err', err);
    }
  };

  const getCustomersApi = async () => {
    try {
      const res = await getCustomers({ status: 'active' });
      setCustomers(res?.data?.records);
    } catch (e) {
      if (isLoading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const loadTaxes = async () => {
    try {
      const res = await getTaxes();
      setTaxes(res?.data?.taxes);
    } catch (err) {
      console.log('err', err);
    }
  };

  const findReconcileDetails = async () => {
    const matchedAccount = possibleAccounts.find(
      (account) => account.id === Number(accountId),
    );
    if (id) {
      const historyRes = await getReconciliationHistory(accountId);
      const matchedHistory = historyRes?.find(
        (history) => history.id === Number(id),
      );
      setReconcileDetail(matchedHistory);
    } else {
      const draftReconcileDetail = matchedAccount?.reconcileBalances.find(
        (balance) => !balance.complete && balance.draft,
      );
      setReconcileDetail(draftReconcileDetail);
    }
  };

  const getAccountTransactionDetails = async ({
    startDate = id
      ? reconcileDetail?.draftOpeningBalanceDate
      : openingBalance?.openingBalanceDate
        ? openingBalance?.openingBalanceDate
        : '',
    endDate = reconcileDetail?.endingBalanceDate,
  }) => {
    try {
      if (startDate === '1900-01-01') {
        startDate = '';
      }
      const res = await getAccountTransDetails(accountId, startDate, endDate);
      setAccountTransDetail(res?.data?.counts);
    } catch (e) {
      if (isLoading) {
        setIsLoading(false);
      }
      console.log('error', e);
    }
  };

  const getOpeningBalance = async () => {
    if (accountId && reconcileDetail?.endingBalanceDate) {
      try {
        const res = await getAccountOpeningBalance(
          accountId,
          reconcileDetail?.endingBalanceDate,
        );
        setOpeningBalance(res);
        if (!res?.firstReconcile) {
          getTransactionsApi({ startDate: res?.openingBalanceDate });
          getAccountTransactionDetails({ startDate: res?.openingBalanceDate });
        } else {
          getTransactionsApi({});
          getAccountTransactionDetails({});
        }
      } catch (e) {
        if (isLoading) {
          setIsLoading(false);
        }
        console.log('error', e);
      }
    }
  };

  const checkForAutoComplete = async () => {
    try {
      const res = await checkAutoReconcile(accountId);
      if (res) {
        setAutoReconcileModal(true);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const loadData = async () => {
    try {
      const accountRes = await getAccountById(accountId);
      await getOpeningBalance();
      setAccount(accountRes);
      const accountsRes = await getAccounts();
      setAllAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
      setAccounts(transformAccounts(accountsRes, 'PHYSICAL_ACCOUNT'));
      setAllCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT'),
      );
      setAllExpenseCategories(
        transformAccounts(accountsRes, 'CATEGORY_PHYSICAL_ACCOUNT', 'expense'),
      );
      getMerchantsApi();
      getCustomersApi();
      loadTaxes();
      loadTags();
      loadAllProjects();
      setSelectedTransactions([]);
      await checkForAutoComplete();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log('error', e);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedTransactions(allTransactions?.results?.map((li) => li.id));
    if (selectAll) {
      setSelectedTransactions([]);
    }
  };

  const handleTransactionCheckbox = (e) => {
    e.stopPropagation();
    const { id } = e.target;
    setSelectAll(false);
    const parsedId = parseInt(id);
    if (selectedTransactions.includes(parsedId)) {
      setSelectedTransactions(
        selectedTransactions.filter((item) => item !== parsedId),
      );
    } else {
      setSelectedTransactions((prev) => [...prev, parsedId]);
    }
  };

  const deleteTransactions = async (id) => {
    setDeleteModalLoading(true);
    try {
      const data = {
        ids: [id],
      };
      await deleteTransactionsByIDs(data);
      getAccountTransactionDetails({});
      getTransactionsApi({});
      setSelectedTransactions([]);
      setSelectAll(false);
      setDeleteTransactionModal('');
      setDeleteModalLoading(false);
    } catch (err) {
      setDeleteTransactionModal('error');
      setDeleteModalLoading(false);
      console.log('err', err);
      // toast.error("Deleting selected transactions is not permitted");
    }
  };

  // Mark Reviewed Selected Items
  const handleMarkReviewedSelected = async ({ reviewed = true }) => {
    try {
      setReviewLoading((prevState) => {
        const newState = { ...prevState };
        selectedTransactions.forEach((id) => {
          newState[id] = true;
        });
        return newState;
      });
      const matchedAccount = possibleAccounts.find(
        (account) => account.id === Number(accountId),
      );
      await bulkUpdateReviewJournalEntry({
        journalEntryIds: selectedTransactions,
        reviewed,
        reconciliationId: matchedAccount?.reconcileBalances?.[0]?.id,
      });
      getAccountTransactionDetails({});
      await getTransactionsApi({
        status: showOnlyUnrev ? 'review' : '',
      });
      setSelectedTransactions([]);
      setSelectAll(false);
      setReviewLoading((prevState) => {
        const newState = { ...prevState };
        selectedTransactions.forEach((id) => {
          newState[id] = false;
        });
        return newState;
      });
      if (reviewed) {
        toast.success('Transactions marked as reviewed');
      } else {
        toast.success('Transactions marked as unreviewed');
      }
    } catch (e) {
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  const onChangeCategory = async (
    transactionId,
    categoryAccountId,
    isJournalEntry = false,
  ) => {
    try {
      if (isJournalEntry) {
        await changeJournalEntryCategory(transactionId, {
          categoryAccountId,
        });
      } else {
        await updateTransactionCategory(transactionId, {
          categoryAccountId,
        });
      }
      getAccountTransactionDetails({});
      await getTransactionsApi({});
    } catch (e) {
      console.log('error', e);
    }
  };

  const onCompleteReconcilation = async (allowUnreviewed = false) => {
    setCompleteLoading(true);
    try {
      await completeAccountReconcilation({
        accountId,
        allowUnreviewed,
      });
      setCompleteLoading(false);
      toast.success('Account Reconciled');
      navigate('/reconciliation');
    } catch (e) {
      setCompleteLoading(false);
      console.log('error', e);
    }
  };

  const updateReconcileDraft = async (id, data) => {
    try {
      const res = await updateAccountReconcilationDraft(id, data);
      setReconcileDetail(res);
    } catch (e) {
      console.log('error', e);
    }
  };

  const { page, totalRecords, results } = allTransactions;

  //  const openingBalance = id ? reconcileDetail?.draftOpeningBalanceAmount : openingBalance?.openingBalanceAmount;
  const difference = () => {
    const openingBalanceAmt = id
      ? parseFloat(reconcileDetail?.draftOpeningBalanceAmount) || 0
      : parseFloat(openingBalance?.openingBalanceAmount);
    if (account?.type === 'Assets') {
      const reviewedAmount = reconcileDetail?.complete
        ? reconcileDetail?.reviewedSum
        : id
          ? (accountTransDetails?.reviewedData?.amount).toString()
          : accountTransDetails?.reviewedData?.amount?.toString();
      const differenceAmount =
        parseFloat(reconcileDetail?.endingBalanceAmount).toFixed(2) -
        (openingBalanceAmt + parseFloat(reviewedAmount));
      return differenceAmount;
    }
    const reviewedAmount = reconcileDetail?.complete
      ? reconcileDetail?.reviewedSum
      : accountTransDetails?.reviewedData?.amount?.toString();
    const differenceAmount =
      parseFloat(reconcileDetail?.endingBalanceAmount).toFixed(2) -
      (openingBalanceAmt + parseFloat(reviewedAmount));
    return differenceAmount;
  };

  useEffect(() => {
    difference();
  }, [reconcileDetail]);

  useEffect(() => {
    getPossibleAccounts();
  }, []);

  useEffect(() => {
    if (reconcileDetail?.endingBalanceDate) {
      loadData();
    }
  }, [reconcileDetail]);

  useEffect(() => {
    setIsLoading(true);
    if (accountId && possibleAccounts.length > 0) {
      findReconcileDetails();
    }
  }, [accountId, possibleAccounts]);

  const onSaveLater = () => {
    toast.info('Saved For Later!');
    navigate('/reconciliation');
  };

  const onDelete = async () => {
    try {
      if (!id) {
        await deleteAccountReconcilation(accountId);
      } else {
        await deleteAccountReconcilation(accountId, id);
      }
      navigate('/reconciliation');
    } catch (err) {
      toast.error(err);
    }
  };

  const getReviewedBalance = () => {
    const revAmount = reconcileDetail?.complete
      ? reconcileDetail?.reviewedSum
      : id
        ? parseFloat(accountTransDetails?.reviewedData?.amount)
        : parseFloat(accountTransDetails?.reviewedData?.amount);
    // Works for making the addition of income/expenses but doesn't actually look right with the difference
    return parseFloat(revAmount).toFixed(2);
  };

  const onReviewed = async (transactionId, status) => {
    try {
      setReviewLoading((prevState) => {
        const newState = { ...prevState };
        newState[transactionId] = true;
        return newState;
      });
      await bulkUpdateReviewJournalEntry({
        journalEntryIds: [transactionId],
        reviewed: status,
      });
      await getTransactionsApi({ status: showOnlyUnrev ? 'review' : '' });
      getAccountTransactionDetails({});
      setReviewLoading((prevState) => {
        const newState = { ...prevState };
        newState[transactionId] = false;
        return newState;
      });
      if (status) {
        toast.success('Transaction marked as reviewed');
      } else {
        toast.success('Transaction marked as unreviewed');
      }
    } catch (e) {
      console.log('error', e);
      toast.error(
        'An error occurred while saving. Please refresh and try again.',
      );
    }
  };

  const handlePageChange = (args) => {
    const status = showOnlyUnrev ? 'review' : '';
    getTransactionsApi({
      ...args,
      status,
    });
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModalOpen}
        setIsOpen={setDeleteModalOpen}
        handleDone={onDelete}
        dateRange={
          id
            ? reconcileDetail?.draftOpeningBalanceDate &&
              reconcileDetail?.draftOpeningBalanceDate !== '1900-01-01'
              ? ` between period of ${moment(
                  reconcileDetail?.draftOpeningBalanceDate,
                ).format('MMM Do, YYYY')} and
               ${moment(reconcileDetail?.endingBalanceDate).format(
                 'MMM Do, YYYY',
               )} `
              : `  up to ${moment(reconcileDetail?.endingBalanceDate).format(
                  'MMM Do, YYYY',
                )} `
            : openingBalance?.firstReconcile
              ? `  up to
              ${moment(reconcileDetail?.endingBalanceDate).format(
                'MMM Do, YYYY',
              )} `
              : ` between
              ${moment(openingBalance?.openingBalanceDate).format(
                'MMM Do, YYYY',
              )} and ${moment(reconcileDetail?.endingBalanceDate).format(
                'MMM Do, YYYY',
              )} `
        }
      />
      <DeleteTransactionModal
        isOpen={deleteTransactionModal}
        setIsOpen={setDeleteTransactionModal}
        handleDeleteTransaction={deleteTransactions}
        deleteModalLoading={deleteModalLoading}
      />
      <AdjustmentModal
        isOpen={adjustmentModalOpen}
        setIsOpen={setAdjustmentModalOpen}
      />
      <AutoReconcileModal
        modalOpen={autoReconcileModal}
        setModalOpen={setAutoReconcileModal}
        completeReconcile={onCompleteReconcilation}
      />
      <EndingStatementModal
        account={account}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isEdit
        editSubmit={updateReconcileDraft}
        reconcileDetail={reconcileDetail}
        openingBalance={openingBalance}
        teamCurrency={currencies?.find?.(
          (currency) => currency.isoCode === teamDefaultCurrency,
        )}
      />
      <AddTransactionModal
        isOpen={addTransactionModalOpen}
        setIsOpen={setAddTransactionModalOpen}
        allCategories={allCategories}
        accounts={accounts}
        allAccounts={[
          {
            id: 0,
            name: 'Reconcile Account',
            categories: account ? [account] : [],
          },
        ]}
        getTransactionsApi={() => {
          getTransactionsApi({});
        }}
        vendors={vendors || []}
        setVendors={setVendors}
        transType={transType}
        setTransType={setTransType}
        currencies={currencies}
        team={team}
        customers={customers}
        setCustomers={setCustomers}
        allExpenseCategories={allExpenseCategories}
        minDate={reconcileDetail?.draftOpeningBalanceDate}
        maxDate={moment(reconcileDetail?.endingBalanceDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD')}
        accountId={accountId}
      />
      <CreateRuleModal
        open={ruleModalOpen}
        setOpen={setRuleModalOpen}
        category={popupCategory}
        transaction={popupTransaction}
        reloadTransactions={getTransactionsApi}
      />
      <AddTaskModal
        modalOpen={taskModalOpen}
        setModalOpen={setTaskModalOpen}
        transactions
        selectedTransaction={selectedRow?.transaction}
        projects={projects}
      />
      {/* <EndingStatementModal
          isOpen={endingStatementModalOpen}
          setIsOpen={setEndingStatementModalOpen}
        /> */}
      {popupOpen && (
        <CreateRulePopup
          open={popupOpen}
          setOpen={setPopupOpen}
          category={popupCategory}
          transaction={popupTransaction}
          openModal={setRuleModalOpen}
        />
      )}
      {selectedRow && (
        <TransactionDetails
          transaction={selectedRow?.transaction}
          setTransaction={setSelectedRow}
          transactionPanelOpen={transactionPanelOpen}
          setTransactionPanelOpen={setTransactionPanelOpen}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          allCategories={allCategories}
          allExpenseCategories={allExpenseCategories}
          vendors={vendors}
          fromDropdown={fromDropdown}
          setFromDropdown={setFromDropdown}
          tags={tags}
          reloadTransaction={getTransactionsApi}
          setSplitTransactionModalOpen={() => {}}
          customers={customers}
          reconcile
          ruleModalOpen={ruleModalOpen}
          setRuleModalOpen={setRuleModalOpen}
          setPopupOpen={setPopupOpen}
          setPopupCategory={setPopupCategory}
          setPopupTransaction={setPopupTransaction}
          handlePopupLogic={handlePopupLogic}
          journalEntry
          taskModalOpen={taskModalOpen}
          setTaskModalOpen={setTaskModalOpen}
          team={team}
          currencies={currencies}
          taxes={taxes}
          loadTaxes={loadTaxes}
        />
      )}
      <AppLayout pageId="reconcileAccountsPageContainer" ref={pageRef}>
        <main className="relative grow">
          {/* Content */}
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-full flex flex-col">
            {/* Page header */}
            <div className="flex xl:justify-between gap-2 xl:flex-wrap items-center mb-10">
              <div className="flex items-center gap-2">
                {id && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      navigate('/reconciliation');
                    }}
                    role="button"
                    tabIndex="0"
                    onKeyDown={null}
                  >
                    <BackBtn />
                  </div>
                )}
                <h1 className="text-black text-opacity-60 text-4xl font-medium leading-[2.375rem]">
                  Reconcile
                </h1>
              </div>
              {!isLoading && (
                <>
                  <div className="flex items-center gap-4">
                    <div className="flex">
                      <div className="flex gap-4 items-center">
                        {account?.institution?.logoUrl ? (
                          <img
                            src={account?.institution?.logoUrl}
                            alt={account?.name}
                            className="w-[1.875rem] h-[1.875rem] rounded-full flex items-center justify-center"
                          />
                        ) : (
                          <div
                            className="w-[1.875rem] h-[1.875rem] min-w-[1.875rem] min-h-[1.875rem] rounded-full flex items-center justify-center bg-slate-400 dark:bg-slate-600"
                            style={
                              account?.color || account?.institution?.color
                                ? {
                                    backgroundColor:
                                      account?.color ||
                                      account?.institution?.color,
                                  }
                                : {}
                            }
                          >
                            <div
                              className={`${getContrast(
                                account?.color || account?.institution?.color,
                                'text-black',
                                'text-white',
                              )} text-xs uppercase font-normal`}
                            >
                              {account?.officialName
                                ? account?.officialName
                                    ?.replace?.(/[^\w\s]/gi, '')
                                    .split(' ')
                                    .filter(Boolean)
                                    .slice(0, 2)
                                    .map((word) => word.charAt(0))
                                    .join('')
                                : ''}
                            </div>
                          </div>
                        )}
                        <div className="border-r gap-1 border-[#D0D5DD] pr-4 flex flex-col justify-center items-start">
                          <p className="text-slate-600 text-[15px] font-medium leading-tight">
                            {account?.name}
                          </p>
                          <p className="text-slate-600 leading-tight text-opacity-90 text-[13px] font-normal">
                            {account?.mask ? `...${account.mask}` : ''}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className="text-[#667085] font-medium leading-tight">
                        {account?.type}
                      </p>
                      <p className="text-sm text-[#667085E5]">
                        {account?.subType?.name}
                      </p>
                    </div>
                  </div>
                  {!id ? (
                    <div className="flex items-center gap-4">
                      <button
                        className="h-10 whitespace-nowrap w-[141px] bg-white border border-slate-600 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-slate-600 text-base leading-6 flex items-center justify-center gap-2 font-normal"
                        onClick={() => setDeleteModalOpen(true)}
                        type="button"
                      >
                        Delete Draft
                      </button>
                      <button
                        className="h-10 whitespace-nowrap w-[141px] bg-white border border-indigo-500 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-indigo-500 text-base leading-6 flex items-center justify-center gap-2 font-normal"
                        onClick={onSaveLater}
                        type="button"
                      >
                        Save For Later
                      </button>
                      <Tooltip
                        content={`${
                          parseInt(
                            accountTransDetails?.unReviewedData?.count,
                          ) !== 0
                            ? 'All Transactions must be reviewed'
                            : 'Difference must be zero'
                        }`}
                        contentClassName="border-none overflow-visible text-sm text-[#667085] relative"
                        position="bottom"
                        size="sm"
                        tooltipShow={
                          parseInt(
                            accountTransDetails?.unReviewedData?.count,
                          ) !== 0 || difference() !== 0
                        }
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (difference() !== 0) {
                              setAdjustmentModalOpen(true);
                            } else {
                              onCompleteReconcilation();
                            }
                          }}
                          disabled={
                            completeLoading ||
                            parseInt(
                              accountTransDetails?.unReviewedData?.count,
                            ) !== 0 ||
                            difference() !== 0
                          }
                          className="h-10 whitespace-nowrap px-[18px]  border disabled:bg-indigo-400 disabled:cursor-not-allowed bg-indigo-500 disabled:border-indigo-400 border-indigo-500 rounded-[0.313rem]  py-2.5 shadow text-white text-base leading-6 flex items-center justify-center gap-2 font-normal"
                          type="button"
                        >
                          {completeLoading && (
                            <svg
                              className="animate-spin w-4 h-4 fill-current shrink-0"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                            </svg>
                          )}
                          Complete Reconciliation
                        </button>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex items-center gap-4">
                      <button
                        className="h-10 whitespace-nowrap w-[160px] bg-white border border-slate-600 rounded-[0.313rem] px-[1.125rem] py-2.5 shadow text-slate-600 text-base leading-6 flex items-center justify-center gap-2 font-normal"
                        onClick={() => setDeleteModalOpen(true)}
                        type="button"
                      >
                        Undo Reconciliation
                      </button>{' '}
                    </div>
                  )}
                </>
              )}
            </div>

            {/* Stats Box  */}
            {isLoading ? (
              <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="animate-spin w-10 h-10 fill-current shrink-0"
                >
                  <path
                    d="M14.5 2.41699V7.25033"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.5 21.749V26.5824"
                    stroke="#E48642"
                    strokeWidth="2.55882"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 5.95703L9.37662 9.37662"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 19.6211L23.0426 23.0407"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.41699 14.5H7.25033"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.749 14.5H26.5824"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95703 23.0407L9.37662 19.6211"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.623 9.37662L23.0426 5.95703"
                    stroke="#E48642"
                    strokeWidth="1.58955"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-slate-600 font-medium text-[0.875rem] text-center">
                  Fetching data, please wait...
                </p>
              </div>
            ) : (
              <>
                <div className="mb-9 py-5 px-7 border border-[#D0D5DD] rounded-[12px]">
                  {id ? (
                    reconcileDetail?.draftOpeningBalanceDate &&
                    reconcileDetail?.draftOpeningBalanceDate !==
                      '1900-01-01' ? (
                      <h3 className="font-medium text-[#00000099] leading-tight mb-2">
                        Transactions between{' '}
                        {moment(
                          reconcileDetail?.draftOpeningBalanceDate,
                        ).format('MMM Do, YYYY')}{' '}
                        and{' '}
                        {moment(reconcileDetail?.endingBalanceDate).format(
                          'MMM Do, YYYY',
                        )}
                      </h3>
                    ) : (
                      <h3 className="font-medium text-[#00000099] leading-tight mb-2">
                        Transactions up to{' '}
                        {moment(reconcileDetail?.endingBalanceDate).format(
                          'MMM Do, YYYY',
                        )}
                      </h3>
                    )
                  ) : openingBalance?.firstReconcile ? (
                    <h3 className="font-medium text-[#00000099] leading-tight mb-2">
                      Transactions up to{' '}
                      {moment(reconcileDetail?.endingBalanceDate).format(
                        'MMM Do, YYYY',
                      )}
                    </h3>
                  ) : (
                    <h3 className="font-medium text-[#00000099] leading-tight mb-2">
                      Transactions between{' '}
                      {moment(openingBalance?.openingBalanceDate).format(
                        'MMM Do, YYYY',
                      )}{' '}
                      and{' '}
                      {moment(reconcileDetail?.endingBalanceDate).format(
                        'MMM Do, YYYY',
                      )}
                    </h3>
                  )}
                  <div className="flex justify-between flex-wrap gap-y-4 lg:gap-y-0">
                    <div className="py-4 w-[48%] lg:w-auto">
                      <p className="text-[#667085] font-medium leading-tight mb-5">
                        Statement Opening Balance
                      </p>
                      {id ? (
                        <h3 className="text-[28px] font-semibold text-[#101828] leading-tight">
                          {getCurrencySymbolFromIso(
                            teamDefaultCurrency,
                            currencies,
                          )}
                          {parseFloat(
                            reconcileDetail?.draftOpeningBalanceAmount || 0,
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </h3>
                      ) : (
                        <h3 className="text-[28px] font-semibold text-[#101828] leading-tight">
                          {getCurrencySymbolFromIso(
                            teamDefaultCurrency,
                            currencies,
                          )}
                          {parseFloat(
                            openingBalance?.openingBalanceAmount,
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </h3>
                      )}
                    </div>
                    <div className=" w-fit flex flex-col justify-end py-4">
                      <span className="text-[28px] font-semibold h-fit text-[#101828] leading-tight mb-1">
                        +
                      </span>
                    </div>
                    <div className="py-4   w-[48%] lg:w-auto">
                      <p className="text-[#667085] font-medium leading-tight mb-5">
                        Reviewed Transactions Balanace
                      </p>
                      <h3 className="text-[28px] font-semibold text-[#101828] leading-tight">
                        {account?.type === 'Assets' ? (
                          <>
                            {getReviewedBalance() === 0
                              ? ''
                              : getReviewedBalance()?.toString().charAt(0) ===
                                  '-'
                                ? '-'
                                : ''}
                            {getCurrencySymbolFromIso(
                              teamDefaultCurrency,
                              currencies,
                            )}
                            {getReviewedBalance()?.toString().replace('-', '')}
                          </>
                        ) : (
                          <>
                            {' '}
                            {getCurrencySymbolFromIso(
                              teamDefaultCurrency,
                              currencies,
                            )}
                            {getReviewedBalance()}
                          </>
                        )}
                      </h3>
                    </div>
                    <div className=" w-fit flex flex-col justify-end py-4">
                      <span className="text-[28px] font-semibold h-fit text-[#101828] leading-tight mb-1">
                        =
                      </span>
                    </div>
                    <div className="py-4 w-[48%] lg:w-auto ">
                      <p className="text-[#667085] font-medium leading-tight mb-5">
                        Statement Ending Balance
                      </p>
                      <div className="items-center flex relative gap-1">
                        <h3 className="text-[28px] font-semibold text-[#101828] leading-tight">
                          {getCurrencySymbolFromIso(
                            teamDefaultCurrency,
                            currencies,
                          )}
                          {parseFloat(
                            reconcileDetail?.endingBalanceAmount,
                          )?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </h3>
                        {!id && (
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cursor-pointer"
                            onClick={() => setIsOpen(true)}
                          >
                            <path
                              d="M9.94507 3.52853L3.78757 10.046C3.55507 10.2935 3.33007 10.781 3.28507 11.1185L3.00757 13.5485C2.91007 14.426 3.54007 15.026 4.41007 14.876L6.82507 14.4635C7.16257 14.4035 7.63507 14.156 7.86757 13.901L14.0251 7.38353C15.0901 6.25853 15.5701 4.97603 13.9126 3.40853C12.2626 1.85603 11.0101 2.40353 9.94507 3.52853Z"
                              stroke="#667085"
                              strokeOpacity="0.8"
                              strokeWidth="1.125"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.91742 4.61523C9.23992 6.68523 10.9199 8.26773 13.0049 8.47773"
                              stroke="#667085"
                              strokeOpacity="0.8"
                              strokeWidth="1.125"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2.25 17.3281H15.75"
                              stroke="#667085"
                              strokeOpacity="0.8"
                              strokeWidth="1.125"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </div>

                    <div className=" w-fit flex-col justify-end py-4 hidden lg:flex">
                      <span className="w-[1px] h-10 bg-[#D0D5DD]" />
                    </div>
                    <div className="py-4   w-[48%] lg:w-auto">
                      <p className="text-[#667085] font-medium leading-tight mb-5">
                        Difference
                      </p>
                      <h3
                        className={`text-[28px] ${
                          parseFloat(difference()) === 0
                            ? 'text-[#78BD4F]'
                            : 'text-indigo-500'
                        }  font-semibold leading-tight`}
                      >
                        {difference()?.toString().charAt(0) === '-' ? '-' : ''}
                        {getCurrencySymbolFromIso(
                          teamDefaultCurrency,
                          currencies,
                        )}
                        {parseFloat(difference())
                          ?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                          ?.toString()
                          .replace('-', '')}
                      </h3>
                    </div>
                    <div className=" w-[1px] bg-[#D0D5DD]" />
                    {!id ? (
                      <div className="py-4  w-[48%] lg:w-auto">
                        <p className="text-[#667085] font-medium leading-tight mb-5">
                          Transactions Reviewed
                        </p>
                        <div>
                          <p className="text-sm mb-3 font-medium text-[#667085] leading-tight">
                            {accountTransDetails?.reviewedData?.count} of{' '}
                            {parseInt(
                              accountTransDetails?.reviewedData?.count,
                            ) +
                              parseInt(
                                accountTransDetails?.unReviewedData?.count,
                              )}
                          </p>
                          <div className="h-2.5 w-[215px] rounded-full bg-[#D0D5DD]">
                            <div
                              className="h-2.5 rounded-full bg-[#78BD4F]"
                              style={{
                                width: `${calculatePercentage(
                                  parseInt(
                                    accountTransDetails?.reviewedData?.count,
                                  ),
                                  parseInt(
                                    accountTransDetails?.reviewedData?.count,
                                  ) +
                                    parseInt(
                                      accountTransDetails?.unReviewedData
                                        ?.count,
                                    ),
                                )}%`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="py-4 w-[48%] lg:w-auto mr-4">
                        <p className="text-[#667085] font-medium leading-tight text-center mb-5 mx-auto">
                          Reconciliation Completed
                        </p>
                        <div className="w-full flex items-center justify-center">
                          <Checkmark width="30" height="30" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {!id && (
                  <div className="mb-6 w-full flex justify-between">
                    <AddTransactionButton
                      hideTransfer
                      hideSyncBankAccount
                      transType={transType}
                      setTransType={setTransType}
                      accessType={accessType}
                      handleClick={(val) => {
                        setTransType(val);
                        setAddTransactionModalOpen(true);
                      }}
                    />
                    <SearchForm
                      searchVal={searchVal}
                      setSearchVal={onSearch}
                      placeholder="Search"
                      transaction
                    />
                  </div>
                )}
                <TransactionsTable
                  transactions={results}
                  selectedTransactions={selectedTransactions}
                  handleSelectAll={handleSelectAll}
                  selectAll={selectAll}
                  setSelectAll={setSelectAll}
                  accessType={accessType}
                  setSelectedTransactions={setSelectedTransactions}
                  currencies={currencies}
                  handleTransactionCheckbox={handleTransactionCheckbox}
                  setDeleteTransactionModal={setDeleteTransactionModal}
                  handleMarkReviewedSelected={handleMarkReviewedSelected}
                  showOnlyUnrev={showOnlyUnrev}
                  onShowUnreviewed={onShowUnreviewed}
                  allAccounts={allAccounts}
                  onChangeCategory={onChangeCategory}
                  allCategories={allCategories}
                  allExpenseCategories={allExpenseCategories}
                  onReviewed={onReviewed}
                  id={id}
                  setSelectedRow={setSelectedRow}
                  setTransactionPanelOpen={setTransactionPanelOpen}
                  onSearch={onSearch}
                  searchVal={searchVal}
                  deleteTransactions={deleteTransactions}
                  reviewLoading={reviewLoading}
                  setReviewLoading={setReviewLoading}
                  handlePopupLogic={handlePopupLogic}
                />
                {/* Pagination */}
                <div className="mt-8">
                  {!isLoading && page >= 0 && (
                    <PaginationClassic
                      pagination={{ limit: localLimit, page, totalRecords }}
                      onPageChange={handlePageChange}
                      setLimit={setLocalLimit}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default ReconcileAccounts;
