import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { checkPermission, PermissionKeys } from 'utils/PermissionsUtils';
import ModalBasic from '../../components/ModalBasic';
import DropdownFull from '../../components/DropdownFull';
import CreateBillTable from './CreateBillTable';
import {
  createBill,
  createMerchant,
  getBillById,
  updateBillById,
  uploadMerchantLogo,
  getMerchants,
  updateBillTags,
} from '../../API/backend_helper';
import TextInputDash from '../../components/TextInputDash';
import MerchantsDropdown from '../../components/MerchantsDropdown';
import ReccuringModal from './ReccuringModal';
import DatePickerDash from '../../components/DatePickerDash';
import WarningBox from '../../components/WarningBox';
import VendorForm from '../vendors/VendorForm';
import ReceiptModal from '../transactions/ReceiptModal';
import BasicCheckboxDropdown from '../component/BasicCheckboxDropdown';
import {
  formatDateLocally,
  getFormattedDate,
  TEAM_CURRENCY,
  TEAM_DATE_FORMAT,
} from '../../utils/Utils';
import BillsWarningModal from './BillsWarningModal';

const TABLE_ROW_SCHEMA = {
  description: { value: '', error: false },
  categoryAccountId: { value: null, error: false },
  quantity: { value: '', error: false },
  price: { value: '', error: false },
  tax: { value: '', error: false },
  customerId: { value: null, error: false },
  projectId: { value: null, error: false },
  tags: { value: [], error: false },
};

const BillsModal = ({
  vendors,
  setVendors,
  activeBillId,
  setActiveBillId,
  loadAllBills,
  setIsActive,
  currencies,
  expenseCategories,
  projects,
  uploadedBillData,
  setUploadedBillData,
  tags,
  setReceiptModalOpen,
  setActiveReceipt,
  billModalPdfView,
  setBillModalPdfView,
  customers,
  setCustomers,
}) => {
  const formikRef = useRef();
  const { accessType, permissions } = useSelector((state) => state.User);
  const { team } = useSelector((state) => state.Team);

  const teamDateFormat =
    team?.defaults?.dateFormat || localStorage.getItem(TEAM_DATE_FORMAT);

  const [loading, setLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [initialLoader, setInitialLoader] = useState(false);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [billRows, setBillRows] = useState([
    JSON.parse(JSON.stringify(TABLE_ROW_SCHEMA)),
  ]);
  const [tax, setTax] = useState();
  const [discount, setDiscount] = useState(uploadedBillData?.discount);
  const [activeBill, setActiveBill] = useState({});
  const [reccurringModalOpen, setReccurringModalOpen] = useState(false);
  const [vendorModalOpen, setVendorModalOpen] = useState(false);
  const [pdfBlockLeftDistance, setPdfBlockLeftDistance] = useState(32);
  const [pdfBlockHeight, setPdfBlockHeight] = useState(400);
  const [billsWarningModalOpen, setBillsWarningModalOpen] = useState(false);

  const options = useMemo(
    () => ({
      mode: 'single',
      enableTime: false,
      static: true,
      monthSelectorType: 'static',
      allowInput: true,
      dateFormat: teamDateFormat === 'dd/mm/yyyy' ? 'd/m/Y' : 'm/d/Y',
      prevArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    }),
    [team],
  );

  const getWarningMessage = (
    firstDueDate,
    repeat,
    ends,
    lastDate,
    occurrences,
  ) =>
    `Recurring bill starts on ${
      firstDueDate ? moment(firstDueDate).format('MMM Do YYYY') : '--'
    }, repeats ${repeat} and ${
      ends === 'after'
        ? `lasts for ${occurrences} occurrences`
        : ends === 'on'
          ? `last payment on ${
              lastDate ? moment(lastDate).format('MMM Do YYYY') : '--'
            }`
          : 'continues indefinitely'
    }`;

  const checkBillRowsError = () => {
    let isError = false;
    if (billRows?.length === 0) {
      return true;
    }
    const tempRows = [...billRows];
    billRows.forEach((d, i) => {
      Object.keys(d || {}).forEach((key) => {
        const tempRow = { ...billRows[i] };
        if (
          d[key].value !== 0 &&
          !d[key].value &&
          key !== 'tax' &&
          key !== 'description' &&
          key !== 'quantity' &&
          key !== 'price' &&
          key !== 'customerId' &&
          key !== 'projectId' &&
          key !== 'tags'
        ) {
          tempRow[key].error = true;
          isError = true;
        }
        if (key === 'categoryAccountId' && !d[key].value) {
          tempRow[key].error = 'Category should not be empty';
          // isError = "Description should not be empty";
          isError = true;
        }
        if (key === 'description' && d[key].value?.trim()?.length === 0) {
          tempRow[key].error = 'Description should not be empty';
          // isError = "Description should not be empty";
          isError = true;
        }
        if (key === 'description' && d[key].value?.length > 300) {
          tempRow[key].error = 'Description must be 300 characters or less';
          // isError = "Description must be 300 characters or less";
          isError = true;
        }
        // if (key === 'price' && d[key].value <= 0) {
        //   tempRow[key].error = 'Price should be above 0';
        //   // isError = "Price should be above 0";
        //   isError = true;
        // }
        // if (key === 'quantity' && d[key].value < 0) {
        //   tempRow[key].error = 'Qty should be above 0';
        //   // isError = "Quantity should be above 0";
        //   isError = true;
        // }
      });
    });
    setBillRows(tempRows);
    return isError;
  };

  const loadUploadedBill = async () => {
    setInitialLoader(true);

    const res = await getMerchants({ status: 'active' });
    setVendors(res?.vendors);

    const tempItems = [];
    if (uploadedBillData?.tax) {
      setTax(uploadedBillData?.tax);
    }
    if (uploadedBillData?.discount) {
      setDiscount(uploadedBillData?.discount);
    }

    uploadedBillData?.items?.forEach((d) => {
      tempItems.push({
        description: { value: d?.description, error: false },
        categoryAccountId: { value: d?.categoryId, error: false },
        quantity: { value: d?.quantity || 0, error: false },
        price: {
          value: parseFloat(d?.unitPrice || d?.amount),
          error: false,
        },
      });
    });
    setBillRows(tempItems);
    setInitialLoader(false);
  };

  const loadActiveBill = async () => {
    setInitialLoader(true);
    try {
      const res = await getBillById(activeBillId);
      if (res?.purchaseOrderNumber || res?.billNumber || res?.notes) {
        setShowAdditionalFields(true);
      }
      const tempItems = [];
      res?.lineItems?.forEach((d) => {
        tempItems.push({
          description: { value: d?.description, error: false },
          categoryAccountId: { value: d?.categoryAccountId, error: false },
          quantity: { value: d?.quantity, error: false },
          price: { value: parseFloat(d?.price), error: false },
          tax: { value: parseFloat(d?.tax), error: false },
          customerId: {
            value: !d?.projectId ? d?.customerId : null,
            error: false,
            customer: d?.customerId ? d?.customer : {},
          },
          projectId: { value: d?.projectId || null, error: false },
          tags: { value: d?.tags?.map((t) => t?.id) || [], error: false },
        });
      });
      if (res?.tax) {
        setTax(parseFloat(res?.tax));
      }
      if (res?.discount) {
        setDiscount(parseFloat(res?.discount));
      }
      setBillRows(tempItems);
      setActiveBill(res);
      setInitialLoader(false);
    } catch (err) {
      setInitialLoader(false);
      console.log('err', err);
    }
  };

  useEffect(() => {
    if (activeBillId) {
      loadActiveBill();
    } else {
      setActiveBill({});
      setBillRows([JSON.parse(JSON.stringify(TABLE_ROW_SCHEMA))]);
    }
  }, [activeBillId]);

  useEffect(() => {
    if (Object.keys(uploadedBillData || {}).length > 0) {
      loadUploadedBill();
    } else {
      setBillRows([JSON.parse(JSON.stringify(TABLE_ROW_SCHEMA))]);
    }
  }, [uploadedBillData]);

  useEffect(() => {
    if (
      activeBill?.receiptUrl ||
      Object.keys(uploadedBillData || {}).length > 0
    ) {
      setBillModalPdfView(true);
    }
  }, [activeBill?.receiptUrl, uploadedBillData]);

  const handleSubmit = async (values, formikHandler) => {
    if (checkBillRowsError()) {
      // setLineItemsError(checkBillRowsError());
      // setTimeout(() => setLineItemsError(false), 5000);
      return;
    }
    const lineItems = billRows.map((d) => ({
      description: d?.description?.value,
      categoryAccountId: d?.categoryAccountId?.value,
      quantity: parseFloat(d?.quantity?.value || 0),
      price: parseFloat(d?.price?.value || 0),
      tax: parseFloat(d?.tax?.value),
      customerId: d?.customerId?.value || null,
      projectId: d?.projectId?.value || null,
      tags: d?.tags?.value,
    }));
    if (values.approvalStatus === 'draft') {
      setDraftLoading(true);
    } else {
      setLoading(true);
    }
    try {
      const {
        vendorId,
        notes,
        recurring,
        firstDueDate,
        billNumber,
        purchaseOrderNumber,
        recurringFrequency,
        ends,
        recurringLastDate,
        recurringOccurrences,
        firstBillDate,
        currency,
        approvalStatus,
      } = values;
      let data = {
        vendorId,
        notes,
        recurring,
        firstDueDate,
        billNumber,
        purchaseOrderNumber,
        lineItems,
        currency,
        tax,
        discount,
        approvalStatus,
      };
      data.firstDueDate = getFormattedDate(firstDueDate, teamDateFormat);
      data.firstBillDate = getFormattedDate(firstBillDate, teamDateFormat);
      if (values.recurring) {
        data = { ...data, recurringFrequency };
        if (ends === 'on') {
          data = { ...data, recurringLastDate };
        } else if (ends === 'after') {
          data = { ...data, recurringOccurrences };
        }
      }
      if (uploadedBillData?.receiptUrl || activeBill?.receiptUrl) {
        data.receiptUrl =
          uploadedBillData?.receiptUrl || activeBill?.receiptUrl;
      }
      let res = {};
      if (activeBillId) {
        res = await updateBillById(activeBillId, data);
      } else {
        res = await createBill(data);
      }
      if (res?.id) {
        await loadAllBills({ loading: false });
        setIsActive('');
        if (activeBillId) {
          setActiveBillId(null);
        }
        if (Object.keys(uploadedBillData || {}).length > 0) {
          setUploadedBillData({});
        }
        if (activeBillId) {
          toast.success('Bill Updated Successfully');
        } else {
          toast.success('Bill Created Successfully');
        }
        setBillRows([JSON.parse(JSON.stringify(TABLE_ROW_SCHEMA))]);
        formikHandler.resetForm();
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoading(false);
      setDraftLoading(false);
    }
  };

  const handleUploadVendorLogo = async (data, id) => {
    try {
      await uploadMerchantLogo(data, id);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleAddVendor = async (data) => {
    try {
      const response = await createMerchant(data);
      setVendors((prev) => [response, ...prev]);
      formikRef?.current?.setFieldValue('vendorId', response?.id);
      return response;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handlePdfViewFixedBlock = () => {
    setPdfBlockLeftDistance((window.innerWidth - 2000) / 2 + 32);
    let blockHeight =
      window.innerWidth > 1400
        ? window.innerHeight - 150
        : window.innerHeight - 350;
    if (
      document.getElementById('billsModalViewImageId') &&
      blockHeight >
        document.getElementById('billsModalViewImageId')?.clientHeight
    ) {
      blockHeight =
        document.getElementById('billsModalViewImageId')?.clientHeight + 10;
    }
    setPdfBlockHeight(blockHeight);
  };

  useEffect(() => {
    handlePdfViewFixedBlock();
    window.addEventListener('resize', handlePdfViewFixedBlock);

    return () => window.removeEventListener('resize', handlePdfViewFixedBlock);
  }, [billModalPdfView]);

  return (
    <>
      <ModalBasic
        modalOpen={vendorModalOpen}
        setModalOpen={setVendorModalOpen}
        title="Add Vendor"
        width="!w-[1088px]"
      >
        <VendorForm
          handleUploadVendorLogo={handleUploadVendorLogo}
          handleAddVendor={handleAddVendor}
          setFormOpen={setVendorModalOpen}
          isModalView
          activeVendor={null}
        />
      </ModalBasic>
      <BillsWarningModal
        isOpen={billsWarningModalOpen}
        setIsOpen={setBillsWarningModalOpen}
        handleDone={() => {
          setIsActive('');
          if (Object.keys(uploadedBillData || {}).length > 0) {
            setUploadedBillData({});
          }
        }}
      />
      <div className="   ">
        <div className="flex justify-between items-center mb-10">
          <h3
            className={`text-[36px] font-medium text-[#00000099]
              ${billModalPdfView ? 'w-fit 1xl:w-[500px] xxl:w-[600px] overflow-hidden 3xl:w-[700px] 4xl:w-[800px] max-w-full 1xl:translate-x-full 1xl:ml-5' : ''}
              `}
          >
            {activeBillId ? 'Edit Bill' : 'Add a Bill'}
          </h3>
          <button
            onClick={() =>
              billModalPdfView
                ? setBillsWarningModalOpen(true)
                : setIsActive('')
            }
            type="button"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="6.17676"
                y="7.82422"
                width="2.3292"
                height="19.7982"
                rx="1.1646"
                transform="rotate(-45 6.17676 7.82422)"
                fill="#667085"
              />
              <rect
                x="20.177"
                y="6.17676"
                width="2.3292"
                height="19.7982"
                rx="1.1646"
                transform="rotate(45 20.177 6.17676)"
                fill="#667085"
              />
            </svg>
          </button>
        </div>
        {initialLoader ? (
          <div className="flex flex-col gap-2 items-center pb-2 justify-center mt-8 mb-5">
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="animate-spin w-10 h-10 fill-current shrink-0"
            >
              <path
                d="M14.5 2.41699V7.25033"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.5 21.749V26.5824"
                stroke="#E48642"
                strokeWidth="2.55882"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 5.95703L9.37662 9.37662"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 19.6211L23.0426 23.0407"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.41699 14.5H7.25033"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.749 14.5H26.5824"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.95703 23.0407L9.37662 19.6211"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.623 9.37662L23.0426 5.95703"
                stroke="#E48642"
                strokeWidth="1.58955"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="text-slate-600 font-medium text-[0.875rem] text-center">
              Fetching data, please wait...
            </p>
          </div>
        ) : (
          <div
            className={
              billModalPdfView ? 'flex 1xl:flex-row flex-col gap-6' : ''
            }
          >
            {billModalPdfView && (
              <div
                className={`relative 1xl:w-[500px] xxl:w-[600px] overflow-hidden 3xl:w-[700px] 4xl:w-[800px] max-w-full  1xl:max-h-none

                  ${
                    (uploadedBillData?.receiptUrl || activeBill?.receiptUrl)
                      ?.toLowerCase()
                      ?.endsWith?.('.pdf')
                      ? '  w-full'
                      : 'h-fit w-fit mx-auto'
                  }`}
              >
                <div
                  className={`1xl:fixed overflow-hidden top-24 left-8 1xl:w-[500px] xxl:w-[600px] 3xl:w-[700px] 4xl:w-[800px] max-w-full max-h-[30rem] 1xl:max-h-none border  border-gray-300 rounded-xl
                      ${
                        (uploadedBillData?.receiptUrl || activeBill?.receiptUrl)
                          ?.toLowerCase()
                          ?.endsWith?.('.pdf')
                          ? '1xl:max-h-[80dvh]  w-full'
                          : 'max-h-fit 1xl:max-h-fit w-fit mx-auto !overflow-auto scrollbar'
                      }`}
                  style={{
                    left: `${pdfBlockLeftDistance > 32 ? pdfBlockLeftDistance : 32}px`,
                    height: `${pdfBlockHeight}px`,
                  }}
                >
                  {(
                    uploadedBillData?.receiptUrl || activeBill?.receiptUrl
                  )?.endsWith?.('.pdf') ||
                  (
                    uploadedBillData?.receiptUrl || activeBill?.receiptUrl
                  )?.endsWith?.('.PDF') ? (
                    <iframe
                      src={
                        uploadedBillData?.receiptUrl || activeBill?.receiptUrl
                      }
                      title="Receipt PDF"
                      className="w-full h-full rounded-xl min-h-[35rem] 1xl:min-h-[40rem]"
                    />
                  ) : (
                    <img
                      src={
                        uploadedBillData?.receiptUrl || activeBill?.receiptUrl
                      }
                      alt="Uploaded receipt"
                      className=" max-w-full max-h-[inherit] mx-auto min-h-fit h-fit object-contain  rounded-xl"
                      id="billsModalViewImageId"
                    />
                  )}
                </div>
              </div>
            )}
            <Formik
              innerRef={formikRef}
              enableReinitialize
              initialValues={{
                vendorId: activeBill?.vendorId
                  ? activeBill?.vendorId
                  : uploadedBillData?.matchingVendor?.vendorId === 'null' ||
                      uploadedBillData?.matchingVendor?.vendorId ===
                        'undefined' ||
                      !uploadedBillData?.matchingVendor?.vendorId
                    ? null
                    : uploadedBillData?.matchingVendor?.vendorId,
                notes: uploadedBillData?.note || activeBill?.notes || '',
                recurring: activeBill?.recurring || false,
                firstDueDate: formatDateLocally(
                  activeBill?.instances?.[0]?.dueDate ||
                    uploadedBillData?.dueDate ||
                    new Date(),
                  teamDateFormat,
                ),
                approvalStatus: activeBill?.approvalStatus || 'approved',
                billNumber:
                  activeBill?.billNumber || uploadedBillData?.invoiceId || '',
                purchaseOrderNumber:
                  activeBill?.purchaseOrderNumber ||
                  uploadedBillData?.purchaseOrder ||
                  '',
                recurringFrequency: activeBill?.recurringFrequency || 'daily',
                ends: activeBill?.recurringLastDate ? 'on' : 'after',
                recurringLastDate: activeBill?.recurringLastDate || '',
                recurringOccurrences: activeBill?.recurringOccurrences || 1,
                firstBillDate: formatDateLocally(
                  activeBill?.firstBillDate ||
                    uploadedBillData?.invoiceDate ||
                    new Date(),
                  teamDateFormat,
                ),
                currency:
                  activeBill?.currency ||
                  uploadedBillData?.currency ||
                  team?.currency ||
                  localStorage.getItem(TEAM_CURRENCY) ||
                  '',
                PO: '',
              }}
              validationSchema={Yup.object({
                vendorId: Yup.string().required('Please Select Vendor Name'),
                firstDueDate: Yup.string().required('Please Select Due Date'),
                billNumber: Yup.string().max(
                  300,
                  'Bill Number must be 300 characters or less',
                ),
                purchaseOrderNumber: Yup.string().max(
                  300,
                  'Order Number must be 300 characters or less',
                ),
                notes: Yup.string().max(
                  300,
                  'Notes must be 300 characters or less',
                ),
              })}
              onSubmit={handleSubmit}
            >
              {(validation) => (
                <Form className=" flex-1 overflow-hidden">
                  <ReccuringModal
                    validation={validation}
                    isOpen={reccurringModalOpen}
                    setIsOpen={setReccurringModalOpen}
                    getWarningMessage={getWarningMessage}
                  />
                  <div className=" pb-1 grid grid-cols-12 gap-3 sm:gap-6 sm:gap-y-1 gap-y-1 mb-8">
                    {billModalPdfView && !activeBillId && (
                      <div className="col-span-full mb-4">
                        <WarningBox
                          isInfo
                          content={`COUNT AI has successfully extracted the key details from your uploaded bill and pre-filled the form below. Please review the information, make any necessary adjustments, and hit 'Create' to proceed!`}
                        />
                      </div>
                    )}
                    <div
                      className={`col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <div
                        className={`flex flex-col items-start w-full ${
                          validation.errors.vendorId ? '' : 'pb-5'
                        }`}
                      >
                        <label
                          className={`text-sm font-medium text-slate-600 block mb-2 ${
                            validation.touched.vendorId &&
                            validation.errors.vendorId
                              ? '!text-rose-400'
                              : ''
                          }`}
                          htmlFor="vendorId"
                        >
                          Vendor <span className="text-rose-500">*</span>
                        </label>
                        <MerchantsDropdown
                          vendors={vendors}
                          setVendors={setVendors}
                          inActiveVendor={
                            activeBill?.vendor?.status === 'inactive'
                              ? activeBill?.vendor
                              : {}
                          }
                          selectedMerchant={validation.values.vendorId}
                          setSelectedMerchant={(value) => {
                            validation.setFieldValue('vendorId', value);
                          }}
                          isSetCategoryStyle
                          height={`h-12 border-[#D0D5DD] ${
                            validation.touched.vendorId &&
                            validation.errors.vendorId
                              ? '!border-rose-400'
                              : ''
                          }`}
                          id="vendorId"
                          name="vendorId"
                          addNewOptionButton
                          handleNewOptionButton={() => {
                            setVendorModalOpen(true);
                          }}
                        />
                        {validation.touched.vendorId &&
                        validation.errors.vendorId ? (
                          <div className="text-xs text-rose-400 mt-[2px]">
                            {validation.errors.vendorId}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={`col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 pb-5  ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <DatePickerDash
                        label="Bill Date"
                        required
                        validation={validation}
                        placeholder="Select Date"
                        value={
                          validation.values.firstBillDate
                            ? validation.values.firstBillDate
                            : null
                        }
                        options={options}
                        onChange={(selectedDates) => {
                          validation.setFieldValue(
                            'firstBillDate',
                            selectedDates[0],
                          );
                        }}
                        name="firstBillDate"
                        id="firstBillDate"
                        error={
                          validation.touched.firstBillDate &&
                          validation.errors.firstBillDate
                            ? validation.errors.firstBillDate
                            : ''
                        }
                        onBlur={(e) => {
                          if (!e.target.classList?.value?.includes('active')) {
                            validation.setFieldValue(
                              'firstBillDate',
                              e.target.value,
                            );
                          }
                        }}
                      />
                    </div>
                    {/* ====== */}
                    <div
                      className={`col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 pb-5  ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <div className="flex flex-wrap gap-y-0 gap-4">
                        <div className="flex-1 ">
                          <DatePickerDash
                            label="Due Date"
                            required
                            validation={validation}
                            placeholder="Select Date"
                            value={
                              !validation.values.recurring &&
                              validation.values.firstDueDate
                                ? validation.values.firstDueDate
                                : null
                            }
                            options={
                              validation.values.firstBillDate
                                ? {
                                    ...options,
                                    minDate: validation.values.firstBillDate,
                                  }
                                : options
                            }
                            onChange={(selectedDates) => {
                              validation.setFieldValue(
                                'firstDueDate',
                                selectedDates[0],
                              );
                              if (validation.values.recurring) {
                                validation.setFieldValue('recurring', false);
                              }
                            }}
                            name="firstDueDate"
                            id="firstDueDate"
                            error={
                              validation.touched.firstDueDate &&
                              validation.errors.firstDueDate
                                ? validation.errors.firstDueDate
                                : ''
                            }
                            onBlur={(e) => {
                              if (
                                !e.target.classList?.value?.includes('active')
                              ) {
                                validation.setFieldValue(
                                  'firstDueDate',
                                  e.target.value,
                                );
                              }
                            }}
                          />
                        </div>
                        {/* <button
                      className={`h-12 mt-[30px] px-6 border-[1.3px] border-indigo-500
                      shadow-button rounded-[5px] font-medium text-indigo-500 ${
                        validation.values.recurring ? "bg-[#E486421A]" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setReccurringModalOpen(true);
                      }}
                      type="button"
                    >
                      Reccurring?
                    </button> */}
                      </div>
                    </div>
                    {validation.values.recurring &&
                      validation.values.firstDueDate &&
                      validation.values.recurringFrequency &&
                      (validation.values.ends ||
                        validation.values.recurringLastDate ||
                        validation.values.recurringOccurrences) && (
                        <div className="col-span-full xl:-my-4 ">
                          <WarningBox
                            content={getWarningMessage(
                              validation.values.firstDueDate,
                              validation.values.recurringFrequency,
                              validation.values.ends,
                              validation.values.recurringLastDate,
                              validation.values.recurringOccurrences,
                            )}
                            className="w-fit my-5 md:my-0 h-auto xl:ml-auto"
                          />
                        </div>
                      )}
                    {/* <div
                      className={`col-span-full sm:col-span-full md:col-span-6 xl:col-span-4  ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <label
                        className="block text-sm font-medium mb-2"
                        htmlFor="approved"
                      >
                        Approved
                      </label>
                      <DropdownFull
                        options={[
                          {
                            id: 'draft',
                            name: 'Draft',
                          },
                          {
                            id: 'approved',
                            name: 'Approved',
                          },
                        ]}
                        name="name"
                        setSelected={(id) =>
                          validation.setFieldValue('approvalStatus', id)
                        }
                        selected={validation.values.approvalStatus}
                        error={
                          validation.touched.approvalStatus &&
                          validation.errors.approvalStatus
                        }
                        accountStyle
                        className="!h-fit"
                        scrollIntoView={false}
                      />
                      {validation.touched.approvalStatus &&
                      validation.errors.approvalStatus ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.approvalStatus}
                        </div>
                      ) : null}
                    </div> */}
                    <div
                      className={`col-span-full sm:col-span-full md:col-span-6 xl:col-span-4  ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <TextInputDash
                        label="Bill Number"
                        id="billNumber"
                        name="billNumber"
                        placeholder="Bill Number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billNumber || ''}
                        error={
                          validation.touched.billNumber &&
                          validation.errors.billNumber
                        }
                      />
                    </div>
                    <div
                      className={`col-span-full sm:col-span-full md:col-span-6 xl:col-span-4  ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <TextInputDash
                        label="Purchase Order Number"
                        id="billNumber"
                        name="purchaseOrderNumber"
                        placeholder="Purchase Order Number"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.purchaseOrderNumber || ''}
                        error={
                          validation.touched.purchaseOrderNumber &&
                          validation.errors.purchaseOrderNumber
                        }
                      />
                    </div>
                    <div
                      className={`col-span-full sm:col-span-full md:col-span-6 xl:col-span-4 pb-5  ${billModalPdfView ? 'lg:col-span-4 1xl:!col-span-6  4xl:col-span-3' : ''}`}
                    >
                      <label
                        className="block text-sm font-medium mb-2"
                        htmlFor="currency"
                      >
                        Currency
                      </label>
                      <DropdownFull
                        options={currencies?.map((c) => ({
                          ...c,
                          id: c?.isoCode,
                          name: `${c?.symbol} ${c?.name}`,
                        }))}
                        name="name"
                        setSelected={(id) =>
                          validation.setFieldValue('currency', id)
                        }
                        selected={validation.values.currency}
                        error={
                          validation.touched.currency &&
                          validation.errors.currency
                        }
                        accountStyle
                        className="!h-fit"
                        scrollIntoView={false}
                      />
                      {validation.touched.currency &&
                      validation.errors.currency ? (
                        <div className="text-xs mt-1 text-rose-500">
                          {validation.errors.currency}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <CreateBillTable
                    allCategories={expenseCategories}
                    billRows={billRows}
                    setBillRows={setBillRows}
                    TABLE_ROW_SCHEMA={TABLE_ROW_SCHEMA}
                    isLineItemsError={false}
                    checkBillRowsError={checkBillRowsError}
                    tax={tax}
                    setTax={setTax}
                    discount={discount}
                    setDiscount={setDiscount}
                    currency={currencies?.find?.(
                      (c) => c.isoCode === validation.values.currency,
                    )}
                    ref={formikRef}
                    vendors={vendors}
                    setVendors={setVendors}
                    tags={tags}
                    customers={customers}
                    setCustomers={setCustomers}
                    projects={projects}
                  />

                  {/* Modal footer */}
                  <div className="px-5 pt-4 pb-20">
                    <div className="flex flex-wrap justify-end space-x-4">
                      <button
                        type="button"
                        className="btn-sm h-11 px-8 text-base border-[#667085]  hover:border-slate-800  text-[#667085]"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (billModalPdfView) {
                            setBillsWarningModalOpen(true);
                          } else {
                            setIsActive('');
                            if (
                              Object.keys(uploadedBillData || {}).length > 0
                            ) {
                              setUploadedBillData({});
                            }
                          }
                        }}
                      >
                        Close
                      </button>
                      {(!activeBillId ||
                        validation.values.approvalStatus === 'draft' ||
                        activeBill?.approvalStatus === 'draft') && (
                        <button
                          type="button"
                          className="btn-sm h-11 px-4 text-base border-indigo-500  text-indigo-500  disabled:cursor-not-allowed"
                          disabled={loading || draftLoading}
                          onClick={() => {
                            validation.setFieldValue('approvalStatus', 'draft');
                            validation.handleSubmit();
                          }}
                        >
                          {draftLoading && (
                            <svg
                              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                            </svg>
                          )}
                          Save As Draft
                        </button>
                      )}
                      {checkPermission(
                        PermissionKeys.APPROVE_BILLS,
                        accessType,
                        permissions,
                      ) && (
                        <button
                          type="button"
                          className="btn-sm h-11 px-8 text-base bg-indigo-500 hover:bg-indigo-600 text-white
              disabled:bg-indigo-400    disabled:cursor-not-allowed"
                          disabled={loading || draftLoading}
                          onClick={() => {
                            validation.setFieldValue(
                              'approvalStatus',
                              'approved',
                            );
                            validation.handleSubmit();
                          }}
                        >
                          {loading && (
                            <svg
                              className="animate-spin w-4 h-4 fill-current shrink-0 mr-2"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                            </svg>
                          )}
                          {activeBillId
                            ? `Update ${activeBill?.approvalStatus === 'draft' ? ' & Approve' : ''}`
                            : 'Create & Approve'}
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default BillsModal;
